.addsupplier__modal_wrap {
  min-height: 210px;
  width: 90%;
  max-width: 550px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
}
.addsupplier__header {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid lightgray;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 30px;
}
.addsupplier__title {
  font-size: 15px;
  margin-bottom: 0;
  color: #454545;
  font-weight: bold;
  text-transform: uppercase;
}
.addsupplier__body {
  /* display: flex;
    flex */
}

.addsupplier__inputwrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
}

.addsupplier__modalfooter {
  width: 100%;
  padding: 10px;
  height: 60px;
  border-top: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}

.addsupplier__modalsubmit {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c69f3;
  border: 1px solid #0c69f3;
  border-radius: 3px;
  margin-left: 10px;
  color: white;
  cursor: pointer;
  width: 70px;
  height: 35px;
  font-size: 13px;
}

.addsupplier__modalreset {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #454545;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  width: 70px;
  height: 35px;
  font-size: 13px;
}
