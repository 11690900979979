.addprod__formsection_wrap {
  width: 100%;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 0 5px 5px rgba(235, 235, 235, 0.616);
  padding: 20px;
  margin: 20px 0;
}

.addprod__fsidesection_wrap {
  width: 100%;
  min-width: 300px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 0 5px 2px rgba(235, 235, 235, 0.616);
  padding: 20px;
  margin: 20px 0;
}

.addprod__custominput_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.addprod__formsection_heading {
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 17px;
}

.addprod__custominput_title {
  font-size: 15px;
  padding-left: 5px;
}

.addprod__custominputfield {
  border: 1px solid rgb(190, 190, 190);
  width: 100%;
  border-radius: 3px;
  height: 40px;
  padding: 10px;
  margin: 5px 0;
  outline: none;
  font-size: 14px;
}

.addprod__custominputtextarea {
  border: 1px solid rgb(190, 190, 190);
  width: 100%;
  border-radius: 3px;
  height: 150px;
  padding: 10px;
  margin: 5px 0;
  outline: none;
  font-size: 14px;
}

.addprod__custominput_box {
  font-size: 14px;
}

.addprod__formsection_mediabox {
  width: 100%;
  height: 150px;
  border-radius: 5px;
  border: 1px dashed lightgray;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.addprod__formsec_uploadimagebtn {
  display: block;
  width: 170px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  border: 1px solid rgb(228, 226, 226);
  font-size: 15px;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
}

.addprod__formsec_addurlbtn {
  display: block;
  width: 170px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  border: 1px solid rgb(228, 226, 226);
  font-size: 15px;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
}

.addprod__formsection_mdbtnicon {
  margin-right: 5px;
}

.addprod__formsec_imagebtns {
  display: flex;
  margin-top: 15px;
}

.addprod__secformrow_images {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  margin: 15px 0;
}

.addprod__secformrow_imagewrap {
  width: 80px;
  height: 60px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  overflow: hidden;
  margin: 5px 0px;
  position: relative;
}

.addprod__secformrow_imageclose {
  position: absolute;
  top: 3px;
  right: 3px;
  padding: 2px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 1px 1px 3px 0px lightgrey;
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.addprod__secformrow_image {
  width: 100%;
  height: 80%;
  object-fit: cover;
}

.addprod__secformimg_setmainbtn {
  position: absolute;
  padding: 3px 8px;
  font-size: 10px;
  font-weight: 500;
  color: white;
  background-color: #0c6efd;
  width: 100%;
  bottom: 0;
}

.addprod__secformimg_mainbadge {
  position: absolute;
  padding: 3px 8px;
  font-size: 10px;
  font-weight: 500;
  color: white;
  box-shadow: 1px 1px 3px 2px lightgrey;
  background-color: grey;
  width: 100%;
  bottom: 0;
  pointer-events: none;
}

.addprod__formsec_addimgurl {
  display: flex;
  align-items: center;
}

.addprod__formsec_addimgurlfield {
  border: 1px solid lightgrey;
  border-radius: 5px;
  width: 300px;
  height: 35px;
  padding: 0 10px;
  margin-right: 5px;
  margin-left: 5px;
}

.addprod__formsec_addimgurbtn {
  border: 1px solid lightgrey;
  border-radius: 5px;
  font-size: 12px;
  height: 35px;
  width: 80px;
}

.addprod__formsec_addimgurlback {
  cursor: pointer;
}

.addprod__formsection_mdbtmtext {
  margin-top: 5px;
  font-size: 12px;
  color: grey;
}

.addprod__custominput_flexrow {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  position: relative;
}

.addprod__custominput_flexcol {
  flex: 1;
  min-width: 250px;
}

.addprod__custominput_flexcoldisabled {
  opacity: 0.7;
  pointer-events: none;
}

.addprod__customnoneditablefield {
  pointer-events: none;
  box-shadow: 1px 1px 2px 2px rgb(233, 233, 233) inset;
  width: 100%;
  border-radius: 10px;
  height: 40px;
  padding: 0 10px;
  margin: 5px 0;
  outline: none;
}

.addprod__formsection_addvarbtn {
  padding: 4px 8px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background-color: rgb(236, 234, 234);
  font-size: 13px;
  font-weight: 500;
}

.addprod__formsection_addvarbtnicn {
  margin-right: 3px;
}

.aprodforms__addvarr_section {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid lightgrey;
}

.addprod__custominputfield_cloneablewrap {
  width: 100%;
}

.addprod__custominputfield_cloneable {
  display: flex;
  width: 100%;
  align-items: center;
}

.addprod__custominputfield_cloneicn {
  color: grey;
  margin-left: 10px;
  cursor: pointer;
}

.addprod__custominputfield_cloneicn:active {
  transform: scale(0.96);
}

.addpaddvar__done_btb {
  padding: 3px 8px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  font-size: 14px;
  float: right;
}

.addpaddvar__done_btbcancel {
  padding: 3px 8px;
  margin: 0 5px;
  border: 1px solid lightgrey;
  background-color: lightgrey;
  border-radius: 5px;
  font-size: 14px;
  float: right;
}

.addprod__variants_attribute {
  width: 100%;
  min-height: 70px;
  border: 0.5px solid lightgrey;
  margin-bottom: 5px;
  padding: 5px 10px;
  border-radius: 5px;
}

.addprod__variants_attributetop {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.addprod__variants_attributbottom {
  display: flex;
  margin-top: 5px;
}

.addprod__variants_atrbtmvalue {
  padding: 2px 5px;
  margin: 0 2px;
  background-color: rgba(233, 231, 231, 0.534);
  font-size: 12px;
  border-radius: 3px;
}

.variants__combinations_table {
  margin-top: 30px;
  width: 100%;
  font-size: 14px;
}

.variants__combinations_table thead tr td {
  background-color: rgb(231, 230, 230);
  padding: 5px;
}

.variants__combinations_table td {
  padding: 0 5px;
  min-width: fit-content;
}

.addprod__custominput_supcostsection {
  margin-bottom: 20px;
  padding: 10px 0;
  border-bottom: 2px solid lightgrey;
  background-color: rgba(240, 238, 238, 0.322);
  border-radius: 5px;
  position: relative;
}

.addprod__custominput_supcostsectionclose {
  position: absolute;
  right: 5px;
  top: 8px;
  cursor: pointer;
}

.addprod__idensec_delrowicn {
  position: absolute;
  right: 15px;
  top: 5px;
  cursor: pointer;
}

.addprod__custominput_titleflex {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.addprod__cusinputitle_addnewopt {
  font-size: 14px;
  font-weight: 500;
  color: #0c6efd;
  cursor: pointer;
}

.addprod__prodcombo_table {
  width: 100%;
}

thead .addprod__prodcombo_trow {
  background-color: #e7e6e6;
}

.addprod__prodcombo_tcell {
  padding: 3px 8px;
  max-width: 300px;
  text-align: center;
}

.addprod__prodcombo_tcelldel {
  margin: auto;
  cursor: pointer;
}

.addprod__custominputfield_error {
  font-size: 13px;
  font-weight: 500;
  color: tomato;
  display: flex;
  align-items: center;
}

.addprod__custominputfield_erroricn {
  font-size: 15px;
  margin-right: 2px;
  color: tomato;
}

.addprod__custominputfielderror {
  border-color: tomato;
}

@media only screen and (max-width: 768px) {
  .variants__combinations_table_wrap {
    width: 100%;
    overflow: auto;
    padding-bottom: 10px;
  }

  table.variants__combinations_table {
    width: 740px;
    font-size: 12px;
  }

  .add__prod_bottomsec {
    font-size: 13px;
  }

  .addprod__custominput_flexcol {
    padding: 0;
  }

  .addprod__custominput_flexrow {
    column-gap: 10px;
  }

  .addprod__formsection_addvarbtn {
    margin-left: 0;
  }
}