.proddetails__desc_modal {
  min-height: 250px;
  max-width: 920px;
  width: 90%;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
}
.notes_attachments_wrap .invmodal__shshiptracking_grid.shipment_tracking_wrap {
  grid-template-columns: 115px 80px 71px 129px;
}
.proddetails__desc_modal .swiper-backface-hidden .swiper-slide {
  display: flex;
}
.proddetails__desc_modalheading {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid lightgray;
  font-weight: 500;
  font-size: 12px;
  padding-bottom: 5px;
}
.proddetails__desc_modalcontent {
  padding: 20px 0 10px 0;
}
.proddetails__desc_modalcontent  .swiper-button-prev:after,.proddetails__desc_modalcontent  .swiper-button-next:after{
  font-size: 17px;
  color: #000;
}
.proddetails__desc_modalcontent.warehouse_cust_prod {
  padding: 0;
}

.proddetails__desc_modalcontent.warehouse_cust_prod .invmodal__shipment_sumitemhding {
  margin: 0px 0 4px 0;
    font-size: 11px;
    background: #dcdddf;
    margin-right: 2px;
    padding: 2px 7px;
    font-weight: 600;
}

.proddetails__desc_modalcontent  .swiper-thumbs .swiper-slide {
  width: 60px !important;
  height: 60px;
}

.proddetails__desc_modalcontent  .swiper-thumbs img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.proddetails__desc_modalcontent  .swiper-slide-thumb-active {
  border: 1px solid #1976d2;
}
.proddetails__desc_modalcontent  .swiper-slide{
  cursor:pointer;
}
.proddetails__desc_modalcontent  .swiper-thumbs .swiper-wrapper{
  justify-content: center;
}
.proddetails__desc_modalfooter {
  width: 100%;
  padding: 10px;
  height: 60px;
  border-top: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.proddetails__desc_modalcncl {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #454545;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}
.proddetails__desc_modalsave {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c69f3;
  border: 1px solid #0c69f3;
  border-radius: 3px;
  margin-left: 10px;
  color: white;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .proddetails__desc_modal{
    height: 90vh;
    overflow: auto;
  }
}