/* brands list  header  */
.brands_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: grey;
  margin: 20px 5px 0px 5px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid lightgrey;
}
.brands_hdrheading {
  font-weight: 400 !important;
  font-size: 22px;
}
.brands_buttonsWrapper {
  display: flex;
  gap: 20px;
}
.brands_hdraddprod {
  border-radius: 5px;
  box-shadow: 0.3px 0.3px 3px 1px rgb(230, 228, 228);
  display: flex;
  padding: 7px 8px;
  align-items: center;
  font-weight: 500;
  font-size: 8px;
  text-decoration: none;
  color: grey;
  cursor: pointer;
}
.brands_hdraddprod:hover {
  color: grey;
}
.brands_hdraddprod > * {
  margin-right: 3px;
}
.brandStock__main_wrap {
  display: flex;
  align-items: flex-start;
  column-gap: 30px;
  flex-wrap: wrap;
}
.brandStock__table_list {
  width: 100%;
}
.brandStock__add_new_brand_modal_wrap {
  min-height: 250px;
  min-width: 310px;
  width: 550px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
}

.brandStock__add_new_brand_modal_wrap .file__upload_csv_row label {
  padding: 9px 15px;
}

.brandStock__add_new_brand .brandStock__add_new_brand_title {
  font-size: 15px;
  margin-bottom: 0;
  color: #454545;
  font-weight: bold;
  text-transform: uppercase;
}

.brandStock__add_new_brand .brandStock__add_new_brand_name {
  width: 49%;
  margin-bottom: 20px;
}

.brandStock__add_new_brand .brandStock__add_new_brand_code {
  width: 49%;
  margin-bottom: 20px;
}

.brandStock__add_new_brand .file__upload_csv_row {
  width: 100%;
  margin-bottom: 20px;
}

.brandStock__add_new_brand .brandStock__add_new_brand_create_btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
  color: #fff !important;
  background: #1976d2;
  padding: 8px 10px;
  column-gap: 6px;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;
  margin-top: 20px;
  margin-left: auto;
}
.brandStock__table_list_image_list {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
  width: fit-content;
}

.brandStock__add_new_brand_header {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid lightgray;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 30px;
}

.brandStock__add_new_brand_body {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 2%;
}
button.brandStock__add_new_brand_create_btn {
  border-radius: 5px;
  box-shadow: 0.3px 0.3px 3px 1px rgb(230, 228, 228);
  display: flex;
  padding: 8px 18px;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  color: grey !important;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-bottom: 15px;
  margin-top: 15px;
  column-gap: 5px;
  cursor: pointer !important;
}
@media only screen and (max-width: 768px) {
  .brandStock__add_new_brand {
    order: 1;
    margin-bottom: 30px;
  }

  .brandStock__table_list {
    order: 2;
  }
  .brands_buttonsWrapper span {
    display: none;
}

.brands_header {
    margin-bottom: 15px;
}
}
@media only screen and (min-width: 1190px) {
  .brands_hdrheading{
    font-size: 1.311vw;
  }
  .brands_hdraddprod{font-size: 1.025vw;padding: 0.439vw 0.586vw}
  .brands_hdraddprod svg {width: 1.025vw; height: 1.025vw;}
}