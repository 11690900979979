/* .admnclntreg__deluser_btn {
  padding: 5px 10px;
  color: white;
  background-color: #be2143;
  border-radius: 5px;
} */

.warehouse_clients_shipment_status {
  border: 1px solid grey;
  border-radius: 2px;
  color: #000;
  padding: 3px 6px;
  text-transform: capitalize;
  font-size: 8px;
}
.warehouse_clients__page_wrap {
  margin: auto;
  max-width: 97%;
  padding: 10px 20px;
}
.warehouse_clients__adpage_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: grey;
  margin: 20px 5px 0px 5px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid lightgrey;
}
.warehouse_clients__adpage_hdrheading {
  font-weight: 400 !important;
  font-size: 22px;
}

@media only screen and (min-width: 1190px) {
  .warehouse_clients__adpage_hdrheading{
    font-size: 1.611vw;
  }
}

