/* header  */
.payment__approve_header {
  display: flex;
  justify-content: space-between;
  align-items: end;
  color: grey;
  margin: 0px 5px 0px 5px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid lightgrey;
}
.payment__approve_heading {
  font-weight: 400 !important;
  font-size: 22px;
}

/* add balance  */
.payment__approve_box {
  width: 280px;
  height: 110px;
  margin-left: 20px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  box-shadow: -2px -2px 5px 1px rgba(197, 196, 196, 0.75);
  -webkit-box-shadow: -2px -2px 5px 1px rgba(197, 196, 196, 0.75);
  -moz-box-shadow: -2px -2px 5px 1px rgba(197, 196, 196, 0.75);
  padding: 13px 15px;
  padding-right: 0;
  color: rgb(163, 162, 162);
  border-radius: 5px;
  margin-bottom: 5px;
}
.payment__approve_boxleft {
  height: 100%;
  flex: 1 1;
  min-width: -moz-fit-content;
  min-width: fit-content;
}
.payment__approve_boxleftprice {
  font-size: 32px;
  margin-top: 8px;
  margin-left: 2px;
  color: #454545;
  font-weight: 500;
}
.payment__approve_boxleftprice > span {
  font-size: 14px;
  font-weight: 600;
  color: #0c6efd;
  margin-left: 15px;
  position: relative;
  bottom: 5px;
}
.payment__approve_boxright {
  width: 90px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.payment__approve_boxbtn {
  background-color: white;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 15px;
  padding-left: 5px;
  box-shadow: 2px 2px 5px 2px rgba(197, 196, 196, 0.75);
  -webkit-box-shadow: 2px 2px 5px 2px rgba(197, 196, 196, 0.75);
  -moz-box-shadow: 2px 2px 5px 2px rgba(197, 196, 196, 0.75);
}
.payment__approve_boxbtn:hover {
  box-shadow: 0px 0px 5px 2px rgba(197, 196, 196, 0.75);
  -webkit-box-shadow: 0px 0px 5px 2px rgba(197, 196, 196, 0.75);
  -moz-box-shadow: 0px 0px 5px 2px rgba(197, 196, 196, 0.75);
}
.payment__approve_boxbtn:active {
  transform: scale(0.95);
}
.payment__approve_boxbtn > * {
  margin-right: 5px;
}

@media only screen and (max-width: 992px) {
  .payment__approve_box {
    width: fit-content;
    height: auto;
  }
  .payment__approve_boxleftlbl {
    font-size: 12px;
  }
  .payment__approve_boxleftprice {
    font-size: 18px;
  }
  .payment__approve_boxright {
    width: 35px;
    height: 35px;
    margin-top: 20px;
  }
  .payment__approve_boxbtn {
    width: 100%;
    height: 100%;
  }
}


@media only screen and (min-width: 1190px) {
  .payment__approve_box{
    font-size: 0.878vw;
    height: auto;
    width: fit-content;
    padding: 1.098vw;
    column-gap: 1.098vw;
  }
  .payment__approve_boxleftprice{
    font-size: 1.464vw;
    margin-top: 0;
  }
  .payment__approve_boxright{
    width: fit-content;
    height: auto;
  }
  .payment__approve_boxbtn {
    width: 1.83vw;
    height: 1.83vw;
  }
  .payment__approve_heading{
    font-size: 1.611vw;
  }
  .payment__approve_boxbtn svg {
    width: 0.805vw;
    height: 0.805vw;
}
}


@media only screen and (max-width: 768px) {
  .payment__approve_heading{
    font-size: 15px;
  }
  .payment__approve_box {
    margin: 0 0 0 10px;
    padding: 4px 8px;
}
.payment__approve_boxleftprice{
  margin: 0;
}
.payment__approve_boxright {
  width: 25px;
  height: 25px;
}

.payment__approve_boxright svg {
  width: 15px;
  height: 15px;
}
.payment__approve_header {
  margin-bottom: 8px;
}
.dispatcher__screen{
  padding: 20px 15px;
}
}


