.navbar-nav .nav-link.active {
  color: #1976d2 !important;
  font-weight: bold;
  /* text-decoration-line: underline ! important;
      text-decoration-thickness: 5px ! important;
      text-decoration-skip-ink: 1; */
  /* border-top: 3px solid; */
  border-bottom: 5px solid;
  border-top-left-radius: 100px;
  /* border-radius: 15px; */
  /* bottom: -10px; */
}

.navbar .navbar-nav .nav-link:hover {
  color: #1976d2 !important;
  /* font-weight: bold; */
}

.overlaybtninvoice {
  color: #000000;
}

.overlaybtninvoice:hover {
  color: #ffffff !important;
  background-color: #6d90c4 !important;
  border-color: #6d90c4 !important;
}

.overlaybtn {
  color: #000000;
}

.overlaybtn:hover {
  color: #ffffff !important;
  background-color: #1976d2 !important;
  border-color: #1976d2 !important;
}

.fade-in-form {
  flex: 1;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.btn-close {
  border: 2px solid #e2edfb !important;
  border-radius: 25% !important;
  width: 2px;
  color: #000000;
}

.btn-close:hover {
  color: #ffffff !important;
  background-color: #0c6efd !important;
}

.modal-dialog.modal-xl {
  max-width: 1293px !important;
  margin-top: 6rem;
}

.forget {
  color: #0c6efd;
}

.forget:hover {
  color: #000000;
  text-shadow: #edf0f3;
  cursor: pointer;
}

.signup {
  color: #0c6efd;
  text-decoration-line: none;
}
.address_feild {
  max-width: 50% !important;
}

.signup:hover {
  color: #000000;
  text-shadow: #edf0f3;
  cursor: pointer;
}

input[type="time" i] {
  /* align-items: center;
      display: inline-flex;
      font-family: monospace; */
  /* padding-inline-start: 1px; */
  /* cursor: default;
      overflow: hidden; */
  /* padding: 0px; */
}

.o::-webkit-calendar-picker-indicator {
  filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg)
    brightness(95%) contrast(80%);
  /* width: 64px;
      height: 64px;
      background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAEr0lEQVR4nO2af2hVZRjHP88550I/zNrCiLaUpBYkJYYWNmtFUU3UchPJzGGJs1oRhRDDJkWaYLAERdugZG70hwyj1BY0Y6VWWCEUEqbVH1v900hI/8ndc57+uLvRLtvded9zzr2i5wOXcc7e9/k+93mf57nnPedASkpKSsqli5TbgZkv6fTsCFsR6oDrLc2cVej3Alp/2yUnTSaWNQA3Pae3InwNVMRiUPjLdZh9ersMhZ3ixSJsiSdsUaUC6HOUtb6DI0o3UCfQdWqXrA5j5+YXtZosnSj1BLQDy8P64Ni5Hg+uz4NeAIzQ/PO78vsvO2XQ8XnWC8ANeDSsndPbZegyYZ0XgBfwiIkPViUwa50uJuAVhHnAlTY2QqAnOsVogWY1qwKc6JTQ38u4BO5Yq2/h0wqAms42wnhx3MBcxCgAd67RxRrQCpwXaFOl5/j78oe5bHHmrFGr0LoWs4wC4ObSHhE2HntPtprLhXTKMrNs5pkGYC4CrkM3wN2rdaEoHQjV5tJFsEhlKEEJuDAFhaOjaZ9ROhSqE+4FoSlFCYzBCXIr/2V3+K4bhvtWWfaApDOgsMZsa9VUJ8l5kTLAJuI2OknOMwuAFj+OC1u7ifeAtATSEih+nGfDYT2KcE9Is0c2L5B7w9htXK6Po6xSUFW6P+yVj8LMK0YiGVDph/7yAAsm0wFY3qgt6rPjf6caly3Tlt5e2TmZP8Uw2m15OrbOCo/zVI6YfSbTWdmgta7yTv68B695imYCtq1s0NrJ/Cn6nUwGhy2Ba7NmToyjcxa46skGnRFkGRFlrwuZ/P979snmp5bqNcB6YO8TS3Se45Fxcv78baKVTAlEDYDPAYQVLvyAAAFTgQHg/vyYoTO0zria9cANrvATfm77rHDARCuRDKjMcoRxansCDheeuMKn5R+XCsjdFVL4zHdZ4fkM58cMDEj2mSX/OTB19G9f4NASUhcwvQ4Iih/nuW3p2K5uyq6Dcgaof7pep0mGzO6Pc5uv5kVjI57XH/G5LuOhnftluNDWZFyQV4J5dvfJn2H0OwvGmZBIAF5YaLab2/FJuN1kEguQSAlMdD4qYfWNbJoMDrsC2z6N9/6Aqb4JF+ReoJT6iWRA60N2d3QK2dI/NpPKngFpDwi5Am9+frH2gBJfB5RCP22CRoNLdEuslPppBhg5cKn3gHFScAio3jE/nt/9SVFAGJzIHxuiPRpTmkXpAG6M7kooBsWheSJ/bIhUAs9/I33A9Ohu2FH2n8Fy0jVfq7Lj3FA1JVIPKCd6nqY4XnGLVALloGu2VrkeTSivx2HPqgQ+mKN1orwKzAWmxeGIEX58puwyQBmIzwVjhoHvRXlbhf6oxmy3w+cF2hyPnseOx/+WWFj23R69Jq0yQISNS35M7i2xsJRtO+z57IkuHZ04AmD2cDTgnBeAE5T/Nfv9NVo1+m6w0bPAQowC4CrfugoiNEURjYPLHZrc3NPg76LYMWuCPu0qPAC8cahG8YU9D58sbRM8VKNVAk0Eo9cBSnsUe8ap/MUtugnYEEU0RjbVnZK2KAasavmrmbpIhZeBu4ApURyw4BxwDKW99lc5WGLtlJSUlJSLin8BxSm4QhC/BVoAAAAASUVORK5CYII=");
     */
}

.o::-webkit-calendar-picker-indicator:hover {
  cursor: pointer;
}

.c::-webkit-calendar-picker-indicator {
  filter: invert(18%) sepia(93%) saturate(5628%) hue-rotate(359deg)
    brightness(97%) contrast(123%);
}

.c::-webkit-calendar-picker-indicator:hover {
  cursor: pointer;
}

.marketplace:hover {
  cursor: pointer;
  border: 3px solid #0c6efd !important;
  transform: translateY(1px);
  transition: 0.5s;
}

.navigation:hover {
  cursor: pointer;
  color: #0c6efd;
  transform: translateY(1px);
  transition: 0.5s;
}

.check_button_true {
  color: #0c6efd;
}

.check_button_false {
  color: #ffffff;
}

.horiscl::-webkit-scrollbar {
  height: 10px !important;
}

/* Track */
.horiscl::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 2px;
}

/* Handle */
.horiscl::-webkit-scrollbar-thumb {
  background: #c8c9ca;
  border-radius: 2px;
}

/* Handle on hover */
.horiscl::-webkit-scrollbar-thumb:hover {
  background: #c8c9ca;
}

.single_list {
  transition: transform 0.2s ease-out;
}

.single_list:hover {
  transform: scale(1.05);
}

.kgEPYv:hover {
  background-color: #ecf4ff !important;
  border: none !important;
}

.kgEPYv {
  border: 1px solid #e5e7eb !important;
  margin-top: 1rem;
}

.gZWIKX {
  border-bottom-color: #ffffff !important;
}

.jxflYm {
  border-top-color: #ffffff !important;
}

.active > .page-link,
.page-link.active {
  background-color: #1976d2 !important;
}

/* .MuiFormHelperText-root {
  color: #1976D2 !important;
} */
.marketplace__page_header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
}
.marketplace__page_headericon > img {
  width: 70px;
  object-fit: contain;
}
.marketplace__page_headertxt {
  font-size: 22px;
  color: #454545;
  margin: 10px 0;
  font-weight: 500;
  text-transform: uppercase;
}
.marketplace__page_headertxt > span {
  color: #0c6efd;
}
.shipper__marketplace_cardwrap {
  width: 300px;
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  -webkit-box-shadow: 2px 2px 11px -2px rgba(189, 189, 189, 0.9);
  -moz-box-shadow: 2px 2px 11px -2px rgba(189, 189, 189, 0.9);
  box-shadow: 2px 2px 11px -2px rgba(189, 189, 189, 0.9);
  transition: transform 0.5s ease-out;
  position: relative;
  cursor: pointer;
}

.shipper__marketplace_cardimg > img {
  max-width: 160px;
  max-height: 180px;
  object-fit: contain;
}

.shipper__marketplace_cardwrap:hover {
  transform: scale(1.1);
}

.display__data_profile {
  display: flex;
  align-items: center;
  column-gap: 20px;
  padding: 30px;
  margin-top: 40px;
}

.profile__content_details span {
  font-weight: 600;
  width: fit-content;
  display: inline-block;
  margin-bottom: 2px;
  padding-right: 10px;
}

.profile__content_details {
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
}

.profile__info_name {
  display: flex;
  width: 50%;
}

.profile__info_role {
  display: flex;
  width: 50%;
}

.profile__info_username {
  display: flex;
  width: 50%;
}

.profile__info_email {
  display: flex;
  width: 50%;
}
.profile__content_details > small {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.profile__data_edit {
  width: calc(100% - 70px);
  display: flex;
  flex-wrap: wrap;
  align-items: self-start;
}

.profile__data_edit > h3 {
  width: 100%;
  margin: 0;
  font-size: 22px;
  line-height: 1;
}

.profile__data_edit > small {
  width: 100%;
}

.form__profile_field_wrap {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2%;
  width: 100%;
}

.btn__footer_sec {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  column-gap: 15px;
}

.fieldset__list_col {
  width: 32%;
}

.fieldset__list_col .form-control:disabled {
  background: #ccc !important;
  cursor: not-allowed;
}

.profile__edit_content_wrap {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 30px;
  margin-top: 30px;
}

.profile__image_block {
  position: relative;
  border-radius: 180px;
  overflow: hidden;
  width: 170px;
}
.btn_Icon_edit {
  cursor: pointer;
}
.btn__cancle_wrap.btn.btn-primary {
  color: #454545;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
}
button.image__icon_upload.btn.btn-light {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  background: #000000bf;
  border: none;
  outline: none;
  box-shadow: none;
  color: #bfbfbf;
}
.additonal_info_list_view {
  display: flex;
  width: 100%;
  padding-top: 20px;
  /* flex-wrap: wrap;
  padding-bottom: 20px;
  column-gap: 50px; */
}

.additonal_info_list_item {
  display: flex;
  width: 100%;
  /* max-width: 280px; */
  max-width: 400px;
  column-gap: 16px;
  margin-bottom: 20px;
}
.additonal_info_list_item_address {
  max-width: 100% !important;
}

.additonal_info_list_item_label {
  width: 100%;
  max-width: 135px;
  font-size: 16px;
  font-weight: 500;
  flex: 6;
}

.additional_info_wrap.bg-white.shadow {
  margin-top: 40px;
  padding: 35px;
}
.profile_buttons {
  display: flex !important;
  justify-content: center;
}

.additonal_info_list_item_data {
  /* font-size: 16px; */
  font-size: 14px;
  font-weight: 100;
  flex: 4;
}

.additional_info_list_edit_form {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  align-items: flex-end;
}

.field__main_additional_info_footer_submit {
  width: 100%;
  /* max-width: 516px; */
  display: flex;
  justify-content: flex-end;
  column-gap: 19px;
}
.field__main_additional_info {
  width: 100%;
  max-width: 250px;
}
.field__main_additional_info label.form-label {
  margin-bottom: 5px;
  margin-top: 10px;
}
.field__main_additional_info label.form-label {
  margin-bottom: 5px;
  margin-top: 10px;
}

.additional_info_wrap.bg-white.shadow small {
  display: flex;
  justify-content: space-between;
}
.additional_info_list_edit_btn {
  cursor: pointer;
  font-size: 16px;
}
.stockcount__attachment_modal_main .MuiPaper-elevation {
  height: 20rem;
}
.stockcount__attachment_modal_main .uploaded__attachments_listitem {
  width: 100%;
  max-width: 140px;
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  cursor: pointer;
}
.stockcount__attachment_modal_main .MuiDialogContentText-root {
  display: flex;
  flex-wrap: wrap;
}
form.passwor__edit_wrap {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  margin-top: 20px;
}

form.passwor__edit_wrap .additonal_info_list_item {
  width: 100%;
  max-width: 335px;
  /* align-items: center; */
  align-items: start;
}

form.passwor__edit_wrap .additonal_info_list_item label.form-label {
  width: 100%;
  max-width: fit-content;
}

.bg-white.shadow.password__sec_wrap {
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 35px;
}

form.passwor__edit_wrap .additonal_info_list_item:last-child {
  width: 100%;
  max-width: 100%;
  justify-content: flex-end;
  margin-top: 10px;
}

.bg-white.shadow.password__sec_wrap > small {
  display: flex;
  justify-content: space-between;
}

.bg-white.shadow.password__sec_wrap .password__display_text {
  display: flex;
  padding: 8px 0;
  align-items: center;
}
.password_editable {
  cursor: pointer;
  font-size: 16px;
}

/*marketplace styling*/
.marketplace_list_wrap {
  display: flex;
  flex-wrap: wrap;
  max-width: 810px;
  column-gap: 30px;
  row-gap: 30px;
  margin: 0 auto;
  justify-content: center;
}

.marketplace_list_box {
  display: inline-flex;
  align-items: center;
  width: 250px;
  border-radius: 10px;
  box-shadow: 2px 2px 11px -2px hsla(0, 0%, 74%, 0.9);
  cursor: pointer;
  height: 170px;
  justify-content: center;
  position: relative;
  transition: -webkit-transform 0.5s ease-out;
  transition: transform 0.5s ease-out;
  transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
  position: relative;
  overflow: hidden;
}

.marketplace_list_box img {
  width: 100%;
  max-width: 120px;
}

.marketplace_list_box:before {
  content: "Coming Soon";
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #000000a6;
  transition: ease all 0.2s;
  opacity: 0;
}
.label_background {
  background: #ccc;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.marketplace_list_box:hover:before {
  opacity: 1;
}

.marketplace_list_box.available_marketplace:hover {
  transform: scale(1.05);
}
.marketplace_list_box.available_marketplace:before {
  display: none;
}
@media only screen and (max-width: 768px) {
  .display__data_profile,
  .profile__edit_content_wrap {
    flex-wrap: wrap;
  }
  .profile__content_details {
    margin-top: 20px;
  }
  .profile__edit_content_wrap .fieldset__list_col {
    width: 100%;
    /* max-width: calc(50% - 4px); */
  }

  .profile__data_edit {
    margin-top: 20px;
    width: 100%;
  }
  .field__main_additional_info_footer_submit {
    margin-top: 10px;
  }
  .field__main_additional_info {
    width: 100%;
    /* max-width: 152px; */
    max-width: 100%;
  }

  .profile__edit_content_wrap .fieldset__list_col label.form-label {
    margin-top: 10px;
  }

  .profile__info_name,
  .profile__info_role,
  .profile__info_username,
  .profile__info_email {
    width: 100%;
    max-width: 320px;
  }
  form.passwor__edit_wrap .additonal_info_list_item label.form-label {
    width: fit-content;
  }
  .marketplace__page_header {
    text-align: center;
    max-width: 95%;
    margin: 0 auto;
  }

  .marketplace_list_wrap {
    justify-content: center;
    width: 90%;
    column-gap: 15px;
    row-gap: 15px;
  }

  .marketplace_list_box {
    width: 130px;
    height: 110px;
  }

  .marketplace_list_box img {
    max-width: 100px;
  }
  .additonal_info_list_view {
    flex-wrap: wrap;
  }
}

@media only screen and (min-width: 1170px) {
  .marketplace__page_headericon > img {
    width: 5.124vw;
  }

  .marketplace__page_headertxt {
    font-size: 1.611vw;
    margin: 0.732vw 0;
  }

  .marketplace_list_box {
    width: 18.302vw;
    height: 12.445vw;
  }

  .marketplace_list_wrap {
    max-width: 59.3vw;
  }

  .marketplace_list_box img {
    max-width: 8.785vw;
  }
}
