.invoice__modal_wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1160px;
  width: 90%;
  background-color: white;
  border-radius: 5px;
  color: black;
  font-family: "Arial";
  margin-top: 20px;
  margin-bottom: 20px;
  max-height: 90vh;
  overflow: hidden;
}

.parcel__history_icone {
  cursor: pointer;
  margin-left: 5px;
}

.parcel__history_icone:hover {
  color: #0d6efd !important;
}

.fpinvoice__modal_wrap {
  width: 1160px;
  height: fit-content;
  background-color: white;
  box-shadow: 24;
  border-radius: 5px;
  color: black;
  font-family: "Arial";
  margin-top: 45px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.fpinvoice__modal_box {
  height: fit-content;
  padding: 40px 80px;
  width: fit-content;
}

.invoice__modal_box {
  position: relative;
  max-height: inherit;
  height: 100%;
  overflow: auto;
  padding: 40px 80px;
}

/* .printable__invoice {
  transform: scale(0.6);
  transform-origin: top left;
  width: 170% !important;
  height: 100% !important;
  overflow: visible !important;
} */
.invoice__modal_closeinvoicewrap {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.invoice__modal_downloadinvoicewrap {
  position: absolute;
  left: 0px;
  top: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* set the width of the scrollbar */
.invoice__modal_box::-webkit-scrollbar {
  width: 5px;
}

/* set the color of the thumb (the draggable part of the scrollbar) */
.invoice__modal_box::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
}

/* set the color of the track (the non-draggable part of the scrollbar) */
.invoice__modal_box::-webkit-scrollbar-track {
  background-color: #dbd9d9;
}

.invoicemdl__download_btn {
  display: flex;
  align-items: center;
  font-size: 14px;
  background-color: #0c69f3;
  color: white;
  padding: 2px 10px;
  border-radius: 3px;
  margin: 0 5px;
  border: 1px solid #0c69f3;
  transition: 0.5s;
}

.invoicemdl__download_btn svg {
  margin-right: 3px;
}

.invoicemdl__download_btn_danger {
  display: flex;
  align-items: center;
  font-size: 14px;
  background-color: #be2143;
  color: white;
  padding: 2px 10px;
  border-radius: 3px;
  margin: 0 5px;
  border: 1px solid #be2143;
  transition: 0.5s;
}

.invoicemdl__download_btn_danger svg {
  margin-right: 3px;
}

.invoicemdl__download_btnoutlined {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #0c69f3;
  background-color: white;
  padding: 2px 10px;
  border-radius: 3px;
  margin: 0 5px;
  border: 1px solid #0c69f3;
  transition: 0.5s;
}

.invoicemdl__download_btnoutlined svg {
  margin-right: 3px;
}

/* .invoicemdl__download_btn:hover,
.invoicemdl__download_btnoutlined:hover {
  letter-spacing: 0.5px;
} */
.invoice__modal_topbar {
  width: 100%;
  height: 30px;
  background: #0c69f3;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.invoice__modal_header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 0;
}

.invoice__modal_headerleftimg {
  height: 27px;
  object-fit: contain;
}

.invmodal__hleft_infoitems {
  margin-top: 20px;
}

.invmodal__hleft_infoitem {
  margin-bottom: 5px;
  font-size: 13px;
}

.invmodal__hleft_amntitems {
  margin-top: 30px;
}

.warehouse_cust_prod .invmodal__hleft_amntitem {
  display: flex;
  font-size: 10px;
  padding: 2px 4px;
  justify-content: space-between;
  background: #acdff9;
  margin-bottom: 0;
}

.invmodal__hleft_amntitemleft {
  width: 270px;
}

.bold_700 {
  font-weight: 700;
}

.invmodal__hright_infoitemleft {
  width: 130px;
}

.invmodal__hright_infoitemright {
  color: #000;
  display: flex;
}

.invmodal_hright_contacthding {
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  color: #131466;
  margin-top: 30px;
  margin-bottom: 10px;
}

.invmodal_hright_contactitem {
  font-size: 13px;
  margin-top: 5px;
}

.invmodal__shipment_summary {
  padding-bottom: 5px;
}

.warehouse_cust_prod .invmodal__shipment_summaryhding {
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase;
  color: #131466;
  background: #dcdddf;
  margin-right: 2px;
  padding: 2px 7px;
  width: -moz-fit-content;
  width: 99%;
}

.invmodal__shipment_summarycntnt {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.invmodal__shipment_summarycntnt>div {
  flex: 1;
}

.invmodal__shipment_sumitemhding {
  font-size: 18px;
  text-transform: uppercase;
  color: #131466;
  margin-bottom: 18px;
}

.invmodal__shipment_sumitem {
  display: flex;
  font-size: 11px;
  width: fit-content;
  margin-bottom: 6px;
}

.invmodal__shipment_sumitemleft {
  width: 70px;
  font-weight: 700;
}

.invmodal__international_sumitemleft {
  width: 150px;
  font-weight: 700;
}

.invmodal__shipment_details {
  padding: 15px 0 0 0;
}

.warehouse_cust_prod .invmodal__shipment_detailshding {
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase;
  color: #131466;
  background: #dcdddf;
  margin-right: 2px;
  padding: 2px 7px;
  width: -moz-fit-content;
  width: 99%;
}

.invmodal__shdetails_grid {
  display: grid;
  margin-top: 10px;
  grid-template-columns: 90px 125px 120px 125px 120px 120px 90px 90px;
}

.invmodal__shbilling_grid .invmodal__shdetails_gridhd {
  font-size: 13px;
  font-weight: 600;
  padding: 8px;
  box-sizing: border-box;
  border-bottom: 1.42px solid #d9d9d9;
  display: table-cell;
}

.invmodal__shdetails_table {
  margin-top: 10px;
}

.invmodal__shbilling_grid tr {
  position: relative;
}

.invmodal__shdetails_tablehd {
  font-size: 10px;
  display: table-cell;
  font-weight: 600;
  padding: 8px;
  box-sizing: border-box;
  border-bottom: 1.42px solid #d9d9d9;
}

.invmodal__shdetailgi_carrlogo {
  height: 20px;
  object-fit: contain;
  max-width: 80%;
}

.invmodal__shdetails_modaltxtrtcopy {
  margin-top: 3px;
  cursor: pointer;
}

.invmodal__shdetails_tableitem {
  padding: 8px 10px;
  font-size: 13px;
  position: relative;
}

.invmodal__shdetails_griditem {
  padding: 8px;
  font-size: 13px;
  position: relative;
}

.invmodal__shdetails_griditem>span {
  position: relative;
}

.invmodal__shdetails_griditemdisabled {
  width: 100%;
  height: 1px;
  background-color: black;
  top: 50%;
  position: absolute;
}

.pinvmodal__shdetails_griditemdisabled {
  width: 100%;
  height: 1px;
  background-color: black;
  top: 88%;
  position: absolute;
}

.invmodal__shdetails_content {
  display: flex;
  padding: 20px 0;
}

.invmodal__shdetails_content>div {
  flex: 1;
  position: relative;
}

.invmodal__shdetails_cntleft {
  min-width: fit-content;
}

.invmshdetails_cnleft_heading {
  width: 255px;
  display: flex;
  justify-content: center;
  margin-left: auto;
  font-weight: 700;
  font-size: 13px;
  padding-bottom: 5px;
  border-bottom: 0.5px solid #0c69f3;
}

.invmshdetails_cnleft_grid {
  display: grid;
  grid-template-columns: 60px 120px 120px 180px 120px 80px 80px;
}

.invmshdetails_cnleft_footer {
  font-size: 13px;
  height: 35px;
  align-items: center;
  background: #e3eefe;
  border: 1px solid rgba(0, 0, 0, 0.57);
  margin-top: 5px;
}

/* .content_center {
  display: flex;
  justify-content: center;
} */
.invmodal__shdetails_tableitem {
  min-width: 50px;
  max-width: 300px;
}

.invmshdetails_cnleft_fitemttl {
  font-weight: bold;
  padding-left: 15px;
}

.add__dimension_button {
  width: 70%;
  height: 35px;
  margin-left: 30px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  background: #fee7e7;
  border: 1px dashed #d21c1c;
  font-weight: 700;
  font-size: 12px;
  color: #d21c1c;
  cursor: pointer;
  user-select: none;
}

.add__dimension_buttonicon {
  margin-right: 5px;
}

.invmodal__shbilling_grid {
  /* display: grid; */
  margin: 10px 0 15px 0;
  /* grid-template-columns: 80px 90px 150px 100px 100px 105px; */
}

.invmodal__shdetails_addtxtbtn {
  border: 1px dashed #000000;
  width: fit-content;
  padding: 4px 10px;
  margin-top: -8px;
  cursor: pointer;
  user-select: none;
}

.invbilled__invoice_button {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fee7e7;
  border: 1px dashed #d21c1c;
  font-weight: 700;
  font-size: 12px;
  color: #d21c1c;
  cursor: pointer;
  margin: 20px 0 20px 0;
  user-select: none;
}

.invmodal__note_box {
  width: 100%;
  padding: 15px;
  overflow-y: auto;
  margin-bottom: 20px;
  margin-top: 10px;
}

.invmodal__note_boxhding {
  font-weight: 700;
  font-size: 12.5px;
  color: #000000;
}

.invmodal__note_boxdesc {
  font-size: 12px;
}

.invmodal__shshiptracking_grid {
  display: grid;
  margin-top: 10px;
  grid-template-columns: 160px 160px 130px 535px;
  padding-bottom: 80px;
}

.invmodal__invoicedashed_line {
  width: 100%;
  height: 1px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%231F1F1FFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.invmodal__bottom_titletxt {
  margin: 10px;
  font-size: 11.9333px;
  display: flex;
  justify-content: flex-end;
}

.invmodal_footer {
  margin: 20px 0;
}

.invmodal_footertop {
  display: flex;
  height: 80px;
}

.invmodal_footertop_left {
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
}

.invmodal_footertop_right {
  display: grid;
  grid-template-columns: 140px 150px 120px 100px;
  font-size: 16px;
}

.invmodal_footertop_ritemdanger {
  color: teal;
}

.invmodal_footerbottom {
  width: 100%;
}

.invmodal__paymentterms_box {
  width: 100%;
  height: 80px;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.57);
}

.invmodal__paymentterms_boxleft {
  border-right: 1px solid rgba(0, 0, 0, 0.57);
  width: 70%;
  height: 100%;
  padding: 15px 20px;
  padding-right: 18%;
  font-size: 12px;
  background: #e3eefe;
}

.invmodal__paymentterms_boxright {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  font-size: 12px;
  width: 30%;
  position: relative;
}

.invmodal__paymentterms_boxright>div {
  position: relative;
  top: 2px;
  width: fit-content;
}

.invmodal__paymentterms_brapprice {
  font-size: 28px;
  font-weight: bold;
}

.invmodal__paymentterms_boxleft>span {
  min-width: fit-content;
}

.invmodal__paymentterms_brapprice>span {
  font-size: 25px;
}

.invoice__modal_downloadinvoiceicon {
  margin: 0 5px;
}

.invoice__modal_downloadinvoiceicon2 {
  margin: 0 5px;
}

.invmodal__shdgi_input {
  border: 1px solid lightgrey;
  text-align: center;
  padding: 1px 3px;
  outline: none;
}

.payments__add_invchargebtn {
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #0c69f3;
  color: white;
  margin-left: 5px;
  cursor: pointer;
  font-size: 16px;
  text-transform: capitalize;
}

.invmodal__charge_desc {
  width: 100%;
  display: flex;
  height: 80px;
  border: 1px solid lightgrey;
  padding: 5px 10px;
  outline: none;
  border-radius: 5px;
}

.invmodal__blfinaltotal_row {
  font-weight: 600;
  border-top: 1px solid black;
}

.invmodal__shdetails_sidebadge {
  position: absolute;
  background-color: white;
  padding: 3px 5px;
  border-radius: 5px;
  right: 100.5%;
  width: fit-content;
  overflow-wrap: normal;
  font-size: 10px;
  box-shadow: -2px 0px 3px 1px rgb(226, 226, 226);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
}

.invmodal__shdetails_sidebadge::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%) rotateX(45deg);
  border-top: 8px solid transparent;
  border-left: 6px solid rgba(36, 91, 211, 0.384);
  border-bottom: 8px solid transparent;
  animation: moveLeftRight 0.7s infinite ease-in-out;
}

.invmodal__note_boxdescitem {
  margin-top: 2px;
}

.invmodal__note_boxdescitem>b {
  margin-right: 5px;
  margin-top: 2px;
}

.invmodal__shdetails_tablehdinner {
  display: flex;
}

.update__charge_reasonbox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.update__charge_reasonbox>div {
  flex: 1;
}

.update__charge_reasonboxrt {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0.7 !important;
}

.update_charge_attachment {
  display: flex;
  width: 100%;
  height: 80px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: grey;
  border: 1px dashed grey;
  border-radius: 5px;
  padding: 8px 10px;
  cursor: pointer;
}

.update_charge_atchmentimg {
  width: 30px;
  height: 30px;
  margin: 2px 0;
}

.update__charge_rblftbtns {
  padding: 10px;
  display: flex;
}

.update__charge_rblftbtncncl {
  width: 80px;
  height: 35px;
  background-color: lightgrey;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border-radius: 5px;
}

.update__charge_rblftbtnsave {
  width: 80px;
  height: 35px;
  background-color: #0c6efd;
  color: white;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.invmoda__note_atchmentdownloadwrap {
  position: relative;
}

.invmoda__note_atchmentdownload {
  color: grey;
  cursor: pointer;
  display: inline;
  margin: 0 5px;
}

.invmodal__shippymnt_verifiedtoggle {
  width: 90%;
  color: grey;
  font-size: 15px;
  font-weight: normal;
  cursor: pointer;
}

.invmodal__shippymnt_atchmentbtn {
  color: grey;
  font-size: 15px;
  font-weight: normal;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  border: 1px solid lightgrey;
  width: 90%;
  height: 40px;
  border-radius: 3px;
}

.invmodal__shippymnt_atchmentbtn>span {
  margin-left: 5px;
}

.invmodal__shippymnt_atbtnloaderwrap {
  width: 25px;
  height: 25px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 2px 0 0 2px;
}

.invmodal__shippymnt_verifiedtoggle .MuiSelect-standard {
  font-size: 12px;
  line-height: 15px;
}

.invmodal__paymentterms_brprevcharge {
  /* position: absolute !important; */
  /* left: 110px; */
  text-decoration: line-through;
  color: grey;
  top: 18px !important;
  font-size: 15px;
  font-weight: normal;
  overflow-wrap: normal !important;
}

.invmodal__paymentterms_editcount {
  position: relative;
  top: -16px;
  left: 2px;
  font-size: 12px;
}

.invmodal__hleft_amntitemright {
  font-weight: 600;
}

.invmodal__hleft_airprevcharge {
  position: relative;
  color: grey;
  left: 10px;
  text-decoration: line-through;
  font-size: 14px;
  font-weight: normal;
}

.invmodal__shdetails_newchargebtn {
  font-size: 11px;
  border: 0.5px solid #0c6efd;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: #0c6efd;
  color: white;
  min-width: fit-content;
}

.invmodal__shippymnt_attachmenicon {
  color: grey;
  font-size: 15px;
  font-weight: normal;
  margin: 0 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.invmodal__editinv_actionbtns {
  display: inline;
  font-weight: normal !important;
  margin-left: 25px;
}

.invmodal__shippymnt_attachmenbadge {
  font-size: 9px;
  min-width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  background-color: #0c69f3;
  color: white;
  border-radius: 50%;
  position: absolute;
  right: -15px;
  top: -8px;
}

.invmodal__shippymnt_attachmenbadge2 {
  font-size: 8px;
  min-width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px;
  background-color: #0c69f3;
  color: white;
  border-radius: 50%;
  position: absolute;
  right: -10px;
  top: -0px;
}

.invmodal__paymentterms_linethrough {
  position: relative;
  text-decoration: none;
}

.invmodal__paymentterms_linethrough::before {
  content: "";
  position: absolute;
  bottom: 150%;
  /* Adjust as needed */
  left: 0;
  right: 0;
  height: 1px;
  /* Line thickness */
  background-color: black;
}

.invmodal__paymentterms_linethrough2 {
  position: relative;
  text-decoration: none;
}

.invmodal__paymentterms_linethrough2::before {
  content: "";
  position: absolute;
  bottom: 10%;
  /* Adjust as needed */
  left: 0;
  right: 0;
  height: 1px;
  /* Line thickness */
  background-color: black;
}

.invmodal__einvactions_content {
  margin: 20px 0 10px 0;
  display: flex;
  width: 100%;
}

.invmodal__einvactions_contentlft {
  flex: 1;
  display: flex;
  align-items: flex-end;
}

.invmodal__einvactions_contentrt {
  flex: 1;
  padding-right: 50px;
  display: flex;
  align-items: flex-end;
  width: 50%;
  color: grey;
}

.invmodal__shippymnt_atchmentbtnwrap {
  width: 100%;
  position: relative;
}

.deatil_modal_footer_btn_save {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #eaeaea;
  padding-top: 10px;
  margin-top: 10px;
}

.deatil_modal_footer_btn_save .prodidentifier__desc_modalsave {
  height: 30px;
  width: 70px;
  font-size: 14px;
}

.invmodal__spnote_tablecell {
  padding: 2px 5px;
  max-width: 450px;
  min-width: 80px;
  vertical-align: top;
}

.notes__attachments_modal_attachment_thumbnail2>img {
  margin: 2px 5px;
}

.invmodal__shippymnt_addattachmnetbtn2 {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
}

.invmodal__shippymnt_addattachmnetbtnwrap {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

.invmodal__einvactions_contentrtformgroup {
  width: 100%;
}

.invmodal__einvactions_contentrtformgroup>label {
  display: block;
}

.invmodal__einvactions_contentrtformfieldwrap {
  width: 100%;
  display: flex;
}

th.invmodal__spnote_tablecell {
  border-bottom: 1px solid #e4e4e4;
}

.invmodal__spnote_tablecell .bolt__tablemax_fixed_height {
  max-width: 210px;
}

.invmodal__note_box.invmodal__note_user_box {
  padding: 0;
  border: none;
}

.notes__attachments_modal_attachment_thumbnail2 {
  column-gap: 6px;
}

.notes__attachments_modal_attachment_thumbnail.notes__attachments_modal_attachment_thumbnail img {
  border: 1px solid #dfdfdf;
  border-radius: 0;
  padding: 5px;
  margin: 0;
  max-width: fit-content !important;
  max-height: fit-content !important;
  width: 35px;
  height: 35px;
}

.notes__attachments_modal_attachment_thumbnail2 .invmodal__shippymnt_addattachmnetbtn2 {
  background: #eaeaea;
  border-radius: 0;
  width: 35px;
  height: 35px;
}

.notes__attachments_modal_attachment_thumbnail2 .invmodal__shippymnt_addattachmnetbtnwrap {
  width: fit-content;
  height: fit-content;
}
.proddetails__desc_modalcontent .invmodal__shdetails_content table.invmshdetails_cnleft_table td.ordedetail__shdetails_tableitem svg {
  min-width: 13px;
}

.proddetails__desc_modalcontent .invmodal__shdetails_content table.invmshdetails_cnleft_table td.ordedetail__shdetails_tableitem [aria-label="Click To View History"] svg{
margin-left: 0;
}
.invoice__modal_box.printable__invoice.invoice__shipment_style_optamize_wrap .invmodal__shdetails_tableitem svg {
  position: relative;
  bottom: -3px;
  width: 9px;
}
.invoice__modal_box.printable__invoice.printable__order__details .ordedetail__shdetails_tableitem svg{
  position: relative;
  bottom: -2px;
  width: 9px;
  min-width:9px ;
}
@keyframes moveLeftRight {
  0% {
    transform: translateX(0) translateY(-50%) rotateX(45deg);
  }

  50% {
    transform: translateX(0px) translateY(-50%) rotateX(45deg);
  }

  100% {
    transform: translateX(0.7px) translateY(-50%) rotateX(45deg);
  }
}

@media only screen and (max-width: 600px) {
  .invoice__modal_box {
    padding: 45px 20px 20px 20px;
  }

  .invmodal__shipment_summarycntnt>div {
    flex: auto;
    margin-bottom: 20px;
  }

  .invmodal__shipment_sumitem {
    width: 100%;
  }

  .invmodal__shipment_sumitemleft {
    width: 30%;
  }

  .invmodal__shipment_sumitemright {
    width: 70%;
  }

  .invmodal__hleft_amntitem {
    flex-wrap: wrap;
  }

  .invmodal__hleft_amntitemleft {
    width: 100%;
  }

  .invoivemdl__btn_dwn_text {
    display: none;
  }

  .notes__attachments_modal_attachment_thumbnail2 .invmodal__shippymnt_addattachmnetbtnwrap {
    width: 30px;
  }

  .invmodal__shdetails_content.package__details_row_custom {
    overflow: auto;
    width: 95%;
    margin: 0 auto;
  }

  .invmodal__shdetails_content.package__details_row_custom .invmodal__shdetails_cntleft {
    min-width: 630px;
  }

  .invmodal__shdetails_content.package__details_row_custom .invmodal__shipment_packagedetails_heading {
    position: sticky;
    left: 0;
  }

  .invmodal__shdetails_content.package__details_row_custom td.invmodal__shdetails_tableitem {
    font-size: 10px;
  }

  .invmodal__shdetails_content.package__details_row_custom .invmshdetails_cnleft_heading {
    font-size: 10px;
  }

  .invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__editableinv_actions .invmodal__einvactions_content {
    flex-wrap: wrap;
  }

  .static__invoice_wrap .invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__einvactions_contentrt {
    width: fit-content;
  }
}

/*modified styling of invoice*/
.invoice__modal_box.invoice__shipment_style_optamize_wrap {
  padding: 0;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invoice__modal_topbar {
  display: none;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invoice__modal_downloadinvoicewrap {
  border-bottom: 1px solid #bfbfbf;
  padding: 5px 0 10px 0;
  width: 95%;
  margin: 0 auto !important;
  position: relative;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invoice__modal_header {
  width: 95%;
  margin: 0 auto;
  padding-bottom: 10px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invoice__modal_header .invmodal__hleft_amntitems {
  margin: 0;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invoice__modal_header .invmodal__hleft_amntitem {
  justify-content: space-between;
  font-size: 10px;
  background: #ccc;
  padding: 2px 10px 2px 10px;
  align-items: center;
  display: flex;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__hleft_amntitem.total__amount_wrap {
  align-items: center;
  background: #acdff9;
  border: 3px solid #fff;
  outline: 1px solid #a5a5a5;
  padding: 0 3px 2px 3px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invoice__modal_header .invmodal__hleft_amntitem .invmodal__hleft_amntitemleft {
  padding: 0;
  line-height: 1.8;
  width: fit-content;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invoice__modal_header .invmodal__hleft_amntitemright span.invmodal__hleft_airprevcharge {
  font-size: 10px;
  left: 2px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invoice__modal_header .invmodal__hleft_amntitemright {
  font-weight: 500;
  line-height: 1;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__hright_infoitem {
  width: 50%;
  margin: 0;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__hright_infoitem .invmodal__hright_infoitemleft {
  font-size: 10px;
  width: fit-content;
  padding: 0 3px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__hright_infoitem .invmodal__hright_infoitemright {
  font-size: 10px;
  width: fit-content;
  padding: 0 3px;
  color: #000;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__hright_infoitems {
  display: flex;
  flex-wrap: wrap;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap img.invoice__modal_headerleftimg {
  margin-bottom: 10px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invoice__modal_headerleft {
  width: 70%;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invoice__modal_headerright {
  width: 30%;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__hright_infoitem .invmodal__hright_infoitemleft {
  width: 50%;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__hright_infoitem .invmodal__hright_infoitemright {
  width: 50%;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invoice__modal_header_logo_wrap {
  width: 100%;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__hright_infoitem .invmodal__hright_infoitemleft {
  width: 50%;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__hright_infoitem .invmodal__hright_infoitemright {
  width: 50%;
}

.invoice__modal_header_logo_wrap {
  width: 100%;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__shipment_summary {
  width: 95%;
  margin: 0 auto;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__shipment_summary .invmodal__shipment_sumitemhding {
  margin: 0px 0 4px 0;
  font-size: 11px;
  background: #dcdddf;
  margin-right: 2px;
  padding: 2px 7px;
  font-weight: 600;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__shipment_summary .invmodal__shipment_sumitemleft {
  font-size: 10px;
  color: #000;
  font-weight: 600;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__shipment_summary .invmodal__shipment_sumitemright {
  font-size: 10px;
  color: #000;
  font-weight: 400;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invoice__modal_downloadinvoicewrap labeltxt {
  font-size: 11px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invoice__modal_downloadinvoicewrap svg {
  width: 13px;
  height: 13px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__shipment_summary .invmodal__shipment_summarycntnt {
  margin-top: 0;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__shipment_details {
  width: 95%;
  margin: 0 auto;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__shipment_details .invmodal__shipment_detailshding {
  margin: 0px 0 4px 0;
  font-size: 11px;
  background: #dcdddf;
  margin-right: 2px;
  padding: 2px 7px;
  font-weight: 600;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__shipment_details .invmodal__shdetails_tableitem {
  font-size: 9px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__shipment_details .invmodal__shdetails_tablehd {
  font-size: 10px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__shipment_details .invmshdetails_cnleft_heading {
  font-size: 11px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__editableinv_actions {
  width: 95%;
  margin: 0 auto;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__editableinv_actions .invmodal__shipment_detailshding {
  margin: 0px 0 4px 0;
  font-size: 11px;
  background: #dcdddf;
  margin-right: 2px;
  padding: 2px 7px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__editableinv_actions .invmodal__einvactions_content {
  margin-top: 0;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__editableinv_actions .invmodal__shipment_detailshding button {
  padding: 0;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__editableinv_actions .invmodal__shipment_detailshding button svg {
  width: 17px;
  height: 17px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__editableinv_actions .invmodal__einvactions_content .invmodal__shippymnt_verifiedtoggle {
  font-size: 9px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__editableinv_actions .invmodal__einvactions_content .invmodal__shippymnt_verifiedtoggle label {
  font-size: 10px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__shippymnt_atchmentbtnwrap label {
  font-size: 10px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__shipment_details_wrap {
  width: 95%;
  margin: 0 auto;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__shipment_details_wrap .invmodal__shipment_detailshding {
  margin: 0px 0 4px 0;
  font-size: 11px;
  background: #dcdddf;
  margin-right: 2px;
  padding: 2px 7px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__shipment_details_wrap .invmodal__shdetails_gridhd {
  font-size: 10px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__shipment_details_wrap .invmodal__shdetails_griditem {
  font-size: 10px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__shipment_details_wrap .invmodal__shdetails_griditem span {
  font-size: 10px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__note_box {
  width: 95%;
  margin: 0 auto 15px auto;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__note_box .invmodal__note_boxhding {
  font-size: 10px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__note_box .invmodal__note_boxdesc {
  font-size: 10px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__invoicedashed_line {
  width: 95%;
  margin: 0 auto;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__bottom_titletxt {
  width: 95%;
  margin: 10px auto 0px auto;
  font-size: 10px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal_footer {
  width: 95%;
  margin: 20px auto;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal_footer .invmodal_footertop_ritem {
  font-size: 10px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal_footer .invmodal_footertop_right {
  grid-template-columns: 100px 100px 100px 100px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__shipment_details_wrap .invmodal__shshiptracking_grid {
  grid-template-columns: 160px 160px 130px 353px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal_footer .invmodal_footertop {
  height: 50px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal_footer .invmodal__paymentterms_boxleft {
  font-size: 10px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal_footer .invmodal__paymentterms_boxright {
  font-size: 10px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal_footer .invmodal__paymentterms_brapprice {
  font-size: 18px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal_footer .invmodal__paymentterms_brapprice span {
  font-size: 18px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__editableinv_actions textarea.invmodal__charge_desc {
  font-size: 10px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__editableinv_actions label {
  font-size: 10px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__editableinv_actions .invmodal__einvactions_content .invmodal__shippymnt_verifiedtoggle .MuiInputBase-root {
  font-size: 10px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__editableinv_actions label.invmodal__shippymnt_addattachmnetbtn2 svg {
  width: 14px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__editableinv_actions label.invmodal__shippymnt_addattachmnetbtn2 {
  font-size: 10px;
  height: 30px;
  width: 20px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal_footer .invmodal__paymentterms_brapprice span sup.invmodal__paymentterms_editcount {
  top: -10px;
  left: -3px;
  font-size: 10px;
}

.invoice__billing_admin_wrap {
  width: 95%;
  margin: 0 auto;
}

.invoice__billing_admin_wrap .invmodal__shbilling_grid .invmodal__shdetails_gridhd {
  font-size: 10px;
}

.invoice__billing_admin_wrap .invmodal__shdetails_griditem {
  font-size: 10px;
}

.invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__editableinv_actions .invmodal__einvactions_content .invmodal__einvactions_contentrtformfieldwrap .MuiInputBase-root {
  font-size: 11px;
}

.invmodal__note_user_box.invoice__modal_user_box .invmodal__spnote_tablecell {
  font-size: 10px;
}

.invmodal__note_user_box.invoice__modal_user_box .invmodal__spnote_tablecell {
  font-size: 9px;
  border: 1px solid #ababab;
}

/*full page invoice */
.fpinvoice__modal_box.shipment__fp_invoice_custom_box {
  padding: 0 0 30px 0;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invoice__modal_topbar {
  display: none;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invoice__modal_downloadinvoicewrap {
  border-bottom: 1px solid #bfbfbf;
  padding: 5px 0 10px 0;
  width: 95%;
  margin: 0 auto !important;
  position: relative;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invoice__modal_header {
  width: 95%;
  margin: 0 auto;
  padding-bottom: 10px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invoice__modal_header .invmodal__hleft_amntitems {
  margin: 0;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invoice__modal_header .invmodal__hleft_amntitem {
  justify-content: space-between;
  font-size: 10px;
  background: #ccc;
  padding: 2px 10px 2px 10px;
  align-items: center;
  display: flex;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__hleft_amntitem.total__amount_wrap {
  align-items: center;
  background: #acdff9;
  border: 3px solid #fff;
  outline: 1px solid #a5a5a5;
  padding: 0 3px 2px 3px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invoice__modal_header .invmodal__hleft_amntitem .invmodal__hleft_amntitemleft {
  padding: 0;
  line-height: 1.8;
  width: fit-content;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invoice__modal_header .invmodal__hleft_amntitemright span.invmodal__hleft_airprevcharge {
  font-size: 10px;
  left: 2px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invoice__modal_header .invmodal__hleft_amntitemright {
  font-weight: 500;
  line-height: 1;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__hright_infoitem {
  width: 50%;
  margin: 0;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__hright_infoitem .invmodal__hright_infoitemleft {
  font-size: 10px;
  width: fit-content;
  padding: 0 3px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__hright_infoitem .invmodal__hright_infoitemright {
  font-size: 10px;
  width: fit-content;
  padding: 0 3px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__hright_infoitems {
  display: flex;
  flex-wrap: wrap;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box img.invoice__modal_headerleftimg {
  margin-bottom: 10px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invoice__modal_headerleft {
  width: 70%;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invoice__modal_headerright {
  width: 30%;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__hright_infoitem .invmodal__hright_infoitemleft {
  width: 50%;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__hright_infoitem .invmodal__hright_infoitemright {
  width: 50%;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invoice__modal_header_logo_wrap {
  width: 100%;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__hright_infoitem .invmodal__hright_infoitemleft {
  width: 50%;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__hright_infoitem .invmodal__hright_infoitemright {
  width: 50%;
}

.invoice__modal_header_logo_wrap {
  width: 100%;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__shipment_summary {
  width: 95%;
  margin: 0 auto;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__shipment_summary .invmodal__shipment_sumitemhding {
  margin: 0px 0 4px 0;
  font-size: 11px;
  background: #dcdddf;
  margin-right: 2px;
  padding: 2px 7px;
  font-weight: 600;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__shipment_summary .invmodal__shipment_sumitemleft {
  font-size: 10px;
  color: #000;
  font-weight: 600;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__shipment_summary .invmodal__shipment_sumitemright {
  font-size: 10px;
  color: #000;
  font-weight: 400;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invoice__modal_downloadinvoicewrap labeltxt {
  font-size: 11px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invoice__modal_downloadinvoicewrap svg {
  width: 13px;
  height: 13px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__shipment_summary .invmodal__shipment_summarycntnt {
  margin-top: 0;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__shipment_details {
  width: 95%;
  margin: 0 auto;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__shipment_details .invmodal__shipment_detailshding {
  margin: 0px 0 4px 0;
  font-size: 11px;
  background: #dcdddf;
  margin-right: 2px;
  padding: 2px 7px;
  font-weight: 600;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__shipment_details .invmodal__shdetails_tableitem {
  font-size: 9px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__shipment_details .invmodal__shdetails_tablehd {
  font-size: 10px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__shipment_details .invmshdetails_cnleft_heading {
  font-size: 11px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__editableinv_actions {
  width: 95%;
  margin: 0 auto;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__editableinv_actions .invmodal__shipment_detailshding {
  margin: 0px 0 4px 0;
  font-size: 11px;
  background: #dcdddf;
  margin-right: 2px;
  padding: 2px 7px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__editableinv_actions .invmodal__einvactions_content {
  margin-top: 0;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__editableinv_actions .invmodal__shipment_detailshding button {
  padding: 0;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__editableinv_actions .invmodal__shipment_detailshding button svg {
  width: 17px;
  height: 17px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__editableinv_actions .invmodal__einvactions_content .invmodal__shippymnt_verifiedtoggle {
  font-size: 9px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__editableinv_actions .invmodal__einvactions_content .invmodal__shippymnt_verifiedtoggle label {
  font-size: 10px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__shippymnt_atchmentbtnwrap label {
  font-size: 10px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__shipment_details_wrap {
  width: 95%;
  margin: 0 auto;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__shipment_details_wrap .invmodal__shipment_detailshding {
  margin: 0px 0 4px 0;
  font-size: 11px;
  background: #dcdddf;
  margin-right: 2px;
  padding: 2px 7px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__shipment_details_wrap .invmodal__shdetails_gridhd {
  font-size: 10px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__shipment_details_wrap .invmodal__shdetails_griditem {
  font-size: 10px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__shipment_details_wrap .invmodal__shdetails_griditem span {
  font-size: 10px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__note_box {
  width: 95%;
  margin: 0 auto 15px auto;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__note_box .invmodal__note_boxhding {
  font-size: 10px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__note_box .invmodal__note_boxdesc {
  font-size: 10px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__invoicedashed_line {
  width: 95%;
  margin: 0 auto;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__bottom_titletxt {
  width: 95%;
  margin: 10px auto 0px auto;
  font-size: 10px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal_footer {
  width: 95%;
  margin: 20px auto;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal_footer .invmodal_footertop_ritem {
  font-size: 10px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal_footer .invmodal_footertop_right {
  grid-template-columns: 100px 100px 100px 100px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__shipment_details_wrap .invmodal__shshiptracking_grid {
  grid-template-columns: 160px 160px 130px 353px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal_footer .invmodal_footertop {
  height: 50px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal_footer .invmodal__paymentterms_boxleft {
  font-size: 10px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal_footer .invmodal__paymentterms_boxright {
  font-size: 10px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal_footer .invmodal__paymentterms_brapprice {
  font-size: 18px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal_footer .invmodal__paymentterms_brapprice span {
  font-size: 18px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__editableinv_actions textarea.invmodal__charge_desc {
  font-size: 10px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__editableinv_actions label {
  font-size: 10px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__editableinv_actions .invmodal__einvactions_content .invmodal__shippymnt_verifiedtoggle .MuiInputBase-root {
  font-size: 10px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__editableinv_actions label.invmodal__shippymnt_addattachmnetbtn2 svg {
  width: 14px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__editableinv_actions label.invmodal__shippymnt_addattachmnetbtn2 {
  font-size: 10px;
  height: 30px;
  width: 20px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal_footer .invmodal__paymentterms_brapprice span sup.invmodal__paymentterms_editcount {
  top: -10px;
  left: -3px;
  font-size: 10px;
}

.invoice__billing_admin_wrap {
  width: 95%;
  margin: 0 auto;
}

.invoice__billing_admin_wrap .invmodal__shbilling_grid .invmodal__shdetails_gridhd {
  font-size: 10px;
}

.invoice__billing_admin_wrap .invmodal__shdetails_griditem {
  font-size: 10px;
}

.fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__editableinv_actions .invmodal__einvactions_content .invmodal__einvactions_contentrtformfieldwrap .MuiInputBase-root {
  font-size: 11px;
}

.fpinvoice__modal_wrap.shipment__fp_invoice_print_wrap,
.invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invoice__modal_headerleftimg {
  height: 27px !important;
}

.invoice__modal_box.printable__invoice.invoice__shipment_style_optamize_wrap .invmodal__paymentterms_linethrough::before {
  display: none !important;
}

.invoice__modal_box.printable__invoice.invoice__shipment_style_optamize_wrap .invmodal__paymentterms_brapprice {
  top: 0 !important;
}

.invoice__modal_box.printable__invoice.invoice__shipment_style_optamize_wrap span.invmodal__paymentterms_brprevcharge.invmodal__paymentterms_linethrough {
  position: relative;
  top: 0 !important;
  text-decoration: none !important;
  line-height: 0 !important;
  overflow-wrap: inherit !important;
}

.invoice__modal_box.printable__invoice.invoice__shipment_style_optamize_wrap span.invmodal__paymentterms_brprevcharge.invmodal__paymentterms_linethrough::after {
  content: '';
  position: absolute;
  bottom: 10%;
  left: 0;
  right: 0;
  height: 1px;
  background-color: grey;
}

.invmodal__shdetails_content.package__details_row_custom {
  flex-wrap: wrap;
}

.invmodal__shdetails_content.package__details_row_custom .invmodal__shipment_packagedetails_heading {
  margin: 0px 0 4px 0;
  font-size: 11px;
  background: #dcdddf;
  margin-right: 2px;
  padding: 2px 7px;
  font-weight: 600;
  text-transform: uppercase;
  color: #131466;
  width: 100%;
  flex: auto;
}

.invmodal__shipment_summaryhding.internation__declaration_header {
  font-size: 11px;
  margin: 12px 0 2px 0;
}

.invoice__modal_box.printable__invoice.invoice__shipment_style_optamize_wrap .invmodal__shipment_summaryhding.internation__declaration_header {
  font-size: 11px;
  margin-bottom: 5px;
}

.invoice__modal_box.printable__invoice.invoice__shipment_style_optamize_wrap .invmodal__shipment_packagedetails_heading {
  font-size: 11px;
}

@media only screen and (max-width: 767px) {
  .static__invoice_wrap .invoice__modal_box.invoice__shipment_style_optamize_wrap .invoice__modal_headerleft {
    order: 3;
    width: 100%;
  }

  .static__invoice_wrap .invoice__modal_box.invoice__shipment_style_optamize_wrap .invoice__modal_headerright {
    width: 100%;
  }

  .static__invoice_wrap .invoice__modal_box.invoice__shipment_style_optamize_wrap .invoice__modal_header .invmodal__hleft_amntitem .invmodal__hleft_amntitemleft {
    width: fit-content;
  }

  .static__invoice_wrap .invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__hright_infoitem {
    width: 100%;
    max-width: 400px;
  }

  .static__invoice_wrap .invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__shipment_details table.invmodal__shdetails_table {
    width: 800px;
  }

  .static__invoice_wrap .invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__shipment_details table.invmshdetails_cnleft_table {
    width: 800px;
  }

  .static__invoice_wrap .invoice__modal_box.invoice__shipment_style_optamize_wrap table.invmodal__shbilling_grid {
    width: 800px;
  }

  .static__invoice_wrap .invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__shipment_details_wrap {
    overflow: auto;
  }

  .static__invoice_wrap .invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal_footer .invmodal_footertop {
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    margin-bottom: 10px;
  }

  .static__invoice_wrap .invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal_footer .invmodal_footertop_right {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
  }

  .invmodal_footertop_ritem {
    width: 50%;
  }

  .static__invoice_wrap .invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__paymentterms_box {
    height: fit-content;
    flex-wrap: wrap;
  }

  .static__invoice_wrap .invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal_footer .invmodal__paymentterms_boxleft {
    width: 100%;
    border: none;
  }

  .static__invoice_wrap .invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal_footer .invmodal__paymentterms_boxright {
    width: 100%;
  }

  .static__invoice_wrap .invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__einvactions_contentrt {
    padding: 1px;
  }

  .static__invoice_wrap .invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__shipment_details_wrap .invmodal__shshiptracking_grid {
    grid-template-columns: 110px 90px 80px 160px;
    padding-bottom: 10px;
  }

  .invoice__billing_admin_wrap {
    overflow: auto;
  }

  .fpinvoice__modal_box.shipment__fp_invoice_custom_box .invoice__modal_headerleft {
    order: 3;
    width: 100%;
  }

  .fpinvoice__modal_box.shipment__fp_invoice_custom_box .invoice__modal_headerright {
    width: 100%;
  }

  .fpinvoice__modal_box.shipment__fp_invoice_custom_box .invoice__modal_header .invmodal__hleft_amntitem .invmodal__hleft_amntitemleft {
    width: fit-content;
  }

  .fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__hright_infoitem {
    width: 100%;
    max-width: 400px;
  }

  .fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__shipment_details table.invmodal__shdetails_table {
    width: 800px;
  }

  .fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__shipment_details table.invmshdetails_cnleft_table {
    width: 800px;
  }

  .fpinvoice__modal_box.shipment__fp_invoice_custom_box table.invmodal__shbilling_grid {
    width: 800px;
  }

  .fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__shipment_details_wrap {
    overflow: auto;
  }

  .fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal_footer .invmodal_footertop {
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    margin-bottom: 10px;
  }

  .fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal_footer .invmodal_footertop_right {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
  }

  .invmodal_footertop_ritem {
    width: 50%;
  }

  .fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__paymentterms_box {
    height: fit-content;
    flex-wrap: wrap;
  }

  .fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal_footer .invmodal__paymentterms_boxleft {
    width: 100%;
    border: none;
  }

  .fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal_footer .invmodal__paymentterms_boxright {
    width: 100%;
  }

  .fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__einvactions_contentrt {
    padding: 1px;
  }

  .fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__shipment_details_wrap .invmodal__shshiptracking_grid {
    grid-template-columns: 110px 90px 80px 160px;
    padding-bottom: 10px;
  }

  .invoice__billing_admin_wrap {
    overflow: auto;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap {
    width: 100%;
  }

  .fpinvoice__modal_box.shipment__fp_invoice_custom_box {
    width: 100%;
  }

  .fpinvoice__modal_box.shipment__fp_invoice_custom_box .invoice__modal_downloadinvoicewrap {
    flex-wrap: wrap;
    row-gap: 10px;
  }
}




@media only screen and (min-width: 1160px) {

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap {
    max-width: 67.35vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal__hright_infoitemright,
  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal__hright_infoitemleft {
    font-size: 0.735vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap button.invoicemdl__download_btn {
    font-size: 1.029vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap button.invoicemdl__download_btn .labeltxt,
  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invoice__modal_downloadinvoicewrap .labeltxt {
    font-size: 0.809vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invoice__modal_header .invmodal__hleft_amntitem {
    font-size: 0.735vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap svg {
    width: 0.956vw;
    height: 0.956vw;
    top: calc(100% - 1.7vw);
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invoice__modal_header .invmodal__hleft_amntitem span.invmodal__hleft_airprevcharge {
    font-size: 0.735vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invoice__modal_downloadinvoicewrap {
    padding: 0.368vw 0 0.735vw 0;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal__shipment_summary .invmodal__shipment_sumitemhding,
  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal__shipment_detailshding {
    font-size: 0.809vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal__shipment_sumitemleft {
    font-size: 0.735vw;
    width: 5.147vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal__shipment_summary .invmodal__shipment_sumitemright,
  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal__international_sumitemleft {
    font-size: 0.735vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal__shipment_details .invmodal__shdetails_tableitem {
    font-size: 0.735vw
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal__shipment_details .invmodal__shdetails_tablehd {
    font-size: 0.735vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal__shipment_details .invmshdetails_cnleft_heading {
    font-size: 0.809vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal__editableinv_actions label,
  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal__editableinv_actions .invmodal__einvactions_content .invmodal__shippymnt_verifiedtoggle label {
    font-size: 0.735vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal__editableinv_actions .invmodal__einvactions_content .invmodal__shippymnt_verifiedtoggle .MuiInputBase-root,
  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal__editableinv_actions textarea.invmodal__charge_desc {
    font-size: 0.735vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal__shipment_details_wrap .invmodal__shdetails_gridhd {
    font-size: 0.735vw;
    font-weight: 600;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal__editableinv_actions label.invmodal__shippymnt_addattachmnetbtn2 {
    height: 2.206vw;
    width: 1.471vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal__editableinv_actions label.invmodal__shippymnt_addattachmnetbtn2 svg {
    width: 1.029vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal__shipment_details_wrap .invmodal__shdetails_griditem span,
  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal__note_box .invmodal__note_boxhding {
    font-size: 0.735vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal__shipment_details_wrap .invmodal__shdetails_griditem {
    font-size: 0.735vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal__bottom_titletxt {
    font-size: 0.735vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal_footer .invmodal_footertop_ritem {
    font-size: 0.735vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal_footertop_right {
    grid-template-columns: 7.353vw 7.353vw 7.353vw 7.353vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal_footer .invmodal_footertop {
    height: 3.676vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal_footer .invmodal__paymentterms_boxleft {
    font-size: 0.735vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal__paymentterms_box {
    height: fit-content;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal_footer .invmodal__paymentterms_boxright {
    font-size: 0.735vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal_footer .invmodal__paymentterms_brapprice,
  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal_footer .invmodal__paymentterms_brapprice span {
    font-size: 1.324vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal_footer {
    margin: 1.471vw auto;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__editableinv_actions .invmodal__einvactions_content .invmodal__einvactions_contentrtformfieldwrap .MuiInputBase-root {
    font-size: 0.809vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal__note_user_box.invoice__modal_user_box .invmodal__spnote_tablecell {
    font-size: 0.662vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invoice__billing_admin_wrap .invmodal__shbilling_grid .invmodal__shdetails_gridhd {
    font-size: 0.735vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invoice__billing_admin_wrap .invmodal__shdetails_griditem {
    font-size: 0.735vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap {
    max-width: 67.35vw;
    width: 100%;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal__hright_infoitemright,
  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal__hright_infoitemleft {
    font-size: 0.735vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap button.invoicemdl__download_btn {
    font-size: 1.029vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap button.invoicemdl__download_btn .labeltxt,
  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invoice__modal_downloadinvoicewrap .labeltxt {
    font-size: 0.809vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invoice__modal_header .invmodal__hleft_amntitem {
    font-size: 0.735vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap svg {
    width: 0.956vw;
    height: 0.956vw;
    top: calc(100% - 1.7vw);
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invoice__modal_header .invmodal__hleft_amntitem span.invmodal__hleft_airprevcharge {
    font-size: 0.735vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invoice__modal_downloadinvoicewrap {
    padding: 0.368vw 0 0.735vw 0;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal__shipment_summary .invmodal__shipment_sumitemhding,
  .fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__shipment_details .invmodal__shipment_detailshding,
  .fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__shipment_details_wrap .invmodal__shipment_detailshding {
    font-size: 0.809vw;
  }

  .fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__shipment_summary .invmodal__shipment_sumitemleft {
    font-size: 0.735vw;
    width: 5.147vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal__shipment_summary .invmodal__shipment_sumitemright,
  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal__international_sumitemleft {
    font-size: 0.735vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal__shipment_details .invmodal__shdetails_tableitem {
    font-size: 0.662vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal__shipment_details .invmodal__shdetails_tablehd {
    font-size: 0.735vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal__shipment_details .invmshdetails_cnleft_heading {
    font-size: 0.809vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal__editableinv_actions label,
  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal__editableinv_actions .invmodal__einvactions_content .invmodal__shippymnt_verifiedtoggle label {
    font-size: 0.735vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal__editableinv_actions .invmodal__einvactions_content .invmodal__shippymnt_verifiedtoggle .MuiInputBase-root,
  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal__editableinv_actions textarea.invmodal__charge_desc {
    font-size: 0.735vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal__shipment_details_wrap .invmodal__shdetails_gridhd {
    font-size: 0.735vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal__editableinv_actions label.invmodal__shippymnt_addattachmnetbtn2 {
    height: 2.206vw;
    width: 1.471vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal__editableinv_actions label.invmodal__shippymnt_addattachmnetbtn2 svg {
    width: 1.029vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal__shipment_details_wrap .invmodal__shdetails_griditem span,
  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal__note_box .invmodal__note_boxhding {
    font-size: 0.735vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal__shipment_details_wrap .invmodal__shdetails_griditem {
    font-size: 0.735vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal__bottom_titletxt {
    font-size: 0.735vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal_footer .invmodal_footertop_ritem {
    font-size: 0.735vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal_footertop_right {
    grid-template-columns: 7.353vw 7.353vw 7.353vw 7.353vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal_footer .invmodal_footertop {
    height: 3.676vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal_footer .invmodal__paymentterms_boxleft {
    font-size: 0.735vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal__paymentterms_box {
    height: fit-content;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal_footer .invmodal__paymentterms_boxright {
    font-size: 0.735vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal_footer .invmodal__paymentterms_brapprice,
  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal_footer .invmodal__paymentterms_brapprice span {
    font-size: 1.324vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal_footer {
    margin: 1.471vw auto;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__editableinv_actions .invmodal__einvactions_content .invmodal__einvactions_contentrtformfieldwrap .MuiInputBase-root {
    font-size: 0.809vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invmodal__note_user_box.invoice__modal_user_box .invmodal__spnote_tablecell {
    font-size: 0.662vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invoice__billing_admin_wrap .invmodal__shbilling_grid .invmodal__shdetails_gridhd {
    font-size: 0.735vw;
  }

  .fpinvoice__modal_wrap.shipment__fp_invoice_custom_wrap .invoice__billing_admin_wrap .invmodal__shdetails_griditem {
    font-size: 0.735vw;
  }

  .fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__hright_infoitem .invmodal__hright_infoitemleft,
  .fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__hright_infoitem .invmodal__hright_infoitemright {
    font-size: 0.735vw;
  }

  .fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal_footer .invmodal_footertop_right {
    grid-template-columns: 7.353vw 7.353vw 7.353vw 7.353vw;
  }

  .fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__shipment_details_wrap .invmodal__shshiptracking_grid {
    grid-template-columns: 11.765vw 11.765vw 9.559vw 25.956vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal__shipment_details_wrap .invmodal__shshiptracking_grid {
    grid-template-columns: 11.765vw 11.765vw 9.559vw 25.956vw;
  }

  .fpinvoice__modal_box.shipment__fp_invoice_custom_box .invoice__modal_downloadinvoicewrap svg {
    width: 0.956vw;
    height: 0.956vw;
  }

  .fpinvoice__modal_box.shipment__fp_invoice_custom_box .invmodal__shdetailgi_carrlogo {
    height: 1.471vw;
  }

  .notes__attachments_modal_attachment_thumbnail.notes__attachments_modal_attachment_thumbnail img {
    width: 2.562vw;
    height: 2.562vw;
  }

  .notes__attachments_modal_attachment_thumbnail.notes__attachments_modal_attachment_thumbnail .note_attachment {
    width: 1.562vw;
    height: 1.562vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal_footer .invmodal__paymentterms_brapprice span sup.invmodal__paymentterms_editcount {
    font-size: 0.732vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal_footer .invmodal__paymentterms_brapprice span.invmodal__paymentterms_brprevcharge {
    font-size: 1.098vw;
  }

  .invoice__modal_wrap.invoice__modal_style_optamize_wrap.static__invoice_wrap .invmodal__shipment_details .invmodal__shipment_detailshding {
    font-size: 0.809vw;
  }

  .invmodal__shipment_summaryhding.internation__declaration_header {
    font-size: 0.809vw;
  }

  .invmodal__shdetails_content.package__details_row_custom .invmodal__shipment_packagedetails_heading {
    font-size: 0.809vw;
  }

  .invmodal__shdetails_content.package__details_row_custom {
    width: 95%;
    margin: 0 auto;
  }

  .invmodal__shdetails_content.package__details_row_custom .invmodal__shdetails_tablehd {
    font-size: 0.735vw;
  }

  .invmodal__shdetails_content.package__details_row_custom .invmodal__shdetails_tableitem {
    font-size: 0.735vw;
  }


  .invoice__modal_box.invoice__shipment_style_optamize_wrap .invmodal__note_box .invmodal__note_boxdescitem {
    font-size: 0.735vw;
  }
}




.invoice__modal_box.printable__invoice.invoice__shipment_style_optamize_wrap .invmodal__note_boxdescitem {
  font-size: 10px;
}


.invoice__modal_box.printable__invoice.invoice__shipment_style_optamize_wrap .invmodal__shdetails_tablehd {
  font-size: 10px;
}

.invoice__modal_box.printable__invoice.invoice__shipment_style_optamize_wrap .invmodal__shdetails_tableitem {
  font-size: 10px;
}

.invoice__modal_box.printable__invoice.invoice__shipment_style_optamize_wrap .invmodal__shdetails_content.package__details_row_custom {
  width: 95%;
  margin: 0 auto;
}

.invoice__modal_box.printable__invoice.invoice__shipment_style_optamize_wrap td,
.invoice__modal_box.printable__invoice.invoice__shipment_style_optamize_wrap .invmodal__shipment_summary .invmodal__shipment_sumitemhding,
.invoice__modal_box.printable__invoice.invoice__shipment_style_optamize_wrap .invmodal__shipment_details_wrap .invmodal__shipment_detailshding,
.invoice__modal_box.printable__invoice.invoice__shipment_style_optamize_wrap .invmodal__shipment_details .invmodal__shipment_detailshding,
.invoice__modal_box.printable__invoice.invoice__shipment_style_optamize_wrap .invmodal__shipment_packagedetails_heading,
.invoice__modal_box.printable__invoice.invoice__shipment_style_optamize_wrap .invmodal__hleft_amntitemleft,
.invoice__modal_box.printable__invoice.invoice__shipment_style_optamize_wrap .invmodal__hleft_amntitem {
  padding: 0 8px 13px 8px;
}

.invoice__modal_box.printable__invoice.invoice__shipment_style_optamize_wrap .invmodal__hleft_amntitem.total__amount_wrap {
  flex-wrap: wrap;
}

.invoice__modal_box.printable__invoice.invoice__shipment_style_optamize_wrap .invmodal__shipment_summary {
  margin-bottom: 20px;
}