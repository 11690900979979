.package__type_main_wrap {
    display: flex;
    justify-content: center;
}

.package__type_package_main_btn {
    justify-content: center;
    margin-bottom: 15px;
}
.package__divider {
    width: 2px;
    background: #e5e5e5;
    margin: 0 18px;
}
.package__type_main_wrap button {
    margin-right: 0 !important;
}

.package__type_package {
    width: 100%;
    max-width: 810px;
}

.package__type_pallet {
    width: 100%;
    max-width: 810px;
}

.card-body.service__card_box_ship_body{
    padding: 0;
}
.insurance__field_perShipment .MuiFormControl-root {
    width: 100%;
}
.package__type_package_main_btn button {
    font-size: 14px;
    letter-spacing: 2px;
}

.package__type_package_main_btn_wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    column-gap: 12px;
}
.card-header.service__card_box_ship_header b {
    font-size: 12px;
}

.card-header.service__card_box_ship_header button {
    padding: 0;
    width: 12px;
    height: 13px;
}

.service__card_box_ship_body_heading b {
    font-size: 13px;
    margin-bottom: 10px;
}

.package__type_package_main_btn button {
    font-size: 10px;
}

.package__type_package_main_btn_wrap button {
    font-size: 10px;
}
.ship__page_myproduct_listing_bolt_table .editable__table_container table.editable__table {
    width: 100%;
    min-width: 1020px;
}

.ship__page_myproduct_listing_bolt_table .editable__table_container .ship__page_myproduct_listing_bolt_table_name {
    min-width: 8rem;
}
.ship__page_myproduct_listing_bolt_table .editable__table_container tr th{
    text-align: left;
    border: none;
    background: #f7f7f7;
    padding: 7px 5px;
}
.ship__page_myproduct_listing_bolt_table .editable__table_container thead tr{
    border-top: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
}

.ship__page_myproduct_listing_bolt_table .editable__table_container  tbody td{
    border: none;
    border-bottom:1px solid #f7f7f7 ;
}


.ship__page_myproduct_listing_bolt_table .editable__table_container tbody td img{
    border: 1px solid #D0D0C1;
    object-fit: cover;
    max-height: 100% !important;
    height: 30px !important;
    width: 30px;
    border-radius: 8px;
    margin: 2px auto;
}
@media only screen and (max-width: 992px) {
    .css-1d0f7f9-MuiStack-root {
        justify-content: center;
    }
    
    .css-1d0f7f9-MuiStack-root button {
        margin-right: 3px !important;
        font-size: 10px;
        font-weight: 700;
        margin-left: 3px !important;
    }
    
    .package__type_package_main_btn {
        border-bottom: 1px solid #ccc;
        padding-bottom: 12px;
    }
    
    .package__type_main_wrap {
        flex-wrap: wrap;
    }
    
    .package__type_pallet {
        margin-top: 35px;
    }
}

@media only screen and (min-width: 1190px) {
    .card-header.service__card_box_ship_header b{
        font-size: 0.878vw;
    }
    .card-header.service__card_box_ship_header button,.card-header.service__card_box_ship_header button svg{
        width: 0.952vw;
        height: 0.952vw;
    }
    .service__card_box_ship_body_heading b{
        font-size: 0.952vw;
        margin-bottom: 0.732vw;
    }
    .package__type_main_wrap .package__type_package,.package__type_main_wrap  .package__type_pallet{
        max-width: 59.297vw;
    }
    .package__type_package_main_btn button,.package__type_package_main_btn_wrap button{
        font-size: 0.732vw;
    }
    .card-header.service__card_box_ship_header{padding: 0.586vw 1.171vw;}
    .ship__page_myproduct_listing_bolt_table {
        width: calc(100% + 30px );
        margin-left: -15px;
    }
    
    .ship__page_myproduct_listing_bolt_table .editable__table_toolbar {
        padding: 0 15px;
    }
}