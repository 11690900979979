.full__screen_modalheader {
  display: flex;
  padding: 25px 20px 10px 20px;
  border-bottom: 0.5px solid lightgray;
}
.full__screen_modalheaderheading {
  flex: 1;
  color: lightgray;
}

.full__screen_modalheaderclosebtn {
  position: absolute;
  top: 20px;
  right: 15px;
  cursor: pointer;
}
.full__screen_modalcontent {
  width: 100%;
  height: auto;
  padding: 20px 0;
}
