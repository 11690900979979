.create_zone_screen {
  width: 100%;
  height: calc(100vh-76px);
  height: -webkit-calc(100vh - 76px);
  /*for Safari*/
  height: -moz-calc(100vh - 76px);
  /*for Firefox*/
  height: -o-calc(100vh - 76px);
  /*for Opera*/
  height: -ms-calc(100vh - 76px);
  /*for IE*/
  position: relative;
  user-select: none;
  overflow: hidden;
}

.czs_city_searchboxwrap {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
  width: 350px;
  background: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
}

.czs_city_searchboxfield {
  width: 320px;
  height: 40px;
  background-color: white;
  padding: 0 10px;
  outline: none;
}

.czs_csb_icon {
  margin-right: 10px;
}

.czs_createzone_wrap {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
  width: 350px;
  background: white;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
}

.czs_createzone_btn {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  color: #0c69f3;
  cursor: pointer;
}

.czs_createzone_globalpallet {
  border-top: 0.5px solid #9f9a9a;
  width: 100%;
  height: 45px;
  display: flex;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.czs_czgp_icon {
  margin-right: 10px;
  cursor: pointer;
}

.czs_czgp_iconqs {
  position: absolute;
  right: 10px;
}

.czscz_zoneheading_wrap {
  width: 100%;
  display: flex;
}

.czscz_zoneheading_wrapleft {
  width: 80%;
  display: flex;
  font-weight: bold;
  align-items: center;
}

.czscz_zoneheading_wrapright {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.czscz_zoneheading_wrapleftpncl {
  width: 10px;
  height: 13px;
  margin: 3px;
  font-weight: normal;
  cursor: pointer;
}

.czscz_zoneheading_clrbox {
  width: 25px;
  height: 25px;
  background-color: tomato;
  border-radius: 5px;
  margin-right: 10px;
}

.czscz_zoneheading_trash {
  width: 22px;
  height: 22px;
}

.czscz_zoneheading_inputwrap {
  display: flex;
  max-width: 250px;
}

.czscz_zoneheading_editznfield {
  box-shadow: 1px 1px 2px 1px rgba(128, 128, 128, 0.308);
  outline: none;
  font-size: 14px;
  font-weight: normal;
  width: 220px;
  height: 30px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-left: 10px;
}

.czscz_zoneheading_editznbtn {
  font-size: 14px;
  font-weight: normal;
  width: 60px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-top-right-radius: 5px;
  box-shadow: 1px 1px 2px 1px rgba(128, 128, 128, 0.308);
  border-bottom-right-radius: 5px;
  background-color: #2e7df3;
}

.czscz_zoneheading_downarrow {
  position: absolute;
  bottom: 0;
  left: 50%;
  color: grey;
  /* transform: translate(-50%, -50%); */
}

.czscz_zonebody_wrap {
  color: #374957;
  font-weight: 500;
  border-top: 0.5px solid rgb(197, 194, 194);
}

.czscz_zonebody_checkboxes {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
}

.czscz_zonebody_checkbox {
  width: 80px;
  display: flex;
  justify-content: space-between;
  margin-right: 30px;
}

.czscz_zonebody_checkbox>label,
.czscz_zonebody_checkbox>input {
  cursor: pointer;
}

.czscz_zonebody_infobox {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.czs_createzone_globalpallet2 {
  width: 100%;
  height: 35px;
  display: flex;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.czscz_colorpicker {
  position: absolute;
  right: 15px;
  top: 35px;
  z-index: 20;
}

.cz_expandaccor_arrowup {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.205);
  background-color: white;
  left: 50%;
  top: -30px;
  transform: translate(-50%, -50%);
  z-index: 10;
  cursor: pointer;
}

.delete_area_component {
  width: 25px;
  height: 25px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  z-index: 900000000000000;
  border-radius: 50%;
  margin-left: -15px;
  margin-top: -5px;
}

.zone_arealabel_component {
  width: 50px;
  height: 12px;
  color: white;
  padding: 4px 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  z-index: 900000000000000;
  margin-left: -25px;
  margin-top: -10px;
  font-weight: 500;
  font-size: 9px;
  border: 1px solid lightgrey;
}

.zone_arealabelc_marker {
  position: absolute;
  margin-bottom: 38px;
  font-size: 30px !important;
}

.no_zone_createdplaceholder {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: lightgray;
  font-size: 20px;
  border-top: 1px solid lightgrey;
}

.czs_createzone_btndisabled {
  pointer-events: none;
}

.czs_czgp_pencilicon {
  transform: scale(1.3);
  transition: 0.2s;
}

.is__drawing_zonetab {
  width: 100%;
  height: 35px;
  position: relative;
  bottom: -10px;
  background-color: white;
  color: tomato;
  border: none;
  padding: 0px 16px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.is__drawing_zonetab:hover {
  transform: translateY(-0.5px);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
}

.zszs_zones_section {
  position: absolute;
  bottom: -25px;
  width: 100%;
  max-height: 90vh;
}