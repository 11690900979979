.transtable__invoice_btndisabled {
  pointer-events: none;
  opacity: 0.4;
}

.paymentHistory_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: grey;
  margin: 20px 5px 0px 5px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid lightgrey;
}

.paymentHistory_heading {
  font-weight: 400 !important;
  font-size: 22px;
}
