.sales__section_screen {
  width: 100%;
  padding: 40px 5%;
}
.saless__address_section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.saless__address_sectionleft {
  flex: 1;
  padding: 5px 20px;
}
.saless__address_sectionright {
  flex: 1;
  padding: 5px 20px;
}
.saless__products_section {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.saless__products_sectionleft {
  padding: 5px 20px;
  flex: 1;
  min-width: 590px;
}
.saless__products_sectionright {
  padding: 5px 20px;
  flex: 1.5;
}
.saless__bottomcreatebtn_wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.saless__bottomcreate_btn {
  width: 250px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: rgb(41, 182, 95);
  color: white;
  font-weight: 500;
}

.saless__order_loader {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}


@media (max-width: 600px) {
  .saless__products_sectionleft {
    min-width: 300px;
  }
 .saless__products_sectionright .sss__products_section, .saless__address_sectionleft .sss__payment_section{
    height: fit-content;
  }
  .package__name_list_wrap{
    width: 100% !important;
    margin: 0 !important;
    border: none !important;
    padding-right: 0 !important;
  }
  .input_row_order_type {
    width: 100%;
    flex-wrap: nowrap;
}

.input_row_order_type .sss__addressection_inputcol {
    margin-left: 0 !important;
    max-width: 170px !important;
    min-width: fit-content;
}
}
