.carriers__rdetails_tableswrap {
  padding: 30px 25px;
  text-align: left;
}
.carriers__rdetails_tablesrow {
  width: 100%;
  display: flex;
}
.carriers__rdetails_tablescol {
  margin-right: 25px;
}
.carriers__rdetails_tablescolheading {
  font-weight: bold;
  color: #454545;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.carriers__rdetails_verticletable {
  margin-bottom: 15px;
  border-bottom: 0.5px solid lightgray;
  padding-bottom: 10px;
}
.carriers__rdverttable_row {
  display: flex;
  width: fit-content;
  text-transform: uppercase;
  font-size: 11px;
}
.carriers__rdverttable_heading {
  width: 150px;
  min-height: 35px;
  max-height: fit-content;
  display: flex;
  padding: 10px 10px 0 10px;
  overflow-wrap: anywhere;
  font-weight: bold;
}

.carriers__rdverttable_data {
  width: 200px;
  min-height: 35px;
  max-height: fit-content;
  display: flex;
  padding: 10px 10px 0 10px;
  overflow-wrap: anywhere;
  white-space: normal;
}
.carriers__rdverttable_smhcol {
  width: 130px;
}
.carriers__rdverttable_smcol {
  width: 106px;
}
.carriersrdvt__shipment_status {
  padding: 2px 10px;
  height: fit-content;
  font-weight: 500;
  text-transform: capitalize;
  border: 1px solid grey;
  font-size: 9px;
}
.carriersthd__rvtdata_badge {
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 2px 10px;
  font-size: 9px;
  font-weight: 500;
  border: 0.5px solid grey;
  text-transform: capitalize;
}
.carriersthd__rvtdata_badgeicon > img {
  margin-right: 3px;
  width: 9px;
}
.carriers__rdetails_additionserviceslist {
  padding-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  max-width: 500px;
}
.carriers__rdetails_horizontaltable {
  min-width: 705px;
}
.carriers__rdetails_horizontaltable {
  max-height: 250px;
  overflow: auto;
}
.carriers__rdhtable_header {
  display: flex;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
}
.carriers__rdhtable_row {
  display: flex;
  text-transform: uppercase;
  font-size: 12px;
  margin: 5px 0px;
}
.carriers__rdhtable_col {
  height: fit-content;
  display: flex;
  align-items: center;
}
.carriers__rdhtable_smptstatus {
  height: fit-content;
  padding: 2px 10px;
  border: 1px solid grey;
  border-radius: 1px;
  font-size: 9px;
  text-transform: capitalize;
  font-weight: 500;
}
.dispscrn__selectdriver_btn {
  padding: 4px 10px;
  font-size: 14px;
  color: #0c69f3;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
}
.dispscrn__selectdriver_btn > * {
  font-size: 18px;
  margin-right: 5px;
}
.pagination__wrap {
  position: sticky;
  background-color: white;
  top: 100%;
  right: 0;
  left: 0;
  width: 100%;
  height: fit-content;
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  padding: 5px;
}
.dispscrn__selectdriver_editicon {
  color: #0c69f3;
  font-size: 20px;
  margin-left: 5px;
  cursor: pointer;
}

.carriers__theader_flagwrap {
  width: 30px;
  height: 18px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 1px 1px rgb(216, 216, 216);
}
