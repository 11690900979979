.dispatcher__screen_tablewrap {
  background: #ffffff;
  border: 1px solid #dedede;
  box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  width: fit-content;
  height: fit-content;
  position: relative;
  overflow-x: auto;
  max-width: 100%;
}
.dispatcher__soption_disabled {
  pointer-events: none;
  color: lightgray !important;
}

.dispscreen__tdata_vcolor {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.dispscreen__thdata_dropwrap * {
  border: none !important;
  outline: none !important;
  height: 10px !important;
  font-weight: 500 !important;
  font-size: 8px !important;
}

.vehicle_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: grey;
  margin: 20px 5px 0px 5px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid lightgrey;
}
.vehicle_hdrheading {
  font-weight: 400 !important;
  font-size: 22px;
}
.vehicle_page_headerrt_wrap {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
}
.vehicle_page_hdraddprod {
  border-radius: 5px;
  box-shadow: 0.3px 0.3px 3px 1px rgb(230, 228, 228);
  display: flex;
  padding: 6px 8px;
  align-items: center;
  font-weight: 500;
  font-size: 8px;
  text-decoration: none;
  color: grey;
  cursor: pointer;
  height: fit-content;
}
.vehicle_page_hdraddprod:hover {
  color: grey;
}
.vehicle_page_hdraddprod > * {
  margin-right: 3px;
}

@media screen and (min-width: 480px) {
  .vehicle_header {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 1190px) {
  .vehicle_hdrheading {
    font-size: 1.611vw;
  }
}

@media only screen and (max-width: 768px) {
  .vehicle_page_headerrt_wrap {
    column-gap: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .vehicle_page_hdraddprod span {
    display: none;
  }
}
@media only screen and (min-width: 1170px) {
  .vehicle_page_hdraddprod {
    font-size: 0.805vw;
    padding: 0.439vw 0.586vw;
  }
  .vehicle_page_hdraddprod svg {
    width: 1.025vw;
    height: 1.025vw;
  }
}
