.register__form_screen * {
  box-sizing: border-box;
}
.register__form_screen {
  width: 100%;
  min-height: 100vh;
  padding: 0 5%;
  background: #f5f5f5;
}
.regform_screen_header {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
}
.register__form_scontent {
  max-width: 1800px;
  width: 100%;
  margin: auto;
  min-height: 500px;
  display: flex;
  padding-bottom: 30px;
  align-items: center;
}
.regform_sheader_image {
  cursor: pointer;
}
.register__form_scleft,
.register__form_scright {
  flex: 1;
  height: 100%;
}
.register__form_scleft {
  display: flex;
  flex: 1.3;
  justify-content: center;
  align-items: center;
  min-height: inherit;
}
.register__form_scright {
  padding: 0 60px;
  box-sizing: border-box;
  overflow-x: hidden;
}
.register__scleft_animationwrap {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 9px;
  overflow: hidden;
}
.register__scleft_animation {
  width: 100%;
  height: 100%;
}
.register__form_scrighttabs {
  width: 100%;
  height: 50px;
  display: flex;
  box-sizing: border-box;
}
.register__form_scrighttab {
  width: 50%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
  color: #131466;
  background-color: #ebebeb;
  border-radius: 2px;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
}
.registerf__scrighttabactive {
  background-color: #131466;
  color: white;
}
.registerf__scright_stepper {
  margin: 45px 0 20px 0;
}
.login__form_heading {
  padding: 20px 5px;
}
.login__form_headingtext {
  font-weight: 400;
  font-size: 40.61px;
  line-height: 56px;
  color: #131466;
}
.login__form_subheadingtext {
  font-weight: 400;
  font-size: 14.61px;
  line-height: 20px;
  color: #131466;
}
.forgot__password_link {
  font-weight: 500;
  font-size: 14px;
  color: #0d6efd;
  text-decoration: none;
  cursor: pointer;
}
.rfsregister__steps_googlebtn {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8f8f8;
  border: 1px solid #bcbcbc;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
}

.register__form_screen .lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.register__form_screen .lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.register__form_screen .lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.register__form_screen .lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.register__form_screen .lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.register__form_screen .lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
.recaptcha__checkbox_wrap {
  width: 100%;
  margin-top: 10px;
}
.slide-enter {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 600ms, transform 600ms !important;
}

.slide-enter-active {
  opacity: 1 !important;
  transform: translateX(0%) !important;
  transition: opacity 600ms, transform 600ms !important;
}

.slide-exit {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 600ms, transform 600ms;
}

.slide-exit-active {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 600ms, transform 600ms;
}
/* custom design */
.register__form_scright {
  max-width: 550px;
  margin: 0 auto;
}

.login__form_heading {
  text-align: center;
}

.rfsregister__steps_fieldscol {
  padding: 0;
}

.regform_screen_header {
  justify-content: center;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
@media (min-width: 1600px) {
  .register__scleft_animation {
    height: 450px;
    object-fit: contain;
  }
  .register__form_scleft {
    min-height: 500px;
  }
}
@media (max-width: 1100px) {
  .register__form_scontent {
    flex-direction: column;
  }
  .register__form_scright {
    margin-top: 25px;
  }
  .login__form_headingtext {
    font-weight: 400;
    font-size: 30.61px;
  }
  .login__form_subheadingtext {
    font-weight: 400;
    font-size: 10.61px;
  }
  .register__form_scleft {
    min-height: auto;
  }
}
@media (max-width: 500px) {
  .register__form_scrighttab {
    height: 35px;
    font-size: 10px;
  }
  .register__form_scright {
    padding: 0 10px;
  }
}

@media screen and (max-width: 992px) {
  .register__form_scontent {
    display: block;
  }
  .register__form_scrighttab {
    font-size: 15px;
    line-height: 1.7;
  }
}

/*Login page revamping*/
.loginpage__new_landing_bg.register__form_screen .loginpage__new_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.loginpage__new_landing_block {
  text-align: center;
}

.loginpage__new_logo img {
  margin: 0 auto;
}

.loginpage__new_info_content span {
  display: block;
  color: #0d6efd;
  font-weight: 700;
}

.loginpage__new_buttons {
  display: flex;
  justify-content: center;
  margin: 18px 0;
  flex-wrap: wrap;
  column-gap: 10px;
}

.loginpage__new_buttons_shipper {
  background: #131466;
  padding: 10px 50px;
  color: #fff;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.loginpage__new_buttons_carrier {
  background: #0d6efd;
  padding: 10px 50px;
  color: #fff;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.loginpage__new_buttons_logistic {
  background: #131466;
  padding: 10px 50px;
  color: #fff;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 10px;
}
.loginpage__new_buttons_shipper:hover,
.loginpage__new_buttons_carrier:hover,
.loginpage__new_buttons_logistic:hover {
  background: #4d4d5c;
}
.shipper__tab_main_wrap {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  column-gap: 25px;
  flex-wrap: wrap;
}
.shipper__tab_left_logo {
  max-width: 360px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}

.shipper__tab_left_col {
  width: 100%;
  max-width: 560px;
}

.shipper__tab_right_col {
  width: 100%;
  max-width: 480px;
}
.loginpage__new_form_back_btn {
  background: #131466;
  border: none;
  border-radius: 3px !important;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  padding: 7px 25px;
  position: relative;
  width: 100%;
  max-width: fit-content;
  margin-left: auto;
  cursor: pointer;
}

.loginpage__new_form_back_btn:hover {
  background: #0d6efd;
}
.loginpage__new_form_field {
  margin-bottom: 11px;
}

.loginpage__new_form_field
  .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 0;
}

.loginpage__new_form_heading {
  text-align: center;
  margin-bottom: 20px;
}

.shipper__tab_right_col .login__page_login_btn,
.shipper__tab_right_col .login__page_google_btn {
  height: 43px;
  margin-top: 15px;
}
.register__now_link {
  font-weight: 500;
  font-size: 14px;
  color: #131466;
  text-decoration: none;
  margin-top: 5px;
}

.register__now_link span {
  color: #0d6efd;
  cursor: pointer;
}

.forget__lin_wrap {
  text-align: right;
}
.loginpage__new_form_field svg path {
  fill: #a5a5a5;
}

.loginpage__new_form_field svg {
  width: 20px;
  height: 20px;
}

.loginpage__new_form_field .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #c7c7c7;
}
.loginpage__new_landing_bg .register__main_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.register__main_landing_main {
  text-align: center;
}

.register__main_button {
  display: flex;
  justify-content: center;
  margin: 18px 0;
  flex-wrap: wrap;
  column-gap: 10px;
}

.register__main_logo img {
  margin: 0 auto;
}

.registerpage__new_buttons_shipper {
  background: #131466;
  padding: 10px 50px;
  color: #fff;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.registerpage__new_buttons_carrier {
  background: #0d6efd;
  padding: 10px 50px;
  color: #fff;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.registerpage__new_buttons_logistic {
  background: #131466;
  padding: 10px 50px;
  color: #fff;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.register__main_info_content span {
  display: block;
  color: #0d6efd;
  font-weight: 700;
}
h3.register__tab_heading {
  text-align: center;
  margin-bottom: 30px;
}

.register__tab_main .crstprcomp_itembottomactive {
  background: #131466 !important;
}
.register__tab_main .crstepper__component_itemtop svg {
  color: #fff;
  background: #c5c5c5;
  width: 30px;
  height: 30px;
  padding: 6px;
  border-radius: 2px;
  margin-top: -21px;
  margin-left: -3px;
  margin-bottom: 6px;
}

.register__tab_main .crstepper__component_itembottom {
  background: #c5c5c5;
}
.register__tab_main .crstepper__component_itemtop {
  font-size: 12px;
  font-weight: 400;
}

.regiterpage__new_form_back_btn {
  background: #131466;
  border: none;
  border-radius: 3px !important;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  padding: 7px 25px;
  position: relative;
  width: 100%;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-left: auto;
  cursor: pointer;
}

.register__tab_login_link {
  font-weight: 500;
  font-size: 14px;
  color: #131466;
  text-decoration: none;
  margin-top: 5px;
}

.register__tab_main .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root {
  border-color: #000;
}

.register__tab_main .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #969696 !important;
}

.register__tab_main
  .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root
  .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 0;
}
.register__tab_main
  .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root
  .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input::placeholder {
  color: #969696 !important;
}

.register__tab_main
  .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root
  svg
  path {
  fill: #969696;
}

.register__tab_main
  .css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root
  svg
  image {
  filter: brightness(0) saturate(100%) invert(56%) sepia(50%) saturate(24%)
    hue-rotate(52deg) brightness(97%) contrast(91%);
}
.register__tab_right_col .rfsregister__steps_nextbtndisabled,
.register__tab_right_col .rfsregister__steps_nextbtn.false {
  height: 40px;
}
.register__tab_main {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  column-gap: 25px;
  flex-wrap: wrap;
}

.register__tab_left_col {
  width: 100%;
  max-width: 560px;
}

.register__tab_right_col {
  width: 100%;
  max-width: 480px;
}

.register__tab_left_logo {
  max-width: 360px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}
.register__tab_login_link span {
  color: #0d6efd;
  cursor: pointer;
}
.register__tab_main .crstepper__component_item:last-child {
  display: inline-flex;
  width: 100%;
  position: relative;
}

.register__tab_main
  .crstepper__component_item:last-child
  .crstepper__component_itemtop {
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  position: absolute;
  right: 0px;
}

.register__tab_main .crstepper__component_item:nth-child(2) {
  display: inline-flex;
  position: relative;
}

.register__tab_main
  .crstepper__component_item:nth-child(2)
  .crstepper__component_itemtop {
  display: flex;
  flex-wrap: wrap;
  background: transparent;
  justify-content: center;
  text-align: center;
  position: absolute;
  width: 100%;
}
.register__tab_main
  .crstepper__component_item:last-child
  .crstepper__component_itemtop
  span {
  width: 100%;
  text-align: right;
}

.register__tab_main .crstepper__component_item:first-child {
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.register__tab_main
  .crstepper__component_item:first-child
  .crstepper__component_itemtop {
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  position: absolute;
  left: 1px;
}
.register__tab_main
  .crstepper__component_item:first-child
  .crstepper__component_itemtop
  span {
  width: 100%;
}
.register__tab_main .crstepper__component_itembottom.false {
  width: 100%;
}

.register__tab_main
  .crstepper__component_item:nth-child(2)
  .crstepper__component_itemtop
  span {
  display: block;
  width: 100%;
}
.register__tab_main
  .crstepper__component_itemtop.crstepper__component_itemtop.active
  svg {
  background: #131466;
}
.register__tab_main .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  height: 45px;
  padding: 0 0 0 7px !important;
}

@media only screen and (max-width: 1024px) {
  .register__tab_left_col {
    max-width: 340px;
  }

  .register__tab_right_col {
    max-width: 90%;
    margin-top: 8%;
  }

  .register__tab_main {
    height: fit-content;
    padding-top: 8%;
  }
}
