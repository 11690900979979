/* .stockCount__add_new_btn {
    border-radius: 5px;
    box-shadow: 0.3px 0.3px 3px 1px rgb(230, 228, 228);
    display: flex;
    padding: 8px 18px;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
    color: grey !important;
    width: fit-content;
    margin-left: auto;
    margin-bottom: 15px;
    margin-top: 15px;
    column-gap: 5px;
    cursor: pointer !important;
}

.stockCount__add_new_btn svg {
    font-size: 21px;
    line-height: 1;
} */
/* product stock count header  */
.product_stockcount_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: grey;
  margin: 20px 5px 0px 5px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid lightgrey;
}
.product_stockcount_hdrheading {
  font-weight: 400 !important;
  font-size: 22px;
}
.product_stockcount_buttonsWrapper {
  display: flex;
  gap: 20px;
}
.product_stockcount_hdraddprod {
  border-radius: 5px;
  box-shadow: 0.3px 0.3px 3px 1px rgb(230, 228, 228);
  display: flex;
  padding: 5px 10px;
  align-items: center;
  font-weight: 500;
  font-size: 8px;
  text-decoration: none;
  color: grey;
  cursor: pointer;
}
.product_stockcount_hdraddprod:hover {
  color: grey;
}
.product_stockcount_hdraddprod > * {
  margin-right: 3px;
}
/* close  */

.addStockCount__desc_modal {
  min-height: 250px;
  min-width: 310px;
  width: 550px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
}
.addStockCount__desc_modalheading {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid lightgray;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 40px;
}

.addStockCount__desc_modalcontent {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
}

.addStockCount__desc_modalcontent .MuiFormControl-root {
  width: 48%;
  margin-bottom: 20px;
}

.addStockCount__desc_modalcontent .MuiFormControl-root:nth-child(1) {
  width: 100%;
}

.addStockCount__desc_modalfooter {
  width: 100%;
  padding: 10px;
  height: 60px;
  border-top: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}

.addStockCount__desc_modalsubmit {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c69f3;
  border: 1px solid #0c69f3;
  border-radius: 3px;
  margin-left: 10px;
  color: white;
  cursor: pointer;
  width: 70px;
  height: 35px;
  font-size: 13px;
}

.addStockCount__desc_modalreset {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #454545;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  width: 70px;
  height: 35px;
  font-size: 13px;
}
.finalizeStockCount__desc_modal {
  min-height: 250px;
  width: 100%;
  max-width: 1100px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
}
.finalizeStockCount__desc_modalheading {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid lightgray;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 25px;
}
.finalizeStockCount__desc_body_list {
  display: flex;
  margin-bottom: 10px;
  flex-wrap: wrap;
  width: 32%;
}

.finalizeStockCount__desc_body_list_title {
  font-size: 14px;
  border-right: 0;
  font-weight: 700;
}

.finalizeStockCount__desc_body_list_data {
  font-size: 14px;
  border-left: 0;
  padding-left: 14px;
}

.finalizeStockCount__desc_body {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.5%;
  border: 1px solid #d9d9d9;
  padding: 16px 16px 5px 16px;
  margin-bottom: 15px;
}

.introContent__file_download {
  font-size: 14px;
}

.text__file_download {
  margin: 0;
  font-size: 12px;
}

.infoContent__file_download {
  font-size: 14px;
  margin-top: 9px;
  color: #001191;
}

.text__file_download span {
  color: #0777ef;
  font-weight: 500;
}

.file__download_main_wrap {
  border: 1px solid #d9d9d9;
  padding: 16px 16px 5px 16px;
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.file__download_btn_box a,
.file__download_btn_box a:hover {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #fff;
  background: #1976d2;
  padding: 8px 10px;
  column-gap: 6px;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;
}
.fileupload__main_wrap {
  border: 1px solid #d9d9d9;
  padding: 16px 16px 5px 16px;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.notes__contentarea_sec {
  width: 100%;
}

.csvUpload__content {
  width: 100%;
}

.csvfileupload__main_wrap {
  width: 100%;
}

.file__upload_csv_row input[type="file"] {
  opacity: 0;
}

label.file__upload_csv_row {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #cfcfcf;
  border-radius: 3px;
  padding-left: 10px;
  margin-bottom: 20px;
  cursor: pointer;
}

.file__upload_csv_row p {
  margin: 0;
}
.file__upload_csv_row label {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 15px;
  cursor: pointer;
}
.file__upload_csv_row label * {
  font-size: 14px;
  color: #1976d2;
}
.finalizeStock__footer_btn_sec {
  width: 100%;
  padding: 5px 0;
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.finalizeStock__submit {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #fff;
  background: #1976d2;
  padding: 8px 10px;
  column-gap: 6px;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 10px;
  width: 70px;
  height: 35px;
  justify-content: center;
}

.finalizeStock__reset {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #454545;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  padding: 8px 10px;
  column-gap: 6px;
  border-radius: 3px;
  width: 70px;
  height: 35px;
  justify-content: center;
}
.file__upload_csv_title {
  font-size: 14px;
  border-right: 0;
  font-weight: 700;
}
.file__main_copy {
  cursor: pointer;
  border: 1px solid #b7b7b7;
  padding: 6px;
  border-radius: 2px;
  color: #4c4c4c;
}

.file__main_download {
  cursor: pointer;
  border: 1px solid #b7b7b7;
  padding: 6px;
  border-radius: 2px;
  color: #464545 !important;
}

/* .custabscreen__details_thdata_actions_btn {
  cursor: pointer;
  border: 1px solid #b7b7b7;
  padding: 6px;
  border-radius: 2px;

  background: #4c4c4c;
  color: #fff;
} */

/* .custabscreen__details_thdata_actions_btn:hover {
  background: transparent;
  color: #4c4c4c;
} */
.download__uploaded_files svg {
  width: 15px;
  height: 15px;
}
.download__generated_files svg {
  width: 15px;
  height: 15px;
}
.download__uploaded_files span.MuiBadge-badge {
  padding: 0;
  font-size: 8px;
  min-width: 13px;
  min-height: 13px;
  height: fit-content;
  top: 3px;
  border-radius:100%;
}
@media only screen and (max-width: 768px) {
  .product_stockcount_header .product_stockcount_hdrheading {
    font-size: 18px;
  }
  .product_stockcount_hdraddprod span {
    display: none;
  }
  .product_stockcount_header {
    margin-bottom: 15px;
  }
  .addStockCount__desc_modal {
    min-width: fit-content !important;
    width: 92%;
    top: calc(50% - 120px);
  }

  .addStockCount__desc_modal .MuiFormControl-root {
    width: 47% !important;
  }
  .file__download_main_wrap {
    flex-wrap: wrap;
    padding-bottom: 15px;
  }
}


@media only screen and (min-width: 1170px) {
  .product_stockcount_hdraddprod{
    font-size: 0.805vw;
  }
  .product_stockcount_hdrheading{font-size: 1.311vw;}
  .product_stockcount_hdraddprod svg {
    width: 0.952vw;
    height: 0.952vw;
}


.download__uploaded_files svg,.download__generated_files svg  {
  width: 1.098vw;
  height: 1.098vw;
}

.download__uploaded_files span.MuiBadge-badge {
  padding: 0;
  font-size: 0.586vw;
  min-width: 0.952vw;
  min-height: 0.952vw;
  top: 0.22vw;
}
}