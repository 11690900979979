.payment__add__card_btn {
  padding: 6px 16px;
  border-radius: 5px;
  box-shadow: 0 0 5px 2px rgba(230, 229, 229, 0.774);
  width: fit-content;
  margin: 10px 0;
  cursor: pointer;
  font-size: 14px;
  color: grey;
  display: flex;
  align-items: center;
  user-select: none;
  color: #0c6efd;
}
.payment__add__card_btn * {
  position: relative;
  left: -3px;
  top: -1px;
}
.payment__add__card_btn:active {
  transform: translate(0px, -1px);
}
.payment__credit_cards {
  width: 100%;
  min-width: 95vw;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
}
@media only screen and (min-width: 1190px) {
  .payment__add__card_btn{font-size: 1.025vw;}
  .payment__add__card_btn svg{
    width: 1.464vw;
    height: 1.464vw;
  }
}