.add__bundle_modal {
  min-height: 250px;
  max-height: 95vh;
  overflow-y: auto;
  min-width: 310px;
  width: 1150px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 30px;
}
.pkgbundle__details_modalcontent {
  padding: 20px 0 10px 0;

  width: 100%;
}
.pkgbundle__details_modalcontent > p {
  margin-left: 5px;
}

.pkgbundle__details_modalcontent::-webkit-scrollbar {
  width: 5px;
}

.pkgbundle__details_modalcontent::-webkit-scrollbar-thumb {
  background-color: #0c69f3;
}

.pkgbundle__details_modalcontent::-webkit-scrollbar-track {
  background-color: #dbd9d9;
}
.pkgbundle__details_modalcontentlft {
  flex: 1;
}
.pkgbundle__details_modalcontentrt {
  width: fit-content;
  padding-left: 25px;
}

.add__bundle_modalheading {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid lightgray;
  font-weight: 500;
  font-size: 18px;
}
.add__bundle_modalcontent {
  padding: 20px 0 10px 0;
}
.add__bundle_modalrow {
  display: flex;
  width: 100%;
}
.add__bundle_modalinputwrap {
  flex: 1;
  margin: 0 5px 10px 5px;
  height: fit-content;
}
.add__bundle_modalinput {
  width: 100%;
  border: 0.5px solid lightgrey;
  outline: none;
  border-radius: 5px;
  height: 50px !important;
  padding: 0 10px;
  transition: 0.3s;
}
.add__bundle_modallabel {
  width: 100px;
  height: 40px;
  margin: 0 5px 10px 5px;
  font-size: 17px;
  display: flex;
  padding: 2px;
  align-items: center;
}
.add__bundle_modalinput:focus {
  outline: 0.5px solid #0c68f373;
}
.add__bundle_modalinput::-webkit-inner-spin-button,
.add__bundle_modalinput::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.add__bundle_modallabel2 {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
}
.add__bundle_modalfooter {
  width: 100%;
  padding: 10px;
  height: 60px;
  border-top: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.add__bundle_modalcncl {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #454545;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}
.add__bundle_modalsave {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c69f3;
  border: 1px solid #0c69f3;
  border-radius: 3px;
  margin-left: 10px;
  color: white;
  cursor: pointer;
}
.add__bundle_profimagelbl {
  height: 46px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 0px 3px 3px rgba(219, 215, 215, 0.24);
    -webkit-box-shadow: 0px 0px 3px 3px rgba(219, 215, 215, 0.24);
    -moz-box-shadow: 0px 0px 3px 3px rgba(219, 215, 215, 0.24); */
  cursor: pointer;
  color: #454545;
  font-weight: 500;
  outline: 2px dashed lightgrey;
  margin-top: 2px;
  border-radius: 5px;
  background-color: white;
}
.add__bundle_profimagelbl > * {
  margin-right: 5px;
}
.add__bundle_profimageinput {
  display: none;
}
.add__bundle_mrpkgsheading {
  padding: 5px;
}
.add__bundle_addpkgbtn {
  width: 47px;
  height: 47px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 1px 1px 2px 2px rgb(223, 222, 222);
  color: #0c69f3;
  margin-top: 1px;
  transition: 0.4s;
}
.add__bundle_addpkgbtn:hover {
  background-color: #0c69f3;
  color: white;
}
.add__bundle_delpkgbtn:active {
  transform: scale(0.9);
}
.add__bundle_delpkgbtn {
  width: 47px;
  height: 47px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 1px 1px 2px 2px rgb(223, 222, 222);
  color: tomato;
  margin-top: 1px;
  transition: 0.4s;
}
.add__bundle_delpkgbtn:hover {
  background-color: tomato;
  color: white;
}
.add__bundle_addpkgbtn:active {
  transform: scale(0.9);
}
.add__bundle_mrpkgslist {
  width: 100%;
  height: 250px;
  padding: 15px 0;
  margin-bottom: 20px;
  overflow-y: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(../../../../../../../assets/images/package.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 250px;
}
.add__bundle_mrpkgslist * {
  z-index: 2;
}
.add__bundle_mrpkgslistbg {
  height: 120px;
  top: 80px;
  position: absolute;
  z-index: 1;
}
.add__bundle_mrpkgslist::-webkit-scrollbar {
  width: 5px;
}

.add__bundle_mrpkgslist::-webkit-scrollbar-thumb {
  background-color: #0c69f3;
}
.add__bundle_mrpkgslist::-webkit-scrollbar-track {
  background-color: #dbd9d9;
}
.add__bundle_modalrowspacebetween {
  display: flex;
  justify-content: space-around !important;
  align-items: center;
}
.add_bundle__unitbtnswrap {
  display: flex;
  font-size: 14px;
  background-color: white;
  color: #0c69f3;
  cursor: pointer;
  box-shadow: 0 0 3px 0 rgb(211, 210, 210);
  border-radius: 5px;
  user-select: none;
  overflow: hidden;
}

.add_bundle__unitbtn {
  width: 100px;
  display: flex;
  justify-content: center;
  padding: 5px 0;
  transition: 0.4s;
}
.add_bundle__unitbtn:active {
  transform: translateY(1px);
}
.add_bundle__unitbtnactive {
  color: white;
  background-color: #0c69f3;
}
.add__bundle_modal.add__bundle_modal_wrap .add__bundle_modalheading {
  font-size: 17px;
}
.add__bundle_modal.add__bundle_modal_wrap input.add__bundle_modalinput {
  height: 40px !important;
  font-size: 11px;
}
.add__bundle_modal.add__bundle_modal_wrap label.add__bundle_profimagelbl {
  height: 35px;
  font-size: 10px;
}
.add__bundle_modal.add__bundle_modal_wrap  .add__bundle_modalrowspacebetween {
  justify-content: center !important;
  column-gap: 10px;
}
.add__bundle_modal.add__bundle_modal_wrap .add__bundle_modalrowspacebetween .add_bundle__unitbtnswrap {
  font-size: 11px;
}
.add__bundle_modal.add__bundle_modal_wrap .add__bundle_modalrowspacebetween .add__bundle_mrpkgsheading {
  font-size: 16px;
}
.add__bundle_modal.add__bundle_modal_wrap .add__bundle_modalrowspacebetween  h6.add__bundle_mrpkgsheading {
  margin: 0;
}
.add__bundle_modal.add__bundle_modal_wrap .pkgbundle__details_pkgslist .pkgbundle__details_pkgslistheading {
  font-size: 12px;
}
.add__bundle_modal.add__bundle_modal_wrap .pkgbundle__details_pkgslist .pkgbundle__details_pkgslistdata {
  font-size: 11px;
}
.add__bundle_modal.add__bundle_modal_wrap .add__bundle_modalfooter .add__bundle_modalcncl {
  font-size: 12px;
  height: 30px;
}
.add__bundle_modal.add__bundle_modal_wrap .add__bundle_modalfooter .add__bundle_modalsave {
  font-size: 12px;
  height: 30px;
}
.add__bundle_modal.add__bundle_modal_wrap {
  height: fit-content;
}
.add__bundle_modal.add__bundle_modal_wrap .pkgbundle__details_modalcontent{
  padding-top: 10px;
}
.add__bundle_modal.add__bundle_modal_wrap .add__bundle_modalrowspacebetween{
  margin-top: 15px;
  flex-wrap: wrap;
}
.add__bundle_modal.add__bundle_modal_wrap  .add__bundle_modal.add__bundle_modal_wrap {
  height: fit-content;
}
.add__bundle_modal.add__bundle_modal_wrap .pkgbundle__details_modalcontent {
  padding-bottom: 0;
}
.add__bundle_modal.add__bundle_modal_wrap .add__bundle_modalcontent {
  padding-bottom: 0;
}
@media only screen and (min-width: 1199px) {
  .add__bundle_modal.add__bundle_modal_wrap{
    max-width: 57.813vw;
  }
  .add__bundle_modal.add__bundle_modal_wrap .add__bundle_modalheading{
    font-size: 0.885vw;
  }
  .add__bundle_modal.add__bundle_modal_wrap input.add__bundle_modalinput{
    height: 2.083vw !important;
    font-size: 0.573vw;
  }
  .add__bundle_modal.add__bundle_modal_wrap label.add__bundle_profimagelbl{
    height: 1.823vw;
    font-size: 0.521vw;
  }
  .add__bundle_modal.add__bundle_modal_wrap .add__bundle_modalrowspacebetween .add__bundle_mrpkgsheading{
    font-size: 0.833vw;
  }
  .add__bundle_modal.add__bundle_modal_wrap .add__bundle_modalrowspacebetween .add_bundle__unitbtnswrap{
    font-size: 0.673vw;
  }
  .add__bundle_modal.add__bundle_modal_wrap .pkgbundle__details_pkgslist .pkgbundle__details_pkgslistheading{
    font-size: 0.625vw;
  }
  .add__bundle_modal.add__bundle_modal_wrap .pkgbundle__details_pkgslist .pkgbundle__details_pkgslistdata{
    font-size: 0.673vw;
  }
  .add__bundle_modal.add__bundle_modal_wrap .add__bundle_modalfooter .add__bundle_modalcncl,.add__bundle_modal.add__bundle_modal_wrap .add__bundle_modalfooter .add__bundle_modalsave{
    height: 1.563vw;
    font-size: 0.673vw;
  }
  .add__bundle_modal.add__bundle_modal_wrap .pkgbundle__details_pkgslist{
    grid-template-columns: 13.896vw 13.938vw 6.688vw 6.688vw 6.688vw 6.688vw;
  }
}
