.payments__settings_wrap {
  width: 100%;
  min-width: 95vw;
  padding: 60px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.payments__settings_card {
  width: 90vw;
  max-width: 95%;
  height: auto;
  border-radius: 20px;
  box-shadow: 1px 1px 5px 2px rgb(228, 228, 228);
  padding: 25px;
}
