.currencyStock__add_new_currency_modalfooter {
  width: 100%;
  padding: 10px;
  height: 60px;
  border-top: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}

.currencyStock__add_new_currency_modalsubmit {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c69f3;
  border: 1px solid #0c69f3;
  border-radius: 3px;
  margin-left: 10px;
  color: white;
  cursor: pointer;
  width: 70px;
  height: 35px;
  font-size: 13px;
}

.currencyStock__add_new_currency_modalreset {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #454545;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  width: 70px;
  height: 35px;
  font-size: 13px;
}
