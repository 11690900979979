.QuoteTabledataselected{
    padding: 0px 12px;
    width: 1%;
    background-color: rgb(25, 118, 210);
    font-weight: bold;
}



.QuoteTabledata2{
    padding: 0px 12px;
    width: 1%;
}

.QuoteTableheaddata{
    padding: 12px;
}

.QuoteTable{
    width: 100%;
    min-width: 100%;
  white-space: nowrap;
    /* margin: 1rem 2rem; */
}

.QuoteTableDivCenter{
    align-items: center;
    justify-content: center;
    display: flex;
}

.QuoteTableRowSelected {
    background-color: #eef2fb;
    border-left: 8px solid #1976d2;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
    cursor: pointer;
  }
  
  .QuoteTableRowSelected td {
    font-weight: bold;
  }
  
  .QuoteTableRowSelected td div {
    font-weight: bold;
  }

.QuoteTableRow{
    background-color: #ffffff;
    border-left: "";
    border-bottom: 1px solid #ddd;
    font-weight: "";
}

.QuoteTableRow:hover{
    background-color: #EEF2FB;
    cursor: pointer;
}

.TableContainer {
    overflow-x: auto;
    margin: 1rem 2rem;
  }