/* add role  */
.create__role_modal {
  min-height: 250px;
  min-width: 310px;
  width: 682px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
}
.role__modalheading {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid lightgray;
  font-weight: 500;
  font-size: 18px;
}
.role__form_content {
  width: 100%;
  padding: 10px 0;
}
.role__fieldwrap {
  width: 100%;
  margin-top: 10px;
  padding: 10px 0px;
}
.role__fieldlbl {
  display: block;
  color: grey;
  font-weight: 500;
  font-size: 15px;
}
.role__inputfield {
  border: 0.5px solid rgb(224, 223, 223);
  width: 100%;
  margin-top: 3px;
  outline: none;
  height: 45px;
  padding: 0 8px;
  border-radius: 2px;
}
.mngroles_heading {
  font-weight: bold;
}
.role__form_content::-webkit-scrollbar {
  width: 5px;
}
.role__form_content::-webkit-scrollbar-thumb {
  background-color: #888;
}
.role__form_content::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
@media screen and (max-width: 599px) {
  .togler {
    display: block;
    margin-right: 1rem;
  }
}

/* end role  */

/* manage helper user roles  */
.mngroles__tabel {
  overflow: auto;
  border-left: 1px solid #d9d9d9;
}
/* .mngroles__tabel table td:first-child {
      position: sticky;
      left: 0;
    }
    .mngroles__tabel table th:first-child {
      position: sticky;
      left: 0;
    } */
.mngroles__tabel table tr th:first-child {
  position: sticky;
  left: 0;
  z-index: 4;
  background: #d1d2d2;
}
.mngroles__tabel table td:first-child {
  position: sticky;
  left: 0;
  z-index: 4;
  background: #ffffff;
}
.mngroles__tabel table tr td.mngroles__td1:first-child {
  position: sticky;
  left: 0;
  z-index: 4;
  background: #f8fafc;
}
.mngroles__header {
  background: #f8fafc;
  border: 1px solid #d9d9d9;
  border-left: none;
}
.mngroles__td1 {
  font-weight: bold;
  padding-left: 20px;
  min-width: 300px;
}
.mngroles__td2 {
  padding-top: 5px;
  text-align: center;
}
.mngroles__sub_td1 {
  padding-left: 20px;
  font-size: 14px;
  min-width: 300px;
}
.mngroles__sub_td2 {
  padding-top: 5px;
  text-align: center;
}
.mngroles__sub_tr {
  border: 1px solid #d9d9d9;
  border-left: none;
}
.mngroles__header_tr {
  background-color: #d1d2d2;
  border: 1px solid #d9d9d9;
  height: 35px;
  border-left: none;
}

.mngroles__header_th {
  padding: 0px 20px;
  min-width: 200px;
  text-align: center;
}
.mngroles__header_delete {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

/* scroll style */
.mngroles__tabel::-webkit-scrollbar {
  height: 3px;
}
.mngroles__tabel::-webkit-scrollbar-thumb {
  background-color: #888;
}
.mngroles__tabel::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.mngroles__input_style {
  background: #f0f0f0;
  border-radius: 5px;
  text-align: center;
  width: 150px;
}
.roles__header_th {
  padding: 0px 20px;
}
.roles__header_tr {
  background-color: #d1d2d2;
  border: 1px solid #d9d9d9;
}

/* add role  */

.child__wraper {
  padding-left: 50px;
}
.parent__label .MuiFormControlLabel-label {
  font-weight: bold !important;
}
.togler {
  display: none;
}
.card__wrapper {
  overflow-y: auto;
  height: 400px;
  margin: 10px 0px;
}
.card__parent {
  margin-top: 10px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
/* end add role */
