.prdtable__images_modal {
  min-height: 350px;
  min-width: 310px;
  width: 550px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
  max-height: 450px;
  overflow-y: auto;
  display: inline-grid;
}
.prdtable__images_modal::-webkit-scrollbar {
  width: 5px;
}

.prdtable__images_modal::-webkit-scrollbar-thumb {
  background-color: grey;
}
.prdtable__images_modal::-webkit-scrollbar-track {
  background-color: #dbd9d9;
}
.prdtable__images_modalheading {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid lightgray;
  font-weight: 500;
  font-size: 18px;
}
.prdtable__images_modalcontent {
  padding: 10px;
}
.prdtable__images_modalfooter {
  width: 100%;
  padding: 10px;
  height: 60px;
  border-top: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.prdtable__images_modalcncl {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #454545;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}
.prdtable__images_modalsave {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c69f3;
  border: 1px solid #0c69f3;
  border-radius: 3px;
  margin-left: 10px;
  color: white;
  cursor: pointer;
}
.prdtable__images_modalimgs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.prdtable__images_modalimg {
  width: 150px;
  height: 120px;
  border-radius: 5px;
  object-fit: contain;
  margin: 5px;
  box-shadow: 0.5px 0.5px 3px 1px lightgrey;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 10px;
}
.prdtable__images_addnewbtn {
  width: 100%;
   /*height: 120px; */
  box-shadow: 0.5px 0.5px 3px 1px lightgrey;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
}
.prdtable__images_addnewbtn:active {
  transform: translateY(-1px);
}
.prdtable__images_addnewbtn > img {
 width: 30px;
    height: auto;
  /* height: 60px;
  object-fit: contain; */
}
.prdtable__images_addnewbtn > div {
      font-size: 10px;
    font-weight: 500;
    margin-top: 2px;
}
.prdtable__images_modaltopbar {
  position: relative;

}
.prdtable__images_modalclose {
  position: absolute;
  right: -8px;
  top: -8px;
  cursor: pointer;
  color: grey;
}
.prdtable__images_modalback {
  position: absolute;
  left: -8px;
  top: -8px;
  cursor: pointer;
  color: grey;
}
.prdtable__images_fullimgview {
  object-fit: contain;
  overflow: hidden;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 0.5px solid lightgrey;
}
.prdtable__images_fullimgview > img {
  max-width: 100%;
  max-height: 340px;
}
.prod__imag_es_modalfooter {
  width: 100%;
  padding: 10px;
  height: 60px;
  border-top: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.prod__imag_es_modalfootercncl {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #454545;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}
.prod__imag_es_modalfootersave {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c69f3;
  border: 1px solid #0c69f3;
  border-radius: 3px;
  margin-left: 10px;
  color: white;
  cursor: pointer;
}
.primary__image_wrap .prdtable__images_modalimg{
  border: 1px solid #000;

}

.primary__image_wrap .prdtable__images_modalimg svg {
  color: #fdd40c;
}
.prdtable__images_modalimg,.prdtable__images_addnewbtn{
  height: 100px !important;
  max-width:112px
}

.uploaded__image{    border-radius: 12px;}
.imageSelection__title {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid lightgray;
  font-weight: 500;
  font-size: 18px;
}
.prdtable__images_fullimgview.note__attachment_modal_wrap {
  margin: 0;
  width: 100%;
  height: auto;
}