.admn__img_modal {
  min-width: 600px;
  max-width: 95%;
  min-height: 300px;
  max-height: 800px;
  position: relative;
  background-color: white !important;
}
.admn__img_modal > img {
  min-width: inherit;
  max-height: inherit;
  object-fit: contain;
}
.image__modalclose_btn {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #0c6efd;
  background-color: white;
  padding: 3px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
  transition: 0.2s;
}
.image__modalclose_btn:hover {
  transform: scale(1.2);
}
.disable__click {
  pointer-events: none !important;
}
.image__imgdownload_btn {
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: white;
  background-color: #0c6efd;
  transition: 0.4s;
}
.image__imgdownload_btn:hover {
  background-color: #075ad6;
}
.image__imgdownload_btnicon {
  font-weight: 500;
  color: white;
  font-size: 20px;
  margin-right: 5px;
}
.admnimg__modal_spinnerwrap {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admn__img_modalspinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.admn__img_modalspinner div {
  transform-origin: 40px 40px;
  animation: admn__img_modalspinner 1.2s linear infinite;
}
.admn__img_modalspinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #075ad6;
}
.admn__img_modalspinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.admn__img_modalspinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.admn__img_modalspinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.admn__img_modalspinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.admn__img_modalspinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.admn__img_modalspinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.admn__img_modalspinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.admn__img_modalspinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.admn__img_modalspinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.admn__img_modalspinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.admn__img_modalspinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.admn__img_modalspinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes admn__img_modalspinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@media (max-width: 600px) {
  .image__modal_wrap {
    min-width: 95%;
  }
}
