/* units header  */
.units_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: grey;
  margin: 20px 5px 0px 5px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid lightgrey;
}
.units_hdrheading {
  font-weight: 400 !important;
  font-size: 22px;
}
.units_buttonsWrapper {
  display: flex;
  gap: 20px;
}
.units_hdraddprod {
  border-radius: 5px;
  box-shadow: 0.3px 0.3px 3px 1px rgb(230, 228, 228);
  display: flex;
  padding: 8px 18px;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
  color: grey;
  cursor: pointer;
}
.units_hdraddprod:hover {
  color: grey;
}
.units_hdraddprod > * {
  margin-right: 3px;
}
.units__main_wrap {
  display: flex;
  align-items: flex-start;
  column-gap: 30px;
  flex-wrap: wrap;
}
.units__table_list {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .units__add_new_unit {
    order: 1;
    margin-bottom: 30px;
  }

  .units__table_list {
    order: 2;
  }
  .units_header {
    margin-bottom: 15px;
  }
  .units_hdraddprod span {
    display: none;
  }
}
