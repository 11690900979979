/* transfer notes header  */
.transfnotes_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: grey;
  margin: 20px 5px 0px 5px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid lightgrey;
}
.transfnotes_hdrheading {
  font-weight: 400 !important;
  font-size: 22px;
}
.transfnotes_buttonsWrapper {
  display: flex;
  gap: 20px;
}
.transfnotes_hdraddprod {
  border-radius: 5px;
  box-shadow: 0.3px 0.3px 3px 1px rgb(230, 228, 228);
  display: flex;
  padding: 6px 8px;
  align-items: center;
  font-weight: 500;
  font-size: 8px;
  text-decoration: none;
  color: grey;
  cursor: pointer;
}
.stockCount__add_new_btn {
  text-decoration: none;
}
.transfnotes_hdraddprod:hover {
  color: grey;
}
.transfnotes_hdraddprod > * {
  margin-right: 3px;
}

.custabscreenthd__grn_status {
  font-size: 8px;
  padding: 3px 6px;
  border-radius: 2px;
  text-transform: capitalize;
  border: 1px solid grey;
  min-width: fit-content;
  color: black;
  align-items: center;
  justify-content: center;
}
/* close  */

.createTransferNote__desc_page {
  padding: 10px 20px;
  max-width: 97%;
  margin: 50px auto 0 auto;
}
.addTransferProduct__main_wrap {
  display: -webkit-box;
  padding: 15px;
  overflow: auto;
}

h3.heading__main_ctn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: grey;
  margin: 20px 5px 50px 5px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid lightgrey;
}

.form__field_atn_wrap {
  display: flex;
  column-gap: 20px;
}

.reference_field_wrap,
.fromWarehouse_field_wrap,
.toWarehouse_field_wrap,
.warehouse_status_field_wrap {
  width: 100%;
  max-width: 15.2%;
}
/* .wrapper__transfer_submit {
  width: 100%;
} */

/* button.btn__transfer_submit {
  background: rgb(12, 105, 243);
  border-radius: 3px;
  color: #fff;
  padding: 8px 20px;
  min-width: 200px;
} */
.products_details_table_weight {
  width: 50px;
}
.details_container_modal {
  min-height: 250px;
  width: 96%;
  max-width: 1100px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
}
/* .custabscreen__rdverttable_row {
  display: flex;
  margin-bottom: 10px;
  flex-wrap: wrap;
  width: 32%;
  min-width: 310px;
} */

h3.details_container_modal_title {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid lightgray;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 25px;
}

/* .custabscreen__rdverttable_heading {
  font-size: 14px;
  border-right: 0;
  font-weight: 700;
} */

.details_container_modal_body {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.5%;
  border: 1px solid #d9d9d9;
  padding: 16px 16px 5px 16px;
  margin-bottom: 15px;
}

/* .custabscreen__rdverttable_data {
  font-size: 14px;
  border-left: 0;
  padding-left: 14px;
} */
@media only screen and (max-width: 768px) {
  .transfnotes_hdrheading {
    font-size: 18px;
  }
  .transfnotes_buttonsWrapper span {
    display: none;
  }
  .transfnotes_header {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 1190px) {
  .transfnotes_hdrheading {
    font-size: 1.311vw;
  }
  .transfnotes_hdraddprod {
    font-size: 1.025vw;
    padding: 0.439vw 0.586vw;
  }
  .transfnotes_hdraddprod svg {
    width: 1.025vw;
    height: 1.025vw;
  }
}
