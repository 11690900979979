.payAmount__field_wrap {
    display: flex;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
    margin-bottom: 10px;
    max-width: 480px;
}

label {
    font-size: 13px;
    min-width: 105px;
    display: inline-flex;
    align-items: center;
}

input.pmtsadd__balance_modalinput {
    height: 40px !important;
    border: none;
    border-left: 1px solid #ccc;
    border-radius: 0;
    margin: 0 !important;
    outline: none !important;
    font-size: 12px;
}

.pmtsadd__strpcard_btn {
    font-size: 13px;
    padding: 7px 12px;
    line-height: 1.3;
}

.pmtsadd__strpcard_btnwrap {
    justify-content: flex-start !important;
}

.payViaCard_main_wrap {
    width: 100%;
}
.payAmount__field_label {
    display: inline-flex;
}
.payAmount__form_box #payment-element{
    background: #f1f1f1;
    padding: 19px ;
    border-radius: 5px;
    box-shadow: 0px 0px 50px 0px #b9b9b994;
    border: 1px solid #e5e5e5;
    max-width: 480px;
    margin-bottom: 10px;
    width: 100%;
}
.pmtsadd__balance_modalfooter .pmtsadd__balance_modalcncl,.pmtsadd__balance_modalfooter  .pmtsadd__balance_modalsavedisabled {
    width: 70px;
    height: 35px;
    font-size: 13px;
}

.loader__card_wrap {
    width: 100%;
}
