.category_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: grey;
  margin: 20px 5px 0px 5px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid lightgrey;
}
.category_hdrheading {
  font-weight: 400 !important;
  font-size: 22px;
}
.category_buttonsWrapper {
  display: flex;
  gap: 20px;
}
.category_hdraddprod {
  border-radius: 5px;
  box-shadow: 0.3px 0.3px 3px 1px rgb(230, 228, 228);
  display: flex;
  padding: 6px 8px;
  align-items: center;
  font-weight: 500;
  font-size: 8px;
  text-decoration: none;
  color: grey;
  cursor: pointer;
}
.category_hdraddprod:hover {
  color: grey;
}
.category_hdraddprod > * {
  margin-right: 3px;
}

/* button.Category__add_new_category_create_btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
  color: #fff !important;
  background: #1976d2;
  padding: 8px 10px;
  column-gap: 6px;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;
  margin-top: 0px;
  min-width: 70px;
  justify-content: center;
} */

.categoryPop__add_new_currency {
  min-height: 210px;
  min-width: 310px;
  width: 550px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
}

.categoryPop__add_new_category_header {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid lightgray;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 30px;
}

h3.categoryPop__add_new_category_title {
  font-size: 15px;
  margin-bottom: 0;
  color: #454545;
  font-weight: bold;
  text-transform: uppercase;
}

.categoryPop__add_new_category_body {
  /* display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  align-items: baseline;
  justify-content: ; */
}

.categoryPop__add_new_category_body .MuiFormControl-root {
  width: 100%;
  /* max-width: 245px; */
  margin-bottom: 25px;
}
/* button.category__Modal_Open_btn {
  border-radius: 5px;
  box-shadow: 0.3px 0.3px 3px 1px rgb(230, 228, 228);
  display: flex;
  padding: 8px 18px;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  color: grey !important;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-bottom: 15px;
  margin-top: 15px;
  column-gap: 5px;
  cursor: pointer !important;
} */
.subcategory__main_list_container {
  padding: 20px 15px 5px 15px;
  width: 96%;
  border: 1px solid #eaeaea;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

.noCat__found {
  margin-bottom: 15px;
  padding: 0 15px;
}

svg.custabscreen__details_action_edit,
svg.custabscreen__details_action_del {
  font-size: 20px;
  margin: 0 auto;
}

.subcategory__main_list_container h3 {
  background: #f7f7f7;
  border: 1px solid #d9d9d9;
  border-radius: 2px 2px 0px 0px;
  height: 45px;
  font-size: 15px;
  text-transform: uppercase;
  color: #454545;
  font-weight: bold;
  padding: 0 20px;
  display: flex;
  align-items: center;
  position: sticky;
  right: 0;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 5;
  visibility: visible !important;
}

table.subcategory__main_list_table {
  width: 100%;
}

table.subcategory__main_list_table th,
table.subcategory__main_list_table td {
  padding: 7px 22px;
  font-size: 13px;
}

table.subcategory__main_list_table tr:last-child {
  border: none;
}
.custabscreen__details_thdata_actions {
  display: flex;
  cursor: pointer;
}

td.custabscreen__details_thdata_actions {
  max-width: 90px;
}

/* .custabscreen__subcategory_actions_btn {
  background: transparent;
  color: #4c4c4c;
  cursor: pointer;
  border: 1px solid #b7b7b7;
  padding: 6px;
  border-radius: 2px;
} */
th.custabscreen__details_thdata_actions {
  max-width: 90px;
}

table.subcategory__main_list_table tr {
  border-bottom: 1px solid #eaeaea;
}

@media only screen and (max-width: 768px) {
  .categoryPop__add_new_currency {
    width: 90%;
    top: calc(50% - 165px);
  }
}
@media only screen and (min-width: 1190px) {
  .category_hdrheading{
    font-size: 1.311vw;
  }
  .category_hdraddprod{font-size: 1.025vw;padding: 0.439vw 0.586vw}
  .category_hdraddprod svg {width: 1.025vw; height: 1.025vw;}
}