.addmarketplace__form_wrap {
  width: 100%;
  position: relative;
}
.addmarketplace__form_logo {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;
  position: absolute;
  top: -15px;
}

.addmarketplace__form_logo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.addmarketplace__form_content {
  width: 100%;
  padding: 10px 0;
}
.addmarketplace__fcntnt_fieldwrap {
  width: 100%;
  margin-top: 10px;
  padding: 0 10px;
}
.addmarketplace__fcntnt_fieldlbl {
  display: block;
  color: grey;
  font-weight: 500;
  font-size: 15px;
}
.addmarketplace__fcntnt_field {
  border: 0.5px solid rgb(224, 223, 223);
  width: 100%;
  margin-top: 3px;
  outline: none;
  height: 45px;
  padding: 0 8px;
  border-radius: 2px;
}
.addmarketplace__fcntnt_textarea {
  border: 0.5px solid rgb(224, 223, 223);
  width: 100%;
  margin-top: 3px;
  outline: none;
  height: 105px;
  padding: 8px;
  border-radius: 2px;
}
