.package__screen_tablewrap {
  background: #ffffff;
  border: 1px solid #dedede;
  box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  width: fit-content;
  height: fit-content;
  position: relative;
  overflow-x: auto;
  max-width: 100%;
}
.dispatcher__soption_disabled {
  pointer-events: none;
  color: lightgray !important;
}
.package__screen_loading {
  width: 95vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}
.package__screen_empty {
  width: 95vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  font-size: 35px;
  color: lightgray;
}
.package__screen_tablecontent {
  position: relative;
  width: fit-content;
}
.dispscreen__table_topbar {
  display: flex;
  align-items: center;
}
.package__scrnempt_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.package__scrnempt_contenticon {
  font-size: 60px !important;
}
.dispatcher__screen_optionswrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 25px;
}
.pmnts__prsnlbalance_box {
  width: 280px;
  height: 110px;
  margin-top: 25px;
  margin-left: 20px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  box-shadow: -2px -2px 5px 1px rgba(197, 196, 196, 0.75);
  -webkit-box-shadow: -2px -2px 5px 1px rgba(197, 196, 196, 0.75);
  -moz-box-shadow: -2px -2px 5px 1px rgba(197, 196, 196, 0.75);
  padding: 13px 15px;
  padding-right: 0;
  color: rgb(163, 162, 162);
  border-radius: 5px;
  /* background: #b1acac;
  background: linear-gradient(352deg, #b1acac 0%, #c8c8c0 40%, #ece7e7 80%);
  background: -webkit-linear-gradient(
    352deg,
    #b1acac 0%,
    #c8c8c0 40%,
    #ece7e7 80%
  );
  background: -moz-linear-gradient(
    352deg,
    #b1acac 0%,
    #c8c8c0 40%,
    #ece7e7 80%
  ); */
  margin-bottom: 5px;
}
.pmnts__prsnlbalance_boxleft {
  height: 100%;
  flex: 1;
  min-width: fit-content;
}
.pmnts__prsnlbalance_boxleftlbl {
  font-size: 15px;
}
.pmnts__prsnlbalance_boxleftprice {
  font-size: 32px;
  margin-top: 8px;
  margin-left: 2px;
  color: #454545;
  font-weight: 500;
}
.pmnts__prsnlbalance_boxleftprice > span {
  font-size: 14px;
  font-weight: 600;
  color: #0c6efd;
  margin-left: 15px;
  position: relative;
  bottom: 5px;
}
.pmnts__prsnlbalance_boxright {
  width: 90px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pmnts__prsnlbalance_boxbtn {
  background-color: white;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 15px;
  padding-left: 5px;
  box-shadow: 2px 2px 5px 2px rgba(197, 196, 196, 0.75);
  -webkit-box-shadow: 2px 2px 5px 2px rgba(197, 196, 196, 0.75);
  -moz-box-shadow: 2px 2px 5px 2px rgba(197, 196, 196, 0.75);
}
.pmnts__prsnlbalance_boxbtn:hover {
  box-shadow: 0px 0px 5px 2px rgba(197, 196, 196, 0.75);
  -webkit-box-shadow: 0px 0px 5px 2px rgba(197, 196, 196, 0.75);
  -moz-box-shadow: 0px 0px 5px 2px rgba(197, 196, 196, 0.75);
}
.pmnts__prsnlbalance_boxbtn:active {
  transform: scale(0.95);
}
.pmnts__prsnlbalance_boxbtn > * {
  margin-right: 5px;
}
.payments__page_content {
  display: flex;
  flex-wrap: wrap;
}
.paymsec__credit_cards {
  width: 100%;
  min-width: 95vw;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
  justify-content: center;
}
.add__card_btn {
  padding: 6px 16px;
  border-radius: 5px;
  box-shadow: 0 0 5px 2px rgba(230, 229, 229, 0.774);
  width: fit-content;
  margin: 10px 0;
  cursor: pointer;
  font-size: 14px;
  color: grey;
  display: flex;
  align-items: center;
  user-select: none;
  color: #0c6efd;
}
.add__card_btn * {
  position: relative;
  left: -3px;
  top: -1px;
}
.add__card_btn:active {
  transform: translate(0px, -1px);
}
@media (max-width: 1430px) {
  .payments__page_content {
    flex-direction: column-reverse;
  }
  .pmnts__prsnlbalance_box {
    margin-left: 0;
  }
}
@media only screen and (max-width: 992px) {

.pmnts__prsnlbalance_box {
  width: fit-content;
  height: auto;
}

.pmnts__prsnlbalance_boxleftlbl {
  font-size: 12px;
}

.pmnts__prsnlbalance_boxleftprice {
  font-size: 18px;
}

.dispatcher__screen_searchwrap {
  width: 100% !important;
    max-width: 220px;
}

.pmnts__prsnlbalance_boxright {
  width: 35px;
  height: 35px;
  margin-top: 20px;
}

.pmnts__prsnlbalance_boxbtn {
  width: 100%;
  height: 100%;
}

}

@media only screen and (max-width: 600px) {

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  padding: 0 5px !important;
  font-size: 13px !important;
}
.MuiBox-root.css-8atqhb {margin-bottom: 20px;}
}