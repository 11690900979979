/* settings  */
.parent_wraper {
  display: flex;
}
.dash_table_wrapper {
  padding: 36px;
  width: calc(100% - 300px);
}

.img-fluid {
  background-color: black;
}

.shipper__user_box {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  padding: 3rem;
}
.permission__bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.heading__text {
  font-size: 16px;
  text-transform: uppercase;
  color: #454545;
  font-weight: bold;
}

.permissions__button {
  width: fit-content;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c69f3;
  border: 1px solid #0c69f3;
  border-radius: 3px;
  margin-left: 10px;
  color: white;
  cursor: pointer;
  padding: 0px 15px;
}

/* end settings css  */

.settings__page_hdraddprod {
  border-radius: 5px;
  box-shadow: 0.3px 0.3px 3px 1px rgb(230, 228, 228);
  display: flex;
  padding: 6px 8px;
  align-items: center;
  font-weight: 500;
  font-size: 8px;
  text-decoration: none;
  color: grey;
  cursor: pointer;
  height: fit-content;
}
.settings__page_hdraddprod:hover {
  color: grey;
}
.settings__page_hdraddprod > * {
  margin-right: 3px;
}

@media only screen and (max-width: 768px) {
  .settings__page_hdraddprod span {
    display: none;
  }
}

@media only screen and (min-width: 1170px) {
  .settings__page_hdraddprod {
    font-size: 0.805vw;
    padding: 0.439vw 0.586vw;
  }
  .settings__page_hdraddprod svg {
    width: 1.025vw;
    height: 1.025vw;
  }
}
