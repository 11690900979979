/* my css  */
.fulfilment_byweight_box_wrapper {
  display: flex;
  flex-wrap: wrap;
}
.fulfilment_byweight_formbox1 {
  margin-bottom: 10px;
  padding-bottom: 10px;
  flex: 5;
}
.fulfilment_byweight_formbox2 {
  margin-bottom: 10px;
  padding-bottom: 10px;
  flex: 5;
}
.fulfilment_byweight_formboxheading {
  padding: 10px 0px;
  color: grey;
}
.fulfilment_byweight_formrow {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px 0px;
  align-items: center;
}

.fulfilment_byweight_formfield {
  width: 130px;
}
.fulfilment_byweight_cancel_btn {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: tomato;
  border-radius: 5px;
  color: white;
}
.fulfilment_byweight_addmore_btn {
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: white;
  border-radius: 5px;
  background-color: #0c6efd;
  padding: 5px;
  padding-right: 10px;
  width: fit-content;
  position: relative;
  cursor: pointer;
}
.fulfilment_byweight_addmore_btnicon {
  margin-right: 3px;
}
.fulfilment_byweight_cell__width {
  text-align: left;
  color: black;
}

.fulfilment_byweight__addwrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}
.fulfilment_byweight__kg_lb_label {
  font-size: 12px;
  color: #454545;
  margin-bottom: 2px;
}
.fulfilment_byweight__kg_lb_wrapper {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.fulfilment_byweight__kg_lb {
  align-items: center;
  border-radius: 3px;
  box-shadow: 0.3px 0.3px 3px 1px #e6e4e4;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  height: 26px;
  justify-content: center;
  transition: 0.3s;
  -webkit-user-select: none;
  user-select: none;
  width: 40px;
  cursor: pointer;
}
.fulfilment_byweight__kg_lb_active {
  background-color: #1876d2;
  color: #fff;
}
.fulfilment_byweight_modalerrorwrap {
  width: 100%;
  height: fit-content;
  max-height: 0;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  overflow-y: hidden;
  transition: max-height 0.3s ease-in;
}
.fulfilment_byweight_modalerrorwrapopen {
  overflow-y: auto;
  max-height: fit-content;
}
.fulfilment_byweight_modalerrorbox {
  padding: 15px;
  background-color: rgba(255, 99, 71, 0.37);
  color: tomato;
  border-radius: 10px;
  transform: scale(0);
  transition: transform 0.3s ease-in;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  max-width: 90%;
  animation: reveal 0.5s forwards;
}

.fulfilment_byweight_modalfooter {
  width: 100%;
  padding: 10px;
  height: 60px;
  border-top: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.fulfilment_byweight_modalcncl {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #454545;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}
.fulfilment_byweight_modalsave {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c69f3;
  border: 1px solid #0c69f3;
  border-radius: 3px;
  margin-left: 10px;
  color: white;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .fulfilment_byweight_formbox1 {
    order: 2;
    width: 100%;
  }
}
