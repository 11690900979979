.productShipment__main_wrap {
    display: flex;
    max-width: 1670px;
    margin: 40px auto 0 auto;
    column-gap: 40px;
  }
  .productcards__porductDetails_orderdetails {
    display: flex;
    max-width: 1670px;
    margin: 0 auto;
    column-gap: 40px;
  }
  .productShipment__main_wrap .card.bg-white.shadow.address__form_card {
    height: 100%;
}
  .productCards__productDetails_main_wrap {
    width: 100%;
    max-width: 815px;
    border: 1px solid #d2d2d2;
    margin-top: 30px;
    margin-bottom: 20px;
    border-radius: 4px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  }
  
  .productCards__orderDetails_main_wrap {
    width: 100%;
    max-width: 815px;
  }
  
  .productCards__productDetails_header_main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 16px;
    background: #f7f7f7;
    border-bottom: 1px solid #d2d2d2;
    margin-bottom: 10px;
    border-radius: 5px 5px 0 0;
  }
  
  .productCards__productDetails_header_main .MuiFormControl-root {
    max-width: 260px;
    margin-top: 6px;
  }
  
  .productCards__productDetails_filters_sort_wrap {
    display: flex;
    padding: 0px 15px;
  }
  
  .productCards__productDetails_filters_wrap {
    width: 50%;
    display: flex;
    align-items: center;
  }
  
  .productCards__productDetails_sort_wrap {
    width: 50%;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .productCards__productDetails_filters_tags_wrap {
    display: inline-flex;
    border: 1px solid #d2d2d2;
    padding: 4px 10px;
    height: 39px;
    align-items: center;
    margin-left: 10px;
    border-radius: 5px;
    position: relative;
  }
  
  .productCards__productDetails_filters_tags_wrap .filter_tag {
    margin-right: 5px;
    font-size: 10px;
    font-weight: 500;
    color: #000;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .productCards__productDetails_header_main h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  .productCards__productDetails_filters_wrap h2 {
    margin: 0;
    font-size: 16px;
  }
  
  .productCards__productDetails_filters_tags_wrap .filter_tag:last-child {
    margin: 0;
  }
  
  .productCards__productDetails_sort_wrap .sort_tag {
    font-weight: 600;
  }
  .seprator__sort {
    padding: 0 7px;
  }
  .productCards__productDetails_body_main {
    display: flex;
    column-gap: 9px;
    padding: 15px;
    flex-wrap: wrap;
  }
  
  .productCards__productDetails_overlay_main {
    display: none;
    position: absolute;
    background: #fff;
    width: 220px;
    left: 0%;
    bottom: 105%;
    z-index: 2;
    border: 1px solid #1976d2;
    border-radius: 5px;
    padding: 0 10px 10px 10px;
  }
  .productCards__productDetails_overlay_main > h3 {
    font-size: 13px;
    margin: 0;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    margin: 13px 0 0px 0;
  }
  
  .productCards__productDetails_overlay_dimension {
    text-align: center;
    font-size: 10px;
    margin: 2px 0;
  }
  
  .productCards__productDetails_overlay_weight {
    text-align: center;
    font-size: 10px;
    margin: 2px 0;
  }
  
  .productCards__productDetails_sku_col {
    text-align: center;
    font-size: 10px;
    margin: 2px 0;
  }
  
  .product__box_main:hover .productCards__productDetails_overlay_main {
    display: block;
  }
  .warehouse__count_name_overlay_wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .warehouse__count_name_overlay_col {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 10px;
  }
  .product__box_main {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 149px;
    justify-content: center;
    border: 1px solid #d2d2d2;
    border-radius: 7px;
    column-gap: 6px;
    padding: 5px 0;
    position: relative;
    margin-bottom: 10px;
  }
  
  .product__box_main strong {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: center;
    font-size: 13px;
    padding: 0 5px;
  }
  
  .product__box_main img {
    max-width: 70px;
  }
  
  .warehouse__count_name {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: center;
  }
  
  .warehouse__count_name span {
    display: flex;
    width: 100%;
    font-size: 12px;
    justify-content: flex-start;
  }
  .productCards__productDetails_filters_tags_wrap .filter_tag svg.overlaybtn {
    padding: 0 !important;
    width: 20px;
    height: 19px;
    transform: rotate(180deg);
    margin-left: -6px;
  }
  .productCards__productDetails_filters_tags_wrap .filter_tag span {
    border: 1px solid #000;
    padding: 0 8px 0 3px;
    border-right: none;
  }
  
  .productCards__productDetails_filters_tags_wrap .filter_tag > a {
    display: flex;
    color: #000;
    text-decoration: none;
    align-items: center;
  }
  
  .productCards__productDetails_filters_tags_wrap .filter_tag > a svg:hover {
    color: #000 !important;
    background-color: transparent !important;
    border-color: #000 !important;
  }
  a.add__new_filter svg:hover {
    color: #000 !important;
    background-color: transparent !important;
    border-color: #000 !important;
  }
  a.add__new_filter svg.p-2.overlaybtn {
    border: 1px solid #ccc;
    padding: 0 !important;
    width: 20px;
    height: 20px;
  }
  .product__box_main:hover {
    border-color: #1976d2;
    cursor: pointer;
  }
  .productCards__productDetails_sort_wrap .sort_tag svg.p-2.overlaybtn {
    padding: 0 !important;
    width: 20px;
    height: 20px;
  }
  
  .productCards__productDetails_sort_wrap .sort_tag a {
    display: flex;
    align-items: center;
    column-gap: 5px;
    color: #000;
    text-decoration: none;
  }
  
  .productCards__productDetails_sort_wrap .sort_tag svg.p-2.overlaybtn:hover {
    color: #000 !important;
    background-color: transparent !important;
    border-color: #000 !important;
  }
  
  .productShipment__main_pickup .card-body,
  .productShipment__main_delivery .card-body {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    justify-content: center;
  }
  
  .fullname__main_wrap {
    width: calc(100% - 30px);
    margin-top: 20px;
  }
  
  .country__main_wrap {
    width: calc(50% - 30px);
    margin-top: 20px;
  }
  
  .state__main_wrap {
    width: calc(50% - 30px);
    margin-top: 20px;
  }
  
  .city__main_wrap {
    width: calc(50% - 30px);
    margin-top: 20px;
  }
  
  .zip__main_wrap {
    width: calc(50% - 30px);
    margin-top: 20px;
  }
  
  .instruction__main_wrap {
    width: calc(50% - 30px);
    margin-top: 20px;
  }
    .orderId__main_wrap {
    width: calc(100% - 30px);
    margin-top: 20px;
  }
  
  .reference__main_wrap {
    width: calc(50% - 30px);
    margin-top: 20px;
  }
  input#searchProduct:focus-visible {
    outline: none;
  }
  
  input#searchProduct {
    height: 35px;
    border: 1px solid #c4c4c4;
    border-radius: 6px;
    padding: 9px 5px;
    font-size: 12px;
    min-width: 240px;
    background: #f7f7f7;
  }
  
  .SearchFields__main_wrap {
    position: relative;
  }
  
  .SearchFields__main_wrap label.SearchFields__input__box {
    position: absolute;
    top: -10px;
    left: 10px;
    background: #f7f7f7;
    padding: 0 6px;
  }
  .productCards__productDetails_sort_wrap .sort_tag a.active__btn {
    border-bottom: 1px solid;
  }
  
  .productCards__productDetails_body_main.productLayout__list .product__box_main {
    max-width: 100%;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
    padding: 0 21px;
  }
  .productShipment__main_pickup {
    width: 100%;
    max-width: 815px;
}

.productShipment__main_delivery {
    width: 100%;
    max-width: 815px;
}
  .productCards__productDetails_body_main.productLayout__list
    .product__box_main
    strong {
    width: fit-content;
    order: 1;
  }
  
  .productCards__productDetails_body_main.productLayout__list
    .product__box_main
    .warehouse__count_name {
    width: fit-content;
    display: inline-flex !important;
    overflow: inherit !important;
    flex-wrap: nowrap;
    width: 100%;
  }
  
  .productCards__productDetails_body_main.productLayout__list
    .product__box_main
    img {
    order: 2;
  }
  
  .productCards__productDetails_body_main.productLayout__list
    .product__box_main
    .warehouse__count_name
    span {
    justify-content: flex-end;
  }
  .filter__main_box {
    position: absolute;
    top: 100%;
    right: 0;
    background: #fff;
    z-index: 2;
    border: 1px solid #d2d2d2;
    border-radius: 0 0 5px 5px;
    box-shadow: 0px 4px 11px 0px #00000021;
}

.filter__main_box ul {
    padding: 0;
    margin: 0;
}

.filter__main_box ul li {
    font-size: 12px;
    padding: 5px 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    text-transform: uppercase;
}

.filter__main_box ul li:last-child {
    border: none;
}

.filter__main_box ul li:hover {
    color: #0d6efd;
}
  
.productCards__orderDetails_main_wrap {
  border: 1px solid #d2d2d2;
  margin-top: 30px;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.productCards__orderDetails_header_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 16px;
  background: #f7f7f7;
  border-bottom: 1px solid #d2d2d2;
  margin-bottom: 10px;
  border-radius: 5px 5px 0 0;
}

.productCards__orderDetails_header_main h2 {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 8px 0;
} 

.products_details_table_head_wrap {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding-bottom: 10px;
  column-gap: 6px;
  font-size: 13px;
  border-bottom: 1px solid #eaeaea;
}

.products_details_table_wrap {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  column-gap: 5px;
  font-size: 13px;
  border-bottom: 1px solid #eaeaea;
}

.productCards__orderDetails_body_main {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 15px 5px 0px 5px;
}

.products_details_table_head_col {
  justify-content: center;
  font-weight: 700;
}

.products_details_table_col {
  justify-content: center;
}

.products_details_table_wrap:last-child {border-bottom: none;}
.products_details_table_body_wrap {
  width: 100%;
  max-height: 100%;
  overflow: auto;
  min-height: 237px;
}




.products_details_table_body_wrap::-webkit-scrollbar {
  width: 5px;
}

.products_details_table_body_wrap::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #9f9f9f;
  border-radius: 10px;
}
.products_details_table_body_wrap::-webkit-scrollbar-thumb {
  background: #0d6efd;
  border-radius: 10px;
}
.productCards__productDetails_filters_back {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1000;
}
.productCards__productDetails_filters_box {
  position: absolute;
  z-index: 1001;
  background: #fff;
  padding: 10px 30px 5px 30px;
  border-radius: 5px;
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%);
  width: fit-content;
  display: flex;
  column-gap: 20px;
  max-width: 768px;
}
.productCards__productDetails_brand_btn {
  display: flex;
  align-items: center;
  column-gap: 5px;
  border-bottom: 1px solid #000;
  font-weight: 600;
  cursor: pointer;
}
.productCards__productDetails_filters_box .MuiAutocomplete-root {
  margin-bottom: 20px;
}
.productCards__productDetails_filters_box .MuiAutocomplete-root * {
  font-size: 12px;
}
.productCards__productDetails_filters_box .MuiAutocomplete-root svg.MuiSvgIcon-root {
  font-size: 12px;
}
.productCards__productDetails_filters_box .MuiAutocomplete-root svg.MuiSvgIcon-root[data-testid="ArrowDropDownIcon"] {
  font-size: 23px;
}
.productCards__productDetails_filters_box .css-1qqsdnr-MuiAutocomplete-root .MuiAutocomplete-tag {
  height: 19px;
}

.productCards__productDetails_filters_box .css-1qqsdnr-MuiAutocomplete-root .MuiAutocomplete-tag span.MuiChip-label {
  font-size: 10px;
}
.products_details_table_col.col_trash_prod {
  cursor: pointer;
}

.MuiAutocomplete-popper * {
  font-size: 12px !important;
}
.paymentShipping__porductDetails_orderdetails {
  display: flex;
  max-width: 1670px;
  margin: 0 auto;
  column-gap: 40px;
}

.paymentShipping__productDetails_main_wrap {
  width: 100%;
  max-width: 815px;
  border: 1px solid #d2d2d2;
  margin-top: 15px;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.paymentShipping__productDetails_header_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 16px;
  background: #f7f7f7;
  border-bottom: 1px solid #d2d2d2;
  margin-bottom: 10px;
  border-radius: 5px 5px 0 0;
}

.paymentShipping__productDetails_header_main h2 {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}


.paymentShipping_order_status_label {
  font-weight: 600 !important;
  color: #000 !important;
  margin-bottom: 5px;
}



.paymentShipping__orderDetails_body_main {
  padding: 15px 30px 30px 30px;
}

.paymentShipping__order_status_radio {
  margin-bottom: 15px;
}

.paymentShipping__payment_status_label {
  font-weight: 600 !important;
  color: #000 !important;
  margin-bottom: 5px;
}



.paymentShipping__payment_status_radio {
  margin-bottom: 15px;
}

h3.paymentShipping__payment_recandpay_heading {
  font-weight: 600;
  color: #000 !important;
  font-size: 16px;
  width: 100%;
  margin-bottom: 15px;
}

.paymentShipping__payment_amount_recandpay {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 22px;
}

.paymentShipping__orderDetails_intro_content {
  font-size: 13px;
  margin-bottom: 5px;
}

.paymentShipping__orderDetails_form_fields_block {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
}

h3.paymentShipping__link_field_personal_record {
  font-weight: 600;
  color: #000 !important;
  font-size: 16px;
  width: 100%;
  margin-top: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.paymentShipping__shipping_company {
  width: 100%;
  max-width: 220px;
}

.paymentShipping__shipping_Tracking_code {
  width: 100%;
  max-width: 220px;
}

.paymentShipping__link_field {
  width: 100%;
  max-width: 220px;
}

.paymentShipping__shipping_company>.MuiFormControl-root {
  width: 100%;
}

.paymentShipping__shipping_Tracking_code>.MuiFormControl-root {
  width: 100%;
}

.paymentShipping__link_field>.MuiFormControl-root {
  width: 100%;
}

.paymentShipping__paid__field>.MuiFormControl-root {
  width: 100%;
}

.paymentShipping__paid__field {
  width: 100%;
  max-width: 220px;
}

.shipping__payment_status_field {
  width: 100%;
  max-width: 220px;
}

.shipping__payment_status_field>.MuiFormControl-root {
  width: 100%;
}
.paymentShipping__amount_total_wrap {
  width: 100%;
  max-width: 210px;
}
.paymentShipping__payment_amount_recandpay input {
  padding: 10px 15px 10px 0;
  font-size: 14px;
}

.paymentShipping__orderDetails_form_fields_block div#demo-simple-select {
  padding: 10px;
  font-size: 12px;
}

.paymentShipping__orderDetails_form_fields_block input {padding: 10px 10px 10px 0px;font-size: 14px;}
.shipping__payment_status_field>.MuiFormControl-root label, .paymentShipping__shipping_company>.MuiFormControl-root label {
  font-size: 14px;
  line-height: 1;
}
.btn_submit_product_sale {
  display: flex;
  max-width: 1670px;
  margin: 0 auto;
  column-gap: 40px;
  align-items: flex-start;
  justify-content: center;
}

button.order_fulfilment_submit {
  border: 1px solid #0c69f3;
  border-radius: 3px;
  color: #fff;
  background: #0c69f3;
  padding: 6px 50px;
  margin-bottom: 30px;
}
.MuiMenuItem-root {
  font-size: 0.8rem;
  padding-top: 2px;
  padding-bottom: 2px;
}
.paymentShipping_order_status_radio_group_pending *, .paymentShipping_order_status_radio_group_confirm *, .paymentShipping_order_status_radio_group_onhold *, .paymentShipping__payment_status_group_recieved *, .paymentShipping__payment_status_group_cop *, .paymentShipping__payment_status_group_cod *{
  font-size: 14px !important;
}

.paymentShipping__amount__received,.paymentShipping__amount__pending,.paymentShipping__amount_total_wrap, .paymentShipping__amount_total_tax_wrap, .paymentShipping__shipping_company,.paymentShipping__shipping_Tracking_code,.paymentShipping__paid__field {
  margin-bottom: 15px !important;
}
.paymentShipping__payment_status_radio {
  width: 55%;
}

.paymentShipping__orderDetails_body_main {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
}

.paymentShipping__payment_status_group label {
  min-width: fit-content;
}

.paymentShipping__payment_status_group {
  justify-content: flex-start;
}

.paymentShipping_order_status_radio_group label {
  min-width: fit-content;
}


.paymentShipping__costFulfilment_wrap_main {
  display: flex;
  max-width: 1670px;
  margin: 0 auto;
  column-gap: 40px;
}

.paymentShipping__costFulfilment_card_wrap {
  width: 100%;
  border: 1px solid #d2d2d2;
  margin-top: 15px;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.paymentShipping__costFulfilment_header_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 16px;
  background: #f7f7f7;
  border-bottom: 1px solid #d2d2d2;
  margin-bottom: 10px;
  border-radius: 5px 5px 0 0;
}

.paymentShipping__costFulfilment_header_main h2 {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
.paymentShipping__costFulfilment_fields_cost_type .paymentShipping__costFulfilment_add_more_panel_wrap label {
  font-size: 14px;
}

.total__main_product_footer {
  width: 100%;
  background: #f7f7f7;
  padding: 5px 0;
  font-size: 17px;
    font-weight: 600;
}
.paymentShipping__fulfillment_delivery_nogst_total {
  border-bottom: 1px solid #e9e9e9;
  padding: 2px 0;
}
/*Cost Fulfilment start*/
.paymentShipping__costFulfilment_body_main .MuiFormControl-root {
  min-width: fit-content;
  margin-right: 10px;
}
.custom_button_Unit.Mui-selected {
  background: #1976d2 !important;
  color: #fff  !important;
}
.paymentShipping__costFulfilment_body_main {
  display: flex;
}

.paymentShipping__costFulfilment_body_main .paymentShipping__costFulfilment_fields {
  width: 50%;
  padding: 10px 20px 0px 20px;
  border-right: 1px solid #eaeaea;
  margin-bottom: 20px;
  margin-top: 10px;
}

.paymentShipping__fulfillment_data_wrap {
  width: 50%;
  padding: 10px 20px 20px 20px;
}

.paymentShipping__fulfillment_data_cost {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.paymentShipping__fulfillment_data_cost_list {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 21px;
  font-weight: 600;
}

li.paymentShipping__fulfillment_cost_list_li {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 11px 0;
  font-size: 14px;
}

.paymentShipping__fulfillment_cost_list ul {
  padding: 0;
}

.paymentShipping__fulfillment_cost_list_title {
  width: 53%;
}

.paymentShipping__fulfillment_cost_list_value {
  width: 41%;
  text-align: right;
}

.paymentShipping__fulfillment_delivery_gst {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 16px;
  flex-wrap: wrap;
}

button.paymentShipping__fulfillment_cost_list_remove {
  width: 20px;
}

.paymentShipping__fulfillment_delivery_gst  label {
  font-size: 14px;
  font-weight: 500; 
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.paymentShipping__fulfillment_delivery_total_overall {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
}

.paymentShipping__fulfillment_delivery_total_overall label {
  font-size: 16px;
  font-weight: 600;
}
.paymentShipping__costFulfilment_fields_cost_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.paymentShipping__costFulfilment_fields_cost_type {
  width: 44%;
}

.paymentShipping__costFulfilment_fields_cost_input {
  width: 44%;
}

.paymentShipping__costFulfilment_fields_cost_type .MuiFormControl-root,.paymentShipping__costFulfilment_fields_cost_input .MuiFormControl-root{
  width: 100%;
}
span.labels_Addition_addBtn {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #000;
  color: #fff;
  font-size: 18px;
  border-radius: 0 4px 4px 0;
}

.fulfilment_status_addional_weight_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.fulfilment_status_field_field {
  width: 44%;
}

.fulfilment_status_additional_weight_field {
  width: fit-content;
  position: relative;
}

.fulfilment_status_field_field .MuiFormControl-root,.fulfilment_status_additional_weight_field  .MuiFormControl-root{
  width: 100%;
}

.paymentShipping__costFulfilment_fields_cost_add_btn button, .paymentShipping__costFulfilment_fields_cost_add_btn button:hover {
  background-color: #1976d2;
  color: #fff;
  font-size: 12px;
  border-radius: 0 4px 4px 0;
  height: 45px;
  margin-left: -15px;
  padding: 0 15px;
}
.paymentShipping__fulfillment_delivery_nogst_total {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
}

.paymentShipping__fulfillment_delivery_nogst_total label {
  font-size: 14px;
  font-weight: 500;
}
.paymentShipping__fulfillment_with_total_weight {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
  align-items: center;
  background: #ededed;
  padding: 5px 9px;
  border-radius: 5px;
  margin-top: 5px;
}

.paymentShipping__fulfillment_with_total_weight label {
  font-size: 14px;
  line-height: 1.5;
}

.paymentShipping__fulfillment_with_total_weight label span {
  display: flex;
  font-weight: 500;
}
.paymentShipping__fulfillment_with_total_weight label span em {
  font-style: inherit;
}
.products_details_table_weight input {
  padding: 0;
  text-align: center;
  font-size: 13px;
}
.paymentShipping__costFulfilment_fields_cost_type .MuiFormControl-root {
  width: 90%;
  margin: 0;
}

.paymentShipping__costFulfilment_add_more_Btn {
  min-width: 40px !important;
  margin-left: -6px !important;
  border-radius: 0 5px 5px 0 !important;
  font-size: 18px !important;
}

.paymentShipping__costFulfilment_fields_cost_type {
  display: flex;
}
.paymentShipping__costFulfilment_add_more_panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}

.paymentShipping__costFulfilment_add_more_panel_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.paymentShipping__costFulfilment_add_more_panel_wrap {
  column-gap: 10px;
  min-height: 250px;
  min-width: 310px;
  width: 550px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}
.paymentShipping__costFulfilment_add_more_panel_body {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

.paymentShipping__costFulfilment_add_more_panel_body button {
  margin-top: 9px;
}

.paymentShipping__costFulfilment_add_more_panel_wrap>h3 {
  font-size: 23px;
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
  margin-bottom: 25px;
  border-bottom: 1px solid #d9d9d9;
}
.fulfilment_status_field_field {
  display: flex;
  column-gap: 10px;
}

.fulfilment_status_field_dropdown {
  width: 100%;
  max-width: 170px;
}

.fulfilment_status_field_dropdown .MuiFormControl-root.css-1nrlq1o-MuiFormControl-root {
  min-width: 100%;
}

.paymentShipping__costFulfilment_fields_tax_rate {
  width: 100%;
  max-width: 170px;
}

.paymentShipping__costFulfilment_fields_tax_rate .MuiFormControl-root.css-1nrlq1o-MuiFormControl-root {
  min-width: 100%;
}
.products_details_table_weight .MuiInputBase-root:after,.products_details_table_weight .MuiInputBase-root:before {
  display: none;
}
.additional__tax_popup_wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}

.additional__tax_popup_fields {
  column-gap: 10px;
  min-height: 250px;
  min-width: 310px;
  width: 550px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}

.additional__tax_popup_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.additional__tax_popup_fields h3 {
  font-size: 23px !important;
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
  margin-bottom: 25px;
  border-bottom: 1px solid #d9d9d9;
}

.additional__tax_popup_body {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.additional__tax_popup_body .MuiFormControl-root {
  margin-bottom: 10px;
}
.paymentShipping__shipping_company_add_more_container {
  column-gap: 10px;
  min-height: 250px;
  min-width: 310px;
  width: 550px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}
.paymentShipping__shipping_company_add_more_body {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.paymentShipping__shipping_company_add_more_body .MuiFormControl-root {
  width: 100%;
  margin-bottom: 15px;
}

.paymentShipping__shipping_company_add_more_body input {
  padding: 15px 0;
  height: 1.4375em !important;
}

.paymentShipping__shipping_company_add_more_container h3 {
  font-size: 23px;
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
  margin-bottom: 25px;
  border-bottom: 1px solid #d9d9d9;
}
.toggle__additional_Tax_btn {
  position: absolute;
  top: 1px;
  right: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 18px;
  background: #1976d2;
  padding: 0 5px;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  width: 40px !important;
  justify-content: center;
}


.paymentShipping__costFulfilment_fields_tax_rate {
    position: relative;
}
.paymentShipping__costFulfilment_fields_cost_type label,.fulfilment_status_field_dropdown label {
  font-size: 13px;
}

.paymentShipping__costFulfilment_fields_cost_input *, .paymentShipping__costFulfilment_fields_tax_rate *, .fulfilment_status_additional_weight_field * {
  font-size: 14px !important;
}

.paymentShipping__costFulfilment_fields_tax_rate  .toggle__additional_Tax_btn {
  font-size: 18px !important;
}
.fulfilment_status_additional_weight_btn * {
  font-size: 11px !important;
}
.paymentShipping__costFulfilment_add_more_Btn {
  font-size: 18px !important;
  padding: 0 !important;
  display: block !important;
  width: 30px !important;
  text-transform: capitalize !important;
}
.paymentShipping__shipping_company {
  position: relative;
}
.paymentShipping__shipping_company_add_more_panel_wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}

.paymentShipping__shipping_company_add_more_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.fullfilment__form_expand {
  position: absolute;
  right: 0;
}
/*Cost Fulfilment end*/

@media screen and (min-width: 993px) and (max-width: 1280px) {
  .productCards__orderDetails_body_main {
     overflow: auto;
     width: 100%;
     justify-content: flex-start;
 }
 
 .productCards__orderDetails_main_wrap {
     width: 60%;
 }
 
 .products_details_table_head_wrap {
     width: 813px;
 }
 
 .products_details_table_body_wrap {
     width: fit-content;
     display: flex;
     align-items: flex-start;
     flex-wrap: wrap;
     overflow: inherit !important;
     min-width: fit-content;
 }
 
 .total__main_product_footer {
     width: 813px;
     display: flex;
 }   
 }
 
 
@media only screen and (min-width: 993px) and (max-width: 1360px) {
  input#searchProduct{min-width: 110px;}
}

@media only screen and (min-width: 993px) and (max-width: 1220px) {

  .productCards__productDetails_header_main {
    flex-wrap: wrap;
}

.productCards__productDetails_header_main h2 {
    margin-bottom: 15px;
}

.productCards__productDetails_filters_sort_wrap {flex-wrap: wrap;justify-content: space-between;}

.productCards__productDetails_sort_wrap {
    width: fit-content;
}

.productCards__productDetails_filters_wrap {
    width: fit-content;
}
.productCards__productDetails_main_wrap{
  min-width: 340px;
}

}

@media only screen and (max-width: 992px) {

.productShipment__main_wrap {
  flex-wrap: wrap;
}

.productcards__porductDetails_orderdetails {
  flex-wrap: wrap;
}
.productShipment__main_pickup {
  margin-bottom: 15px;
}

.paymentShipping__porductDetails_orderdetails {
  flex-wrap: wrap;
}

.btn_submit_product_sale {
  flex-wrap: wrap;
}

.productCards__productDetails_body_main {
  justify-content: flex-start;
}

.productCards__orderDetails_body_main {
  overflow: auto;
  justify-content: flex-start;
}

.products_details_table_head_wrap {
  min-width: 810px;
  justify-content: flex-start;
}

.products_details_table_body_wrap,.total__main_product_footer  {
  min-width: 810px;
}

.products_details_table_wrap {display: inline-flex;justify-content: flex-start;}

.productCards__productDetails_overlay_main {
  display: none !important;
}

.btn_submit_product_sale button.order_fulfilment_submit {
  margin-bottom: 10px;
}

.btn_submit_product_sale {
  margin-bottom: 10px;
}

.paymentShipping__payment_amount_recandpay .MuiFormControl-root {
  width: 100%;
  margin-bottom: 18px;
}

h3.paymentShipping__payment_recandpay_heading {
  font-size: 13px;
}

.paymentShipping__order_status_radio * {
  font-size: 12px !important;
}

.paymentShipping__payment_status_radio * {
  font-size: 12px !important;
}

.paymentShipping_order_status_label {
  font-size: 13px !important;
}

.paymentShipping__payment_status_label {
  font-size: 13px !important;
  font-weight: 600;
}
.paymentShipping__productDetails_main_wrap {
  margin-top: 0;
}

.productCards__orderDetails_main_wrap {
  margin-bottom: 0;
  margin-top: 0;
}
.paymentShipping__orderDetails_body_main {
  padding: 15px 20px 30px 20px;
}
.paymentShipping__paid__field,.shipping__payment_status_field,.paymentShipping__link_field,.paymentShipping__shipping_Tracking_code,.paymentShipping__shipping_company {
  max-width: 100%;
  margin-bottom: 19px;
}
.shipping__payment_status_field>.MuiFormControl-root label, .paymentShipping__shipping_company>.MuiFormControl-root label {
  font-size: 15px;
  line-height: 1;
}
.paymentShipping__costFulfilment_body_main {
  flex-wrap: wrap;
}

.paymentShipping__costFulfilment_body_main .paymentShipping__costFulfilment_fields {
  width: 100%;
}

.paymentShipping__fulfillment_data_wrap {
  width: 100%;
}

.paymentShipping__costFulfilment_fields_cost_wrap {
  flex-wrap: wrap;
}

.paymentShipping__costFulfilment_fields_cost_type {
  width: 100%;
  margin-bottom: 10px;
}

.paymentShipping__costFulfilment_fields_cost_input {
  width: calc(100% - 60px);
}

.fulfilment_status_addional_weight_wrap {
  flex-wrap: wrap;
}

.fulfilment_status_field_field {
  width: 100%;
  flex-wrap: wrap;
}

.fulfilment_status_field_dropdown {
  max-width: 100%;
  margin-bottom: 10px;
}

.paymentShipping__costFulfilment_fields_tax_rate {
  max-width: 100%;
  margin-bottom: 10px;
}

.fulfilment_status_additional_weight_field {
  width: calc(100% - 85px);
}

.paymentShipping__fulfillment_cost_list_title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.paymentShipping__fulfillment_cost_list_value {
  padding-right: 5px;
}

.paymentShipping__payment_amount_recandpay .MuiFormControl-root {
  max-width: 100%;
}

.productCards__productDetails_header_main {
  flex-wrap: wrap;
}

.productCards__productDetails_header_main h2 {
  margin-bottom: 15px;
}

.product__box_main {
  max-width: 120px;
}

.productCards__productDetails_body_main {
  justify-content: flex-start;
}
}