.packages_dispatcher__screen {
  padding: 20px 28px;
  margin: auto;
  max-width: -moz-fit-content;
  max-width: 100%;
}

.dispatcher__soption_disabled {
  pointer-events: none;
  color: lightgray !important;
}
