.selectQuote__table_wrap .TableContainer .MuiToolbar-root p {
    margin: 0;
}
.shadow.select__quote_card_header b {
    font-size: 12px;
}

.shadow.select__quote_card_header button {
    padding: 0;
    width: 12px;
    height: 12px;
}

.shadow.select__quote_card_body * {
    font-size: 11px;
}

.shadow.select__quote_card_body  img {
    width: 40px;
}

.shadow.select__quote_card_body  .QuoteTableDivCenter svg {
    width: 11px;
}

.shadow.select__quote_card_body thead * {
    font-size: 13px;
}
.shadow.select__quote_card_body .QuoteTabledataselected {
    padding: 0;
}
.select__quote_card_body .MuiBox-root.css-1zye22 {margin: 0;}

.select__quote_card_body .MuiBox-root.css-1zye22 button {
    padding: 0;
}

.select__quote_card_body .MuiBox-root.css-1zye22 button svg {
    font-size: 16px;
}

.select__quote_card_body  .MuiInputBase-root {
    margin: 0;
}
.pagination__select_service .MuiToolbar-root {
    padding: 0;
    margin: 0 0 0 auto;
    display: flex;
    width: fit-content;
    text-align: center;
    align-items: center;
}

.pagination__select_service .css-194a1fa-MuiSelect-select-MuiInputBase-input {
    border: 1px solid #eaeaea;
    margin-left: 4px;
    padding: 2px 0;
}

.selectQuote__table_wrap .TableContainer .MuiToolbar-root p.MuiTablePagination-displayedRows {
    margin: 0 12px;
}
@media only screen and (max-width: 992px) {
    .selectQuote__table_wrap .TableContainer {
        margin: 0;
        padding-bottom: 30px;
    }
    .selectQuote__table_wrap .TableContainer tfoot {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
    }
    .selectQuote__table_wrap .TableContainer .MuiToolbar-root p {
        margin: 0;
    }
    .selectQuote__table_wrap .TableContainer .MuiToolbar-root {
        padding: 0;
    }
    
    .selectQuote__table_wrap {
        position: relative;
    }
    
    .selectQuote__table_wrap .TableContainer tfoot .MuiBox-root.css-1zye22 {
        margin-left: 12px;
    }
    
    .selectQuote__table_wrap .TableContainer tfoot .MuiBox-root.css-1zye22 button {
        padding: 0;
    }
    .selectQuote__table_wrap .MuiRating-icon svg{
        width: 18px;
    }

    .selectQuote__table_wrap .QuoteTableDivCenter {
        font-size: 13px;
    }
    .selectQuote__table_wrap .TableContainer tfoot .MuiBox-root.css-1zye22 svg {
        width: 22px;
        font-size: 23px !important;
    }
    
    
    .selectQuote__table_wrap .TableContainer tfoot .MuiToolbar-root * {
        font-size: 13px !important;
    }
    
    .selectQuote__table_wrap .TableContainer tfoot svg.MuiSvgIcon-root {width: 22px;
        font-size: 23px !important;}
    
    
    li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.MuiTablePagination-menuItem.css-10by3a5-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem {
        padding: 0;
        font-size: 13px;
        justify-content: center;
    }
}
@media only screen and (min-width: 1190px) {
    .shadow.select__quote_card_header b{
        font-size: 0.878vw;
    }
    .shadow.select__quote_card_header button,.shadow.select__quote_card_header button svg{
        width: 0.878vw;
        height: 0.878vw;
    }
    .shadow.select__quote_card_body thead *{
        font-size: 0.952vw;
    }
    .shadow.select__quote_card_body *{
        font-size: 0.805vw;
    }
    .shadow.select__quote_card_body img{
        width: 2.928vw;
    }
    .shadow.select__quote_card_body .QuoteTableDivCenter svg{
        width: 0.805vw;
    }
    .select__quote_card_body .MuiBox-root.css-1zye22 button svg {
        font-size: 1vw;
    }
    
}