.admnchng__pass_modal {
  min-height: 250px;
  min-width: 310px;
  width: 550px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
  max-height: 95vh;
  overflow-y: auto;
}
.admnchng__pass_modalheading {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid lightgray;
  font-weight: 500;
  font-size: 18px;
}
.admnchng__pass_modalcontent {
  padding: 20px 0 10px 0;
}
.admnchng__pass_modalrow {
  display: flex;
}
.admnchng__pass_modalinputwrap {
  flex: 1;
  margin: 0 5px 5px 5px;
}
.admnchng__pass_modalinputwrap > label {
  font-size: 12px;
  color: grey;
}
.admnchng__pass_modalinput {
  width: 100%;
  border: 0.5px solid lightgrey;
  outline: none;
  border-radius: 5px;
  height: 50px;
  padding: 0 10px;
  transition: 0.3s;
}

.admnchng__pass_modallabel {
  width: 100px;
  height: 40px;
  margin: 0 5px 10px 5px;
  font-size: 17px;
  display: flex;
  padding: 2px;
  align-items: center;
}
.admnchng__pass_modalinput:focus {
  outline: 0.5px solid #0c68f373;
}
.admnchng__pass_modallabel2 {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
}
.admnchng__pass_modalfooter {
  width: 100%;
  padding: 10px;
  height: 60px;
  border-top: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.admnchng__pass_modalcncl {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #454545;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}
.admnchng__pass_modalsave {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c69f3;
  border: 1px solid #0c69f3;
  border-radius: 3px;
  margin-left: 10px;
  color: white;
  cursor: pointer;
}

.admnchng__pass_btndisabled {
  opacity: 0.5;
  pointer-events: none;
}
