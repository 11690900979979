.warehouse_orders_status {
  border: 1px solid grey;
  border-radius: 2px;
  color: #000;
  padding: 3px 6px;
  text-transform: capitalize;
  font-size: 8px;
}
.warehouse_orders_carrlogo {
  max-height: 50px;
  max-width: 95%;
  object-fit: contain;
}

.warehouse_orders_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: grey;
  margin: 20px 5px 0px 5px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid lightgrey;
}
.warehouse_orders_hdrheading {
  font-weight: 400 !important;
  font-size: 22px;
}

.warehouse_orders_bolttable_actions{
min-width: 40px !important;
  min-height: 25px !important;
  min-width: fit-content;
  padding: 2px 2px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 2px 1px rgb(228, 227, 227);
  cursor: pointer;
  font-size: 7px;
}
.editable__table tbody td   .warehouse__attachments_thumbnail_wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 5px;
  min-width: fit-content;
  max-width: 110px;
}

.editable__table tbody td  .warehouse__attachments_thumbnail_wrap img {
  max-width: 30px;
  max-height: 30px;
  width: 30px;
  height: 30px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0px -2px 10px 0px #eaeaea;
}
@media screen and (min-width: 480px) {
  .warehouse_orders_header {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 1190px) {
  .warehouse_orders_hdrheading{
    font-size: 1.611vw;
  }
  .editable__table tbody td .warehouse__attachments_thumbnail_wrap{max-width: 8.053vw;}
  .editable__table tbody td .warehouse__attachments_thumbnail_wrap img{    max-width: 2.196vw;
    max-height: 2.196vw;
    width: 2.196vw;
    height: 2.196vw;}
}

