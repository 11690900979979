/* product adjustment header  */
.products__adpage_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: grey;
  margin: 20px 5px 0px 5px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid lightgrey;
}
.products__adpage_hdrheading {
  font-weight: 400 !important;
  font-size: 22px;
}
.products__adpage_buttonsWrapper {
  display: flex;
  gap: 20px;
}
.products__adpage_hdraddprod {
  border-radius: 5px;
  box-shadow: 0.3px 0.3px 3px 1px rgb(230, 228, 228);
  display: flex;
  padding: 8px 18px;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
  color: grey;
  cursor: pointer;
}
.products__adpage_hdraddprod:hover {
  color: grey;
}
.products__adpage_hdraddprod > * {
  margin-right: 3px;
}

.custabscreenthd__grn_status {
  font-size: 8px;
  padding: 3px 6px;
  border-radius: 2px;
  text-transform: capitalize;
  border: 1px solid grey;
  min-width: fit-content;
  justify-content: center;
  align-items: center;
  color: black;
}
/* close  */

@media only screen and (max-width: 768px) {
  .products__adpage_header{
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 1190px) {
  .products__adpage_hdrheading{
    font-size: 1.311vw;
  }
  .products__adpage_hdraddprod{font-size: 1.025vw;}
  .products__adpage_hdraddprod svg {width: 1.025vw; height: 1.025vw;}
}
