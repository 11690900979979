.details_byweight_formrow {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.details_byweight_modalsave {
  width: 90px;
  height: 34px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c69f3;
  border-radius: 3px;
  margin-left: 10px;
  color: white;
  cursor: pointer;
}
