.manage__services_modal {
  min-height: 450px;
  max-height: 95vh;
  overflow-y: auto;
  min-width: 310px;
  width: 1150px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 30px 50px;
}
.manage__services_closewrap {
  position: absolute;
  /* right: 3px;
  top: 2px; */
  right: 10px;
  top: 10px;
}
.manage__services_modalheader {
  width: 100%;
  min-height: 100px;
  display: flex;
  font-weight: 500;
  font-size: 18px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 0.5px solid lightgrey;
  color: grey;
}
.manage__services_modalheader .manage__services_mhheadingcarr {
  color: #0c6efd;
}
.manage__services_modalhdrimg {
  max-width: 250px;
  max-height: 80px;
  object-fit: contain;
  align-self: flex-start;
}
.manage__services_modalcontent {
  padding: 20px 0 10px 0;
  width: 100%;
}

.manage__services_srvcsgrid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.manage__services_srvcsgitem {
  margin: 10px 0;
  border-radius: 10px;
  box-shadow: 1px 1px 3px 1px rgb(228, 227, 227);
  padding: 10px;
  margin-right: 25px;
}
.manage__services_srvcsgitxt {
  font-weight: 500;
  font-size: 18px;
  margin-left: 10px;
}
.manage__services_srvcsempt {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 600;
  color: lightgrey;
}

@media (max-width: 1200px) {
  .manage__services_modal {
    max-width: 95%;
    height: auto;
  }
}
@media (max-width: 1000px) {
  .manage__services_modalheader {
    flex-direction: column;
  }
}
