.mng__shipper__bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.mng__shipper__text {
  font-size: 16px;
  text-transform: uppercase;
  color: #454545;
  font-weight: bold;
}

.mng__shipper__button {
  width: fit-content;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c69f3;
  border: 1px solid #0c69f3;
  border-radius: 3px;
  margin-left: 10px;
  color: white;
  cursor: pointer;
  padding: 0px 15px;
}
