.prdtable__hist_modal.storagBrreakDown__modal_container .storagBrreakDown__modal_header {
    background: #e3e3e3;
    padding: 15px;
}

.prdtable__hist_modal.storagBrreakDown__modal_container {
    padding: 0;
    border-radius: 15px;
    overflow: hidden;
}

.storagBrreakDown__modal_main_heading {
    font-size: 16px;
    font-weight: 600;
}

.storagBrreakDown__modal_sub_heading {
    font-size: 11px;
}

.prdtable__hist_modal.storagBrreakDown__modal_container .history__product_footer_btn {
    background: transparent;
    padding: 0;
    color: #8a8a8a;
    font-size: 20px;
    line-height: 0.6;
}

.storagBrreakDown__modal_container .history__product_footer {
    top: 22px;
}
.stockreciveform__table_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
}

.stockreciveform__table_vol_col {
    display: inline-flex;
    font-size: 14px;
}

.stockreciveform__table_qty_col {
    font-size: 14px;
}

.stockreciveform__table_palletized_col {
    font-size: 14px;
}

.prdtable__hist_modal.storagBrreakDown__modal_container .prdtable__hist_modalcontent {
    padding: 0;
}
.stockreciveform__table_content_header_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 15px 12px 15px;
}

.stockreciveform__table_content_wrap {
    border-top: 1px solid #eaeaea;
}

.stockreciveform__table_content_header_title {
    font-size: 13px;
    font-weight: 600;
}

.stockreciveform__table_content_header_btn_col {
    display: inline-flex;
    align-items: center;
    column-gap: 10px;
}

.stockreciveform__table_content_header_btn_col .MuiButtonBase-root.MuiButton-root {
    padding: 0;
    font-size: 11px;
    color: #535353;
    text-transform: capitalize;
    border: 1px solid #b5b5b5;
    line-height: 1;
    padding: 6px 6px;
    font-weight: 600;
}

.stockreciveform__table_content_container table.stockreciveform__table.stockreciveform__detail_table {
    font-size: 12px;
    margin: 0 15px;
    width: calc(100% - 30px);
}

table.stockreciveform__table.stockreciveform__detail_table th.stockreciveform__tablehd:nth-child(4) {
    text-align: center;
}

table.stockreciveform__table.stockreciveform__detail_table td.stockreciveform__tableitem:nth-child(4) a svg {width: 15px;height: 15px;padding: 0 !important;}

table.stockreciveform__table.stockreciveform__detail_table td.stockreciveform__tableitem:nth-child(4) input[type="number"] {
    font-size: 10px !important;
    width: 50px;
    height: 18px;
}

table.stockreciveform__table.stockreciveform__detail_table td.stockreciveform__tableitem {
    padding: 4px 0;
    border-top: 1px solid #eaeaea;
    font-size: 11px;
}
table.stockreciveform__table.stockreciveform__detail_table td.stockreciveform__tableitem:nth-child(2) {
    font-weight: 600;
}
.stockreciveform__detail_table_footer {display: flex;justify-content: flex-end;padding: 11px 15px 14px 15px;}

.stockreciveform__detail_table_footer button.MuiButtonBase-root {
    padding: 10px 12px;
    background: #303030;
    text-transform: capitalize;
    font-size: 11px;
    line-height: 1;
    min-width: fit-content;
    border-radius: 9px;
    margin: 0 !important;
}

.stockreciveform__table_content_container {
    max-height: 60vh;
    overflow: auto;
}



.stockreciveform__table_content_container::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  
  .stockreciveform__table_content_container::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .stockreciveform__table_content_container::-webkit-scrollbar-thumb {
    background: #d2d2d2;
  }
  
  .stockreciveform__table_content_container::-webkit-scrollbar-thumb:hover {
    background: #9f9a9a;
  }
  table.stockreciveform__table.stockreciveform__detail_table th.stockreciveform__tablehd {
    padding: 0 0 7px 0 !important;
}



.existing__pallet_list_modal_wrap {
    min-height: 250px;
    max-width: 760px;
    width: 95%;
    border-radius: 5px;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 24px;
    border: none;
    outline: none;
    border-radius: 15px;
    overflow: hidden;
}

.existing__pallet_list_modal_wrap .cexisting__pallet_list_modal_content_wrapper {
    max-height: 40vh;
    overflow: auto;
    padding: 0px 15px 15px 15px;
    margin-bottom: 15px;
}
.existing__pallet_list_modal_wrap .cexisting__pallet_list_modal_content_wrapper table.existing__pallet_list_modal_content_table thead {
    position: sticky;
    top: 0;
    background: #fff;
    height: 40px;
    z-index: 9;
}

.existing__pallet_list_modal_wrap .cexisting__pallet_list_modal_content_wrapper table.existing__pallet_list_modal_content_table {
    width: 100%;
    font-size: 12px;
}

.existing__pallet_list_modal_wrap .cexisting__pallet_list_modal_content_wrapper table.existing__pallet_list_modal_content_table span.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root {
    padding: 0;
}

.existing__pallet_list_modal_wrap .cexisting__pallet_list_modal_content_wrapper table.existing__pallet_list_modal_content_table span.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root svg.MuiSvgIcon-root {
    width: 15px;
    height: 15px;
}

.existing__pallet_list_modal_wrap .cexisting__pallet_list_modal_content_wrapper td.stockreciveform__tableitem:last-child {
    text-align: center;
}

.existing__pallet_list_modal_wrap .cexisting__pallet_list_modal_content_wrapper th.stockreciveform__tablehd:last-child {
    text-align: center;
}

.existing__pallet_list_modal_wrap .cexisting__pallet_list_modal_content_wrapper td.stockreciveform__tableitem {
    padding: 4px 0;
    border-top: 1px solid #eaeaea;
    font-size: 11px;
}

.existing__pallet_list_modal_wrap .pexisting__pallet_list_modal_main_heading {
    background: #e3e3e3;
    padding: 15px;
    font-size: 16px;
    font-weight: 600;
}

.existing__pallet_list_modal_wrap .history__product_footer_btn {
    background: transparent;
    color: #8a8a8a;
}

.cexisting__pallet_list_modal_content_wrapper::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  
  .cexisting__pallet_list_modal_content_wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .cexisting__pallet_list_modal_content_wrapper::-webkit-scrollbar-thumb {
    background: #d2d2d2;
  }
  
  .cexisting__pallet_list_modal_content_wrapper::-webkit-scrollbar-thumb:hover {
    background: #9f9a9a;
  }
  .add__pallet_modal_wrapper .add__package_modalheading {justify-content: flex-start;font-size: 14px;border: none;height: auto;}

.add__pallet_modal_wrapper .add__package_modalcontent {
    padding: 6px 0;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
}

.add__pallet_modal_wrapper .add__package_modalcontent .add__package_modalinputwrap {
    margin: 0;
}

.add__pallet_modal_wrapper .add__package_modalcontent .add__package_modalrow{
    width: 100%;
}
.add__pallet_modal_wrapper .add__package_modalcontent .add__package_modalinputwrap input.add__package_modalinput {
    height: 30px !important;
    font-size: 12px !important;
}
.add__pallet_modal_wrapper .add__package_modalcontent .add__package_modalrow {
    display: flex;
    column-gap: 10px;
}
.add__pallet_modal_wrapper .add__package_modalcontent .add__package_modalrow .add__package_modalinputwrap  label#demo-controlled-radio-buttons-group {
    font-size: 12px !important;
    min-width: fit-content;
}
.add__pallet_modal_wrapper .add__package_modalcontent .add__package_modalinputwrap span.MuiButtonBase-root svg.MuiSvgIcon-root {
    width: 15px;
    height: 15px;
}
.add__pallet_modal_wrapper .add__package_modalcontent .add__package_modalinputwrap span.MuiButtonBase-root svg.MuiSvgIcon-root {
    width: 15px;
    height: 15px;
}
.add__package_modalinputwrap.add__package_modalinputwrap_location .MuiInputBase-root.MuiOutlinedInput-root {
    height: 30px;
    padding: 0 !important;
}

.add__package_modalinputwrap.add__package_modalinputwrap_location .MuiInputBase-root.MuiOutlinedInput-root input#product-location {
    font-size: 12px !important;
}

.add__package_modalinputwrap.add__package_modalinputwrap_location .MuiInputBase-root.MuiOutlinedInput-root fieldset legend span {
    font-size: 9px !important;
}

.add__package_modalinputwrap.add__package_modalinputwrap_location .MuiFormControl-root.MuiFormControl-fullWidth * {
    font-size: 12px !important;
}

.add__pallet_modal_wrapper .add__package_modalcontent .add__package_modalinputwrap .MuiFormGroup-root.css-11tjprg-MuiFormGroup-root {
    justify-content: flex-start;
}

.add__pallet_modal_wrapper .add__package_modalcontent .add__package_modalinputwrap .MuiFormGroup-root.css-11tjprg-MuiFormGroup-root label.MuiFormControlLabel-root {
    margin: 0;
    min-width: fit-content;
}

.add__pallet_modal_wrapper .add__package_modalcontent .add__package_modalinputwrap .MuiFormGroup-root.css-11tjprg-MuiFormGroup-root label.MuiFormControlLabel-root span.MuiTypography-root {
    font-size: 12px;
}.add__package_modalfooter.add__package_modalinputwrap_modalfooter {
    border: none;
    padding: 10px 0 0 0;
    height: fit-content;
}

.add__package_modalfooter.add__package_modalinputwrap_modalfooter .add__package_modalcncl {
    height: 32px;
    width: fit-content;
    font-size: 12px;
    color: #303030;
    font-weight: 500;
    border: 1px solid #ccc;
    background: transparent;
    border-radius: 10px;
    padding: 0px 12px;
}

.add__package_modalfooter.add__package_modalinputwrap_modalfooter .add__package_modalsave {
    height: 32px;
    width: fit-content;
    font-size: 12px;
    color: #fff;
    font-weight: 500;
    border: 1px solid #ccc;
    background: #1a1a1a;
    border-radius: 10px;
    padding: 0px 12px;
}

.add__package_modalinputwrap.add__package_modalinputwrap_client .MuiInputBase-root.MuiOutlinedInput-root {
    font-size: 12px !important;
}

.add__package_modalinputwrap.add__package_modalinputwrap_client div#mui-component-select-client {
    padding-top: 7px;
    padding-bottom: 7px;
}

@media only screen and (max-width: 991px) {

    .stockreciveform__table_wrapper {
        flex-wrap: wrap;
        padding: 10px;
    }
    .stockreciveform__table_content_container table.stockreciveform__table.stockreciveform__detail_table {
        min-width: 820px;
    }
    
    .stockreciveform__table_content_header_wrap {
        position: sticky;
        left: 0;
        top: 0;
        background: #fff;
    }
    .existing__pallet_list_modal_wrap .cexisting__pallet_list_modal_content_wrapper table.existing__pallet_list_modal_content_table {
        min-width: 660px;
    }
    
    .existing__pallet_list_modal_wrap .cexisting__pallet_list_modal_content_wrapper table.existing__pallet_list_modal_content_table th.stockreciveform__tablehd:last-child,.existing__pallet_list_modal_wrap .cexisting__pallet_list_modal_content_wrapper table.existing__pallet_list_modal_content_table tr td.stockreciveform__tableitem:last-child {
        position: sticky;
        right: -15px;
        background: #fff;
    }
}