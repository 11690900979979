.shipper__sidebar_wrapper {
  padding-top: 50px;
  min-width: 300px;
  width: 300px;
  min-height: 100vh;
  transition: width 2s;
  /* background: #ffffff; */
}
.shipper__sidebar_wrapper2 {
  padding-top: 50px;
  width: 60px;
  min-height: 100vh;
  transition: width 2s;
  background: #ffffff;
}
.shipper__sidebar_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 15px;
}
.shipper__sidebar_lgoo {
  width: 131px;
  height: 40px;
}
.shipper__sidebar_navicon {
  font-size: 25px;
  cursor: pointer;
}
.shipper__sidebar__itemsbox {
  display: flex;
  flex-direction: column;
}

.shipper__sidebar__itemsactive {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  background-color: white;
  cursor: pointer;
  padding: 0px 0px 0px 60px;
}
.shipper__sidebar_heading {
  font-size: 12px;
}
.shipper__sidebar__nitems_parent {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 10px;
}
.shipper__sidebar__nitems_parent:hover {
  background: #56545417;
  border-radius: 7px;
}
.shipper__sidebar__active_class {
  background: #56545417;
  border-radius: 7px;
}
.shipper__sidebar__nitems_icon {
  height: 19px !important;
  margin-right: 5px;
}
.shipper__sidebar__nitems {
  font-size: 14px;
}
.shipper__sidebar__divider_line {
  background: #56545417;
  height: 2px;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
}

.shipper__sidebar_items {
  width: 100%;
  height: 50px;
  background-color: white;
  cursor: pointer;
  color: black;
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 20px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  padding: 0px 15px;
}
.shipper__sidebar__active_icon {
  color: white !important;
}
