/* Pickup and Delivery Address Section  */
.sss__address_section {
  width: 100%;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 0 5px 4px rgba(235, 235, 235, 0.616);
  padding: 10px 20px;
  margin: 20px 0;
  min-height: 600px;
}
.sss__prodsection_heading {
  display: flex;
  align-items: center;
  min-width: fit-content;
}
.sss__prodsection_headingback {
  cursor: pointer;
  margin-right: 5px;
}
.sss__addressection_heading {
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 17px;
}
.sss__addressection_inputrow {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  position: relative;
}
.sss__addressection_inputcol {
  flex: 1;
  min-width: 250px;
}
.sss__addressection_inputbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  font-size: 14px;
}
.sss__addressection_inputtitle {
  font-size: 14px;
  padding-left: 5px;
}
.sss__addressection_inputbox{
  margin-bottom: 0;
}

.sss__addressection_inputbox .custom__dropdown_container{
  margin: 5px 0 0px 0;
}

.sss__addressection_inputfield {
  border: 1px solid rgb(190, 190, 190);
  width: 100%;
  border-radius: 3px;
  height: 40px;
  padding: 10px;
  margin: 5px 0;
  outline: none;
  font-size: 14px;
}
.sss__addressection_inputfielddisabled {
  pointer-events: none;
  opacity: 0.7;
  user-select: none;
}
.sss__addressection_inputfielderror {
  font-size: 13px;
  font-weight: 500;
  color: tomato;
  display: flex;
  align-items: center;
}
.sss__addressection_inputfielderroricn {
  font-size: 15px;
  margin-right: 2px;
  color: tomato;
}
.sss__addressection_textarea {
  border: 1px solid rgb(190, 190, 190);
  width: 100%;
  border-radius: 3px;
  height: 80px;
  padding: 10px;
  margin: 5px 0;
  outline: none;
  font-size: 14px;
}

/* Avaialble Products  */

.sss__products_section {
  width: 100%;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 0 5px 4px rgba(235, 235, 235, 0.616);
  padding: 10px 20px;
  margin: 20px 0;
  height: fit-content;
}

.sss__prodsection_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sss__prodsection_heading {
  font-weight: 500;
  font-size: 17px;
  margin-right: 100px;
}
.sss__warehousesection_heading {
  width: 100%;
  text-align: center;
  margin: 10px 0;
  font-weight: 500;
  font-size: 20px;
}
.sss__prodsection_headerright {
  display: flex;
  align-items: center;
}
.sssprodsec__searchbar_wrap {
  display: flex;
  align-items: center;
  border-radius: 3px;
  height: 35px;
  overflow: hidden;
  width: 220px;
  padding: 5px 8px;
  border: 1px solid #d9d9d9;
}
.sssprodsec__searchbar_field {
  width: 92%;
  border: none;
  outline: none;
  height: 100%;
  padding: 3px 5px;
  flex: 1;
}
.sssprodsec__prodaction_iconwrap {
  margin-left: 10px;
  cursor: pointer;
}
.sssprodsec__prodaction_icon {
  color: rgb(59, 59, 59);
}
.sss__prodsection_body {
  padding: 10px 0;
  height: 380px;
  overflow-y: auto;
}
.sss__prodsection_body::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.sss__prodsection_body::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.sss__prodsection_body::-webkit-scrollbar-thumb {
  background: #d2d2d2;
}
.sss__prodsection_bodygrid {
  display: flex;
  flex-wrap: wrap;
}

.sss__warehousesection_bodygrid {
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;
}
.sss__prodsecbgrid_item {
  width: 155px;
  height: 110px;
  border-radius: 5px;
  border: 0.5px solid lightgrey;
  margin: 0 6px;
  margin-top: 10px;
  padding: 5px;
  cursor: pointer;
}
.sss__warehousesecbgrid_item {
  width: 230px;
  height: 160px;
  border-radius: 5px;
  border: 0.5px solid lightgrey;
  margin: 0 6px;
  margin-top: 10px;
  padding: 5px;
  cursor: pointer;
  overflow-y: auto;
}
.sss__prodsecbgrid_itemheading {
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}
.sss__warehousesecbgrid_itemheading {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
}
.sss__prodsecbgrid_itemcontent {
  display: flex;
  height: 80%;
}
.sss__warehousesecbgrid_itemcontent {
  padding: 10px 5px 5px 5px;
  overflow-y: auto;
}
.sss__warehousesecbgrid_itemcontitem {
  display: flex;
  font-size: 12px;
  margin-bottom: 2px;
  padding-bottom: 2px;
}
.sss__warehousesecbgrid_itemcontileft {
  min-width: fit-content;
}
.sss__warehousesecbgrid_itemcontiright {
  padding-left: 5px;
}
.sss__prodsecbgrid_itemcontleft {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sss__prodsecbgrid_itemcontleft > img {
  width: 100%;
}
.sss__prodsecbgrid_itemcontright {
  flex: 1;
  padding-left: 5px;
  padding-top: 6px;
  font-size: 12px;
}
.sss__prodsection_bodylist {
  padding-right: 5px;
}
.sss__prodsecblist_item {
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  border: 0.5px solid lightgrey;
  margin-top: 10px;
  cursor: pointer;
}
.sss__prodsecblist_itemimagewrap {
  width: 80px;
}
.sss__prodsecblist_itemimage {
  max-width: 100%;
  max-height: 55px;
  object-fit: contain;
}
.sss__prodsecblist_itemtitle {
  font-weight: bold;
  font-size: 14px;
  padding: 0 10px;
  flex: 1;
}
.sss__prodsecblist_itemstock {
  font-size: 12px;
}

/* Selected Products  */

.sss__selected_products {
  width: 100%;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 0 5px 4px rgba(235, 235, 235, 0.616);
  padding: 10px 20px;
  margin: 20px 0;
  height: 450px;
  overflow-y: auto;
}
.sss__selected_products::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.sss__selected_products::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.sss__selected_products::-webkit-scrollbar-thumb {
  background: #d2d2d2;
}
.sss__selectedprods_tableimg {
  max-width: 45px;
  max-height: 40px;
  object-fit: contain;
}
.sss__selectedprods_table {
  width: 100%;
}
.sss__selectedprods_table td {
  padding: 3px;
  max-width: 125px;
}
.sss__selectedprods_table thead {
  font-weight: 500;
  font-size: 14px;
}
.sss__selectedprods_table tbody {
  font-size: 14px;
  max-height: 20px !important;
  overflow: auto;
}
.sss__selectedprods_tablenotefield {
  width: 110px;
  border: none;
  outline: none;
  border-bottom: 1.5px solid grey;
}

/* Fulfillment Cost  */
.sss__fulfillment_cost {
  width: 100%;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 0 5px 4px rgba(235, 235, 235, 0.616);
  padding: 10px 20px;
  margin: 20px 0;
  min-height: 350px;
}
.sss__flmntdetails_subheading {
  font-weight: 600;
}
.sss__flmntdetails_listitem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 15px;
  padding: 3px 1px;
  margin: 3px 0;
}
.sss__flmntdetails_listitemb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 15px;
  padding: 3px 1px;
  margin: 3px 0;
  font-weight: 600;
}
.sss__flmntdetails_listitemright {
  display: flex;
  align-items: center;
}
.sss__flmntdetails_litemrdel {
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin-left: 10px;
}
.sss__addressection_body {
  width: 100%;
  display: flex;
}
.sss__addressection_bodyleft {
  flex: 1;
  padding-right: 20px;
  margin-right: 20px;
}
.sss__addressection_bodyright {
  flex: 1;
  padding-left: 20px;
}
/* Payment Section  */
.sss__payment_section {
  width: 100%;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 0 5px 4px rgba(235, 235, 235, 0.616);
  padding: 10px 20px;
  margin: 20px 0;
  height: fit-content;
}
.sss__pmtsection_heading {
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 10px;
}

.sss__pmtdetails_subheading {
  font-weight: 500;
}
.sss__pmtsection_labelbold {
  font-weight: 500 !important;
  color: #000 !important;
  margin-bottom: 8px;
  margin-top: 6px;
}
.sss__pmtsection_radiogroup * {
  font-size: 14px !important;
}
.sss__shppingdetsection_info {
  font-size: 14px;
}
.fcadd__additioncost_btn {
  margin: 0 5px;
  background-color: rgb(232, 231, 231);
  color: black;
  font-size: 14px;
  height: 35px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 2px;
}
.fcadd__additioncost_btn2 {
  margin: 0 5px;
  background-color: rgb(232, 231, 231);
  color: black;
  font-size: 20px;
  height: 35px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 2px;
  position: relative;
  top: 2px;
}
.attachments__formsection_mediabox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  border: 1px dashed lightgrey;
  margin-top: 20px;
  flex-direction: column;
  border-radius: 20px;
}
.attachments__formsec_uploadimagebtn {
  width: 250px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  border: 1px solid rgb(228, 226, 226);
  font-size: 14px;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
}
.attachments__formsection_mdbtnicon {
  margin-right: 10px;
}
.attachments__formsection_mdbtmtext {
  font-size: 14px;
  margin-top: 10px;
}
.attachments__secformrow_images {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  margin: 15px 0;
}
.attachments__secformrow_imagewrap {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  overflow: hidden;
  margin: 5px 0px;
  position: relative;
  border: 0.5px solid lightgrey;
}
.attachments__secformrow_imageclose {
  position: absolute;
  top: 3px;
  right: 3px;
  padding: 2px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 1px 1px 3px 0px lightgrey;
  cursor: pointer;
  width: 16px;
  height: 16px;
}
.attachments__secformrow_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sss__addressection_inputcol.sss__addressection_inputcol_warehouse {
  position: relative;
  padding-right: 30px;
}

.sss__addressection_inputcol_warehouse .form__warhouse_select_dropdown_helper_text {
  right: 0;
  bottom: 10px;
  position: absolute;
}
.sales__section_screen .sss__addressection_heading {
  background: #f7f7f7;
  padding: 20px 15px;
  width: calc(100% + 40px );
  margin-left: -20px;
  margin-top: -10px;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid #d2d2d2;
  color: #000;
    font-weight: 600;
}

.sales__section_screen .sss__products_section,.sales__section_screen .saless__products_sectionleft>div ,.sales__section_screen  .sss__address_section , .sales__section_screen  .sss__payment_section {
  border: 1px solid #d2d2d2;
}

.sales__section_screen  .sss__prodsection_heading,.sales__section_screen .sss__pmtsection_heading {
  background: #f7f7f7;
  padding: 20px 15px;
  width: calc(100% + 40px );
  margin-left: -20px;
  margin-top: -10px;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid #d2d2d2;
  color: #000;
  font-weight: 600;
}
.saless__page_header_sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.saless__page_header_sec .add__prod_sheader {
  margin-bottom: 0;
}
.sss__addressection_prevnotesbtn {
  position: absolute;
  right: 5px;
  color: #0c6efd;
  font-weight: bold;
  cursor: pointer;
}
