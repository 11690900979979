.payment__invoices_header {
  display: flex;
  justify-content: space-between;
  align-items: end;
  color: grey;
  margin: 20px 5px 0px 5px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid lightgrey;
}

.payment__invoices_heading {
  font-weight: 400 !important;
  font-size: 22px;
}


@media only screen and (min-width: 1190px) {
  .payment__invoices_heading{
    font-size: 1.611vw;
  }
}

