/* grn__available */
.grn__available_productswrapper {
  width: 100%;
  box-shadow: 0 0 5px 4px rgba(235, 235, 235, 0.616);
  padding: 30px 20px;
  border-radius: 20px;
}
.grn__available_heading {
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 17px;
}
.grn__available_inputrow {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  position: relative;
}
.grn__available_inputcol {
  flex: 1;
  min-width: 250px;
}
.grn__available_inputbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  font-size: 14px;
}
.grn__available_inputtitle {
  font-size: 14px;
  padding-left: 5px;
}
.grn__available_inputfield {
  border: 1px solid rgb(190, 190, 190);
  width: 100%;
  border-radius: 3px;
  height: 40px;
  padding: 10px;
  margin: 5px 0;
  outline: none;
  font-size: 14px;
}
.grn__availableproductList__card_wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.grn__availableproductList__card_title {
  text-align: center;
  font-weight: 600;
  font-size: 11px;
  width: 96%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
}

.grn__availableproductList__card_details_wrap {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.grn__availableproductList__card_details_measures {
  display: flex;
  flex-wrap: wrap;
  font-size: 10px;
}
.grn__availableproductList__card_details_measures_LWH {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}
.grn__availableproductList__card_details_measures_LWH span {
  display: block;
  width: fit-content;
  margin: 0 auto;
  border: 1px solid #ccc;
  padding: 1px 5px;
  line-height: 1.2;
}
.grn__available_screen_empty {
  width: 94vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  font-size: 35px;
  color: lightgray;
}
.grn__available_scrnempt_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.grn__available_scrnempt_contenticon {
  font-size: 60px !important;
}
.grn__available_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}