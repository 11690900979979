.credit__card_wrap {
  border-radius: 10px;
  box-shadow: 0 0 27px 2px rgb(247 247 247);
  width: 230px;
  height: 120px;
  padding: 10px;
  position: relative;
  transition: transform 0.3s ease;
  border: 1px solid #e9e9e9;
  background: rgb(242,242,242);
  background: linear-gradient(90deg, rgb(231 231 231 / 45%) 35%, rgba(204,206,212,0) 100%);
}

.credit__card_brand {
  font-weight: 500;
  color: rgb(92, 87, 79);
  position: absolute;
  bottom: 10px;
}
.credit___card_number {
  letter-spacing: 2px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
}
.credit___card_date {
  margin-top: 0px;
  font-size: 12px;
}
.credit___card_btn {
  position: absolute;
    right: 10px;
    bottom: 10px;
    border-radius: 5px;
    background-color: rgba(46, 45, 45, 0.986);
    color: white;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    padding: 3px 6px;
}
.credit___card_btn:active {
  transform: scale(0.99);
} 
.credit___card_default {
  position: absolute;
    right: 10px;
    bottom: 10px;
    border-radius: 5px;
    background-color: #5a99f7;
    padding: 3px 6px;
    color: white;
    transition: 0.2s;
    font-size: 10px;
}
.credit__card_del {
  color: rgb(250, 77, 46);
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  width: 13px;
  height: 16px;
}
.paymsec__credit_cards{
  justify-content: flex-start;
}


@media only screen and (min-width: 1190px) {
  .credit__card_wrap {
    width: 16.837vw;
    height: 8.785vw;
    padding: 0.732vw;
}

.credit___card_number {
    font-size: 1.025vw;
}

.credit___card_date {
    font-size: 0.878vw;
}

svg.credit__card_del {
    width: 0.952vw;
    height: 1.171vw;
}

button.credit___card_btn, .credit___card_default {
    font-size: 0.732vw;
    padding: 0.22vw 0.439vw;
}

.credit__card_brand {
    font-size: 1.098vw;
}
}