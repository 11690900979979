.prod__pckgdetails_modal {
  min-height: 250px;
  max-width: 670px;
  width: 90%;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
}
.prod__pckgdetails_formbox {
  padding: 20px 0 10px 0;
}
.prod__pckgdetails_formrow {
  display: flex;
  gap: 10px;
  margin: 20px 0px;
  align-items: center;
}
.prod__pckgdetails_addmore {
  padding: 4px 8px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background-color: rgb(236, 234, 234);
  font-size: 13px;
  font-weight: 500;
  width: fit-content;
  cursor: pointer;
}
.prod__pckgdetails_addvarbtnicn {
  margin-right: 3px;
}

.prod__dimensionmdl_formbox_field {
  width: 50%;
  max-width: 127px;
}

.prod__dimensionmdl_formbox_field:nth-child(5) {
  max-width: calc(100% - 23px);
  width: calc(100% - 23px);
}
@media only screen and (max-width: 767px) {
  .prod__pckgdetails_formrow{
    flex-wrap: wrap;
  }
  .prod__dimensionmdl_formbox_field {
    width: 47%;
  max-width: 320px;
  }
  .prod__dimensionmdl_formbox_field:nth-child(5){
    width: calc(100% - 25px);
  }
  .editabletab__searchbar_wrap{
    width: 100%;
  }
  .prod__pckgdetails_modal {
    max-height: 90%;
    overflow: auto;
  }
}