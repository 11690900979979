/* Messages header  */
.messages_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: grey;
  margin: 20px 5px 0px 5px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid lightgrey;
}
.messages_hdrheading {
  font-weight: 400 !important;
  font-size: 22px;
}
.messages__main_wrap {
  display: flex;
  align-items: flex-start;
  column-gap: 30px;
  flex-wrap: wrap;
}
.messages__table_list {
  width: 100%;
}
.messages__parentwrapper {
  white-space: initial;
}
.messages__larg_text:hover {
  overflow: auto;
}
.messages__larg_text {
  word-wrap: break-word !important;
  align-items: flex-start !important;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  max-height: 68px;
  overflow-wrap: anywhere !important;
  padding: 10px 1px !important;
}

@media only screen and (max-width: 768px) {
  .messages__table_list {
    order: 2;
  }
  .messages_header {
    margin-bottom: 15px;
  }
}
