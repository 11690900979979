.custom__rstepper_component {
  width: 100%;
  height: 30px;
  display: flex;
}
.crstepper__component_item {
  flex: 1;
}
.crstepper__component_itemtop {
  height: 60%;
  font-size: 11px;
  line-height: 16px;
  color: #131466;
  padding-left: 2px;
  cursor: pointer;
}
.crstepper__component_itembottom {
  height: 30%;
  background: #d9d9d9;
  width: 100%;
  margin-top: 3px;
}
.crstprcomp_itembottomactive {
  background: #131466;
}
@media (max-width: 992px) {
  .crstepper__component_itemtop {
    font-size: 9px;
  }
  .custom__rstepper_component {
    margin-bottom: 20px;
}
}
