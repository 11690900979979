.uploaded__attachments_list {
  width: 300px;
  max-height: 300px;
  overflow-y: auto;
  font-size: 14px;
  padding: 10px 0;
}
.uploaded__attachments_list::-webkit-scrollbar {
  width: 3px;
}

.uploaded__attachments_list::-webkit-scrollbar-thumb {
  background-color: grey;
}

.uploaded__attachments_list::-webkit-scrollbar-track {
  background-color: #dbd9d9;
}
.uploaded__attachments_listheading {
  margin: 3px 8px 6px 8px;
  padding-bottom: 3px;
  border-bottom: 0.5px solid lightgrey;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.uploaded__attachments_listitem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 8px;
  margin: 8px;
  box-shadow: 0 0 2px 1px rgba(218, 218, 218, 0.952);
}
.uploaded__attachments_listitemdeleting {
  animation: fadeInOut 1s infinite;
}
.uploaded__attachments_litxt {
  flex: 1;
  height: 100%;
  padding-right: 5px;
  font-size: 12px;
}
.uploaded__attachments_liicondownload svg {
  width: 13px;
}

.uploaded__attachments_liicondel svg {
  width: 15px;
}
.uploaded__attachments_liicon {
  width: 50px;
  height: 100%;
  cursor: pointer;
  color: brown;
  font-weight: 500;
}
.uploaded__attachments_liicondel {
  color: rgb(253, 79, 48);
  cursor: pointer;
}
.uploaded__attachments_liicondownload {
  color: #0c6efd;
  cursor: pointer;
}
.uploaded__attachments_listhdingicons {
  display: flex;
}
.uploaded__attachments_downloadall {
  color: #0c6efd;
  cursor: pointer;
  margin-right: 7px;
}
.uploaded__attachments_closeicon {
  cursor: pointer;
}
@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
