.dispatcher__screen_tablewrap {
  background: #ffffff;
  border: 1px solid #dedede;
  box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  width: fit-content;
  height: fit-content;
  position: relative;
  overflow-x: auto;
  max-width: 100%;
}
.dispatcher__soption_disabled {
  pointer-events: none;
  color: lightgray !important;
}
.driver__screen_loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}
.driver__screen_empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  font-size: 35px;
  color: lightgray;
}
.dispatcher__screen_tablecontent {
  position: relative;
  width: fit-content;
}
.dispscreen__table_topbar {
  display: flex;
  align-items: center;
}
.driver__scrnempt_content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.driver__scrnempt_contenticon {
  font-size: 60px !important;
}
@media only screen and (min-width: 1190px) {
  .driver__scrnempt_content{font-size: 1.245vw;}
  .driver__scrnempt_content svg{width: 2.928vw;height: 2.928vw;}
}