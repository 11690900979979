/* transfer notes header  */
.transferrequest_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: grey;
  margin: 20px 5px 0px 5px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid lightgrey;
}
.transferrequest_hdrheading {
  font-weight: 400 !important;
  font-size: 22px;
}

@media only screen and (max-width: 768px) {
  .transferrequest_hdrheading {
    font-size: 18px;
  }
  .transferrequest_header {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 1190px) {
  .transferrequest_hdrheading {
    font-size: 1.311vw;
  }
}
