/* landing_company_details header  */
.l_c_d_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: grey;
  margin: 20px 5px 0px 5px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid lightgrey;
}
.l_c_d_hdrheading {
  font-weight: 400 !important;
  font-size: 22px;
}
.l_c_d__main_wrap {
  display: flex;
  align-items: flex-start;
  column-gap: 30px;
  flex-wrap: wrap;
}
.l_c_d__table_list {
  width: 100%;
}

.l_c_d__carrlogo {
  max-height: 50px;
  max-width: 95%;
  object-fit: contain;
}

@media only screen and (max-width: 768px) {
  .l_c_d__table_list {
    order: 2;
  }
  .l_c_d_header {
    margin-bottom: 15px;
  }
}
