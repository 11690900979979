.rise_modal {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%);
  z-index: 10000;
  position: absolute;
  min-height: 100px;
  min-width: 100px;
  width: fit-content;
  animation: rise 0.3s linear;
  cursor: default;
}
.backdrop {
  width: 100vw;
  left: 0;
  top: 0;
  height: 100vh;
  position: fixed;
  cursor: default;
  z-index: 9999;
}

@media only screen and (min-width: 1190px) {
  .rise_modal *{
    font-size: 0.732vw;
  }
  .alltables__colsmodal_colslistitemcheck,.alltables__colsmodal_colslistitemdrag {
    width: 10px;
}
.rise_modal .alltables__colsmodal_colslistitem{
    min-height:fit-content;
}
.alltables__colsmodal_autocompltfield{
  height: 20px;
}
}


@keyframes rise {
  0% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media only screen and (max-width: 767px) {
  .rise_modal{
    right: 0;
    max-width: 180px;
  }
  .rise_modal .uploaded__attachments_list{
    width: 100%;
  }
}
