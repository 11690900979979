/* settings__3pl_ */
.settings__3pl_mainwrapper {
  margin: auto;
  margin-top: 50px;
  width: fit-content;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 4px;
}
.settings__3pl_boxwrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
}

/* header  */
.settings__3pl_box {
  min-height: 250px;
  min-width: 310px;
  width: 550px;
  background-color: white;
  box-shadow: 24px;
  padding: 20px;
  position: relative;
}

.settings__3pl_box:not(:last-child)::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 90%;
  width: 1px;
  background-color: lightgray;
}

.settings__3pl_header {
  display: flex;
  justify-content: center;
  gap: 10px;
  border-bottom: 0.5px solid lightgray;
  align-items: center;
}
.settings__3pl_methodicon {
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.settings__3pl_heading {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
}

/* footer */
.settings__3pl_boxfooter {
  width: 100%;
  padding: 10px;
  height: 60px;
  border-top: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.settings__3pl_boxcncl {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #454545;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}
.settings__3pl_boxsave {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c69f3;
  border: 1px solid #0c69f3;
  border-radius: 3px;
  margin-left: 10px;
  color: white;
  cursor: pointer;
}

/* modal content */
.settings__3pl_boxcontent {
  position: relative;
  height: 300px;
}

.settings__3pl_boxtxtrow {
  display: flex;
  align-items: center;
  padding: 7px 10px;
  width: 100%;
  font-size: 20px;
  border-bottom: 0.4px solid rgba(235, 234, 234, 0.616);
  width: 100%;
}

.settings__3pl_modaltxtlft {
  width: 35%;
  color: grey;
  display: flex;
  align-items: center;
}

.settings__3pl_modaltxtrt {
  flex: 1 1;
  display: flex;
  align-items: center;
}

.settings__3pl_boxcontentbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  width: 100%;
  border-radius: 10px;
  transition: all 0.5s ease-out;
  position: absolute;
  z-index: 1000;
  opacity: 0;
}
.settings__3pl_boxcontentactivebox {
  transform: translateX(0);
  opacity: 1;
}

input.settings__3pl_modalinput {
  height: 40px !important;
  border: none;
  border-left: 1px solid #ccc;
  border-radius: 0;
  margin: 0 !important;
  outline: none !important;
  font-size: 12px;
}
.settings__3pl_modalinput {
  width: 100%;
  padding: 0 10px;
  transition: 0.3s;
}
