.grn__products_section {
  width: 100%;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 0 5px 4px rgba(235, 235, 235, 0.616);
  padding: 10px 20px;
  margin: 20px 0;
  height: fit-content;   
}

.grn__addressection_heading {
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 17px;
}
.grn_details_table_weight input {
  padding: 0;
  text-align: center;
  font-size: 13px;
}

.grn_details_table_weight .MuiInputBase-root:after,
.grn_details_table_weight .MuiInputBase-root:before {
  display: none;
}

.grn__available_heading, .add__adj_byform_heading,.grn__addressection_heading {
  background: #f7f7f7;
  padding: 20px 15px;
  width: calc(100% + 40px );
  margin-left: -20px;
  margin-top: -20px;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid #d2d2d2;
  color: #000;
  font-weight: 600;
}



.grn__available_productswrapper {
  padding-top: 20px;
}


.no_arrows_buttons {
  -moz-appearance: textfield; /* For Firefox */
}

.no_arrows_buttons::-webkit-outer-spin-button,
.no_arrows_buttons::-webkit-inner-spin-button {
  -webkit-appearance: none; /* For Chrome, Safari, and Opera */
  margin: 0; /* Remove margin */
}
/* .GRN_selected-product {
  max-height: 370px;
  overflow: auto;
} */
/* .package__name_list_wrap {
  max-height: 350px;
  overflow: auto;
}

.GRN_selected-product::-webkit-scrollbar-track,.package__name_list_wrap::-webkit-scrollbar-track
{
-webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 33%);
background-color: #f5f5f500;
border-radius: 2px;
}

.GRN_selected-product::-webkit-scrollbar,.package__name_list_wrap::-webkit-scrollbar
{
width: 10px;
background-color: #f5f5f500;
}

.GRN_selected-product::-webkit-scrollbar-thumb,.package__name_list_wrap::-webkit-scrollbar-thumb
{
background-color: #c8c9ca;
border: 2px solid #c8c9ca;
} */
.package__name_list_names {
  position: sticky;
  top: 0;
  background: #fff;
  padding: 15px 0;
  z-index: 2;
}
.text-center.my-auto.field_col_sequence.col-md-4{
  padding: 0;
}
.stockreciveform__table_wrapper {
  overflow: auto;
}
th.stockreciveform__tablehd {
  font-size: 12px;
  padding: 0 6px;
  text-align: center;
  font-weight: 600;
}

td.stockreciveform__tableitem {
  font-size: 11px;
  padding: 2px 6px;
  text-align: center;
}

td.stockreciveform__tableitem input.stock_received_accept_reject_input.form-control {
  margin: 0 auto;
}

td.stockreciveform__tableitem .MuiOutlinedInput-input{
  padding: 3px 3px;
    text-align: left;
    text-indent: 10px;
}
thead.stockreciveform__grndetails_body_header th.stockreciveform__detail_table_tablehd {
  font-size: 12px;
  text-align: center;
}
table.stockreciveform__table.stockreciveform__detail_table td.stockreciveform__detail_table_tableitem {
  font-size: 11px;
  text-align: center;
  padding: 4px 0;
}
thead.stockreciveform__grndetails_body_header th.stockreciveform__detail_table_tablehd:nth-child(2),thead.stockreciveform__grndetails_body_header th.stockreciveform__detail_table_tablehd:nth-child(3) {
  text-align: left;
}
table.stockreciveform__table.stockreciveform__detail_table td.stockreciveform__detail_table_tableitem:nth-child(2),table.stockreciveform__table.stockreciveform__detail_table td.stockreciveform__detail_table_tableitem:nth-child(3) {
  text-align: left;
}
@media only screen and (max-width: 600px) {

  .grn__products_section {
    height: fit-content !important;
  }

  td.stockreciveform__tableitem input.stock_received_accept_reject_input.form-control {
    margin: 0;
  }
  
}