.dispselect__driver_heading {
  font-size: 18px;
}
.dispselect__driver_btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  top: 8px;
}
.dispselect__driver_cancelbtn {
  width: 80px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background-color: lightgray;
  color: #454545;
  margin: 10px 10px 0 0;
  cursor: pointer;
}
.dispselect__driver_savebtn {
  width: 80px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background-color: #0c69f3;
  color: white;
  margin: 10px 0 0 0;
  cursor: pointer;
}
.dispselect__driver_savebtndis {
  pointer-events: none;
  opacity: 0.7;
}
