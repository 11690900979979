.fulfilment_tier_modal {
  min-height: 50vh;
  min-width: 310px;
  width: 90%;
  max-width: 1200px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
  max-height: 95vh;
  overflow-y: auto;
}
.fulfilment_tier_modalheading {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid lightgray;
  font-weight: 500;
  font-size: 18px;
}
.fulfilment_tier_modalclose {
  position: absolute;
  top: 0px;
  right: 0px;
}

.fulfilment_tier_updatewrapper {
  display: flex;
  justify-content: end;
  margin-top: 10px;
}
.fulfilment_tier_btn {
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: white;
  border-radius: 5px;
  background-color: #0c6efd;
  padding: 5px;
  padding-right: 10px;
  width: fit-content;
  position: relative;
  cursor: pointer;
}
.fulfilment_tier_btnicon {
  margin-right: 3px;
}
.fulfilment_tier_switch_wrapper {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  gap: 5px;
}
.fulfilment_tier_switch_btn_active {
  background-color: #0c6efd;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: white;
  border-radius: 3px;
  padding: 5px;
  padding-right: 10px;
  width: fit-content;
  position: relative;
  cursor: pointer;
}
.fulfilment_tier_switch_btn {
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: black;
  border-radius: 3px;
  padding: 5px;
  padding-right: 10px;
  width: fit-content;
  position: relative;
  cursor: pointer;
  border: 1px solid #ced4da;
}
.fulfilment_tier_empty_wrapper {
  width: "auto";
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  font-size: 35px;
  color: lightgray;
}
.fulfilment_tier_empty_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  text-align: center;
}
.fulfilment_tier_icon {
  height: 40px !important;
  width: 40px !important;
}
.fulfilment_tier_loader {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}
