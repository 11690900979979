.dispatcher__screen {
  padding: 20px 28px;
  margin: auto;
  max-width: fit-content;
}
.dispatcher__screen_searchwrap {
  width: 341px;
  height: 40px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding-right: 5px;
  margin: 8px 0 5px 0;
}
.dispatcher__screen_searchicon {
  margin: 0 15px;
  width: 17px;
}
.dispatcher__screen_searchfield {
  border: none;
  outline: none;
  height: 100%;
  flex: 1;
}
.dispatcher__screen_searchfield::placeholder {
  color: #6c6c6c;
}
.dispatcher__screen_options {
  color: #0c69f3;
  display: flex;
  align-items: center;
  margin: 20px 0;
  user-select: none;
}
.dispscreen__colsmodal_autocompltfield {
  border: none;
  outline: none;
  border-bottom: 0.5px solid #9f9a9a;
  width: 100%;
  font-size: 14px;
  height: 30px;
  color: #9f9a9a;
  padding-bottom: 9px;
  font-weight: 500;
}
.dispatcher__screen_option {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
  margin-right: 25px;
  align-items: center;
  position: relative;
}
.dispatcher__screen_optionicon {
  margin-right: 5px;
  width: 16px;
}
.dispatcher__screen_optcolicon {
  margin-right: 5px;
  display: flex;
}
.dispatcher__screen_optcolbar {
  width: 4px;
  height: 16px;
  margin-right: 2px;
  background: #0c69f3;
  border-radius: 1px;
}
.dispscreen__cols_modal {
  width: 244px;
  height: 305px;
  position: absolute;
  top: 30px;
  left: 0;
  box-shadow: 0px 2px 7px 0px #00000040;
  background: #ffffff;
  border-radius: 5px;
  padding: 16px 20px;
  cursor: default;
  z-index: 1000;
}
.dispscreen__size_modal {
  width: 190px;
  height: 125px;
  position: absolute;
  top: 30px;
  left: -10px;
  box-shadow: 0px 2px 7px 0px #00000040;
  background: #ffffff;
  border-radius: 5px;
  cursor: default;
  z-index: 1000;
  font-weight: 400;
  color: #454545;
  font-size: 20px;
}
.dispscreen__export_modal {
  width: 200px;
  height: 80px;
  position: absolute;
  top: 30px;
  left: -10px;
  box-shadow: 0px 2px 7px 0px #00000040;
  background: #ffffff;
  border-radius: 5px;
  cursor: default;
  z-index: 100;
  font-weight: 400;
  color: #454545;
  font-size: 17px;
}
.dispscreen__size_modalitem {
  width: 100%;
  height: 33%;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  cursor: pointer;
}
.dispscreen__export_modalitem {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  cursor: pointer;
}
.dispscreen__size_modalitem:hover {
  background-color: rgb(241, 238, 238);
}
.dispscreen__export_modalitem:hover {
  background-color: rgb(241, 238, 238);
}
.dispscreen__size_mileft {
  margin-right: 20px;
}
.dispscreen__size_mileft > img {
  width: 20px;
}
.dispscreen__size_miright {
  color: black;
}
.dispscreen__colsmodal_autocomplt {
  width: 100%;
  position: relative;
}
.dispscreen__colsmodal_colslist {
  margin: 10px 0;
  overflow-y: auto;
  height: 70%;
}
.dispscreen__colsmodal_colslist::-webkit-scrollbar {
  width: 2px !important;
}

/* Handle */
.dispscreen__colsmodal_colslist::-webkit-scrollbar-thumb {
  background: #262727;
  border-radius: 2px;
}

.dispscreen__colsmodal_colslistitem {
  display: flex;
  min-height: 25px;
  margin-top: 5px;
  justify-content: space-between;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  align-items: center;
  font-weight: 400;
}
.dispscreen__colsmodal_colslistitemleft {
  display: flex;
  align-items: center;
}
.dispscreen__colsmodal_colslistitemcheck {
  margin-right: 8px;
  width: 12px;
  height: 12px;
  cursor: pointer;
  border: 0.5px solid red !important;
  color: red;
  background-color: red;
}
.dispscreen__colsmodal_colslistitemdrag {
  width: 15px;
  margin-right: 10px;
  /* cursor: grab; */
}
.dispscreen__colsmodal_footer {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  font-size: 14px;
}
.dispscreen__colsmodal_footer > * {
  cursor: pointer;
}
.dispscreen__colsmodal_tabs {
  width: 100%;
  height: 30px;
  display: flex;
  border: 0.5px solid #d9d9d9;
  border-radius: 1px;
  font-size: 12px;
  color: #9f9a9a;
  margin: 10px 0;
}

.dispscreen__colsmodal_tab {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  border-bottom: 1px solid #d9d9d9;
  color: #9f9a9a;
}
.dispscreen__colsmodal_tabactive {
  border-bottom: 1px solid #0c69f3;
}

.dispscreen__table_topbar {
  background: #f7f7f7;
  border: 1px solid #d9d9d9;
  border-radius: 2px 2px 0px 0px;
  height: 45px;
  font-size: 15px;
  text-transform: uppercase;
  color: #454545;
  font-weight: bold;
  padding: 0 20px;
  display: flex;
  align-items: center;
  position: sticky;
  right: 0;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 5;
}
.dispscreen__thdata_badgedrag {
  cursor: grab;
}
.bottom__margin_10 {
  margin-bottom: 10px;
}
.dispscreen__statusswitch_datainactive {
  opacity: 0.7;
  pointer-events: none;
}
.pluginsscrn__plugin_tabwrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.pluginsscrn__plugin_tab {
  width: 150px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 8px;
  box-shadow: 1px 1px 5px 3px rgba(233, 232, 232, 0.5);
  cursor: pointer;
  border-radius: 5px;
  transition: 0.3s;
  position: relative;
}
.pluginsscrn__plugin_tabactive {
  border-bottom: 2px solid #97b8eb;
}
.pluginsscrn__plugin_tab:hover {
  transform: scale(1.02);
}
.pluginsscrn__ptab_comingsoon {
  position: relative;
}
.pluginsscrn__ptab_comingsoon::before {
  content: "Coming soon!";
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 14px;
}

.pluginsscrn__ptab_comingsoon:hover::before {
  display: flex;
}

.pluginsscrn__plugin_tab:active {
  transform: scale(0.9);
}
.pluginsscrn__plugin_tabimg {
  max-width: 85%;
  max-height: 90%;
  object-fit: contain;
}
.dispatcher__screen_topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dispatcher__screentb_gobackbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: rgb(168, 168, 168);
  border-radius: 15px;
  font-size: 16px;
  width: 220px;
  height: 45px;
  box-shadow: 0px 0px 5px 1px rgba(233, 232, 232, 0.74);
  cursor: pointer;
}
.dispatcher__screentb_gobackbtn:hover {
  text-decoration: none;
  color: rgb(168, 168, 168);
}
.dispatcher__screentb_gobackbtn:active {
  transform: scale(0.99);
}
@media (max-width: 960px) {
  .dispatcher__screen_searchwrap {
    width: 231px;
    height: 30px;
  }
  .dispatcher__screen_searchicon {
    margin: 0 10px;
    width: 12px;
  }
  .dispatcher__screen_searchfield {
    font-size: 11.1px;
    height: 100%;
    flex: 1;
  }
  .dispatcher__screen_options {
    margin: 15px 0;
  }
  .dispatcher__screen_option {
    font-size: 14px;
    margin-right: 15px;
  }
  .dispatcher__screen_optionicon {
    margin-right: 4px;
    width: 14px;
  }
  .dispatcher__screen_optcolicon {
    margin-right: 4px;
  }
  .dispatcher__screen_optcolbar {
    width: 3px;
    height: 12px;
  }
}
@media (max-width: 414px) {
  .dispatcher__screen_option {
    font-size: 14px;
    margin-right: 15px;
  }
  .dispatcher__screen_optionicon {
    margin-right: 4px;
    width: 14px;
  }
  .dispatcher__screen_optcolicon {
    margin-right: 4px;
  }
  .dispatcher__screen_optcolbar {
    width: 3px;
    height: 12px;
  }
}
