.editable__table_container {
  position: relative;
  /* border-radius: 10px; */
  /* min-height: 450px; */
  /* height: calc(100vh - 320px); */
  color: grey;
  overflow: auto;
  padding: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.editable__table {
  border-collapse: collapse;
  width: fit-content;
  height: fit-content;
  min-width: max-content;
}

th.tablecol__fixed_right {
  background: #fff;
}

table.editable__table tr:hover td {
  background: #ecf4ff;
}

table.editable__table th {
  padding-top: 7px;
  padding-bottom: 7px;
  background-color: rgb(237, 237, 237);
}

table.editable__table th input {
  width: 100% !important;
  max-width: 100% !important;
  min-height: 30px;
}

table.editable__table th input::placeholder {
  color: inherit;
  opacity: 1;

}

/* .editable__table thead tr {
  background-color: rgb(244, 250, 253);
  min-width: 1800px !important;
} */
.bolt__table_main_wrap .bolt__table_checkbox {
  padding: 0;
}

.bolt__table_main_wrap tr th,
.bolt__table_main_wrap tr td {
  font-weight: 500;
  font-size: 8px;
}

.bolttabscreen__varrow td,
.bolttabscreen__varrow input {
  background: #f5f5f5 !important;
}

.editable__table th {
  white-space: nowrap;
  min-width: fit-content;
  border: 1px solid #e5e7eb;
  /* background-color: rgb(244, 250, 253); */
}

.bolttabscreen__theader_hdrophide {
  visibility: hidden;
  pointer-events: none;
}

.bolttabscreen__trow_hdrop {
  width: 18px;
  min-width: 18px;
  cursor: pointer;
  transition: 0.5s;
}

.bolttabscreen__trow_hdropactive {
  transform: rotate(-180deg);
}

.editable__table th,
.editable__table td {
  padding: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}

.editable__table tbody td {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: white;
  text-align: start;
  text-wrap: balance;
  border: 1px solid #e5e7eb;
}

.editable__table tbody td img {
  max-height: 50px;
  max-width: 45px;
  object-fit: contain;
  margin: 0 auto;
}

.editable__table tbody td .table_action__btn {
  min-width: 40px !important;
  min-height: 25px !important;
  min-width: fit-content;
  padding: 2px 4px;
  /* border-radius: 5px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 2px 1px rgb(228, 227, 227);
  cursor: pointer;
  font-size: 7px;
  text-decoration: none;
  color: #808080;
}

.editable__table tbody td .table_action__btn:hover {
  background: #0d6efd !important;
  color: #d9d9d9 !important;
}

.editable__table tbody td .table_action__btn__disabled {
  pointer-events: none;
  opacity: 0.4;
}

.editable__table tbody td .table_action__wrap {
  display: flex;
  gap: 5px;
  padding: 0.5px 0.5px;
}

.editable__table tbody td .box_success {
  /* background: rgb(222, 255, 217); */
  /* border: 1px solid rgb(113, 244, 110); */
  border-radius: 2px;
  color: #000;
  font-size: inherit;
  padding: 3px 6px;
  text-transform: capitalize;
  text-align: center;
  max-width: 100px;
}

.editable__table tbody td .box_pending {
  /* background: rgb(219, 233, 255);
  border: 1px solid rgb(96, 160, 255); */
  border-radius: 2px;
  color: #000;
  font-size: inherit;
  padding: 3px 6px;
  text-transform: capitalize;
  text-align: center;
  max-width: 100px;
}

.editable__table tbody td .box_error {
  /* background: rgb(255, 236, 239);
  border: 1px solid rgb(255, 185, 197); */
  border-radius: 2px;
  color: #000;
  font-size: inherit;
  padding: 3px 6px;
  text-transform: capitalize;
  text-align: center;
  max-width: 100px;
}

.editble__table_input {
  width: 19px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1px;
  outline-color: lightgrey;
}

.editable__table_toolbar {
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 0px;
  position: sticky;
  left: 0;
  z-index: 91;
}

.editable__table_toolbarrt {
  display: flex;
  align-items: center;
  font-size: 8px;
  color: grey;
}

.editable__tabrows_perpage {
  outline: none;
  border-radius: 20px;
  box-shadow: 0 0 2px 1px rgba(238, 237, 237, 0.829);
  margin: 0 10px;
  padding: 2px 8px;
  cursor: pointer;
}

.editable__tabtbarlft_tab {
  display: flex;
  padding: 4px 10px;
  color: grey;
  align-items: center;
  /* box-shadow: 0 0 2px 1px rgba(238, 237, 237, 0.829); */
  border-radius: 20px;
  font-size: 8px;
  font-weight: 500;
  position: relative;
  cursor: pointer;
  margin: 0px;
}

.editable__tabtbarlft_tab>span {
  margin-left: 5px;
}

.editable__tabtbarlft_tabbadge {
  position: absolute;
  top: -4px;
  right: -1px;
  border-radius: 50%;
  font-size: 6px;
  padding: 2px 4px;
  background-color: #0d6efd;
  color: white;
  font-weight: 500;
  min-width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: fit-content;
}

.editable__table_toolbarlft {
  display: flex;
  align-items: center;
  height: 100%;
}

.editabletab__searchbar_wrap {
  margin: 0;
  display: flex;
  align-items: center;
  border-radius: 3px;
  height: 26px;
  overflow: hidden;
  width: 226px;
  padding: 4px 3px;
  border: 1px solid #d9d9d9;
  font-size: 8px;
}

.editabletab__searchbar_field {
  width: 92%;
  border: none;
  outline: none;
  height: 100%;
  padding: 3px 5px;
  flex: 1;
}

.tablecol__fixed_left {
  position: sticky;
  left: 0;
  z-index: 2;
  background: #fff;
}

.tablecol__fixed_right {
  position: sticky;
  right: 0;
  z-index: 2;
}

.footer__boltTable_pagination {
  display: flex;
  justify-content: center;
  padding: 12px 0 5px 0;
}

.editable__table_container::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.editable__table_container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.editable__table_container::-webkit-scrollbar-thumb {
  background: #d2d2d2;
}

.editable__table_container::-webkit-scrollbar-thumb:hover {
  background: #9f9a9a;
}

.bolt__table_expandedrow td {
  height: 0px;
  min-height: 0px;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
}

.bolt__table_expandedbox {
  min-width: 100%;
  overflow-y: hidden;
  max-height: 0px;
  transition: max-height 0.6s ease-in-out;
  box-shadow: 0px 0px 5px 0px rgba(179, 173, 173, 0.76) inset;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(179, 173, 173, 0.76) inset;
  -moz-box-shadow: 0px 0px 5px 0px rgba(179, 173, 173, 0.76) inset;
}

.bolt__table_expandedrowactive {
  height: fit-content;
}

.bolt__table_expandedboxactive {
  max-height: 800px;
  overflow-y: auto;
}

.btable__expandedbox_content {
  width: 100%;
  height: 400px;
}

.editabletab__searchbartabel_icon {
  height: 10px;
  width: 16px;
}

.bolt__tablemax_fixed_height {
  max-height: 50px;
  overflow: auto;
}

.bolt_table_cancle_td {
  opacity: 0.3;
  text-decoration: line-through;
}

.bolt__tablemax_fixed_height::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

.bolt__tablemax_fixed_height::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d9d9d9;
  border-radius: 10px;
}

.bolt__tablemax_fixed_height::-webkit-scrollbar-thumb {
  background: #949494;
  border-radius: 10px;
}

.bolt_table_cancle_td {
  cursor: no-drop;
}

.bolt__table_main_wrap .editable__table tbody td .table_action__btn span {
  width: fit-content !important;
  height: fit-content !important;
}

.boltTable__action_more_btn_label span {
  font-size: 10px;
}

.boltTable__action_more_btn_label {
  padding-top: 3px;
  padding-bottom: 3px;
}

.boltTable__action_more_btn_label>div.MuiListItemIcon-root {
  min-width: fit-content;
  margin-right: 5px;
}

.data_search_field_wrap {
  display: flex;
}

.bolttable__search_data_fields label {
  font-size: 10px;
  text-align: left;
  position: absolute;
  left: 6px;
  top: -9px;
  display: block;
  padding-left: 6px;
}

.bolttable__search_data_fields {
  position: relative;
  border: 1px solid;
  margin-top: 8px;
  margin-bottom: 5px;
}

.data_search_field_wrap input {
  min-height: 19px;
  border: none;
  outline: none;
  text-indent: 8px;
}

.bolttable_header_filter_field * {
  font-size: 10px !important;
}

.bolttable_header_filter_field label+.css-1ptx2yq-MuiInputBase-root-MuiInput-root {
  margin-bottom: 10px !important;
}

.bolttable_header_filter_field .css-1ptx2yq-MuiInputBase-root-MuiInput-root:before {
  display: none;
}

.content__wrapping_boltTable {
  width: 100%;
  text-wrap: wrap;
}

svg.icon__status_boltTable {
  position: sticky;
  top: 0;
}

@media only screen and (min-width: 1190px) {
  .bolt__table_main_wrap tr th {
    font-size: 0.732vw;
  }

  .bolt__table_main_wrap thead {
    position: sticky;
    top: -1px;
    background: #fff;
    z-index: 22;
  }

  .bolt__table_main_wrap tr td,
  .bolt__table_main_wrap tr td,
  .editable__table_toolbarrt,
  .editable__tabtbarlft_tab {
    font-size: 0.632vw;
  }

  .editabletab__searchbar_wrap {
    height: 1.9034vw;
    width: 16.5447vw;
    font-size: 0.5857vw;
  }

  .editabletab__searchbartabel_icon {
    height: 0.7321vw;
    width: 1.1713vw;
  }

  .editable__table tbody td img {
    max-height: 1.3vw;
    max-width: 2.2943vw;
  }

  .editable__table tbody td .table_action__btn {
    font-size: 0.6124vw;
  }

  .editable__table tbody td .table_action__btn svg {
    width: 0.9321vw;
    height: 0.9321vw;
    margin-right: 2px;
  }

  .editable__table tbody td .table_action__btn {
    min-width: 3.2vw !important;
    min-height: 1.3vw !important;
  }

  .editable__table tbody td {
    max-width: 14.6413vw !important;
  }

  .editable__table_toolbar {
    height: 4.0264vw;
  }

  .editable__table th,
  .editable__table td {
    padding: 0.1464vw 0.366vw;
  }

  .footer__boltTable_pagination nav button {
    min-width: 1.903vw;
    height: 1.903vw;
    font-size: 1.025vw;
    border-radius: 100%;
  }

  .footer__boltTable_pagination nav button svg {
    width: 100%;
    height: 100%;
  }

  .editble__table_input {
    text-align: center;
    width: 2.196vw;
    justify-content: center;
  }

  .editable__tabtbarlft_tabbadge {
    font-size: 0.512vw;
    padding: 0.146vw 0.146vw;
    top: -0.293vw;
    right: -0.073vw;
    min-width: 1vw;
    height: auto;
  }

  .editable__tabtbarlft_tab svg {
    width: 1.098vw;
    height: auto;
  }

  .editable__table tbody td .box_success,
  .editable__table tbody td .box_error,
  .editable__table tbody td .box_pending {
    max-width: 7.321vw;
    padding: 0.22vw 0.439vw;
  }

  .bolt__tablemax_fixed_height::-webkit-scrollbar {
    width: 0.146vw;
    height: 0.146vw;
  }

  .bolt__tablemax_fixed_height::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.366vw #d9d9d9;
    border-radius: 0.732vw;
  }

  .bolt__tablemax_fixed_height::-webkit-scrollbar-thumb {
    background: #949494;
    border-radius: 0.732vw;
  }

  .bolt__tablemax_fixed_height {
    max-height: 3.66vw;
    padding: 0.146vw;
    text-wrap: balance;
  }


  .boltTable__action_more_btn_label span {
    font-size: 0.732vw;
  }

  .boltTable__action_more_btn_label {
    padding-top: 0.878vw;
    padding-bottom: 0.878vw;
  }

  .boltTable__action_more_btn_label svg {
    width: 0.952vw;
    height: 0.952vw;
  }

  .bolt__tablemax_fixed_height svg {
    width: 0.952vw;
    height: 0.952vw;
    min-width: 0.952vw;
    min-height: 0.952vw;
  }

  .bolttable_header_filter_field * {
    font-size: 0.793vw !important;
  }

  .bolttable_header_filter_field label+.css-1ptx2yq-MuiInputBase-root-MuiInput-root {
    margin-bottom: 0.5vw !important;
  }

}

@media only screen and (max-width: 767px) {
  .editable__table_toolbar {
    flex-wrap: wrap;
    height: fit-content;
  }

  .bolt__table_expandedboxactive {
    max-height: 800px;
    overflow-y: auto;
  }

  .btable__expandedbox_content {
    width: 100%;
    height: 250px;
  }

  .editable__table_toolbar {
    flex-wrap: wrap;
    height: fit-content;
  }

  .editable__table_toolbarlft {
    flex-wrap: wrap;
  }

  .editabletab__searchbar_wrap {
    margin-bottom: 15px;
  }

  .editable__tabtbarlft_tab {
    margin: 0;
    padding: 7px 0;
    margin-right: 14px;
  }

  .editable__table_container {
    margin-top: 20px;
  }

  .tablecol__fixed_left {
    position: inherit;
  }

  .editable__table_container {
    margin-top: 20px;
  }

  .tablecol__fixed_right {
    position: inherit;
  }

  .prdtable__0rder_attachments_modal.edit_table_attach_img_thumb_modal_wrap {
    width: 90%;
  }

  .prdtable__0rder_attachments_modal.edit_table_attach_img_thumb_modal_wrap .prdtable__0rder_attachments_modalcontent .ordinary__image_wrap .prdtable__0rder_attachments_modalimg {
    max-width: 70px;
    height: 70px !important;
  }
}

@media only screen and (min-width: 1190px) {}