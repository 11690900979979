.admncompany__details_modal {
  min-height: 250px;
  max-height: 95vh;
  overflow-y: auto;
  min-width: 310px;
  width: 1150px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 30px;
}
.admncompany__details_modalhdrrttitle {
  display: flex;
  align-items: center;
  height: 100%;
  color: grey;
  font-weight: 400;
}

.admncompany__details_modal::-webkit-scrollbar {
  width: 5px;
}

.admncompany__details_modal::-webkit-scrollbar-thumb {
  background-color: #0c69f3;
}

.admncompany__details_modal::-webkit-scrollbar-track {
  background-color: #dbd9d9;
}
.admncompany__details_modalheader {
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 18px;
  flex-wrap: wrap;
}
.admncompany__details_modalheaderlft {
  width: fit-content;
}
.admncompany__details_closewrap {
  position: absolute;
  right: 3px;
  top: 2px;
}
.admncompany__details_modalheaderrt {
  padding: 0 15px;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.admncompany__details_modalcontent {
  padding: 20px 0 10px 0;

  width: 100%;
}
.admncompany__details_modalcontent > p {
  margin-left: 5px;
}

.admncompany__details_modalcontent::-webkit-scrollbar {
  width: 5px;
}

.admncompany__details_modalcontent::-webkit-scrollbar-thumb {
  background-color: #0c69f3;
}

.admncompany__details_modalcontent::-webkit-scrollbar-track {
  background-color: #dbd9d9;
}
.admncompany__details_modalheaderimg {
  max-width: 250px;
  max-height: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-radius: 5px;
  overflow: hidden;
}
.admncompany__details_modalheaderimg > img {
  max-width: 250px;
  max-height: 100px;
  object-fit: contain;
}
.admncompany__details_modalcontentrow {
  display: flex;
}
.admncompany__details_modalcontentlft {
  flex: 1;
}
.admncompany__details_modalcontentrt {
  width: fit-content;
  padding-left: 25px;
}
.adcmdetails__modal_contriestable {
  display: grid;
  grid-template-columns: 200px 100px 100px;
  margin: 15px 0 20px 0px;
  max-width: fit-content;
  padding: 10px;
  max-height: 200px;
  overflow-y: auto;
}
.adcmdetails__modal_contriestable > div {
  min-height: 35px;
  display: flex;
  align-items: center;
}
.adcmdetails__modal_ctheading {
  font-weight: 500;
}
.adcmdetails__modal_ctdata {
  border-top: 0.5px solid rgb(228, 226, 226);
  height: fit-content;
}
.adcmdetails__modal_contriestable::-webkit-scrollbar {
  width: 5px;
}

.adcmdetails__modal_contriestable::-webkit-scrollbar-thumb {
  background-color: #0c69f3;
}
.adcmdetails__modal_contriestable::-webkit-scrollbar-track {
  background-color: #dbd9d9;
}
.adcmdetails__selected_country {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 10px;
  max-height: 150px;
  overflow-y: auto;
  max-width: 800px;
}
.adcmdetails__selected_country::-webkit-scrollbar {
  width: 5px;
}

.adcmdetails__selected_country::-webkit-scrollbar-thumb {
  background-color: #0c69f3;
}
.adcmdetails__selected_country::-webkit-scrollbar-track {
  background-color: #dbd9d9;
}
.adcmdetails__selected_country > div {
  height: 35px;
  display: flex;
  align-items: center;
  color: grey;
}
.adcmdetails__selected_countryname {
  font-weight: 500;
}
.adcmdetails__selected_countryheading {
  color: black !important;
  font-weight: 500;
  border-bottom: 0.5px solid lightgrey;
  padding-bottom: 10px;
}
.adcmdetails__modal_detimg {
  float: left;
  margin-right: 15px;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
@media (max-width: 1200px) {
  .admncompany__details_modal {
    max-width: 95%;
    height: auto;
  }
}
@media (max-width: 1000px) {
  .admncompany__details_modalheader {
    flex-direction: column;
  }
  .admncompany__details_modalheaderlft {
    width: 100%;
  }
  .admncompany__details_modalheaderrt {
    width: 100%;
  }
}
