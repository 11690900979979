form.form__tax_slip_wrap .row {
    display: flex;
    justify-content: center;
    max-width: 600px;
    margin: 0 auto;
    column-gap: 10px;
    flex-wrap: nowrap;
}

form.form__tax_slip_wrap .row button {
    width: fit-content;
    font-size: 11px;
    height: fit-content;
    padding: 7px 10px;
}
.tax__slip_view_contact_list_box {
    width: 100%;
    display: inline-flex;
    align-items: center;
    column-gap: 10px;
}

.tax__slip_view_contact_list_box p {
    margin: 0;
    font-size: 12px;
}
.fpinvoice__modal_wrap {margin-top: 20px;}

/*custom css hsm*/
.fpinvoice__modal_wrap {margin-top: 20px;}

.tax__slip_view_details_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
}

.tax__slip_view_details_total {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.tax__slip_view_details_interval_total .tax__slip_view_details_total h3 {
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    width: fit-content; 
    padding-right: 10px; 
}

.tax__slip_view_details_interval_total .tax__slip_view_details_total p {
    margin: 0;
    font-size: 12px;
    width: fit-content; 
}
.tax__slip_view_details_interval {
    display: flex;
}
.tax__slip_view_details_interval_total .tax__slip_view_details_interval h3 {
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    width:fit-content;
    min-width: fit-content;
    padding-right: 10px;    
}

.tax__slip_view_details_interval_total .tax__slip_view_details_interval p {
    margin: 0;
    font-size: 12px;
    width:fit-content;  
    min-width: fit-content;
}

.tax__slip_view_details_interval_total .tax__slip_view_details_interval p strong {
    padding-right: 5px;
    padding-left: 5px;
}
.tax__slip_view_created_list {
    display: flex;
    column-gap: 10px;
}

.tax__slip_view_list_box h3 {
    font-size: 12px;
    margin: 0;
    font-weight: 600;
}

.tax__slip_view_list_box p {
    font-size: 12px;
}

.tax__slip_view_contact_details {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.tax__slip_view_contact_list {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.tax__slip_view_contact_list_heading h2 {
    font-size: 13px;
    font-weight: 700;
    margin: 0 0 5px 0 ;
}

.tax__slip_view_contact_list_box h3 {
    font-size: 12px;
    font-weight: 600;
    margin: 0;
}


.tax__slip_view_contact_list_box:nth-child(2) {
    margin-top: -12px;
}
.tax__slip_view_contact_list_heading {width: 100%;}
.form__tax_slip_wrap .MuiBox-root.css-wmzlvu {
    top: 0;
    height: 20px;
}
.tax__slip_view_details_interval_total .tax__slip_view_details_interval h3 span {
    font-size: 11px;
    font-weight: 400;
}
.invoice__modal_downloadinvoicewrap.tax_slip_Action_btn {
    display: flex;
    position: relative;
    justify-content: flex-end;
    column-gap: 8px;
    margin-bottom: 10px;
}

button.tax_slip_download_btn svg {
    border-bottom: 1px solid;
    color: #fff;
}

button.tax_slip_download_btn {
    padding: 4px 5px 7px 5px;
    border-radius: 3px;
    background: #0c69f3;
    border: 2px solid #0c69f3;
}
button.tax_slip_close_btn {
    padding: 6px 5px;
    border-radius: 3px;
    background: #fc0000;
    border: 2px solid #fc0000;
}

button.tax_slip_download_btn svg,button.tax_slip_close_btn svg {
    color: #fff;
}
@media only screen and (min-width: 1190px) {
    .tax__slip_view_created_list{
        column-gap: 0.732vw;
    }
    .tax__slip_view_details_interval_total .tax__slip_view_details_interval h3,.tax__slip_view_details_interval_total .tax__slip_view_details_total h3{padding-right: 0.732vw;}
    /* .invoice__modal_headerleftimg{
        height: 1.977vw !important;
    } */
    button.tax_slip_download_btn,button.tax_slip_close_btn{padding: 0.439vw 0.439vw;}
    button.tax_slip_download_btn svg,button.tax_slip_close_btn svg{width: 1.098vw; height: 1.098vw;}
    .tax__slip_view_contact_list_box h3,
    .tax__slip_view_contact_list_box p,
    .tax__slip_view_details_interval_total .tax__slip_view_details_total h3,
    .tax__slip_view_details_interval_total .tax__slip_view_details_total p,
    .tax__slip_view_details_interval_total .tax__slip_view_details_interval h3,
    .tax__slip_view_details_interval_total .tax__slip_view_details_interval p,
    .tax__slip_view_details_interval_total .tax__slip_view_details_interval h3 span,
    .tax__slip_view_list_box h3,
    .tax__slip_view_list_box p{
        font-size:0.878vw ;
    }
   .taxes__slip_details_wrap .invmodal__shipment_detailshding{font-size: 1.318vw;}
   .taxes__slip_details_wrap .invmodal__shdetails_tablehd{font-size: 0.586vw;}
   .taxes__slip_details_wrap .invmodal__shdetails_tableitem{font-size:0.952vw}
   .taxes__slip_details_wrap    .invmodal__bottom_titletxt{font-size: 0.878vw}
   b.tax__form_heading {font-size: 1.171vw;}
   span.payment__invoices_heading.tax__page_title { font-size: 1.611vw;}
   .taxes__slip_details_wrap     form.form__tax_slip_wrap * {font-size: 0.878vw;}

form.form__tax_slip_wrap .row button{font-size: 0.76vw;}
.form__tax_slip_wrap .MuiBox-root.css-wmzlvu{height: 1.464vw;}
form.form__tax_slip_wrap .row{max-width: 43.924vw;}
}