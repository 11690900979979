.addnotes__status_title {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.MuiFormControl-root.MuiTextField-root.addnotes__status_notes.css-12nl4nz-MuiFormControl-root-MuiTextField-root {
  margin-bottom: 15px;
}
.addnotes_status_update_attachment_wrap label {
  width: 100%;
}
.addnotes_status_update_attachment_wrap label > span {
  background: transparent;
  box-shadow: none;
  color: #1976d2;
  border: 2px dashed;
  width: 100%;
}
.addnotes_status_update_attachment_wrap label > span:hover {
  background: transparent;
  box-shadow: none;
  color: #000;
}
.addnotes_update_attachment_detail_box {
  margin-bottom: 7px;
  width: 100%;
  max-width: 190px;
}
.addnotes__field input {
  padding: 5px;
}
.MuiFormControl-root.MuiTextField-root.addnotes__field.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: calc(100% - 30px);
}

.addnotes_attachment_list_main_wrap .adnotes_remove_attachment {
  padding: 3.5px;
  border: 1px solid #c4c4c4;
  margin-left: -3px;
  border-radius: 0 3px 3px 0;
  background: #fff;
  border-left: 0;
}
.addnotes_fulfilment_btn {
  margin-top: 10px;
}

@media only screen and (max-width: 768px) {
  .addnotes__detail_fulfilment_modal .MuiBox-root {
    width: 90%;
    text-align: center;
  }
  .addnotes__status_notes textarea {
    font-size: 12px;
  }
  .addnotes_fulfilment_btn {
    justify-content: center !important;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 1170px) {
  .addnotes__detail_fulfilment_modal .addnotes__status_title {
    font-size: 1.318vw;
  }

  .addnotes__detail_fulfilment_modal .addnotes__status_notes * {
    font-size: 0.9vw;
  }

  .addnotes__detail_fulfilment_modal
    .addnotes_status_update_attachment_wrap
    label
    > span {
    font-size: 0.952vw;
  }
  .addnotes__detail_fulfilment_modal .addnotes__status_notes * {
    font-size: 0.9vw;
  }
  .addnotes__detail_fulfilment_modal
    .addnotes_status_update_attachment_wrap
    label
    > span {
    font-size: 0.952vw;
  }
  .addnotes_fulfilment_btn {
    font-size: 0.952vw;
    padding: 0.293vw 0.732vw;
  }
}
