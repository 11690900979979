.invmodal__ship_details_top_wrap {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
}

.invmodal__ship_details_top_wrap .invoice__modal_order_shipment_summary {
  width: 100%;
  max-width: fit-content;
  min-width: 50%;
}

.invmodal__ship_details_top_wrap .invoice__modal_order_details_header {
  width: 100%;
  max-width: 320px;
}

.invmodal__ship_details_top_wrap .invoice__modal_order_shipment_summary .invmodal__shipment_sumitemleft {
  width: 75px;
}

.invmodal__ship_details_top_wrap .invoice__modal_order_shipment_summary .invmodal__shipment_sumitemright {
  width: 100%;
  font-size: 10px;
  color: #000;
  font-weight: 600;
}

.invmodal__ship_details_top_wrap .invoice__modal_order_details_header .invoice__modal_header_content {
  border: 1px solid #ccc;
  padding: 3px;
  /* margin-bottom: 10px; */
}

.invmodal__ship_details_top_wrap .invoice__modal_order_details_header .invoice__modal_header_content .invmodal__hleft_infoitems {
  margin: 0;
}

.inv_ship_hajex_logo {
  margin: 0 auto;
  width: calc(100% - 30px);
  margin-top: 10px;
  margin-bottom: 5px;
}

.invmodal__ship_details_top_wrap .invoice__modal_order_shipment_summary .invmodal__shipment_summarycntnt {
  margin: 0;
}

.invmodal__ship_details_top_wrap .invmodal__shipment_sumitem {
  width: 100%;
  max-width: 190px;
}

.invmodal__ship_details_top_wrap .invmodal__shipment_sumitem.fit_content_fifty {
  width: 48%;
  display: inline-flex;
}

.invmodal__ship_details_top_wrap .invmodal__hleft_amntitems {
  margin-top: 0px;
}

.invmodal__shipment_details.invmodal__ship_details_wrap {
  padding-top: 0;
}

.invmodal__shshiptracking_grid.shipment_tracking_wrap {
  padding-bottom: 10px;
}

.package__name_list_fulfilment_list {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.package__name_list_fulfilment_list .addprod__custominput_box {
  margin-bottom: 15px;
}

.proddetails__desc_modalcontent .invmodal__shdetails_content {
  padding-top: 5px;
  padding-bottom: 10px;
}

.invmodal__shshiptracking_grid.shipment_tracking_wrap .invmodal__shdetails_gridhd {
  font-size: 10px;
  font-weight: 600;
}

.fulfilment_info_title_row.row {
  width: 100%;
  padding: 0;
  margin: 0;
}

.invmshdetails_cnleft_footer td {
  padding: 5px 10px;
}

.invmodal__shshiptracking_grid.shipment_tracking_wrap .invmodal__shdetails_griditem {
  padding: 5px 0;
  font-size: 10px;
}

.parsel_id_heading .invmodal__shdetails_modaltxtrtcopy svg {
  width: 11px;
}

th.invmodal__shdetails_tablehd.parsel_id_heading .invmodal__shdetails_tablehdinner {
  align-items: center;
}

.invmodal__hright_infoitem .invmodal__hright_infoitemleft {
  font-weight: 600;
}

.invoice__modal_order_shipment_summary .invmodal__shipment_sumitemhding {
  font-weight: 700;
}

.invoice__modal_header_date_details.content__orderid_date {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

/* .invmodal__hright_infoitem_item_wrapper_with_bg {
  background: #dcdddf;
  padding: 0 3px;
  border: 1px solid #fff;
} */

.warehouse_cust_prod .invmodal__shipment_detailshding.order_detail_heading {
  margin-top: 5px;
  margin-bottom: -10px;
  background: #dcdddf;
  margin-right: 2px;
  padding: 2px 7px !important;
  width: fit-content;
  font-weight: 600;
  font-size: 11px;
  width: 99% !important;
}

.shipment_attachments_main_wrap {
  display: flex;
  border-bottom: 1px dashed #ccc;
  margin-bottom: 10px;
}

.shipment_attachments_main_wrap .shipment_tracking_wrap {
  width: fit-content;
  min-width: 50%;
}

.shipment_attachments_main_wrap .notes_attachments_wrap {
  width: 50%;
}

.not_data_found_shipment_attachment p {
  font-size: 13px;
  margin: 0;
  margin-top: 10px;
}

.invmodal__shipment_details.invmodal__ship_details_wrap .ordedetail__shdetails_tableitem {
  font-size: 10px;
  border: 0.5px solid grey;
  max-width: 150px;
  padding: 1px 4px;
  position: relative;
}

.invmodal__shipment_details.invmodal__ship_details_wrap .ordedetail__shdetails_tablehd {
  font-size: 10px;
  border: 0.5px solid grey;
  padding: 1px 4px;
}

.package__name_list_fulfilment_list .MuiAutocomplete-root.css-gcwvw8-MuiAutocomplete-root * {
  font-size: 10px;
}

.sale__status_title {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.sale_status_update_attachment_wrap label {
  width: 100%;
}

.sale_status_update_attachment_wrap label>span {
  background: transparent;
  box-shadow: none;
  color: #1976d2;
  border: 2px dashed;
  width: 100%;
}

.sale_status_update_attachment_wrap label>span:hover {
  background: transparent;
  box-shadow: none;
  color: #000;
}

.MuiFormControl-root.MuiTextField-root.sale__status_notes.css-12nl4nz-MuiFormControl-root-MuiTextField-root {
  margin-bottom: 15px;
}

.attachment__field input {
  padding: 5px;
}

.salte_attachment_list_main_wrap {
  margin-top: 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 16px;
}

.salte_attachment_list_main_wrap .icon_btn_remove_attachment {
  padding: 3.5px;
  border: 1px solid #c4c4c4;
  margin-left: -3px;
  border-radius: 0 3px 3px 0;
  background: #fff;
  border-left: 0;
}

.sale_status_update_attachment_detail_box {
  margin-bottom: 7px;
  width: 100%;
  max-width: 190px;
}

.MuiFormControl-root.MuiTextField-root.attachment__field.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: calc(100% - 30px);
}

.fulfilment_info_title_row.row .package__name_list_wrap {
  padding-left: 0;
}

p#child-modal-description {
  font-size: 12px;
}

.fulfilment_data_row label {
  font-size: 10px;
}

.invmodal__shshiptracking_grid.shipment_tracking_wrap {
  grid-template-columns: 150px 80px 71px 135px;
}

.proddetails__desc_btn_cross_header {
  position: absolute;
  right: 15px;
  top: 10px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}

.proddetails__desc_btn_cross_header .order_detail_cross {
  transform: rotate(45deg);
  cursor: pointer;
}

svg.order_detail_download {
  color: #fff;
  background: #0c6efd;
  padding: 0 3px;
  border-radius: 2px;
  cursor: pointer;
}

.proddetails__desc_btn_cross_header svg.order_detail_cross circle {
  fill: #fff;
}

.proddetails__desc_btn_cross_header svg.order_detail_cross g {
  fill: #000;
}

.proddetails__desc_btn_cross_header svg.order_detail_cross {
  width: 25px;
  height: 25px;
}

.orderdetails__cnleft_heading {
  width: 229px;
  display: flex;
  justify-content: center;
  margin-left: auto;
  font-weight: 700;
  font-size: 13px;
  padding-bottom: 5px;
  display: none;
}

.warehouse_cust_prod .invmodal__hright_infoitem {
  display: flex;
  font-size: 10px;
  margin-bottom: 4px;
  flex-wrap: wrap;
  width: 100%;
}

.warehouse_cust_prod .invmodal__shdetails_tableitem {
  font-size: 11px;
}

.details_modal_fulfilment_cost_wrap {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.sticky__product_details {
  position: sticky !important;
  background: #fff;
  z-index: 2;
}

.details_billingstatus__history_icon_wrapper {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin: 5px 0px;
}

.details_billingstatus__history_icon {
  cursor: pointer;
  margin-left: 5px;
}

.details_billingstatus__history_icon:hover {
  color: #0d6efd !important;
}

@media only screen and (max-width: 768px) {
  .warehouse_cust_prod .shipment_attachments_main_wrap .notes_attachments_wrap {
    width: 100%;
    overflow: auto;
  }

  .warehouse_cust_prod .shipment_attachments_main_wrap .notes_attachments_wrap .invmodal__shipment_detailshding {
    position: sticky;
    left: 0;
  }

  .warehouse_cust_prod .invmodal__ship_details_top_wrap {
    flex-wrap: wrap;
  }

  .warehouse_cust_prod .invmodal__shdetails_content {
    width: 100%;
    overflow: auto;
  }

  .warehouse_cust_prod table.invmodal__shdetails_table {
    min-width: 750px;
  }

  .warehouse_cust_prod table.invmshdetails_cnleft_table {
    min-width: 580px;
  }

  .warehouse_cust_prod .shipment_attachments_main_wrap {
    flex-wrap: wrap;
    width: 100%;
  }

  .warehouse_cust_prod .shipment_tracking_wrap {
    width: 100%;
    overflow: auto;
  }

  .warehouse_cust_prod .shipment_tracking_wrap .invmodal__shipment_detailshding {
    position: sticky;
    left: 0;
  }

  .warehouse_cust_prod .invmodal__shshiptracking_grid.shipment_tracking_wrap {
    width: fit-content;
  }

  .warehouse_cust_prod .shipment_attachments_main_wrap .notes_attachments_wrap {
    width: 100%;
    overflow: auto;
  }

  .warehouse_cust_prod .shipment_attachments_main_wrap .notes_attachments_wrap .invmodal__shipment_detailshding {
    position: sticky;
    left: 0;
  }

  .warehouse_cust_prod .invmodal__hleft_amntitem {
    flex-wrap: nowrap;
  }

  .warehouse_cust_prod .invmodal__hleft_amntitem .invmodal__hleft_amntitemright {
    min-width: fit-content;
  }

  .package__name_list_wrap.fulfilment_information_form_wrap .package__name_list_fulfilment_list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    margin-bottom: 20px;
  }

  .package__name_list_wrap.fulfilment_information_form_wrap .package__name_list_fulfilment_list .addprod__custominput_box {
    margin: 0;
    width: 100%;
  }

  .custom__drop_order_details .css-gcwvw8-MuiAutocomplete-root {
    width: 100%;
  }

  .custom__drop_order_details {
    width: 100%;
    margin-bottom: 5px;
  }

  .details_modal_fulfilment_cost_wrap {
    width: 100%;
  }

  .details_modal_fulfilment_cost_wrap .MuiFormControl-root {
    width: 100%;
  }

  .proddetails__desc_modal.detail__modal_order_view {
    padding: 17px 0 !important;
  }

  .proddetails__desc_modal.detail__modal_order_view .fulfilment_info_title_row.row {
    padding: 0 8px;
  }

  .package__name_list_wrap.fulfilment_information_form_wrap {
    padding-right: 0;
  }

  .warehouse_cust_prod table.invmshdetails_cnleft_table {
    min-width: 900px;
  }

  .inner__detail_fulfilment_modal .MuiBox-root {
    width: 90%;
    text-align: center;
  }

  .inner__detail_fulfilment_btn {
    justify-content: center !important;
  }

  .MuiMenu-list li {
    font-size: 12px !important;
  }

  .sale__status_notes textarea {
    font-size: 12px;
  }

  .invmodal__shdetails_content.sales_update_inner_modal_table table {
    min-width: 850px;
  }

  .invmodal__shdetails_content.sales_update_inner_modal_table {
    overflow: auto;
    padding-top: 0;
  }

  .invmodal__shdetails_content.sales_update_inner_modal_table table td {
    font-size: 11px;
  }

  .invmodal__shipment_details.sales_update_inner_modal {
    padding-top: 0;
  }

  .deatil_modal_footer_btn_save {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 1170px) {
  .proddetails__desc_modal.detail__modal_order_view {
    max-width: 67.35vw;
  }

  .proddetails__desc_modalcontent.warehouse_cust_prod .invmodal__shipment_sumitemhding,
  .warehouse_cust_prod .invmodal__shipment_detailshding.order_detail_heading,
  .warehouse_cust_prod .invmodal__shipment_summaryhding,
  .warehouse_cust_prod .invmodal__shipment_detailshding,
  .warehouse_cust_prod .not_data_found_shipment_attachment p {
    font-size: 0.805vw;
  }

  .warehouse_cust_prod .invmodal__ship_details_top_wrap .invoice__modal_order_shipment_summary .invmodal__shipment_sumitemright,
  .warehouse_cust_prod .invoice__modal_header_date_details.content__orderid_date .invmodal__hright_infoitem,
  .warehouse_cust_prod .invmodal__hleft_infoitem,
  .warehouse_cust_prod .invmodal__hleft_amntitem,
  .warehouse_cust_prod .invmodal__shdetails_tablehd,
  .warehouse_cust_prod .invmodal__shshiptracking_grid.shipment_tracking_wrap .invmodal__shdetails_gridhd,
  .warehouse_cust_prod .invmodal__shipment_details.invmodal__ship_details_wrap .invmodal__shdetails_tableitem,
  .warehouse_cust_prod .invmodal__shshiptracking_grid.shipment_tracking_wrap .invmodal__shdetails_griditem,
  .warehouse_cust_prod .fulfilment_data_row strong {
    font-size: 0.732vw;
  }

  .warehouse_cust_prod strong.fulfilment_box_title {
    font-size: 0.878vw;
  }

  .warehouse_cust_prod .invmodal__ship_details_top_wrap .invoice__modal_order_details_header {
    max-width: 23.426vw;
  }

  .warehouse_cust_prod .invmodal__hleft_amntitemleft {
    width: fit-content;
  }

  .proddetails__desc_modal.detail__modal_order_view .proddetails__desc_modalheading {
    font-size: 0.878vw;
  }

  .invmodal__shdetails_content.package__detail_modal_table th.ordedetail__shdetails_tablehd,
  .invmodal__shdetails_content.package__detail_modal_table td.ordedetail__shdetails_tableitem {
    font-size: 0.732vw;
  }

  .warehouse_cust_prod .shipment_order_not_available p {
    font-size: 0.732vw;
  }

  .warehouse_cust_prod .invmodal__shshiptracking_grid.shipment_tracking_wrap,
  .warehouse_cust_prod .notes_attachments_wrap .invmodal__shshiptracking_grid.shipment_tracking_wrap {
    grid-template-columns: 10.981vw 5.857vw 5.198vw 9.783vw;
  }

  .warehouse_cust_prod .invmodal__shshiptracking_grid.shipment_tracking_wrap .invmoda__note_atchmentdownloadwrap svg {
    width: 0.952vw;
    height: 0.952vw;
  }

  .warehouse_cust_prod .invmodal__shshiptracking_grid.shipment_tracking_wrap .invmoda__note_atchmentdownloadwrap .invmodal__shippymnt_attachmenbadge2 {
    font-size: 0.586vw;
    min-width: 0.732vw;
    height: 0.732vw;
  }

  .warehouse_cust_prod .notes_attachments_wrap.notes_attachment_col .rise_modal .uploaded__attachments_list {
    width: 14.641vw;
    min-height: fit-content;
  }

  .warehouse_cust_prod .notes_attachments_wrap.notes_attachment_col .rise_modal .uploaded__attachments_list .uploaded__attachments_litxt {
    font-size: 0.732vw;
  }

  .warehouse_cust_prod .notes_attachments_wrap.notes_attachment_col .rise_modal .uploaded__attachments_list span.uploaded__attachments_listhdingicons svg.uploaded__attachments_downloadall {
    width: 0.805vw;
    height: 0.805vw;
  }

  .warehouse_cust_prod .notes_attachments_wrap.notes_attachment_col .rise_modal .uploaded__attachments_list span.uploaded__attachments_listhdingicons svg.uploaded__attachments_closeicon {
    width: 0.805vw;
    height: 0.805vw;
  }

  .warehouse_cust_prod .notes_attachments_wrap.notes_attachment_col .uploaded__attachments_liicondownload svg {
    width: 0.805vw;
    height: 0.805vw;
  }

  .warehouse_cust_prod .notes_attachments_wrap.notes_attachment_col .uploaded__attachments_liicondel svg {
    width: 0.805vw;
    height: 0.805vw;
  }

  .warehouse_cust_prod .package__name_list_fulfilment_list .addprod__custominput_box {
    width: 9.837vw;
    margin-bottom: 1.19vw;
  }

  .warehouse_cust_prod .package__name_list_fulfilment_list .custom__drop_order_details,
  .warehouse_cust_prod .package__name_list_fulfilment_list .css-gcwvw8-MuiAutocomplete-root {
    width: 21.962vw;
  }

  .warehouse_cust_prod .package__name_list_fulfilment_list .addprod__custominput_box *,
  .warehouse_cust_prod .package__name_list_fulfilment_list .custom__drop_order_details *,
  .warehouse_cust_prod .fulfilment_info_title_row .details_modal_fulfilment_cost_wrap * {
    font-size: 0.805vw !important;
    line-height: 1.5;
  }

  .warehouse_cust_prod .details_modal_fulfilment_cost_wrap svg {
    width: 1.464vw;
    height: 1.464vw;
  }

  .inner__detail_fulfilment_modal .sale__status_title {
    font-size: 1.2rem;
  }

  .inner__detail_fulfilment_modal .sale__status_notes * {
    font-size: 0.8rem;
  }

  .inner__detail_fulfilment_modal .sale_status_update_attachment_wrap label>span {
    font-size: 0.9rem;
  }

  .invmodal__shipment_details.sales_update_inner_modal .invmodal__shipment_summaryhding {
    font-size: 1.2rem;
  }

  .invmodal__shipment_details.sales_update_inner_modal .invmodal__shdetails_tablehd {
    font-size: 0.766rem;
  }

  p#child-modal-description {
    font-size: 0.854rem;
  }

  .inner__detail_fulfilment_btn button {
    font-size: 0.8rem;
    padding: 0.293vw 0.732vw;
  }
}

/*order invoice printable*/

.invoice__modal_box.printable__invoice.printable__order__details .invoice__modal_order_shipment_summary {
  max-width: 100%;
}

.invoice__modal_box.printable__invoice.printable__order__details {
  padding: 40px;
}

.invoice__modal_box.printable__invoice.printable__order__details .invmodal__shdetails_cntleft {
  width: 100%;
  flex: auto;
}

.invoice__modal_box.printable__invoice.printable__order__details .invmodal__shdetails_cntleft table.invmshdetails_cnleft_table {
  width: 100%;
  margin-bottom: 20px;
}

.invoice__modal_box.printable__invoice.printable__order__details .shipment_tracking_wrap {
  width: 50%;
}

.invoice__modal_box.printable__invoice.printable__order__details .shipment_attachments_main_wrap {
  flex-wrap: wrap;
  padding-bottom: 20px;
}

.invoice__modal_box.printable__invoice.printable__order__details .inv_ship_hajex_logo {
  width: 100%;
}

.invoice__modal_box.printable__invoice.printable__order__details .invoice__modal_order_shipment_summary .invmodal__shipment_sumitemhding {
  margin: 0;
}

.invoice__modal_box.printable__invoice.printable__order__details .invmodal__shdetails_content {
  padding: 0;
}

.invoice__modal_box.printable__invoice.printable__order__details .invmodal__shdetails_table {
  margin: 0;
}

.invoice__modal_box.printable__invoice.printable__order__details .invmodal__shipment_detailshding {
  padding: 0;
}

.invoice__modal_box.printable__invoice.printable__order__details .invoice__modal_box.printable__invoice.printable__order__details .shipment_tracking_wrap {
  margin: 0;
}

.invoice__modal_box.printable__invoice.printable__order__details .shipment_tracking_wrap {
  margin: 0 0 10px 0;
  padding: 0;
}

.invoice__modal_box.printable__invoice.printable__order__details .not_data_found_shipment_attachment p {
  margin: 0;
}

.invoice__modal_box.printable__invoice.printable__order__details .invmodal__shdetails_tableitem {
  font-size: 9px;
}

.invoice__modal_box.printable__invoice.printable__order__details .ordedetail__shdetails_tableitem {
  font-size: 9px;
}

.invoice__modal_box.printable__invoice.printable__order__details .invmodal__shipment_detailshding.order_detail_heading,
.invoice__modal_box.printable__invoice.printable__order__details .invmodal__shipment_summaryhding,
.invoice__modal_box.printable__invoice.printable__order__details .invoice__modal_order_shipment_summary .invmodal__shipment_sumitemhding,
.invoice__modal_box.printable__invoice.printable__order__details .invmodal__shipment_detailshding {
  font-size: 14px;
}

.invoice__modal_box.printable__invoice.printable__order__details .ordedetail__shdetails_tableitem {
  min-height: 10em;
  display: table-cell;
  vertical-align: middle;
}

.invoice__modal_box.printable__invoice.printable__order__details .invmodal__hright_infoitem .invmodal__hright_infoitemleft {
  font-size: 9px;
  line-height: 1;
  padding-bottom: 11px;
}

.invoice__modal_box.printable__invoice.printable__order__details .invmodal__hright_infoitem .invmodal__hright_infoitemright {
  font-size: 9px;
  line-height: 1;
  padding-bottom: 9px;
}

.invoice__modal_box.printable__invoice.printable__order__details .invmodal__hleft_infoitem {
  font-size: 10px;
  margin-bottom: 0;
}

.invoice__modal_box.printable__invoice.printable__order__details .invmodal__hleft_amntitem {
  font-size: 11px;
}

.invoice__modal_box.printable__invoice.printable__order__details .invoice__modal_order_shipment_summary {
  min-width: 60%;
}

.invoice__modal_box.printable__invoice.printable__order__details .invoice__modal_order_details_header {
  max-width: 35%;
  min-width: 260px;
}

.invoice__modal_box.printable__invoice.printable__order__details .invmodal__shipment_summarycntnt>div {
  padding-right: 15px;
}

.invoice__modal_box.printable__invoice.printable__order__details .invmodal__hleft_amntitem .invmodal__hleft_amntitemright {
  min-width: 30px;
}

.invoice__modal_box.printable__invoice.printable__order__details .ordedetail__shdetails_tablehd,
.invoice__modal_box.printable__invoice.printable__order__details .ordedetail__shdetails_tableitem {
  font-size: 9px;
  line-height: 1;
  padding-bottom: 11px;
}

.invoice__modal_box.printable__invoice.printable__order__details .invmodal__shipment_summaryhding.package_detail_heading {
  margin-bottom: 10px;
}

.invoice__modal_box.printable__invoice.printable__order__details .shipment_order_not_available p,
.invoice__modal_box.printable__invoice.printable__order__details .not_data_found_shipment_attachment p {
  font-size: 10px;
}

.invoice__modal_box.printable__invoice.printable__order__details .fulfilment_box_title,
.invoice__modal_box.printable__invoice.printable__order__details .fulfilment_data_row.total_fulfilment_cost {
  font-size: 11px;
  line-height: 1;
  padding-bottom: 11px;
}

.invoice__modal_box.printable__invoice.printable__order__details .fulfilment_data_row strong {
  font-size: 9px;
  line-height: 1;
  padding-bottom: 9px;
}

.invoice__modal_box.printable__invoice.printable__order__details .fulfilment_data_row.total_fulfilment_cost {
  margin-top: 8px;
}

.invoice__modal_box.printable__invoice.printable__order__details .invmodal__shipment_summaryhding.product_detail_heading {
  margin-top: 0;
  margin-bottom: 10px;
}

.invoice__modal_box.printable__invoice.printable__order__details .invmodal__shipment_summarycntnt {
  margin-bottom: 15px;
}

.order_detail_view_thumbnails_wrap_main {
  width: 100%;
  max-width: 110px;
  overflow: auto;
}

.order_detail_view_thumbnails_wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
  min-width: fit-content;
}

.tracking_notes_conten_box {
  width: 95%;
  /* max-height: 50px; */
  /* overflow: auto; */
}

.order_detail_view_thumbnails_wrap img {
  max-width: 30px;
  max-height: 30px;
  width: 30px;
  height: 30px;
  object-fit: contain;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0px -2px 10px 0px #eaeaea;
}

.order_detail_view_thumbnails_wrap_main::-webkit-scrollbar,
.tracking_notes_conten_box::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.order_detail_view_thumbnails_wrap_main::-webkit-scrollbar-track,
.tracking_notes_conten_box::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.order_detail_view_thumbnails_wrap_main::-webkit-scrollbar-thumb,
.tracking_notes_conten_box::-webkit-scrollbar-thumb {
  background: #d2d2d2;
}

.order_detail_view_thumbnails_wrap_main::-webkit-scrollbar-thumb:hover,
.tracking_notes_conten_box::-webkit-scrollbar-thumb:hover {
  background: #9f9a9a;
}

.PrintableOrderDetails_modal {
  left: -10000px;
}

.order_detail_modal_wrap .invmodal__shipment_summaryfrom .invmodal__shipment_sumitemright,
.order_detail_modal_wrap .invmodal__shipment_summaryto .invmodal__shipment_sumitemright {
  font-weight: 400 !important;
}

.note__attachment_modal_wrap {
  border: none;
  width: 400px;
  height: 400px;
  margin: 0;
  overflow: inherit;
}

.note__attachment_modal_wrap .prdtable__0rder_attachments_modalclose {
  top: 0;
  right: 0;
}

.note__attachment_modal_wrap img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.prdtable__images_modal.note__attachment_modal_container {
  min-height: fit-content;
  min-width: fit-content;
  width: fit-content;
}

.invoice__modal_box.printable__invoice.printable__order__details .package__name_list_wrap.fulfiment_box_wrap {
  max-width: 400px;
}

.invoice__modal_box.printable__invoice.printable__order__details .invmodal__hleft_amntitemleft {
  width: fit-content;
}

.invmodal__hright_infoitem {
  width: 100%;
}

.invmodal__hright_infoitemleft {
  width: 130px;
}

.invmodal__hright_infoitemright {
  width: 100%;
}

.invmodal__hright_infoitem .invmodal__hright_infoitem_item_wrapper {
  width: 100%;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .prdtable__images_modal.note__attachment_modal_container {
    min-width: 90%;
    max-width: 768px;
  }
}

@media only screen and (min-width: 1190px) {
  .order_detail_view_thumbnails_wrap img {
    max-width: 2.196vw;
    max-height: 2.196vw;
    width: 2.196vw;
    height: 2.196vw;
  }

  .order_detail_view_thumbnails_wrap_main {
    max-width: 8.053vw;
  }

  .note__attachment_modal_wrap {
    width: 29.283vw;
    height: 29.283vw;
    margin: 50px 0 0 0;
  }

  .prdtable__images_modal.note__attachment_modal_container {
    max-height: 90vh;
  }

  .notes__attachments_modal_attachment_thumbnail_cell {
    max-width: 7.321vw !important;
    width: 7.321vw !important;
  }

  .notes__attachments_modal_attachment_thumbnail img {
    max-width: 2.196vw;
    max-height: 2.196vw;
    width: 2.196vw;
    height: 2.196vw;
  }

  .proddetails__desc_btn_cross_header svg.order_detail_download {
    width: 1.471vw;
    height: 1.471vw;
  }

  .proddetails__desc_btn_cross_header svg.order_detail_cross {
    width: 1.838vw;
    height: 1.838vw;
  }

  .invmodal__shdetailgi_carrlogo {
    height: 1.471vw;
  }

  .invmodal__shdetails_content.product_detail_table_box th.ordedetail__shdetails_tablehd,
  .invmodal__shdetails_content.product_detail_table_box td.ordedetail__shdetails_tableitem {
    font-size: 0.732vw;
    padding: 0.074vw 0.294vw;
  }

  .invmodal__shdetails_content.product_detail_table_box td.ordedetail__shdetails_tableitem img.product__table_mainimg {
    width: 2.941vw;
    height: 1.838vw;
  }

  .proddetails__desc_modalcontent .invmodal__shdetails_content table.invmshdetails_cnleft_table th.ordedetail__shdetails_tablehd {
    font-size: 0.732vw;
    padding: 0.074vw 0.294vw;
  }

  .proddetails__desc_modalcontent .invmodal__shdetails_content table.invmshdetails_cnleft_table td.ordedetail__shdetails_tableitem {
    font-size: 0.732vw;
    padding: 0.074vw 0.294vw;
  }

  .package__name_list_wrap.fulfiment_box_wrap .fulfilment_data_row label {
    font-size: 0.732vw;
  }

  .invmodal__ship_details_top_wrap .invmodal__shipment_sumitem {
    width: 100%;
    max-width: 13.909vw;
  }
}

@media print {
  .ssummary_innertable_head th {
    padding-bottom: 4px;
  }

}