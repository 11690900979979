.manage__userformula_modal {
  min-height: 400px;
  min-width: 310px;
  width: 90%;
  max-width: 1200px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
  max-height: 95vh;
  overflow-y: auto;
}
.driver__scrnempt_content {
  font-size: 17px;
  text-align: center;
}

.driver__scrnempt_content svg {
  width: 40px;
  height: 40px;
}