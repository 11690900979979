.prod__quantity_desc_modal {
  min-height: 250px;
  max-width: 610px;
  width: 90%;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
}
.prod__quantity_desc_modalheading {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
}

.prod__quantity_desc_modalcontent2 {
  padding: 20px 0 10px 0;
}
.prod__quantity_desc_modalfooter {
  width: 100%;
  padding: 10px;
  height: 60px;
  border-top: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.prod__quantity_desc_modalcncl {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #454545;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}
.prod__quantity_desc_modalsave {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c69f3;
  border: 1px solid #0c69f3;
  border-radius: 3px;
  margin-left: 10px;
  color: white;
  cursor: pointer;
}
.product_quantity_box_wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 5px;
}
.product_quantity_box {
  width: 100%;
  padding: 0px 10px;
}
.product_quantity_box_active {
  width: 100%;
  padding: 0px 10px;
}
.product_quantity_box_innerwrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  gap: 20px;
  align-items: end;
}
.product_quantity_box_name {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #4d4d4d;
  cursor: pointer;
}
.product_quantity_box_active,
.product_quantity_box {
  padding-top: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px 4px rgba(235, 235, 235, 0.616);
}
.product_quantity_box_textarea {
  border: 1px solid #dedede;
  border-radius: 5px;
  padding: 6px 7px;
  width: 100%;
  font-size: 14px;
  height: 50px;
}
.product_quantity_box_line {
  border-top: 0.5px solid lightgray;
  margin-bottom: 5px;
}

.product_quantity_box_reason_wrapper {
  width: 100%;
}
.product_quantity_box_newquantitybox_wrapper {
  display: flex;
  height: 25px;
  align-items: center;
}
.product_quantity_box_newquantitybox {
  min-width: 30px;
  padding: 0.375rem 0rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-clip: padding-box;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.product_quantity_box_modalsave {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: #0c69f3;
  border: 1px solid #0c69f3;
  border-radius: 3px;
  margin-left: 10px;
  color: white;
  cursor: pointer;
}

.product_quantity_box_actualquantity {
  color: grey;
  text-decoration: line-through;
  font-size: 12px;
  font-weight: normal;
}

.product_quantity_box_adjquantity_wrapper {
  display: flex;
  justify-content: center;
  align-items: end;
}
.product_quantity_box_button {
  display: flex;
  align-items: end;
  height: 100%;
}
.product_quantity_box_adj_input {
  height: 25px;
  font-size: 12px !important;
}

@media only screen and (max-width: 767px) {
  .prod__quantity_desc_modal {
    max-height: 90vh;
    overflow: auto;
  }

  .prod__quantity_desc_modalheading {
    font-size: 17px;
    text-align: center;
    border: none;
  }
  .prod__quantity_box_label {
    min-width: 80px;
  }
  .product_quantity_box_innerwrapper {
    gap: 10px;
  }
}
