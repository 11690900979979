/* transfer_note */
.transfer_note_section_wrap {
  width: 100%;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 0 5px 5px rgba(235, 235, 235, 0.616);
  padding: 20px;
  margin: 20px 0;
}
.transfer_note_heading {
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 17px;
}
.transfer_note_mediabox {
  width: 100%;
  height: 150px;
  border-radius: 5px;
  border: 1px dashed lightgray;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.transfer_note_imagebtns {
  display: flex;
  margin-top: 15px;
}
.transfer_note_uploadimagebtn {
  display: block;
  width: 170px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  border: 1px solid rgb(228, 226, 226);
  font-size: 15px;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
}
.transfer_note_mdbtnicon {
  margin-right: 5px;
}
.transfer_note_mdbtmtext {
  margin-top: 5px;
  font-size: 12px;
  color: grey;
}
.transfer_note_images {
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0;
}
.transfer_note_imagewrap {
  width: 80px;
  height: 60px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  overflow: hidden;
  margin: 5px 10px;
  position: relative;
}
.transfer_note_imageclose {
  position: absolute;
  top: 3px;
  right: 3px;
  padding: 2px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 1px 1px 3px 0px lightgrey;
  cursor: pointer;
}
.transfer_note_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.transfer_note_setmainbtn {
  position: absolute;
  padding: 3px 8px;
  font-size: 10px;
  font-weight: 500;
  color: white;
  background-color: #0c6efd;
  width: 100%;
  bottom: 0;
}
.transfer_note_mainbadge {
  position: absolute;
  padding: 3px 8px;
  font-size: 10px;
  font-weight: 500;
  color: white;
  box-shadow: 1px 1px 3px 2px lightgrey;
  background-color: grey;
  width: 100%;
  bottom: 0;
  pointer-events: none;
}
