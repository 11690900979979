.add__warehouse__client__user_modal {
    min-height: 250px;
    min-width: 310px;
    width: 750px;
    height: 80%;
    border-radius: 5px;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 24px;
    border: none;
    outline: none;
    padding: 20px;
    max-height: 95vh;
    overflow-y: auto;
}

.add__warehouse__client__user_modalheading {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 0.5px solid lightgray;
    font-weight: 500;
    font-size: 18px;
}

.add__warehouse__client__user_modalcontent {
    padding: 20px 0 10px 0;
}

.add__warehouse__client__user_modalrow {
    display: flex;
}

.add__warehouse__client__user_modalinputwrap {
    flex: 1;
    margin: 0 5px 5px 5px;
}

.add__warehouse__client__user_modalinputwrap>label {
    font-size: 12px;
    color: grey;
}

.add__warehouse__client__user_modalinput {
    width: 100%;
    border: 0.5px solid lightgrey;
    outline: none;
    border-radius: 5px;
    height: 40px;
    padding: 0 10px;
    transition: 0.3s;
}

.add__warehouse__client__user_modalinput .MuiOutlinedInput-root {
    padding: 0 !important;
    height: 40px;
}

.add__warehouse__client__user_modalinput .MuiOutlinedInput-root input {
    padding-left: 10px !important;
}

.add__warehouse__client__user_modalinput:focus {
    outline: 0.5px solid #0c68f373;
}

.add__warehouse__client__user_modalfooter {
    width: 100%;
    padding: 10px;
    height: 60px;
    border-top: 0.5px solid lightgray;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.add__warehouse__client__user_modalcncl {
    width: 90px;
    height: 40px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    color: #454545;
    background: #d9d9d9;
    border: 1px solid #d9d9d9;
    cursor: pointer;
}

.add__warehouse__client__user_modalsave {
    width: 90px;
    height: 40px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0c69f3;
    border: 1px solid #0c69f3;
    border-radius: 3px;
    margin-left: 10px;
    color: white;
    cursor: pointer;
}

.add__warehouse__settings__user_nextbtndisabled {
    opacity: 0.5;
    pointer-events: none;
}