.newswrapper__wrapper {
  margin: auto;
  max-width: 97%;
  padding: 10px 20px;
}
.admincustable__actions_btns {
  display: flex;
  align-items: center;
}
.admincustable__actions_btn {
  padding: 0 7px;
}
.custable__options_wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custabscreen__theader_cntryflags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
