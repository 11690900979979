.prdtable__hist_modal {
  min-height: 250px;
  max-width: 860px;
  width: 95%;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
}
.prdtable__hist_modalheading {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid lightgray;
  font-weight: 500;
  font-size: 18px;
}
.prdtable__hist_modalcontent {
  padding: 20px 0 10px 0;
  height: 60vh;
  overflow: auto;
}
.popup_history_main {
  background: transparent;
  box-shadow: none;
}
.prdtable__hist_modalrow {
  display: flex;
}
.prdtable__hist_modalinputwrap {
  flex: 1;
  margin: 0 5px 10px 5px;
}
.prdtable__hist_modalinput {
  width: 100%;
  border: 0.5px solid lightgrey;
  outline: none;
  border-radius: 5px;
  height: 200px;
  padding: 5px 10px;
  transition: 0.3s;
}
.prdtable__hist_modallabel {
  width: 100px;
  height: 40px;
  margin: 0 5px 10px 5px;
  font-size: 17px;
  display: flex;
  padding: 2px;
  align-items: center;
}
.prdtable__hist_modalinput:focus {
  outline: 0.5px solid #0c68f373;
}
.prdtable__hist_modalfooter {
  width: 100%;
  padding: 10px;
  height: 60px;
  border-top: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.prdtable__hist_modalcncl {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #454545;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}
.prdtable__hist_modalsave {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c69f3;
  border: 1px solid #0c69f3;
  border-radius: 3px;
  margin-left: 10px;
  color: white;
  cursor: pointer;
}
.prdtable__hist_pagination {
  width: 100%;
  display: flex;
  justify-content: center;
}
.prdtable__hist_loader {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}
.prdtable__hist_modalcontent.history__product_content {
  height: max-content;
}

.prdtable__hist_modalcontent.history__product_content .editable__table_container {
  height: 50vh;
}
.history__product_footer {
  position: absolute;
  top: 20px;
  right: 10px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.prdtable__hist_modalheading.history__product_heading.history__main_heading {
  padding: 0;
  font-size: 18px;
  line-height: 1;
  height: fit-content;
}

.prdtable__hist_modalheading.history__product_heading.history__sub_heading {
  padding: 0;
  font-size: 12px;
  font-weight: 200;
}
.history__product_footer .history__product_footer_btn {
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  background: #f00;
  color: #fff;
  font-family: cursive;
  line-height: 1;
  cursor: pointer;
}
@media only screen and (min-width: 1190px) {
  .prdtable__hist_modalcontent.history__product_content
    .MuiTableCell-sizeSmall {
    font-size: 0.805vw;
  }
  .prdtable__hist_modal {
    max-width: 62.958vw;
  }
  .prdtable__hist_modalheading.history__product_heading {
    font-size: 1.318vw;
  }
}
