.payment_pending_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: grey;
  margin: 20px 5px 0px 5px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid lightgrey;
}

.payment_pending_heading {
  font-weight: 400 !important;
  font-size: 22px;
}
.payment_pendingpayment_btn {
  width: 100px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 500;
}

.payment_pendingpayment_btn:active {
  transform: scale(0.99);
}
