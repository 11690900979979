/* manage Permissions  */
.mngpermission__tabel {
  overflow: auto;
  border-left: 1px solid #d9d9d9;
}
/* .mngpermission__tabel table td:first-child {
      position: sticky;
      left: 0;
    }
    .mngpermission__tabel table th:first-child {
      position: sticky;
      left: 0;
    } */
.mngpermission__tabel table tr th:first-child {
  position: sticky;
  left: 0;
  z-index: 4;
  background: #d1d2d2;
}
.mngpermission__tabel table td:first-child {
  position: sticky;
  left: 0;
  z-index: 4;
  background: #ffffff;
}
.mngpermission__tabel table tr td.mngpermission__td1:first-child {
  position: sticky;
  left: 0;
  z-index: 4;
  background: #f8fafc;
}
.mngpermission__header {
  background: #f8fafc;
  border: 1px solid #d9d9d9;
  border-left: none;
}
.mngpermission__td1 {
  font-weight: bold;
  padding-left: 20px;
  min-width: 300px;
}
.mngpermission__td2 {
  padding-top: 5px;
  text-align: center;
}
.mngpermission__sub_td1 {
  padding-left: 20px;
  font-size: 14px;
  min-width: 300px;
}
.mngpermission__sub_td2 {
  padding-top: 5px;
  text-align: center;
}
.mngpermissions__sub_tr {
  border: 1px solid #d9d9d9;
  border-left: none;
}
.mngpermissions__header_tr {
  background-color: #d1d2d2;
  border: 1px solid #d9d9d9;
  height: 35px;
  border-left: none;
}

.mngpermissions__header_th {
  padding: 0px 20px;
  min-width: 200px;
  text-align: center;
}
.mngpermissions__header_delete {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
/* scroll style */
.mngpermission__tabel::-webkit-scrollbar {
  height: 3px;
}
.mngpermission__tabel::-webkit-scrollbar-thumb {
  background-color: #888;
}
.mngpermission__tabel::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* end manage Permissions  */
