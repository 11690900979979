/* Poppins font in normal style */

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  src: url("./fonts/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/Poppins-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  src: url("./fonts/Poppins-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  src: url("./fonts/Poppins-Black.ttf") format("truetype");
}

/* Poppins font in italic style */
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 200;
  src: url("./fonts/Poppins-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 300;
  src: url("./fonts/Poppins-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  src: url("./fonts/Poppins-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  src: url("./fonts/Poppins-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 600;
  src: url("./fonts/Poppins-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  src: url("./fonts/Poppins-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 800;
  src: url("./fonts/Poppins-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 900;
  src: url("./fonts/Poppins-BlackItalic.ttf") format("truetype");
}

* {
  font-family: "Poppins", sans-serif;
}

* {
  overflow-wrap: anywhere;
}

.disable__click {
  pointer-events: none !important;
}

.disable__click_fade {
  pointer-events: none !important;
  opacity: 0.6;
}



/* For Webkit browsers (e.g., Chrome, Safari) */
.thin__scrollbar::-webkit-scrollbar {
  width: 5px;
  /* Width of the entire scrollbar */
  height: 5px;
  /* Height of the entire scrollbar */
}

.thin__scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(100, 100, 100, 0.5);
  /* Color of the scrollbar thumb */
  border-radius: 10px;
  /* Rounded corners for the scrollbar thumb */
}

.thin__scrollbar::-webkit-scrollbar-track {
  background: rgba(200, 200, 200, 0.3);
  /* Color of the scrollbar track */
}