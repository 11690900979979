.welcome_reguser_screen_wrap {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.welcome_reguser_header img {margin: 0 auto 20px auto;}

.welcome_reguser_screentickwrapper {
    width: 90px;
    margin: 30px auto;
}

.welcome_reguser_screenbtn {
    background: #0d6efd;
    border: none;
    border-radius: 3px!important;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    padding: 12px 30px;
    position: relative;
    text-transform: uppercase;
    width: 210px;
    margin: 0 auto;
    cursor: pointer;
}

.welcome_reguser_screen {
    max-width: 80%;
}