.toc__sidebar_wrapper {
  padding-top: 50px;
  min-width: 300px;
  width: 300px;
  min-height: 60vh;
  transition: width 2s;
  position: sticky;
  top: 0;
}
.toc__sidebar_wrapper * {
  overflow-wrap: normal !important;
}

.toc__sidebar_wrapper2 {
  padding-top: 50px;
  width: 60px;
  min-height: 100vh;
  transition: width 2s;
  background: #ffffff;
}
.toc__sidebar_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 15px;
}
.toc__sidebar_icon {
  color: grey;
  margin-right: 5px;
}
.toc__sidelogo {
  width: 131px;
  height: 40px;
}
.toc__nav_icon {
  font-size: 25px;
  cursor: pointer;
}
.toc__nav_items_box {
  display: flex;
  flex-direction: column;
}

.toc__nav_items_active {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  background-color: white;
  cursor: pointer;
  padding: 0px 0px 0px 60px;
}
.toc__sidebar_heading {
  font-size: 12px;
  margin-bottom: 10px;
}

.toc__mitems_parent {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 2px 10px;
  text-decoration: none;
  color: grey;
}
.toc__mitems_parent:hover {
  background: #c9c7c717;
  border-radius: 7px;
}
.active__class {
  background: #c9c7c717;
  border-radius: 7px;
}
.toc__mitems_icon {
  height: 19px !important;
  margin-right: 5px;
}
.toc__mitems {
  font-size: 14px;
}
.toc__divider_line {
  background: #56545417;
  height: 2px;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
}

.toc__nav_items {
  width: 100%;
  height: 30px;
  background-color: white;
  cursor: pointer;
  color: black;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 20px;
  font-weight: 400;
  font-family: "Lato", sans-serif;
  padding: 0px 15px;
}
.active_icon {
  color: white !important;
}
@media (max-width: 1200px) {
  .toc__sidebar_wrapper {
    display: none;
  }
}
