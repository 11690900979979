.warehouse__details_modal {
  width: 1200px;
  padding: 30px;
  position: relative;
}
.warehouse__details_heading {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid lightgrey;
  display: flex;
  color: rgb(189, 188, 188);
}
.warehouse__details_heading > h2 {
  padding-left: 5px;
}
.warehouse__details_modalrow {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.warehouse__details_modalrowitem {
  display: grid;
  grid-template-columns: 200px 1.2fr;
  min-width: 50%;
}
.warehouse__details_modalcol {
  padding: 0 15px;
}
.warehouse__details_modalcolbold {
  font-weight: bold;
}
.warehouse__details_modalclose {
  position: absolute;
  top: 0px;
  right: 0px;
}

.warehouse__details_comboxheading {
  color: rgb(189, 188, 188);
  margin-top: 20px;
  border-bottom: 0.5px solid rgb(231, 230, 230);
}

@media (max-width: 768px) {
  .warehouse__details_modal_wrap {
    max-width: 95%;
    padding: 15px 0 !important;
  }
  .warehouse__details_modal_wrap .warehouse__details_modalrowitem {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
  }

  .warehouse__details_modal_wrap .warehouse__details_modalcolbold {
    width: 100%;
  }

  .warehouse__details_modal_wrap .warehouse__details_modalcol {
    width: 100%;
    padding: 0;
  }

  .warehouse__details_modal_wrap .warehouse__details_modalrow {
    align-items: flex-start;
  }

  .warehouse__details_modal_wrap .warehouse__details_companiesbox {
    width: 100%;
  }
}
