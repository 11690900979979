.driver__table_size2 .dispscreen__table_header {
  width: 100%;
  display: flex;
  align-items: center;
  height: 45px;
}
.driver__table_size2 .dispscreen__table_innerrow {
  width: 100%;
  display: flex;
  align-items: center;
  height: 45px;
}
.driver__table_size2 .dispscreen__theader_heading {
  padding: 5px;
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  height: 100%;
  font-size: 15px;
  color: black;
  position: relative;
  font-weight: 500;
}
.driver__table_size2 .dispscreen__theader_data {
  padding: 5px;
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  height: 100%;
  font-size: 15px;
  color: #454545;
  position: relative;
}
.driver__table_size2 .dispscreen__theader_hdrop {
  width: 20px;
  cursor: pointer;
  transform: rotate(0deg);
  transition: transform 0.5s ease-in-out;
}
.driver__table_size2 .dispscreen__theader_hdropreverse {
  transform: rotate(-180deg);
}
.driver__table_size2 .dispscreen__theader_hdrophide {
  visibility: hidden;
  pointer-events: none;
}
.driver__table_size2 .dispscreen__theader_hcheck {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.driver__table_size2 .dispscreen__table_row {
  transition: 0.5s;
}
.driver__table_size2 .dispscreen__row_details {
  width: fit-content;
  min-width: 100%;
  height: 800px;
  overflow-y: hidden;
  max-height: 0px;
  transition: max-height 0.5s ease-in-out;
  box-shadow: 0px 0px 5px 0px rgba(179, 173, 173, 0.76) inset;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(179, 173, 173, 0.76) inset;
  -moz-box-shadow: 0px 0px 5px 0px rgba(179, 173, 173, 0.76) inset;
}
.driver__table_size2 .dispscreen__row_detailsactive {
  max-height: 800px;
}
.driver__table_size2 .dispscreen__theader_driverprofile {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.driver__table_size2 .dispscreen__theader_flagwrap {
  width: 30px;
  height: 18px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 1px 1px rgb(216, 216, 216);
}
.driver__table_size2 .dispscreen__thdata_pkgstatus {
  width: 25px;
  height: 25px;
  background: #e88c20;
  font-weight: 500;
  font-size: 10.24022px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
}
.driver__table_size2 .dispscreen__thdata_pnotes {
  font-size: 12px;
}
.driver__table_size2 .dispscreen__thdata_badges {
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
}
.driver__table_size2 .dispscreen__thdata_badges {
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
}
.driver__table_size2 .dispscreen__thdata_badges .swiper-slide {
  width: fit-content !important;
}
.driver__table_size2 .dispscreen__thdata_badge {
  height: 18.81px;
  width: fit-content;
  padding: 1.0652px 5.7558px;
  background: #e8fce5;
  border: 0.205564px solid #20cd04;
  border-radius: 0.822257px;
  display: flex;
  font-weight: 500;
  font-size: 11px;
  align-items: center;
  justify-content: center;
  margin: 3px 2px 3px 0;
}

.driver__table_size2 .dispscreen__thdata_badgeicon {
  margin-right: 4px;
}
.driver__table_size2 .dispscreen__thdata_badgeicon img {
  width: 11px;
}
.driver__table_size2 .dispscreenrd__additionservices_badge {
  height: 25px;
  width: fit-content;
  padding: 0 10px;
  background: #e8fce5;
  border: 0.5px solid #20cd04;
  border-radius: 1px;
  display: flex;
  font-weight: 500;
  font-size: 13px;
  align-items: center;
  margin-right: 10px;
}
.driver__table_size2 .dispscreen__additionservices_badgeicon {
  margin-right: 5px;
}
.driver__table_size2 .dispscreen__additionservices_badgeicon img {
  width: 13px;
}
.driver__table_size2 .dispscreenthd__paystatus_badge {
  width: fit-content;
  padding: 4px 6px;
  font-size: 11px;
  display: flex;
  align-items: center;
  border: 1px solid grey;
  border-radius: 2px;
  color: #000000;
  font-weight: 500;
  min-width: 70px;
  text-transform: capitalize;
}
.driver__table_size2 .dispscreenthd__paystatus_badgeicon > img {
  width: 12px;
  margin-right: 5px;
}
.driver__table_size2 .dispscreenthd__shipment_status {
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 2px;
  text-transform: capitalize;
  border: 1px solid grey;
  min-width: 75px;
  color: black;
}
.driver__table_size2 .dispscreenthd__priority_badge {
  display: flex;
  align-items: center;
  font-size: 13px;
  text-transform: capitalize;
}
.driver__table_size2 .dispscreenthd__priority_badgeicon {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
}
.driver__table_size2 .dispscreenthd__priority_badgetext {
  height: fit-content;
  position: relative;
  top: -1px;
  font-weight: 500;
}
.driver__table_size2 .dispscreen__thdata_actions {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.driver__table_size2 .dispscreen__thdata_action {
  width: 24px;
  height: 24px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dedede;
  cursor: pointer;
}
.driver__table_size2 .dispscreen__thdata_action > img {
  width: 13px;
}
.driver__table_size2 .dispscreen__thdata_dropwrap * {
  border: none !important;
  outline: none !important;
}
.driver__table_size2 .dispscreen__thdata_info {
  margin-left: 5px;
  width: 8px;
}
.driver__table_size2 .dispscreenthd__offerstatus_badge {
  width: fit-content;
  padding: 4px 6px;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid grey;
  border-radius: 2px;
  color: #000000;
  font-weight: 500;
  min-width: 80px;
  text-transform: capitalize;
}
.driver__table_size2 .dispscreenthd__offerstatus_badgeicon > img {
  width: 12px;
  margin-right: 5px;
}
.driver__table_size2 .dispscreen__table_placeholder {
  width: 100%;
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.driver__table_size2 .dispscreen__table_emptimg {
  height: 50%;
  max-width: 80%;
  object-fit: contain;
}
.driver__table_size2 .dispscreen__table_empttext {
  font-size: 3vw;
  font-weight: 500;
  color: #0c68f354;
}
.driver__table_size2 .dispscreen__rdetails_tableswrap {
  padding: 30px 25px;
}
.driver__table_size2 .dispscreen__rdetails_tablesrow {
  width: 100%;
  display: flex;
}
.driver__table_size2 .dispscreen__rdetails_tablescol {
  margin-right: 25px;
}
.driver__table_size2 .dispscreen__rdetails_tablescolheading {
  font-weight: bold;
  color: #454545;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 17px;
}
.driver__table_size2 .dispscreen__rdetails_verticletable {
  margin-bottom: 15px;
  border-bottom: 0.5px solid lightgray;
  padding-bottom: 10px;
}
.driver__table_size2 .dispscreen__rdverttable_row {
  display: flex;
  width: fit-content;
  text-transform: uppercase;
  font-size: 12px;
}
.driver__table_size2 .dispscreen__rdverttable_heading {
  width: 170px;
  min-height: 35px;
  max-height: fit-content;
  display: flex;
  padding: 10px 10px 0 10px;
  /* border: 1px solid #d9d9d9; */
  overflow-wrap: anywhere;
  font-weight: bold;
}
.driver__table_size2 .dispscreen__rdverttable_data {
  width: 250px;
  min-height: 35px;
  max-height: fit-content;
  display: flex;
  padding: 10px 10px 0 10px;
  overflow-wrap: anywhere;
}
.driver__table_size2 .dispscreen__rdverttable_smhcol {
  width: 130px;
}
.driver__table_size2 .dispscreen__rdverttable_smcol {
  width: 106px;
}
.driver__table_size2 .dispscreenrdvt__shipment_status {
  padding: 2px 10px;
  height: fit-content;
  font-weight: 500;
  text-transform: capitalize;
  border: 1px solid grey;
  font-size: 9px;
}
.driver__table_size2 .dispscreenthd__rvtdata_badge {
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 2px 10px;
  font-size: 9px;
  font-weight: 500;
  border: 0.5px solid grey;
  text-transform: capitalize;
}
.driver__table_size2 .dispscreenthd__rvtdata_badgeicon > img {
  margin-right: 3px;
  width: 9px;
}
.driver__table_size2 .dispscreen__rdetails_additionserviceslist {
  padding-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  max-width: 500px;
  /* border-bottom: 0.5px solid lightgray; */
}
.driver__table_size2 .dispscreen__rdetails_horizontaltable {
  max-height: 250px;
  overflow: auto;
}
.driver__table_size2 .dispscreen__rdhtable_header {
  display: flex;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
}
.driver__table_size2 .dispscreen__rdhtable_row {
  display: flex;
  text-transform: uppercase;
  font-size: 14px;
}
.driver__table_size2 .dispscreen__rdhtable_col {
  height: 35px;
  display: flex;
  align-items: center;
}
.driver__table_size2 .dispscreen__rdhtable_smptstatus {
  height: fit-content;
  padding: 2px 10px;
  border: 1px solid grey;
  border-radius: 1px;
  font-size: 9px;
  text-transform: capitalize;
  font-weight: 500;
}
.driver__table_size2 .pagination__wrap {
  position: sticky;
  background-color: white;
  top: 100%;
  right: 0;
  left: 0;
  /* bottom: 1px !important; */
  width: 100%;
  height: fit-content;
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  padding: 5px;
}
