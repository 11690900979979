.add__package_modal {
  min-height: 250px;
  min-width: 310px;
  width: 550px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
}
.add__package_modalheading {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid lightgray;
  font-weight: 500;
  font-size: 18px;
}
.add__package_modalcontent {
  padding: 20px 0 10px 0;
}
.add__package_modalrow {
  display: flex;
}
.add__package_modalinputwrap {
  flex: 1;
  margin: 0 5px 10px 5px;
  height: fit-content;
}
.add__package_modalinput {
  width: 100%;
  border: 0.5px solid lightgrey;
  outline: none;
  border-radius: 5px;
  height: 40px !important;
  padding: 0 10px;
  transition: 0.3s;
}
.add__package_modallabel {
  width: 100px;
  height: 40px;
  margin: 0 5px 10px 5px;
  font-size: 17px;
  display: flex;
  padding: 2px;
  align-items: center;
}
.add__package_modalinput:focus {
  outline: 0.5px solid #0c68f373;
}
.add__package_modallabel2 {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
}
.add__package_modalfooter {
  width: 100%;
  padding: 10px;
  height: 60px;
  border-top: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.add__package_modalcncl {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #454545;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}
.add__package_modalsave {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c69f3;
  border: 1px solid #0c69f3;
  border-radius: 3px;
  margin-left: 10px;
  color: white;
  cursor: pointer;
}
.add__package_profimagelbl {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 0px 3px 3px rgba(219, 215, 215, 0.24);
  -webkit-box-shadow: 0px 0px 3px 3px rgba(219, 215, 215, 0.24);
  -moz-box-shadow: 0px 0px 3px 3px rgba(219, 215, 215, 0.24); */
  cursor: pointer;
  color: #454545;
  font-weight: 500;
  outline: 2px dashed lightgrey;
  margin-top: 5px;
}
.add__package_profimagelbl > * {
  margin-right: 5px;
}
.add__package_profimageinput {
  display: none;
}
.package__name_fullfilment_cost_row span.tile__value_fullfilment_cost {
  min-width: fit-content;
}

label.tile__label_fullfilment_cost {
  padding-right: 10px;
}

/*package list styling*/
.package__list_heading_row {
  display: flex;
  justify-content: space-between;
  column-gap: 15px;
  justify-content: flex-start;
}
.package__list_item_container {
  display: flex;
  justify-content: space-between;
}
.package__list_item {
  width: fit-content;
  min-width: 30px;
}
.package__list_heading_col h6 {
  font-size: 11px;
  margin: 0;
}
.package__list_item *,
.package__list_item {
  font-size: 11px;
}
.package__list_item.package__list_action__btn_col a svg {
  padding: 2px !important;
  width: 100%;
  height: auto;
}
.package__list_item.package__list_action__btn_col {
  display: inline-flex;
  min-width: 70px;
  align-items: center;
}
.package__list_item.package__list_action__btn_col a {
  width: 20px;
  height: 20px;
}
.package__list_heading_col.package__list_heading_my_package {
  width: 250px;
  text-align: center;
}
.package__list_item.package__list_length {
  width: 80px;
}
.package__list_item.package__list_width {
  width: 80px;
}
.package__list_item.package__list_height {
  width: 80px;
}
.package__list_item.package__list_weight {
  width: 90px;
}
.package__list_item.package__list_description {
  width: 200px;
}
.package__list_item.package__list_pieces {
  width: 80px;
}
.package__list_item.package__list_nmfc {
  width: 70px;
}
.package__list_item.package__list_declaration {
  width: 120px;
}
.package__list_item.package__list_package_name {
  width: 200px;
}
.package__list_box .package__list_item_container {
  margin-top: 15px;
  column-gap: 15px;
  justify-content: flex-start;
  padding-bottom: 10px;
}

.package__dimension_unit.package__unit_btn_wrap button {
  background: transparent;
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0 !important;
  color: #ccc;
  font-size: 10px;
}

.package__dimension_unit.package__unit_btn_wrap
  button.MuiButton-containedPrimary {
  color: #1976d2;
  border-bottom-color: #1976d2 !important;
}
.package__list_heading_col.package__list_heading_size {
  width: 250px;
  text-align: center;
}
.package__list_heading_col.package__list_heading_weight {
  width: 90px;
  text-align: center;
}
.package__list_heading_col.package__list_heading_description {
  width: 200px;
  text-align: center;
}
.package__list_heading_col.package__list_heading_peices {
  width: 80px;
  text-align: center;
}
.package__list_heading_col.package__list_heading_nmfc {
  width: 70px;
  text-align: center;
}
.package__list_heading_col.package__list_heading_declaration {
  width: 120px;
  text-align: center;
}
.package__list_heading_col.package__list_action {
  width: 70px;
  text-align: center;
}
.package__list_heading_col.package__list_heading_insurance {
  width: 130px;
  text-align: center;
}
.package__list_item.package__list_insurance {
  width: 130px;
  text-align: center;
}

.package__list_conatiner_box {
  min-width: 1130px;
}
.package__list_conatiner_box .package__list_heading_col.package__list_action {
  position: sticky;
  right: -1px;
  background: #fff;
}
.package__list_conatiner_box .package__list_item.package__list_action__btn_col {
  position: sticky;
  right: -1px;
  background: #fff;
  padding: 12px 0;
  margin-top: -10px;
  z-index: 1;
}
/* dimension styling */
.package__name_list_names_header {
  display: flex;
  justify-content: space-between;
}

.package__name_list_box_content_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f7f7f7;
}

.package__name_list_box_content_wrap .MuiFormControl-root * {
  font-size: 11px;
}

.package__name_list_box_content_wrap
  a.package__name_list_box_delete_prod
  .overlaybtn {
  padding: 3px !important;
  width: 22px;
  height: 22px;
}

.package__name_list_box_content {
  align-items: center;
  display: inline-flex;
}

.package__name_list_box_content_wrap a.package__name_list_qty_btn .overlaybtn {
  padding: 0 !important;
  width: 15px;
  height: 15px;
}
.package__name_list_heading.package__name_head_product {
  width: 100px;
  text-align: center;
}

.package__name_list_heading.package__name_head_name {
  width: 90px;
  padding: 0 5px;
  text-align: center;
}

.package__name_list_heading.package__name_head_sku {
  width: 90px;
  text-align: center;
}

.package__name_list_heading.package__name_head_size {
  width: 110px;
  padding: 0 5px;
  text-align: center;
}

.package__name_list_heading.package__name_head_weight {
  width: 70px;
  text-align: center;
}

.package__name_list_heading.package__name_head_quantity {
  width: 70px;
  text-align: center;
}

.package__name_list_heading.package__name_head_stock {
  width: 70px;
  text-align: center;
}

.package__name_list_heading.package__name_head_notes {
  width: 170px;
  text-align: center;
}

.package__name_list_heading.package__name_head_action {
  width: 40px;
}

.package__name_list_box_content.package__name_content_index {
  width: 30px;
}

.package__name_list_box_content.package__name_content_image {
  width: 50px;
}

.package__name_list_box_content.package__name_content_fullname {
  width: 100px;
  text-align: center;
  justify-content: center;
  padding: 0 5px;
}

.package__name_list_box_content.package__name_content_code {
  width: 80px;
  padding: 0 5px;
  justify-content: center;
}

.package__name_list_box_content.package__name_content_dimension {
  width: 110px;
  justify-content: center;
}

.package__name_list_box_content.package__name_content_weight {
  width: 70px;
  text-align: center;
  justify-content: center;
}

.package__name_list_box_content.package__name_content_qty {
  width: 60px;
  justify-content: center;
}

.package__name_list_box_content.package__name_content_stock {
  width: 70px;
  justify-content: center;
}

.package__name_list_box_content.package__name_content_notes {
  width: 170px;
}

.package__name_list_box_content.package__name_content_delete {
  width: 40px;
  justify-content: center;
}
.package__name_list_box_content_wrap:last-child {
  border: none;
}
@media screen and (max-width: 1260px) {
  .package__list_conatiner_box {
    padding-right: 0;
  }
  .card-body.dimension_weight_card_body {
    padding-right: 0;
  }
}
@media only screen and (min-width: 1170px) {
  .package__list_heading_col h6,
  .package__list_item *,
  .package__list_item {
    font-size: 0.805vw !important;
  }

  .package__list_heading_col.package__list_heading_my_package {
    width: 250px;
    text-align: center;
  }
  .package__list_item.package__list_height {
    width: 5.882vw;
  }
  .package__list_item.package__list_weight {
    width: 6.618vw;
  }
  .package__list_item.package__list_description {
    width: 14.706vw;
  }
  .package__list_item.package__list_pieces {
    width: 5.882vw;
  }
  .package__list_item.package__list_nmfc {
    width: 5.147vw;
  }
  .package__list_item.package__list_declaration {
    width: 9.559vw;
  }
  .package__list_item.package__list_package_name {
    width: 14.641vw;
  }
  .package__list_box .package__list_item_container {
    margin-top: 15px;
    column-gap: 15px;
    justify-content: flex-start;
  }
  .package__list_heading_col.package__list_heading_size {
    width: 250px;
    text-align: center;
  }
  .package__list_heading_col.package__list_heading_weight {
    width: 90px;
    text-align: center;
  }
  .package__list_heading_col.package__list_heading_description {
    width: 200px;
    text-align: center;
  }
  .package__list_heading_col.package__list_heading_peices {
    width: 80px;
    text-align: center;
  }
  .package__list_heading_col.package__list_heading_nmfc {
    width: 70px;
    text-align: center;
  }
  .package__list_heading_col.package__list_heading_declaration {
    width: 120px;
    text-align: center;
  }
  .package__list_heading_col.package__list_action {
    width: 70px;
    text-align: center;
  }
  .package__list_heading_col.package__list_heading_insurance {
    width: 130px;
    text-align: center;
  }
  .package__list_item.package__list_insurance {
    width: 9.559vw;
    text-align: center;
  }
  .package__list_item.package__list_length {
    width: 5.882vw;
  }
  .package__list_item.package__list_width {
    width: 5.882vw;
  }
  .package__list_item.package__list_index {
    min-width: 2.206vw;
  }
  .package__list_item.package__list_action__btn_col a {
    width: 1.8vw;
    height: 1.8vw;
    padding: 0.147vw;
    margin: 0;
  }
  .package__list_heading_col.package__list_heading_my_package {
    width: 18.382vw;
  }
  .package__list_heading_col.package__list_heading_size {
    width: 18.382vw;
  }
  .package__list_heading_col.package__list_heading_weight {
    width: 6.618vw;
  }
  .package__list_heading_col.package__list_heading_description {
    width: 14.706vw;
  }
  .package__list_heading_col.package__list_heading_peices {
    width: 5.882vw;
  }
  .package__list_heading_col.package__list_heading_nmfc {
    width: 5.147vw;
  }
  .package__list_heading_col.package__list_heading_insurance {
    width: 9.559vw;
  }
  .package__list_heading_col.package__list_heading_declaration {
    width: 8.824vw;
  }
  .package__list_heading_col.package__list_action {
    width: 5.147vw;
  }
  .package__name_list_heading.package__name_head_product {
    width: 7.353vw;
  }

  .package__name_list_heading.package__name_head_name {
    width: 6.618vw;
  }

  .package__name_list_heading.package__name_head_sku {
    width: 6.618vw;
  }

  .package__name_list_heading.package__name_head_size {
    width: 8.088vw;
  }

  .package__name_list_heading.package__name_head_weight {
    width: 5.147vw;
  }

  .package__name_list_heading.package__name_head_quantity {
    width: 5.147vw;
  }

  .package__name_list_heading.package__name_head_stock {
    width: 5.147vw;
  }

  .package__name_list_heading.package__name_head_notes {
    width: 12.5vw;
  }

  .package__name_list_heading.package__name_head_action {
    width: 2.941vw;
  }

  .package__name_list_box_content.package__name_content_index {
    width: 2.206vw;
  }

  .package__name_list_box_content.package__name_content_image {
    width: 3.676vw;
  }

  .package__name_list_box_content.package__name_content_fullname {
    width: 7.353vw;
  }

  .package__name_list_box_content.package__name_content_code {
    width: 5.882vw;
  }

  .package__name_list_box_content.package__name_content_dimension {
    width: 8.088vw;
  }

  .package__name_list_box_content.package__name_content_weight {
    width: 5.147vw;
  }

  .package__name_list_box_content.package__name_content_qty {
    width: 4.412vw;
  }

  .package__name_list_box_content.package__name_content_stock {
    width: 5.147vw;
  }

  .package__name_list_box_content.package__name_content_notes {
    width: 12.5vw;
  }

  .package__name_list_box_content.package__name_content_delete {
    width: 2.941vw;
  }

  .package__name_list_box_content_wrap .MuiFormControl-root * {
    font-size: 0.809vw;
    /* padding: 0.388vw 0.29vw; */
  }

  .package__name_list_box_content_wrap
    a.package__name_list_box_delete_prod
    .overlaybtn {
    width: 1.618vw;
    height: 1.618vw;
    padding: 0.221vw !important;
  }
  .package__name_list_box_content.package__name_content_image img {
    max-width: 1.83vw;
  }
}

@media screen and (max-width: 600px) {
  .card-body.dimension_weight_card_body {
    padding-right: 16px;
  }
  .package__list_conatiner_box {
    padding-right: 0;
    min-width: 100%;
    overflow: auto;
  }
  .package__list_heading_row {
    min-width: fit-content;
  }

  .package__list_box {
    min-width: fit-content;
  }
  .package__name_list_wrap {
    width: 100%;
    overflow: auto;
    order: 2;
    margin-top: 35px !important;
  }

  .package__name_list_names_header {
    min-width: 880px;
  }

  .package__name_list_box_content_wrap {
    min-width: 880px;
  }

  .package__name_list_wrap.package__name_list_wrap_fullfilment_cost.col-md-3 {
    order: 1;
    margin: 0 !important;
  }
  .package__name_list_heading.package__name_head_action {
    position: sticky;
    right: 0;
    background: #fff;
    padding: 0px 20px;
    margin-top: 0;
    width: 80px;
    display: inline-flex;
    align-items: center;
  }

  .package__name_list_box_content.package__name_content_delete {
    position: sticky;
    right: 0;
    background: #fff;
    padding: 20px 0;
    width: 80px;
    z-index: 2;
  }

  .package__name_list_wrap {
    margin-top: 20px !important;
    padding-right: 0;
  }
}

@media screen and (max-width: 1100px) {
  .package__list_conatiner_box {
    padding-right: 0;
    min-width: 100%;
    overflow: auto;
  }
  .package__list_heading_row,
  .package__list_box {
    min-width: -moz-fit-content;
    min-width: fit-content;
  }
}
