.warehouses__products_wrapper {
  margin: auto;
  max-width: 100%;
  padding: 10px 20px;
}
.warehouses__products_mainimg {
  border-radius: 10px;
  height: 50px;
  object-fit: contain;
  width: 50px;
}

/* new css  */
.warehouses__products_weight__button_wrapper {
  display: flex;
  gap: 5px;
  width: 135px;
}
.warehouses__products_editble_input {
  outline-color: #d3d3d3;
  padding: 4px;
  width: 100%;
}

.warehouses__products_weight_unitbtn_true {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 26px;
  box-shadow: 0.3px 0.3px 3px 1px rgb(230, 228, 228);
  transition: 0.3s;
  user-select: none;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 500;
  background-color: #1876d2;
  color: white;
}

.warehouses__products_weight_unitbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 26px;
  box-shadow: 0.3px 0.3px 3px 1px rgb(230, 228, 228);
  transition: 0.3s;
  user-select: none;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 500;
}
