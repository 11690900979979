.package__dimension_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.package__dimension_unit {
  display: flex;
  justify-content: flex-start;
  column-gap: 17px;
  align-items: center;
  width: 25%;
}
.package__dimension_weight_volume {
  display: flex;
  justify-content: space-around;
  column-gap: 17px;
  align-items: center;
  width: fit-content;
}
.package__dimension_weight_volume small {
  display: inline-flex;
  align-items: center;
  color: #808080;
}
.package__dimension_wrap span {
  font-weight: 500;
}

.package__dimension_title {
  width: 25%;
}
.package__dimension_btn_col {
  width: 25%;
  text-align: right;
}
.number__listing {
  padding: 0 !important;
}
.package__dimension_wrap.dimension_weight_card_wrap
  .package__dimension_title
  span {
  font-size: 12px;
}

.package__dimension_wrap.dimension_weight_card_wrap
  .package__dimension_title
  button {
  padding: 0;
  width: 13px;
  height: 13px;
}
.package__dimension_wrap.dimension_weight_card_wrap
  .package__dimension_unit
  span {
  font-size: 12px;
}

.package__dimension_wrap.dimension_weight_card_wrap
  .package__dimension_unit
  button {
  font-size: 10px;
}
.package__dimension_wrap.dimension_weight_card_wrap
  .package__dimension_weight_volume
  .package__dimension_weight {
  font-size: 12px;
}

.package__dimension_wrap.dimension_weight_card_wrap
  .package__dimension_weight_volume
  .package__dimension_volume {
  font-size: 12px;
}
.dimension_weight_card_body .package__name_list_names h6 {
  font-size: 11px;
}
.package__dimension_wrap.dimension_weight_card_wrap
  .package__dimension_btn_col
  button {
  font-size: 11px;
}
.dimension_weight_card_add_new_row {
  font-size: 11px;
}
.package__listing_field.pack__list_font_size *,
.package__name_list_wrap * {
  font-size: 12px;
}
.package__listing_field.pack__list_font_size label,
.package__name_list_wrap label {
  font-size: 13px;
  color: #808080;
}

.package__type_package_main_btn_wrap {
  column-gap: 0;
}

.package__type_package_main_btn_wrap button {
  margin-bottom: 0 !important;
}

.package__type_package_main_btn_wrap > span {
  display: block;
  width: 100%;
  max-width: 190px;
}

.package__type_package_main_btn_wrap .serviceBtnActive {
  border: none !important;
  margin-bottom: 0 !important;
  padding: 0;
  width: 100%;
  justify-content: flex-start;
  background: none !important;
  color: #303030;
}
.package__type_package_main_btn_wrap .serviceBtnNotsActive {
  border: none !important;
  margin-bottom: 0 !important;
  padding: 0;
  width: 100%;
  justify-content: flex-start;
  background: none !important;
  color: #303030;
}
.package__type_package_main_btn_wrap .serviceBtnActive:before {
  content: "✔";
  width: 13px;
  height: 13px;
  display: inline-block;
  border: 1px solid #303030;
  margin-right: 5px;
  background: #303030;
  border-radius: 2px;
  color: #fff;
  line-height: 1.5;
  font-size: 8px;
}
.package__type_package_main_btn_wrap .serviceBtnNotsActive:before {
  content: "";
  width: 13px;
  height: 13px;
  display: inline-block;
  border: 1px solid #000;
  margin-right: 5px;
  border-radius: 2px;
}

.package__type_package_main_btn_wrap button span {
  display: none !important;
  width: 100%;
}

.package__type_package_main_btn_wrap .serviceBtnActive[disabled]:before {
  background: #ccc;
  border-color: #ccc;
}
.package__type_package_main_btn_wrap .serviceBtnActive[disabled] {
  color: #ccc;
}

.package__type_package_main_btn_wrap .serviceBtnNotsActive[disabled]:before {
  border-color: #ccc;
}

.package__type_package_main_btn.package__block_active button {
  background: transparent;
  color: #212529;
  border-radius: 0 !important;
  font-weight: 600;
  font-size: 0.805vw;
  letter-spacing: 0;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
}
.package__type_pallet .package__type_package_main_btn button {
  color: #212529 !important;
  letter-spacing: 0;
}
.package__type_package_main_btn.package__block_not_active button {
  border: none;
  background: transparent;
  color: #000;
  border-radius: 0 !important;
  font-weight: 400;
  color: #212529;
  border-radius: 0 !important;
  font-weight: 600;
  font-size: 0.805vw;
  letter-spacing: 0;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
}
.insurance_check_body_container {
  display: flex;
  column-gap: 15px;
  padding: 10px 0 0 0;
}
.card-body.dimension_weight_card_body {
  padding: 0;
}
.package__type_package_main_btn button {
  position: relative;
  padding-left: 25px;
}
.package__type_package_main_btn button:after {
  content: "";
  display: inline-block;
  border: 1px solid #303030;
  margin-right: 15px;
  border-radius: 2px;
  width: 1.16vw;
  height: 1.16vw;
  font-size: 0.78vw;
  position: absolute;
  left: 0;
  min-width: 15px;
  min-height: 15px;
}
.package__type_package_main_btn_wrap .insurance_check_body_container {
  align-items: center;
  width: 100%;
  justify-content: center;
}
.package__type_package_main_btn_wrap
  .insurance_check_body_container
  .insurance_check_body_btn_wrap {
  margin-bottom: 3px;
}
.package__type_package_main_btn button:before {
  content: "ship a ";
  padding-right: 5px;
}
.package__type_package_main_btn.package__block_active button::after {
  content: "✔";
  background: #303030;
  color: #fff;
  line-height: 1.5;
  position: absolute;
  left: 0;
  font-weight: 100;
}

.service__card_box_ship_page.main__service_card_wrap {
  display: flex;
  column-gap: 24px;
  padding: 20px 0 5px 0;
  width: 90%;
  margin: 0 auto;
  flex-wrap: wrap;
}
.service__card_box_ship_page.main__service_card_wrap
  .card.bg-white.shadow.wapper_like_shopify {
  width: calc(50% - 12px);
}
.package__dimension_unit.package__unit_btn_wrap > span {
  font-size: 10px;
}

@media only screen and (max-width: 992px) {
  .package__dimension_title {
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
  }

  .package__dimension_unit {
    width: 100%;
  }

  .package__dimension_weight_volume {
    width: 100%;
    margin-bottom: 5px;
    border-right: none;
    border-left: none;
    padding: 10px 0 0 0;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  .package__dimension_btn_col {
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
  }

  .package__dimension_title button {
    padding-right: 0;
  }
  .mt-1.package__name_list_names.row {
    display: none;
  }
  .package__listing_field {
    margin-bottom: 2px;
    width: 100% !important;
  }
  .package__listing_field .number__listing {
    font-size: 30px;
    text-align-last: center !important;
    width: 100%;
  }
  .package__type_package_main_btn_wrap {
    justify-content: flex-start;
  }
  .package__type_package_main_btn_wrap > span {
    max-width: fit-content;
    min-width: 130px;
  }

  .package__type_pallet {
    margin-top: 0;
  }
  .service__card_box_ship_page.main__service_card_wrap {
    row-gap: 15px;
  }
  .package__type_package_main_btn button:after {
    font-size: 12px;
    top: 4px;
  }
  .package__type_package_main_btn.package__block_active button::after {
    line-height: 1.17;
  }
  .service__card_box_ship_page.main__service_card_wrap {
    width: calc(91.66666667% - 24px);
  }
}

@media only screen and (max-width: 767px) {
  .service__card_box_ship_page.main__service_card_wrap {
    flex-wrap: wrap;
    width: calc(100% - 24px);
    padding: 0;
  }

  .service__card_box_ship_page.main__service_card_wrap
    .card.bg-white.shadow.wapper_like_shopify {
    width: 100%;
  }
  .package__dimension_unit.package__unit_btn_wrap {
    width: fit-content;
  }

  .package__dimension_weight_volume {
    width: fit-content;
  }

  .package__dimension_wrap.dimension_weight_card_wrap {
    justify-content: center;
    column-gap: 15px;
  }

  .package__name_list_wrap.package__name_list_wrap_fullfilment_cost.col-md-3 {
    margin-bottom: 25px !important;
    padding: 0 10px !important;
    width: 100% !important;
  }

  .package__name_list_wrap.package__name_list_wrap_fullfilment_cost.col-md-3
    .row {
    margin: 0;
  }
  .editable__tabtbarlft_tab > span {
    margin-left: 5px;
    min-width: max-content;
  }
  .package__dimension_unit_bundle_btn {
    display: flex;
    align-items: center;
    column-gap: 15px;
    width: 100%;
    max-width: 450px;
    justify-content: center;
    flex-wrap: wrap;
  }
  .package__type_package_main_btn.package__block_active button,
  .package__type_package_main_btn.package__block_not_active button {
    font-size: 10px;
  }
  .productDetailsCustom__icons_tabs_btn {
    column-gap: 3px !important;
  }
  .package__dimension_unit.package__unit_btn_wrap {
    width: 100%;
    max-width: 450px;
  }
  .package__dimension_unit.package__unit_btn_wrap {
    justify-content: center;
    width: 100%;
  }
  .card-header.package__dimension_header_wrap {
    align-items: flex-start;
  }
}

@media only screen and (min-width: 993px) and (max-width: 1300px) {
  .package__name_list_names * {
    font-size: 12px;
  }

  .number__listing {
    padding: 0 !important;
  }

  .package__dimension_unit {
    width: 100%;
    max-width: 220px;
  }

  .package__dimension_wrap * {
    font-size: 13px;
  }
  .package__listing_field input {
    font-size: 12px !important;
    padding-left: 14px;
  }
  .package__dimension_btn_col {
    width: 100%;
    max-width: 145px;
  }

  .package__dimension_title {
    width: 100%;
    max-width: fit-content;
  }

  .package__dimension_weight_volume {
    width: 100%;
    max-width: fit-content;
  }
  .package__dimension_btn_col {
    width: 100%;
    max-width: 145px;
  }

  .package__dimension_title {
    width: 100%;
    max-width: fit-content;
  }

  .package__dimension_weight_volume {
    width: 100%;
    max-width: fit-content;
  }

  .package__listing_field {
    padding: 0 2px !important;
  }
  .package__listing_field.number_wrap {
    padding: 0 12px 0 7px !important;
  }
}

@media only screen and (min-width: 1190px) {
  .package__dimension_wrap.dimension_weight_card_wrap
    .package__dimension_title
    span {
    font-size: 0.878vw;
  }
  .package__dimension_wrap.dimension_weight_card_wrap
    .package__dimension_title
    button,
  .package__dimension_wrap.dimension_weight_card_wrap
    .package__dimension_title
    button
    svg {
    width: 0.952vw;
    height: 0.952vw;
  }
  .package__dimension_wrap.dimension_weight_card_wrap
    .package__dimension_unit
    span {
    font-size: 0.878vw;
  }
  .package__dimension_wrap.dimension_weight_card_wrap
    .package__dimension_unit
    button {
    font-size: 0.732vw;
  }
  .package__dimension_wrap.dimension_weight_card_wrap
    .package__dimension_weight_volume
    .package__dimension_weight,
  .package__dimension_wrap.dimension_weight_card_wrap
    .package__dimension_weight_volume
    .package__dimension_volume {
    font-size: 0.878vw;
  }
  .dimension_weight_card_body .package__name_list_names h6 {
    font-size: 0.805vw;
  }
  .card-body.dimension_weight_card_body .dimension_weight_card_add_new_row {
    font-size: 0.952vw;
  }

  .card-body.dimension_weight_card_body .dimension_weight_card_add_new_row svg {
    font-size: 1.464vw;
  }
  .package__dimension_wrap.dimension_weight_card_wrap
    .package__dimension_btn_col
    button {
    font-size: 0.805vw;
  }
  .dimension_weight_card_body [name="Request A Quote"] {
    font-size: 0.805vw;
  }
  .package__listing_field.pack__list_font_size *,
  .package__name_list_wrap * {
    font-size: 0.705vw;
  }
  .package__listing_field.pack__list_font_size label,
  .package__name_list_wrap label {
    font-size: 0.752vw;
  }
  .dimension_weight_card_body
    .package__name_list_box_content.package__name_content_notes
    p {
    margin: 0;
    padding: 0;
    display: none;
  }
  .package__type_package_main_btn_wrap > span {
    max-width: 9vw;
  }
  .package__type_package_main_btn_wrap.css-1d0f7f9-MuiStack-root {
    justify-content: center;
  }
  .package__type_package_main_btn.package__block_active button,
  .package__type_package_main_btn.package__block_not_active button {
    font-size: 0.809vw;
    line-height: 1.5;
  }

  .package__type_package_main_btn_wrap .serviceBtnNotsActive:before,
  .package__type_package_main_btn_wrap .serviceBtnActive:before {
    width: 0.956vw;
    height: 0.956vw;
    font-size: 0.588vw;
  }

  .package__type_package_main_btn button {
    position: relative;
    padding-left: 1.83vw;
  }
  .card-header.package__dimension_header_wrap {
    display: flex;
    justify-content: space-between;
  }

  .package__dimension_unit.package__unit_btn_wrap {
    justify-content: flex-end;
  }
  .package__dimension_unit_bundle_btn {
    display: flex;
    align-items: center;
    column-gap: 15px;
    width: 33%;
    justify-content: flex-end;
  }

  .package__dimension_unit.package__unit_btn_wrap {
    width: 33%;
  }

  .card-header.package__dimension_header_wrap {
    align-items: flex-start;
  }
  .package__dimension_wrap.dimension_weight_card_wrap.fulfilment__after_brod_add {
    margin-top: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .package__dimension_weight_volume {
    max-width: fit-content;
  }

  .package__dimension_unit.package__unit_btn_wrap {
    max-width: fit-content;
  }
}
