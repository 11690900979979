.alltables__cols_modal {
  width: 244px;
  height: 305px;
  top: 30px;
  left: 0;
  box-shadow: 0px 2px 7px 0px #00000040;
  background: #ffffff;
  border-radius: 5px;
  padding: 16px 20px;
  cursor: default;
  z-index: 1000;
}
.alltables__colsmodal_autocompltfield {
  border: none;
  outline: none;
  border-bottom: 0.5px solid #9f9a9a;
  width: 100%;
  font-size: 14px;
  height: 30px;
  color: #9f9a9a;
  padding-bottom: 9px;
  font-weight: 500;
}
.alltables__colsmodal_autocomplt {
  width: 100%;
  position: relative;
}

.alltables__colsmodal_colslist {
  margin: 10px 0;
  overflow-y: auto;
  height: 70%;
}
.alltables__colsmodal_colslist::-webkit-scrollbar {
  width: 2px !important;
}

/* Handle */
.alltables__colsmodal_colslist::-webkit-scrollbar-thumb {
  background: #262727;
  border-radius: 2px;
}

.alltables__colsmodal_colslistitem {
  display: flex;
  min-height: 25px;
  margin-top: 5px;
  justify-content: space-between;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  align-items: center;
  font-weight: 400;
}
.alltables__colsmodal_colslistitemleft {
  display: flex;
  align-items: center;
}
.alltables__colsmodal_colslistitemcheck {
  margin-right: 8px;
  width: 12px;
  height: 12px;
  cursor: pointer;
  border: 0.5px solid red !important;
  color: red;
  background-color: red;
}
.alltables__colsmodal_colslistitemdrag {
  width: 15px;
  margin-right: 10px;
  /* cursor: grab; */
}
.alltables__colsmodal_footer {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  font-size: 14px;
}
.alltables__colsmodal_footer > * {
  cursor: pointer;
}
