/* blogs header  */
.blogs_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: grey;
  margin: 20px 5px 0px 5px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid lightgrey;
}
.blogs_hdrheading {
  font-weight: 400 !important;
  font-size: 22px;
}
.blogs__adpage_buttonsWrapper {
  display: flex;
  gap: 20px;
}
.blogs__adpage_hdraddprod {
  border-radius: 5px;
  box-shadow: 0.3px 0.3px 3px 1px rgb(230, 228, 228);
  display: flex;
  padding: 8px 18px;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
  color: grey;
  cursor: pointer;
}
.blogs__main_wrap {
  display: flex;
  align-items: flex-start;
  column-gap: 30px;
  flex-wrap: wrap;
}
.blogs__table_list {
  width: 100%;
}

.blogs_carrlogo {
  max-height: 50px;
  max-width: 95%;
  object-fit: contain;
}

.blogs_badge {
  width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: grey;
  border-radius: 25px;
  font-weight: 500;
  box-shadow: 2px 2px 5px 0px rgba(222, 217, 217, 0.74);
  -webkit-box-shadow: 2px 2px 5px 0px rgba(222, 217, 217, 0.74);
  -moz-box-shadow: 2px 2px 5px 0px rgba(222, 217, 217, 0.74);
  font-size: 14px;
}
.blogs_badgecount {
  position: absolute;
  left: 90%;
  bottom: 22px;
  padding: 2px 8px;
  color: white;
  background-color: #0c6efd;
  border-radius: 50%;
}
@media only screen and (max-width: 768px) {
  .blogs__table_list {
    order: 2;
  }
  .blogs_header {
    margin-bottom: 15px;
  }
}
