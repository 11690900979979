/* add Units  */

/* button.unitStock__add_new_unit_create_btn {
  border-radius: 5px;
  box-shadow: 0.3px 0.3px 3px 1px rgb(230, 228, 228);
  display: flex;
  padding: 8px 18px;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  color: grey !important;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-bottom: 15px;
  margin-top: 15px;
  column-gap: 5px;
  cursor: pointer !important;
} */

.unitStock__add_new_unit .unitStock__add_new_unit_title {
  font-size: 15px;
  margin-bottom: 0;
  color: #454545;
  font-weight: bold;
  text-transform: uppercase;
}

.unitStock__add_new_unit .unitStock__add_new_unit_code {
  width: 49%;
  margin-bottom: 20px;
}

.unitStock__add_new_unit .unitStock__add_new_unit_name {
  width: 49%;
  margin-bottom: 20px;
}

.unitStock__add_new_unit .unitStock__add_new_unit_create_btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
  color: #fff !important;
  background: #1976d2;
  padding: 8px 10px;
  column-gap: 6px;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;
  margin-top: 0;
  margin-left: auto;
}

.unitStock__add_new_unit_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 16px;
  background: #f7f7f7;
  border-bottom: 1px solid #d2d2d2;
  margin-bottom: 20px;
  border-radius: 5px 5px 0 0;
  width: 100%;
}

.unitStock__add_new_unit_body {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 2%;
}
.unitStock__add_new_unit_modal_wrap {
  min-height: 230px;
  min-width: 310px;
  width: 550px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.06);
  border: none;
  outline: none;
  padding: 20px;
}

.unitStock__add_new_unit_modalfooter {
  width: 100%;
  padding: 10px;
  height: 60px;
  border-top: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}

.unitStock__add_new_unit_modalsubmit {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c69f3;
  border: 1px solid #0c69f3;
  border-radius: 3px;
  margin-left: 10px;
  color: white;
  cursor: pointer;
  width: 70px;
  height: 35px;
  font-size: 13px;
}

.unitStock__add_new_unit_modalreset {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #454545;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  width: 70px;
  height: 35px;
  font-size: 13px;
}
@media only screen and (max-width: 768px) {
  .unitStock__add_new_unit {
    order: 1;
    margin-bottom: 30px;
  }
  .unitStock__table_list {
    order: 2;
  }

  .unitStock__add_new_unit_modal_wrap {
    width: 90%;
    top: calc(50% - 185px);
  }
}

/* close  */
