.warehouse_payment__formula_btn_wrapper {
  display: flex;
  justify-content: end;
  margin-bottom: 15px;
}
.warehouse_payment__formula_btn {
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: white;
  border-radius: 5px;
  background-color: #0c6efd;
  padding: 5px;
  padding-right: 10px;
  width: fit-content;
  position: relative;
  cursor: pointer;
}
.warehouse_payment__formula_btnicon {
  margin-right: 3px;
}
/* .payment__formula_switch_wrapper {
  display: flex;
  gap: 5px;
} */
/* .payment__formula_switch_btn {
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: black;
  border-radius: 3px;
  padding: 5px;
  padding-right: 10px;
  width: fit-content;
  position: relative;
  cursor: pointer;
  border: 1px solid #ced4da;
} */
/* .payment__formula_switch_btn_active {
  background-color: #0c6efd;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: white;
  border-radius: 3px;
  padding: 5px;
  padding-right: 10px;
  width: fit-content;
  position: relative;
  cursor: pointer;
} */
/* .prodidentifier__desc_modal {
  min-height: 250px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
} */
/* .prodidentifier__desc_modalheading {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid lightgray;
  font-weight: 500;
  font-size: 18px;
} */
/* .prodidentifier__desc_modalcontent {
  padding: 20px 0 10px 0;
} */
.warehouse_prodidentifier__desc_modalfooter {
  width: 100%;
  padding: 10px;
  height: 60px;
  border-top: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.warehouse_prodidentifier__desc_modalcncl {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #454545;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}

.warehouse_prodidentifier__desc_modalsave {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c69f3;
  border: 1px solid #0c69f3;
  border-radius: 3px;
  margin-left: 10px;
  color: white;
  cursor: pointer;
}
.warehouse_prodidentifier__desc_modalsave.prodidentifier__formula_details {
  height: 35px;
  margin: 0;
}

/* .content_wrapper {
  height: 60vh !important;
  overflow: auto !important;
} */

.warehouse_payment__formula_box_wrapper {
  display: flex;
  flex-wrap: wrap;
}
.warehouse_payment__formula_formbox1 {
  margin-bottom: 10px;
  padding-bottom: 10px;
  flex: 5;
}
.warehouse_payment__formula_formbox2 {
  margin-bottom: 10px;
  padding-bottom: 10px;
  flex: 5;
}
.warehouse_payment__formula_formboxheading {
  padding: 10px 0px;
  color: grey;
}
.warehouse_warehouse_payment__formula_formrow {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px 0px;
  align-items: center;
}

/* .payment__formula_formfield {
  width: 130px;
} */
.warehouse_payment__formula_cancel_btn {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: tomato;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}
.warehouse_payment__formula_addmore_btn {
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: white;
  border-radius: 5px;
  background-color: #0c6efd;
  padding: 5px;
  padding-right: 10px;
  width: fit-content;
  position: relative;
  cursor: pointer;
}
.warehouse_payment__formula_addmore_btnicon {
  margin-right: 3px;
}

/* #customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  min-width: 380px;
  width: 100%;
} */

/* #customers td,
#customers th {
  padding: 4px;
} */

/* .cell__width {
  text-align: left;
  color: black;
} */
.warehouse_payment__formula_formcol p {
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .warehouse_payment__formula_formbox1 {
    order: 2;
    width: 100%;
  }
  /* .customer_formula_value_table {
    width: 100%;
    overflow: auto;
    padding-bottom: 20px;
  } */
  .warehouse_prodidentifier__desc_modalsave.prodidentifier__formula_details
    span {
    display: none;
  }

  .warehouse_prodidentifier__desc_modalsave.prodidentifier__formula_details {
    width: fit-content;
    min-width: 30px;
  }
  .warehouse_payment__formula_formboxheading {
    padding: 10px 0px;
    color: grey;
    margin: 0;
    padding-bottom: 10px;
  }
  /* .payment__formula_switch_wrapper {
    margin-bottom: 20px;
  } */
  /* .aduser__details_modal {
    width: 90%;
  } */
}
