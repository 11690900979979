.color_box_wrap {
  width: 300px;
  height: 200px;
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  overflow-y: auto;
  flex-wrap: wrap;
  box-shadow: 0 0 2px 2px rgba(172, 170, 170, 0.425);
}
.zncb_wrap_color {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  outline-style: double;
  outline-offset: 2px;
  transition: 0.3s;
  transform: scale(1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
}
.zncb_wrap_color:hover {
  transform: scale(1.1);
}
