.custom__dropdown_container {
  height: 40px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid lightgrey;
  position: relative;
  margin: 5px 0;
  background-color: white;
}

.custom__dropdown_header {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 100%;
}

.custom__dropdown_list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 100;
  box-shadow: 0 0 2px 3px rgb(230, 230, 230);
  max-height: 200px;
  overflow-y: auto;
}
.custom__dropdown_list::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.custom__dropdown_list::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.custom__dropdown_list::-webkit-scrollbar-thumb {
  background: #d2d2d2;
}
.custom__dropdown_list.open {
  display: block;
  animation: fadeIn 0.2s ease-in-out;
}

.custom__dropdown_item {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.custom__dropdown_item:hover {
  background-color: rgb(234, 234, 235);
}
.custom__dropdown_headericon {
  position: absolute;
  right: 10px;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
