.rfsregister__steps_fieldsrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rfsregister__steps_fieldscol {
  margin: 10px 0 0 0;
  padding: 0 10px;
  flex: 1;
}
.rfsregister__steps_fieldscol * {
  border-color: #131466 !important;
  outline-color: #131466;
  color: #131466 !important;
}
.rfsregister__steps_fieldscolerror * {
  border-color: #aa1522 !important;
}
.rfsregister__steps_fieldscol input::placeholder {
  color: #131466;
  opacity: 1;
}
.rfsregister__steps_fieldscol input::-webkit-input-placeholder {
  color: #131466;
  opacity: 1;
}
.rfsregister__steps_fieldscol input::-moz-placeholder {
  color: #131466;
  opacity: 1;
}
.rfsregister__steps_nextbtn {
  margin-top: 25px;
  width: 100%;
  height: 50px;
  background: #0c69f3;
  box-shadow: 0px 5px 15px rgba(12, 105, 243, 0.35);
  border-radius: 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.rfsregister__steps_fieldscollabel {
  color: #131466;
  font-size: 14px;
  margin-bottom: 5px;
}
.rfsregister__steps_nextbtndisabled {
  opacity: 0.5;
  pointer-events: none;
}
.rfsregister__steps_fieldsrow .MuiAutocomplete-popper {
  max-height: 140px;
  overflow: hidden;
}
.rfsregister__steps_fieldscol label {
  border-color: #131466 !important;
  outline-color: #131466;
  color: #131466 !important;
  font-size: 16px;
}
.rfsregister__steps_next_previous {
  display: flex;
  column-gap: 20px;
}

.rfsregister__steps_nextbtn {
  height: 40px;
}
.field__selectBox .MuiInputBase-colorPrimary {
  max-width: 220px;
  padding: 0 !important;
  height: 45px;
}
.recaptcha__checkbox_wrap {
  padding: 10px 10px 0 10px;
}

.rfsregister__steps_next_previous {
  padding: 0 10px;
}

.register__tab_login_link {
  padding: 0 10px;
}



.fieldC__selectBox .MuiInputBase-colorPrimary {
  max-width: 100%;
  padding: 0 !important;
  height: 45px;
}


@media (max-width: 600px) {
  .rfsregister__steps_fieldsrow {
    flex-direction: column;
  }
  .rfsregister__steps_fieldscol {
    width: 100%;
  }
}
