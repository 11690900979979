.addressForm__main_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formdata__pagination_id_controler {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.formdata__pagination_controler_select_id {
  display: inline-flex;
  align-items: center;
}

.formdata__pagination_controler_btn {
  display: inline-flex;
}
.formdata__pagination_controler_id_Num > span {
  max-width: 160px;
  display: inline-flex;
}
.formdata__pagination_controler_id_Num_span {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  max-width: 110px;
}

.formdata__pagination_controler_select_id small {
  font-weight: 600;
  padding-right: 5px;
}

.formdata__pagination_controler_id_Num > span {
  font-weight: 600;
  padding-right: 5px;
}

.card-header.card_shipper_address_header .addressForm__title_col {
  font-size: 12px;
}

.card-header.card_shipper_address_header .addressForm__stepchecker_col svg {
  width: 15px;
  height: 15px;
}
.card-body.card_shipper_address_body .formdata__pagination_id_controler {
  font-size: 12px;
}
.card-body.card_shipper_address_body
  .formdata__pagination_id_controler
  .formdata__pagination_controler_select_id
  button {
  font-size: 11px;
  height: 15px;
  width: 15px;
  padding: 0;
  min-width: fit-content;
  line-height: 1;
}
.formdata__pagination_controler_id_Num > span {
  font-size: 11px;
}
.formdata__pagination_controler_select_id small {
  font-size: 11px;
}
.card-body.card_shipper_address_body
  .formdata__pagination_id_controler
  .formdata__pagination_controler_select_id
  button
  svg {
  width: 25px;
  height: 25px;
}
.card-header.card_shipper_address_header .addressForm__title_col svg {
  width: 13px;
  height: 13px;
}
.wapper_like_shopify .card-header {
  padding: 0;
  background: transparent;
  border: none;
  font-size: 12px;
  margin-bottom: 20px;
}
.wapper_like_shopify .card-body.card_shipper_address_body {
  padding: 0;
  border: none;
}
.wapper_like_shopify {
  padding: 15px;
}
.wapper_like_shopify .card-header svg {
  width: 20px;
}
.arrow__card_box {
  padding: 0 !important;
}
.card__header_heading button {
  padding: 0;
}
.card__header_heading {
  display: flex;
  align-items: center;
}
.wapper_like_shopify .card-header svg.tooltip__flist {
  width: 12px;
}
@media only screen and (max-width: 992px) {
  .formdata__pagination_controler_select_id button {
    height: 22px !important;
    min-width: 22px !important;
  }
  .card.bg-white.shadow.address__form_card {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}
@media only screen and (min-width: 1190px) {
  .card-header.card_shipper_address_header .addressForm__title_col {
    font-size: 0.878vw;
  }
  .address__form_card .card-header.card_shipper_address_header {
    padding: 0.586vw 1.171vw;
  }
  .card-header.card_shipper_address_header .addressForm__stepchecker_col svg {
    width: 1.098vw;
    height: 1.098vw;
  }
  .formdata__pagination_controler_id_Num > span {
    font-size: 0.805vw;
  }
  .formdata__pagination_controler_select_id small {
    font-size: 0.805vw;
  }
  .card-body.card_shipper_address_body
    .formdata__pagination_id_controler
    .formdata__pagination_controler_select_id
    button {
    font-size: 0.805vw;
    width: 1.1vw;
    height: 1.1vw;
  }
  .card-body.card_shipper_address_body
    .formdata__pagination_id_controler
    .formdata__pagination_controler_select_id
    button
    svg {
    width: 1.2vw;
    height: 1.2vw;
  }
  .card-header.card_shipper_address_header .addressForm__title_col svg {
    width: 0.952vw;
    height: 0.952vw;
  }
}
