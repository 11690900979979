.products__page_wrap {
  padding: 10px 20px;
  max-width: 97%;
  margin: auto;
}
.product_identifier_parent_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
}
/* .product_identifier_parent_wrapper:hover {
  border-radius: 10px;
  background: #a79f9f14;
} */
.product_dimension_parent_wrapper {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 100%;
  cursor: pointer;
}
/* .product_dimension_parent_wrapper:hover {
  border-radius: 10px;
  background: #a79f9f14;
} */
.product_quantity_parent_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
}
.product_quantity_parent_wrapper:hover {
  border-radius: 10px;
  background: #a79f9f14;
}

.product_identifier_wrapper {
  display: flex;
  gap: 5px;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
}
.marketplace_icon {
  text-decoration: none;
  color: black;
}
.marketplace_icon:hover {
  color: #0d6efd;
}
.product__table_identifier_img {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  object-fit: contain;
}
.product__table_mainimg {
  width: 40px;
  height: 25px;
  border-radius: 10px;
  object-fit: contain;
}
.prodtable__view_productdesc {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 65px; */
  width: fit-content;
  padding: 0px 10px;
  height: 30px;
  box-shadow: 0.3px 0.3px 3px 1px rgb(230, 228, 228);
  transition: 0.3s;
  user-select: none;
  border-radius: 3px;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 500;
}
.prodtable__view_productdesc > * {
  margin-right: 3px;
}
.prodtable__view_productdesc:active {
  transform: scale(0.96);
}
.products__page_header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: grey;
  margin: 20px 5px 0px 5px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid lightgrey;
}
.products__page_hdrheading {
  font-weight: 400 !important;
  font-size: 22px;
}
.products__page_hdraddprod {
  border-radius: 5px;
  box-shadow: 0.3px 0.3px 3px 1px rgb(230, 228, 228);
  display: flex;
  padding: 6px 8px;
  align-items: center;
  font-weight: 500;
  font-size: 8px;
  text-decoration: none;
  color: grey;
  cursor: pointer;
  height: fit-content;
}
.products__page_headerrt_wrap {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
}
.products__page_hdraddprod:hover {
  color: grey;
}
.products__page_hdraddprod > * {
  margin-right: 3px;
}
.prodsec__addprod_section {
  width: 100%;
  max-width: 100%;
  display: flex;
  box-sizing: border-box;
}

.prodsec__addprod_sleft {
  width: fit-content;
}
.prodsec__addprod_sright {
  flex: 1;
}
.prodsec__addprod_header {
  margin: 25px;
  font-size: 22px;
  color: grey;
  border-bottom: 1px solid lightgrey;
  padding-bottom: 10px;
}
.prodsec__button_wrapper {
  display: flex;
  gap: 5px;
}
.prodsec__button_wrapper .MuiBadge-badge {
  font-size: 7px;
  padding: 1px;
  min-width: fit-content;
  width: fit-content;
  height: fit-content;
  min-height: fit-content;
}
.products__page_select_client {
  margin-bottom: 10px;
  display: flex;
}
.prodtable__showvariant_btn {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 38px;
  box-shadow: 0 0 2px 2px rgb(230, 230, 230);
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  user-select: none;
  color: grey;
}
.prodtable__showvariant_btn > input {
  margin-right: 8px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.weight__button_wrapper {
  display: flex;
  gap: 5px;
  width: fit-content;
}

td .product__textalign_center {
  text-wrap: balance;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  text-align: start;
}
td .product__textalign_center:hover {
  overflow: auto;
}

td .product__textalign_center::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 62%);
  background-color: #f5f5f500;
  border-radius: 2px;
}

td .product__textalign_center::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f500;
}

td .product__textalign_center::-webkit-scrollbar-thumb {
  background-color: #c8c9ca;
  border: 2px solid #c8c9ca;
}
.prodtable__productweight_unitbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  box-shadow: 0.3px 0.3px 3px 1px rgb(230, 228, 228);
  transition: 0.3s;
  user-select: none;
  border-radius: 3px;
  font-weight: 500;
}

.prodtable__productweight_unitbtn_true {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  box-shadow: 0.3px 0.3px 3px 1px rgb(230, 228, 228);
  transition: 0.3s;
  user-select: none;
  border-radius: 3px;
  font-weight: 500;
  background-color: #1876d2;
  color: white;
}
.prduct_brand_content {
  text-transform: uppercase;
  position: absolute;
  background: #fff;
  width: 100%;
  height: calc(100% + 6px);
  left: 0;
  top: -6px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bolttabscreen__varrow .prduct_brand_content {
  background: #f5f5f5;
}
.productBrand__wrap {
  position: relative;
  height: calc(100% + 17px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.productBrand__dropdown_select {
  max-width: 100px;
}
.productBrand__wrap:hover .prduct_brand_content {
  display: none;
}
.productBrand__dropdown_select_field div#demo-simple-select {
  padding: 2px 23px 0px 0px;
  font-size: 13px;
}

.product__notification_circle_wrapper {
  position: relative;
  padding: 7px 9px 7px 0px;
}

.product__notification_circle {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  font-size: 6px;
  padding: 2px 4px;
  background-color: #0d6efd;
  color: white;
  font-weight: 500;
  /* min-width: 20px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.wptable__quantity_column {
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .products__page_hdraddprod span {
    display: none;
  }

  label.prodtable__showvariant_btn {
    width: fit-content;
    order: 4;
    font-size: 12px;
    padding: 0 9px;
  }
  .products__page_headerlft {
    margin-bottom: 20px;
    width: 100%;
  }
  .warehouse__products__page_wrap
    .warehouse__products__page_wrap_tabs
    .MuiTabs-scroller {
    overflow: scroll !important;
  }
  .warehouse__products__page_wrap
    .warehouse__products__page_wrap_tabs
    .MuiTabs-scroller
    .MuiTabs-flexContainer {
    justify-content: flex-start;
  }
  .prod__dimension_modal {
    width: 80% !important;
    top: calc(50% - 165px) !important;
  }
  .products__page_headerrt_wrap {
    column-gap: 10px;
  }
  .products__page_header {
    margin: 0;
  }
}

@media only screen and (min-width: 1170px) {
  .products__page_hdraddprod {
    font-size: 0.805vw;
    padding: 0.439vw 0.586vw;
  }
  .products__page_hdraddprod svg {
    width: 1.025vw;
    height: 1.025vw;
  }
  .products__page_hdrheading {
    font-size: 1.311vw;
  }
}
