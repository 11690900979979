.prdtable__hist_modal {
  min-height: 250px;
  max-width: 700px;
  width: 100%;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
}
.prdtable__hist_modalheading {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid lightgray;
  font-weight: 500;
  font-size: 18px;
  gap: 10px;
  padding-bottom: 10px;
}
.prdtable__hist_modalcontent {
  padding: 20px 0 10px 0;
  height: 60vh;
  overflow: auto;
}
.popup_history_main {
  background: transparent;
  box-shadow: none;
}
.prdtable__hist_modalrow {
  display: flex;
}
.prdtable__hist_modalinputwrap {
  flex: 1;
  margin: 0 5px 10px 5px;
}
.prdtable__hist_modalinput {
  width: 100%;
  border: 0.5px solid lightgrey;
  outline: none;
  border-radius: 5px;
  height: 200px;
  padding: 5px 10px;
  transition: 0.3s;
}
.prdtable__hist_modallabel {
  width: 100px;
  height: 40px;
  margin: 0 5px 10px 5px;
  font-size: 17px;
  display: flex;
  padding: 2px;
  align-items: center;
}
.prdtable__hist_modalinput:focus {
  outline: 0.5px solid #0c68f373;
}
.prdtable__hist_modalfooter {
  width: 100%;
  padding: 10px;
  height: 60px;
  border-top: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.prdtable__hist_modalcncl {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #454545;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}
.prdtable__hist_modalsave {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c69f3;
  border: 1px solid #0c69f3;
  border-radius: 3px;
  margin-left: 10px;
  color: white;
  cursor: pointer;
}

.notes__attachments_modal_attachment_thumbnail {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.notes__attachments_modal_attachment_thumbnail img {
  max-width: 30px;
  max-height: 30px;
  width: 30px;
  height: 30px;
  object-fit: contain;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0px -2px 10px 0px #eaeaea;
}

.notes__attachments_modal_attachment_thumbnail_wrap {
  width: 100%;
  overflow: auto;
}

.notes__attachments_modal_attachment_thumbnail_wrap::-webkit-scrollbar,
.notes__attachments_modal_content_notes::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.notes__attachments_modal_attachment_thumbnail_wrap::-webkit-scrollbar-track,
.notes__attachments_modal_content_notes::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.notes__attachments_modal_attachment_thumbnail_wrap::-webkit-scrollbar-thumb,
.notes__attachments_modal_content_notes::-webkit-scrollbar-thumb {
  background: #d2d2d2;
}
.notes__attachments_modal_attachment_thumbnail_wrap::-webkit-scrollbar-thumb:hover,
.notes__attachments_modal_content_notes::-webkit-scrollbar-thumb:hover {
  background: #9f9a9a;
}
.notes__attachments_modal_wrap * {
  font-size: 12px;
}

.prdtable__hist_modalfooter.notes__attachments_modal_modal_footer
  .prdtable__hist_modalcncl {
  font-size: 12px;
  padding: 0 0;
  height: 30px;
  width: 70px;
}

.prdtable__hist_modalfooter.notes__attachments_modal_modal_footer {
  padding: 0;
}

.notes__attachments_modal_content_notes {
  /* max-height: 60px;
  overflow: auto; */
}

.prdtable__hist_modalcontent.notes__attachments_modal_wrap > div {
  box-shadow: none;
  height: fit-content;
  padding-top: 15px;
}

.prdtable__hist_modalcontent.notes__attachments_modal_wrap {
  height: fit-content;
  padding-top: 0;
}
.prdtable__hist_modal.notes__attachments_modal_container {
  max-height: 90vh;
  overflow: auto;
}

.prdtable__hist_modal_buttonsWrapper {
  display: flex;
  gap: 20px;
}

.prdtable__hist_modal_buttonsWrapper {
  display: flex;
  gap: 20px;
}
.prdtable__hist_modal_hdraddprod {
  border-radius: 5px;
  box-shadow: 0.3px 0.3px 3px 1px rgb(230, 228, 228);
  display: flex;
  padding: 5px 10px;
  align-items: center;
  font-weight: 500;
  font-size: 8px;
  text-decoration: none;
  color: grey;
  cursor: pointer;
}
.prdtable__hist_modal_hdraddprod:hover {
  color: grey;
}
.prdtable__hist_modal_hdraddprod > * {
  margin-right: 3px;
}
@media only screen and (max-width: 768px) {
  .prdtable__hist_modal_header .prdtable__hist_modal_hdrheading {
    font-size: 18px;
  }
  .prdtable__hist_modal_hdraddprod span {
    display: none;
  }
}

@media only screen and (min-width: 1170px) {
  .prdtable__hist_modal_hdraddprod {
    font-size: 0.805vw;
  }
  .prdtable__hist_modal_hdrheading {
    font-size: 1.311vw;
  }
  .prdtable__hist_modal_hdraddprod svg {
    width: 0.952vw;
    height: 0.952vw;
  }
}
