.custom__table_size1 .custabscreen__table_header {
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 35px;
}

.custom__table_size1 .custabscreen__table_innerrow {
  width: 100%;
  display: flex;
}
.custable__screen_tablewrap {
  background: #ffffff;
  outline: 1px solid #dedede;
  box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  width: 100% !important;
  height: fit-content;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  max-width: 100%;
  /* min-width: 95vw !important; */
  min-width: 95vw;
}
.custabscreen__table_topbar {
  background: #f7f7f7;
  border: 1px solid #d9d9d9;
  border-radius: 2px 2px 0px 0px;
  height: 45px;
  font-size: 15px;
  text-transform: uppercase;
  color: #454545;
  font-weight: bold;
  padding: 0 20px;
  display: flex;
  align-items: center;
  position: sticky;
  right: 0;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 5;
  visibility: visible !important;
}
.custable__screen_tablecontent {
  background: #ffffff;
  border-radius: 2px;
  width: 100%;
  min-height: 350px;
  height: 75vh;
  overflow: auto;
  width: fit-content;
  position: relative;
  padding: 0 20px;
  min-width: 100%;
}
.custable__screen_tablecontent::-webkit-scrollbar {
  width: 2px;
}
.custable__screen_tablecontent::-webkit-scrollbar-thumb {
  background-color: lightgray;
}

.custable__screen_tablewraploading,
.custable__screen_tablewraploading div {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  overflow: hidden !important;
}
.custable__screen_tablewraploading div {
  visibility: hidden;
  max-width: 100vw !important;
}
/* .custabscreen__table_main {
  width: fit-content;
} */
.custabscreen__table_innerrow {
  height: fit-content !important;
  display: flex !important;
  align-items: center !important;
  border-bottom: 1px solid #d9d9d9;
  margin-top: 5px;
  padding-bottom: 6px;
}
.custabscreen__theader_heading,
.custabscreen__theader_data {
  /* padding: 3px 5px !important;
  padding-bottom: 6px; */
  /* background-color: white; */
  border: none !important;
  justify-content: center !important;
  text-align: center !important;
  margin-right: 10px;
}
.custabscreen__theader_data {
  height: fit-content !important;
  max-height: 68px;
  align-items: flex-start !important;
  overflow: hidden !important;
  padding: 10px 1px !important;
  word-wrap: break-word !important;
  overflow-wrap: anywhere !important;
}
.custabscreen__theader_data:hover {
  overflow-y: auto !important;
}
.custabscreen__theader_data::-webkit-scrollbar {
  width: 3px;
}
.custable__screen_tablewrapdragging {
  cursor: grab;
}
.custom__table_tableloading {
  /* width: 100%; */
  height: 100%;
  max-width: 100vw;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.custom__table_tableloading,
.custom__table_tableloading * {
  visibility: visible !important;
}

/* Track */
.custabscreen__theader_data::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(216, 216, 216);
  border-radius: 2px;
}

/* Handle */
.custabscreen__theader_data::-webkit-scrollbar-thumb {
  background-color: #0c69f3;
  border-radius: 2px;
}
.custom__table_size1 .custabscreen__theader_heading {
  padding: 5px;
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  height: 100%;
  font-size: 12px;
  color: black;
  position: relative;
  font-size: 12px;
}
.custom__table_size1 .custabscreen__theader_heading div {
  font-size: 12px;
  font-size: 12px;
  font-family: "Poppins" !important;
  font-weight: 500;
}
.custom__table_size1 .custabscreen__theader_data {
  padding: 5px;
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  height: 100%;
  font-size: 12px;
  color: #454545;
  position: relative;
}
.custom__table_size1 .custabscreen__theader_hdrop {
  width: 16px;
  cursor: pointer;
  transform: rotate(0deg);
  transition: transform 0.5s ease-in-out;
}
.custom__table_size1 .custabscreen__theader_hdropreverse {
  transform: rotate(-180deg);
}
.custom__table_size1 .custabscreen__theader_hdrophide {
  visibility: hidden;
  pointer-events: none;
}
.custom__table_size1 .custabscreen__theader_hcheck {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.custom__table_size1 .custabscreen__table_row {
  transition: 0.5s;
}
.custom__table_size1 .custabscreen__row_details {
  min-width: 100%;
  width: fit-content;
  height: 800px;
  overflow-y: hidden;
  max-height: 0px;
  transition: max-height 0.5s ease-in-out;
  box-shadow: 0px 0px 5px 0px rgba(179, 173, 173, 0.76) inset;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(179, 173, 173, 0.76) inset;
  -moz-box-shadow: 0px 0px 5px 0px rgba(179, 173, 173, 0.76) inset;
}

.custom__table_size1 .custabscreen__row_detailsactive {
  max-height: 800px;
  overflow-y: auto;
}
.custom__table_size1 .custabscreen__theader_carrlogo {
  max-width: 95%;
  max-height: 50px;
  object-fit: contain;
}
.custom__table_size1 .custabscreen__theader_flagwrap {
  width: 30px;
  height: 18px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 1px 1px rgb(216, 216, 216);
}
.custom__table_size1 .custabscreen__thdata_pkgstatus {
  width: 21px;
  height: 21px;
  background: #e88c20;
  font-weight: 500;
  font-size: 8.24022px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
}
.custom__table_size1 .custabscreen__thdata_pnotes {
  font-size: 10.5px;
}
.custom__table_size1 .custabscreen__thdata_badges {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.custom__table_size1 .custabscreen__thdata_badges {
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
}
.custom__table_size1 .custabscreen__thdata_badges .swiper-slide {
  width: fit-content !important;
}
.custom__table_size1 .custabscreen__thdata_badge {
  height: 14.81px;
  width: fit-content;
  padding: 1.0652px 5.7558px;
  background: #e8fce5;
  border: 0.205564px solid #20cd04;
  border-radius: 0.822257px;
  display: flex;
  font-weight: 500;
  font-size: 9px;
  align-items: center;
  justify-content: center;
  margin: 3px 2px 3px 0;
}

.custom__table_size1 .custabscreen__thdata_badgeicon {
  margin-right: 3.5px;
}
.custom__table_size1 .custabscreen__thdata_badgeicon img {
  width: 9px;
}
.custom__table_size1 .custabscreenrd__additionservices_badge {
  height: 25px;
  width: fit-content;
  padding: 0 10px;
  background: #e8fce5;
  border: 0.5px solid #20cd04;
  border-radius: 1px;
  display: flex;
  font-weight: 500;
  font-size: 13px;
  align-items: center;
  margin-right: 10px;
}
.custom__table_size1 .custabscreen__additionservices_badgeicon {
  margin-right: 5px;
}
.custom__table_size1 .custabscreen__additionservices_badgeicon img {
  width: 13px;
}
.custom__table_size1 .custabscreenthd__paystatus_badge {
  width: fit-content;
  padding: 4px 6px;
  font-size: 11px;
  display: flex;
  align-items: center;
  border: 1px solid grey;
  border-radius: 2px;
  color: #000000;
  font-weight: 500;
  min-width: 70px;
  text-transform: capitalize;
}
.custom__table_size1 .custabscreenthd__paystatus_badgeicon > img {
  width: 12px;
  margin-right: 5px;
}
.custom__table_size1 .custabscreenthd__shipment_status {
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 2px;
  text-transform: capitalize;
  border: 1px solid grey;
  min-width: 75px;
  color: black;
}
.custom__table_size1 .custabscreenthd__priority_badge {
  display: flex;
  align-items: center;
  font-size: 11px;
  text-transform: capitalize;
}
.custom__table_size1 .custabscreenthd__priority_badgeicon {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-right: 5px;
}
.custom__table_size1 .custabscreen__thdata_actions {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.custabscreen__thdata_action {
  width: 50px !important;
  height: 45px !important;
  min-width: fit-content;
  padding: 3px 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 2px 1px rgb(228, 227, 227);
  cursor: pointer;
  font-size: 10px;
}
.custabscreen__thdata_action > img {
  width: 60% !important;
}

.custom__table_size1 .custabscreen__thdata_dropwrap * {
  border: none !important;
  outline: none !important;
}
.custom__table_size1 .custabscreen__thdata_info {
  margin-left: 5px;
  width: 8px;
}
.custom__table_size1 .custabscreenthd__offerstatus_badge {
  width: fit-content;
  padding: 4px 6px;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid grey;
  border-radius: 2px;
  color: #000000;
  font-weight: 500;
  min-width: 80px;
  text-transform: capitalize;
}
.custom__table_size1 .custabscreenthd__offerstatus_badgeicon > img {
  width: 12px;
  margin-right: 5px;
}
.custom__table_size1 .custabscreen__table_placeholder {
  width: 100%;
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.custom__table_size1 .custabscreen__table_emptimg {
  height: 50%;
  max-width: 80%;
  object-fit: contain;
}
.custom__table_size1 .custabscreen__table_empttext {
  font-size: 3vw;
  font-weight: 500;
  color: #0c68f354;
}
.custom__table_size1 .custabscreen__rdetails_tableswrap {
  padding: 30px 25px;
}
.custom__table_size1 .custabscreen__rdetails_tablesrow {
  width: 100%;
  display: flex;
}
.custom__table_size1 .custabscreen__rdetails_tablescol {
  margin-right: 25px;
}
.custom__table_size1 .custabscreen__rdetails_tablescolheading {
  font-weight: bold;
  color: #454545;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.custom__table_size1 .custabscreen__rdetails_verticletable {
  margin-bottom: 15px;
  border-bottom: 0.5px solid lightgray;
  padding-bottom: 10px;
}
.custom__table_size1 .custabscreen__rdverttable_row {
  display: flex;
  width: fit-content;
  text-transform: uppercase;
  font-size: 11px;
}
.custom__table_size1 .custabscreen__rdverttable_heading {
  width: 150px;
  min-height: 35px;
  max-height: fit-content;
  display: flex;
  padding: 10px 10px 0 10px;
  /* border: 1px solid #d9d9d9; */
  overflow-wrap: anywhere;
  font-weight: bold;
}
.custom__table_size1 .custabscreen__rdverttable_data {
  width: 200px;
  min-height: 35px;
  max-height: fit-content;
  display: flex;
  padding: 10px 10px 0 10px;
  overflow-wrap: anywhere;
}
.custom__table_size1 .custabscreen__rdverttable_smhcol {
  width: 130px;
}
.custom__table_size1 .custabscreen__rdverttable_smcol {
  width: 106px;
}
.custom__table_size1 .custabscreenrdvt__shipment_status {
  padding: 2px 10px;
  height: fit-content;
  font-weight: 500;
  text-transform: capitalize;
  border: 1px solid grey;
  font-size: 9px;
}
.custom__table_size1 .custabscreenthd__rvtdata_badge {
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 2px 10px;
  font-size: 9px;
  font-weight: 500;
  border: 0.5px solid grey;
  text-transform: capitalize;
}
.custom__table_size1 .custabscreenthd__rvtdata_badgeicon > img {
  margin-right: 3px;
  width: 9px;
}
.custom__table_size1 .custabscreen__rdetails_additionserviceslist {
  padding-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  max-width: 500px;
  /* border-bottom: 0.5px solid lightgray; */
}
.custabscreen__rdetails_horizontaltable {
  min-width: 705px;
}
.custom__table_size1 .custabscreen__rdetails_horizontaltable {
  max-height: 250px;
  overflow: auto;
}
.custom__table_size1 .custabscreen__rdhtable_header {
  display: flex;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
}
.custom__table_size1 .custabscreen__rdhtable_row {
  display: flex;
  text-transform: uppercase;
  font-size: 12px;
  margin: 5px 0px;
}
.custom__table_size1 .custabscreen__rdhtable_col {
  /* height: 35px; */
  height: fit-content;
  display: flex;
  align-items: center;
}
.custom__table_size1 .custabscreen__rdhtable_smptstatus {
  height: fit-content;
  padding: 2px 10px;
  border: 1px solid grey;
  border-radius: 1px;
  font-size: 9px;
  text-transform: capitalize;
  font-weight: 500;
}
.custom__table_size1 .dispscrn__selectdriver_btn {
  padding: 4px 10px;
  font-size: 14px;
  color: #0c69f3;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
}
.custom__table_size1 .dispscrn__selectdriver_btn > * {
  font-size: 18px;
  margin-right: 5px;
}
.custom__table_size1 .pagination__wrap {
  position: sticky;
  background-color: white;
  top: 100%;
  right: 0;
  left: 0;
  /* bottom: 1px !important; */
  width: 100%;
  height: fit-content;
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  padding: 5px;
}
.custom__table_size1 .dispscrn__selectdriver_editicon {
  color: #0c69f3;
  font-size: 20px;
  margin-left: 5px;
  cursor: pointer;
}
.custabscreen__table_rowdisabled * {
  text-decoration: line-through;
  opacity: 0.7;
}
.custabscreen__table_rowhighlighted {
  background-color: #44b3f328;
  border-radius: 5px;
}
.custable__pagination_perpage {
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: grey;
}
.custable__pagination_perpage > span {
  margin-right: 10px;
}
.custable__pagination_perpage select {
  outline: none;
}

.scrollable-container {
  position: relative;
  width: 100%;
  overflow: hidden; /* Hide the default scrollbar */
}

/* Styling for the custom scrollbar */
.scrollbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 10px; /* Customize the height of the scrollbar */
  background-color: #f0f0f0; /* Customize the background color of the scrollbar */
  overflow-x: scroll;
  overflow-y: hidden;
}

/* Hide the default scrollbar arrows */
.scrollbar::-webkit-scrollbar {
  display: none;
}

/* Styling for the inner content */
.scrollable-content {
  width: 100%;
  white-space: nowrap; /* Prevent content from wrapping */
  /* Add any other styling as needed for your content */
}
.custable__child_scrollbar {
  height: 10px;
  width: 100%;
  background-color: lightgray;
  overflow-x: scroll;
  cursor: pointer;
  border-radius: 5px;
}
.custabscreen__theader_data.Sandbox.URL,
.custabscreen__theader_data.Production.URL,
.custabscreen__theader_data.Username,
.custabscreen__theader_data.Password,
.custabscreen__theader_data.Account.No {
  filter: blur(3px);
}

.custabscreen__theader_data.Sandbox.URL:hover,
.custabscreen__theader_data.Production.URL:hover,
.custabscreen__theader_data.Username:hover,
.custabscreen__theader_data.Password:hover,
.custabscreen__theader_data.Account.No:hover {
  filter: blur(0px);
}

@media (min-width: 2000px) {
  .custable__screen_tablewrap {
    min-width: 60vw !important;
  }
}

@media only screen and (max-width: 992px) {
  .custable__screen_tablewrap {
    min-width: 85vw;
  }
  .custabscreen__table_topbar,
  .custable__pagination_perpage {
    font-size: 12px;
  }
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    padding: 0 5px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 600px) {
  .custable__pagination_perpage {
    font-size: 11px;
    flex-wrap: wrap;
    display: inline-flex;
    justify-content: flex-end;
    max-width: 130px;
    position: inherit;
  }
  .custom__table_size2 .custabscreen__theader_data,
  .custom__table_size2 .custabscreen__theader_heading {
    font-size: 13px !important;
  }
  .custabscreen__table_topbar {
    justify-content: space-between;
    padding: 0 0 0 15px;
  }
}
