@media only screen and (max-width: 992px) {
    .addAddressBook__btn,.editAddressBook__btn,.deleteAddressBook__btn {
        width: fit-content !important;
        margin-top: 11px  !important;
        padding-right: 0  !important;
    }
    }


    @media only screen and (min-width: 768px) and (max-width: 1760px) {

    .formdata__pagination_id_wrap,.formdata__pagination_wrap, .formdata__pagination_wrap .col-md-6 {
        width: 100% !important;
    }
    .pagination__btn_main {
        width: 100% !important;
    }
    .paigination__selected_id{
        width: 100% !important;
    }
    .pagination__btn_main{
        margin-bottom: 5px;
    }
}
.addAddressBook__btn {
    padding-right: 0 !important;
    width: fit-content !important;
}
.editAddressBook__btn {
    padding-right: 0 !important;
    padding-left: 5px !important;
    width: fit-content !important;
}
.deleteAddressBook__btn {
    padding-right: 0 !important;
    padding-left: 5px !important;
    width: fit-content !important;
}

a.formdata__pagination_controler_btn_btnedit {
    background: #e4e3e3;
    border-radius: 4px;
    padding: 2px 10px;
    color: #3e3e3e;
    font-weight: 600;
    text-decoration:none;
}

button.formdata__pagination_controler_btn_btnadd {
    background: #e4e3e3;
    border-radius: 4px;
    padding: 2px 10px;
    color: #3e3e3e;
    font-weight: 600;
}
button.formdata__pagination_controler_btn_btndlt {
    background-color: #303030;
    color: #fff;
    border-radius: 4px;
    padding: 2px 10px;
    font-weight: 600;
}
a.formdata__pagination_controler_btn_btnedit:hover,button.formdata__pagination_controler_btn_btnadd:hover{
    background-color: #303030;
    color: #fff;
}
 button.formdata__pagination_controler_btn_btndlt:hover{
    background: #000;
    color: #fff;
 }
.formdata__pagination_controler_btn {
    align-items: center;
    column-gap: 4px;
}

@media only screen and (min-width: 1020px) {

    button.formdata__pagination_controler_btn_btndlt,a.formdata__pagination_controler_btn_btnedit,button.formdata__pagination_controler_btn_btnadd {
        font-size: 0.732vw;
    }

}