.zone_select_zone_screen {
  width: 100%;
}
.zone_select_zone_map {
  position: relative;
  width: 100%;
  height: calc(100vh - 76px);
  overflow: hidden;
}
.zone_select_zone_mapcontent {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
}
.szmc_search_wrap {
  width: 300px;
  height: 35px;
  margin-bottom: 10px;
  position: relative;
}
.szmc_dropdown_wrap {
  width: 300px;
  height: 35px;
  margin-bottom: 10px;
  position: relative;
}
.szmc_search_input {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  outline: none;
  border: none;
  padding: 0 10px;
  box-shadow: 0 0 2px 2px rgba(207, 206, 206, 0.788);
}

.zone_select_wrap {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  outline: none;
  border: none;
  padding: 0 10px;
  box-shadow: 0 0 2px 2px rgba(207, 206, 206, 0.788);
  position: relative;
  background-color: white;
  cursor: pointer;
}
.zone_select_icon {
  position: absolute;
  right: 10px;
  top: 6px;
}
.zone_select_value {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 5px;
}
.zone_options_list {
  width: 100%;
  background-color: white;
  box-shadow: 0 0 2px 2px rgba(207, 206, 206, 0.61);
  max-height: 250px;
  overflow-y: auto;
}
.zone_option {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 15px 5px;
  border-top: 1px solid rgba(211, 211, 211, 0.726);
}
.add_zone_btn {
  width: 35px;
  height: 35px;
  background: rgb(15, 64, 140);
  background: radial-gradient(
    circle,
    rgba(15, 64, 140, 1) 29%,
    rgba(19, 81, 153, 1) 57%,
    rgba(28, 51, 75, 1) 100%
  );
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -40px;
  top: 0;
  border-radius: 20px;
  font-weight: bold;
  font-size: 25px;
  padding-bottom: 7px;
  cursor: pointer;
}
.addtoselectedzone_btn {
  height: 35px;
  padding: 0 10px;
  color: white;
  background: rgba(19, 81, 153, 1);
  border: none;
  font-weight: bold;
  border-radius: 20px;
  font-size: 12px;
  cursor: pointer;
  min-width: 160px;
}
.sz_expandaccor_arrowup {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.205);
  background-color: white;
  left: 50%;
  top: -20px;
  transform: translate(-50%, -50%);
  z-index: 10;
  cursor: pointer;
}
.zszs_zones_section {
  position: absolute;
  bottom: -25px;
  width: 100%;
  max-height: 90vh;
}
