.custabscreen__theader_carrlogo {
  max-height: 50px;
  max-width: 95%;
  object-fit: contain;
}

.paymentlist_iconbtn {
  width: 40px;
  height: 40px;
  box-shadow: 1px 1px 2px 3px rgb(235, 233, 233);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0c6efd;
  transition: box-shadow 0.5s;
}
.paymentlist_iconbtn:hover {
  box-shadow: 1px 1px 2px 3px rgb(228, 226, 226);
}
.paymentlist_iconbtn:active {
  transform: scale(0.9);
  transition: all 0.1s ease-in-out;
}

.paymentlist_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: grey;
  margin: 20px 5px 0px 5px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid lightgrey;
}

.paymentlist_heading {
  font-weight: 400 !important;
  font-size: 22px;
}
