.addcus__account_modal {
  min-height: 250px;
  max-width: 550px;
  width: 90%;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
}
.addcus__account_modalheading {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid lightgray;
  font-weight: 500;
  font-size: 18px;
}
.addcus__account_modalcontent {
  padding: 20px 0 10px 0;
  position: relative;
  min-height: 300px;
  overflow-x: hidden;
}

.addcus__account_modalrow {
  display: flex;
  width: 90%;
  margin-bottom: 15px;
}
.addcus__account_modalinputwrap {
  flex: 1;
  margin: 0 5px 10px 5px;
  height: fit-content;
}
.addcus__account_modalinput {
  width: 100%;
  border: 0.5px solid lightgrey;
  outline: none;
  border-radius: 5px;
  height: 50px !important;
  padding: 0 10px;
  transition: 0.3s;
}
.addcus__account_modallabel {
  width: 100px;
  height: 40px;
  margin: 0 5px 10px 5px;
  font-size: 17px;
  display: flex;
  padding: 2px;
  align-items: center;
}
.addcus__account_modalinput:focus {
  outline: 0.5px solid #0c68f373;
}
.addcus__account_modallabel2 {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
}
.addcus__account_modalfooter {
  width: 100%;
  padding: 10px;
  height: 60px;
  border-top: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.addcus__account_modalcncl {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #454545;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}
.addcus__account_modalsave {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c69f3;
  border: 1px solid #0c69f3;
  border-radius: 3px;
  margin-left: 10px;
  color: white;
  cursor: pointer;
}
.addcus__account_profimagelbl {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 0px 3px 3px rgba(219, 215, 215, 0.24);
      -webkit-box-shadow: 0px 0px 3px 3px rgba(219, 215, 215, 0.24);
      -moz-box-shadow: 0px 0px 3px 3px rgba(219, 215, 215, 0.24); */
  cursor: pointer;
  color: #454545;
  font-weight: 500;
  outline: 2px dashed lightgrey;
  margin-top: 5px;
}
.addcus__account_profimagelbl > * {
  margin-right: 5px;
}
.addcus__account_profimageinput {
  display: none;
}

.addcus__account_modalcontentbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  transition: all 0.5s ease-out;
  position: absolute;
  z-index: 1000;
  opacity: 0;
}
.addcus__account_modalcontent::-webkit-scrollbar {
  width: 5px;
}

.addcus__account_modalcontent::-webkit-scrollbar-thumb {
  background-color: #888;
}

.addcus__account_modalcontent::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.addcus__account_modalcontentboxleft {
  transform: translateX(-100%) rotate3d(1, 0, 0, 100deg);
}
.addcus__account_modalcontentboxright {
  transform: translateX(100%) rotate3d(1, 0, 0, 100deg);
}

.addcus__account_modalcontentactivebox {
  transform: translateX(0);
  opacity: 1;
}

.addcus__account_methods {
  width: 100%;
  padding: 20px 5%;
}
.addcus__account_method {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 25px;
  box-shadow: 0 0 5px 1px rgb(204, 203, 203);
  cursor: pointer;
  transition: 0.3s;
}
.addcus__account_method:hover {
  transform: scale(1.01);
}
.addcus__account_methodicon {
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.addcus__account_modalsavedisabled {
  opacity: 0.7;
  pointer-events: none;
}
.addcus__account_methoddisabled {
  pointer-events: none;
  opacity: 0.5;
}
.addcus__account_modalbackbtns {
  display: flex;
  align-items: center;
}
.addcus__account_modalbackbtn {
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 2px 1px rgba(211, 211, 211, 0.671);
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 15px;
  margin: 5px;
}
.addcus__account_modalbackbtn > * {
  margin: 0 5px;
}
.addcus__account_profimagelbl {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #454545;
  font-weight: 500;
  outline: 2px dashed lightgrey;
  margin-top: 5px;
}
.addcus__account_profimagelbl > * {
  margin-right: 5px;
}
.addcus__account_profimageinput {
  display: none;
}
.addcus__account_modaltxtrow {
  display: flex;
  align-items: center;
  padding: 7px 10px;
  width: 100%;
  font-size: 20px;
  border-bottom: 0.4px solid rgba(235, 234, 234, 0.616);
}

.addcus__account_modaltxtlft {
  width: 35%;
  color: grey;
  display: flex;
  align-items: center;
}
.addcus__account_modaltxtlft > * {
  margin-right: 5px;
}
.addcus__account_modaltxtrt {
  flex: 1;
  display: flex;
  align-items: center;
}
.addcus__account_modaltxtrtcopy {
  color: grey;
  cursor: pointer;
  transition: 0.4s;
}
.addcus__account_modaltxtrtcopy:hover {
  transform: scale(1.1);
}
.pmtsadd__blncecard_info {
  font-size: 14px;
  color: gray;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.pmtsadd__strpcard_btnwrap {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 5px 0;
  margin-bottom: 10px;
}
.pmtsadd__strpcard_btn {
  padding: 4px 18px;
  border-radius: 5px;
  color: white;
  background-color: #0c69f3;
  width: fit-content;
}
.pmtsadd__strpcard_btn:active {
  transform: scale(0.9);
}
.addcus__account_modalbbtnwrap {
  padding: 5px 0;
}
.card__element_wrap {
  width: 100%;
  height: 100%;
}
.card__element {
  margin: 20px 0;
  border: 0.5px solid lightgrey;
  border-radius: 2px;
  font-size: 22px;
  height: 45px;
  padding: 10px 2px;
}
.card__element input {
  height: 45px !important;
  font-size: 22px !important;
}
.loading__strpcard_form {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: lightgrey;
  min-height: 120px;
}
.strperror_text_wrap {
  color: rgb(253, 80, 49);
  font-weight: 500;
  font-size: 15px;
  position: relative;
  bottom: 18px;
}
