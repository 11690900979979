.profile_modal {
  width: 180px;
  padding: 8px 0;
}
.profile_modal_title {
  margin: 10px 0;
  font-size: 12px;
  font-weight: 600;
  color: rgb(177, 175, 175);
  padding: 0 15px;
}
.profile_modal_item {
  width: 100%;
  font-size: 8px;
  display: flex;
  align-items: flex-start;
  /* min-height: 35px; */
  cursor: pointer;
  padding: 7px 15px;
  color: #454545;
  text-decoration: none;
  user-select: none;
  position: relative;
  align-items: center;
  justify-content: flex-start;
}
.profile_modal_icon{
  color: #0c6efd;
  margin-right: 5px;
}
.profile_modal_item:hover {
  background-color: rgba(211, 211, 211, 0.233);
}
.pm_item_icon {
  font-size: 20px !important;
  margin-right: 10px;
  color: #0c6efd !important;
}
.profile_modal_itemdisabled {
  pointer-events: none;
  opacity: 0.6;
}
.pm_item_logoutloader {
  position: absolute;
  right: 10px;
  top: 7px;
}
.version__badge {
  padding: 4px 13px;
  border-radius: 15px;
  font-size: 10px;
  font-weight: 500;
  position: absolute;
  right: 8px;
  top: 4px;
  -webkit-box-shadow: inset 0px 0px 15px -5px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: inset 0px 0px 15px -5px rgba(0, 0, 0, 0.14);
  box-shadow: inset 0px 0px 15px -5px rgba(0, 0, 0, 0.14);
  color: grey;
}
