.container_parent {
  max-width: 1200px;
  margin: auto;
  padding-top: 30px;
}
.hero_section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.slider_header {
  flex: 5;
  width: 200px;
  height: 375px;
}
.description_header {
  padding: 0px 30px;
  flex: 5;
}
.product_name {
  font-size: 25px;
  color: #000000;
  font-weight: 600;
}
.product_price {
  color: #1976d2;
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 10px;
}
.product_brand_title {
  color: #333333;
  font-size: 14px;
}
.product_brand_name {
  color: #1976d2;
  font-size: 14px;
  cursor: pointer;
  display: flex;
}
.product_description {
  color: #000;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
}
.product_variation_parent {
  display: flex;
  gap: 20px;
}
.product_variation_parent_wrap_parent{   
   max-width: 350px;
  overflow: auto;
}

.product_variation_text {
  font-size: 85%;
  margin: 0;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  cursor: default;
  font-weight: 700;
  margin-top: 10px;
}
.product_variation_box {
  border: 1px solid #ececec;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  margin-top: 10px;
}
.product_variation_box:hover {
  border: 1px solid#1976d2;
}
.product_variation_box_active {
  border: 1px solid#1976d2;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  margin-top: 10px;
}
.product_variation_image {
  height: 55px;
  width: 55px;
}
.product_variation_name {
  line-height: 1.2;
  margin: 5px 0 3px 0;
  font-size: 80%;
  font-weight: 600;
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  text-transform: uppercase;
  text-overflow: ellipsis;
  max-width: 80px;
}

.product_warehouse_wrapper {
  display: flex;
  margin-top: 10px;
  gap: 5px;
}
.product_warehouse_box {
  width: 49%;
  padding: 15px 5px;
  border-radius: 5px;
  border: 1px solid #ececec;
}
.product_warehouse_box:hover {
  border: 1px solid#1976d2;
}
.product_warehouse_box_active {
  width: fit-content;
    padding: 7px 5px;
    border: 1px solid#1976d2;
    border-radius: 5px;
    max-width: 130px;
}
.product_warehouse_name {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #4d4d4d;
  cursor: pointer;
}
.product_warehouse_stock {
  text-align: center;
  font-weight: 600;
  color: #1976d2;
  font-size: 17px;
}
.product_warehouse_address {
  text-align: center;
  padding: 3px;
  color: #77838a;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
}
/* swipper  */
.swiper {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mySwiper2 {
  height: 80%;
  width: 100%;
}
.mySwiper {
  box-sizing: border-box;
  /*   margin-top: 10px;
  padding: 10px 0;
border: 1px solid black !important; */
}
.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}
.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swipper_iamge {
  margin: auto;
  width: 300px;
  object-fit: cover;
}
.mySwiper .swipper_iamge{
  width: 100%;
}
.swiper-slide-thumb-active{
  border: 2px solid #000;
  }
@media only screen and (max-width: 768px) {
  .description_header {
    padding: 0;
    flex: 5 1;
    width: 100%;
}
.product_variation_image{
  height: 45px;
  width: 55px;
}
.swipper_iamge{
  width: 100% !important;
}
.mySwiper2{
  height: fit-content;
  width: 100%;
}
.slider_header{
  height: fit-content;
  width: 100%;
}
.container_parent{
  padding-top: 5px;
}
.variants__combinations_table {
  width: 720px;
}

.variants__combinations_table_wrap {
  width: 86vw;
  overflow: auto;
}
}