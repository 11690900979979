.addorid__updatevariant_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 800px;
  min-height: 400px;
  max-height: 95vh;
  min-width: 300px;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 24px;
  padding: 20px;
  overflow-y: auto;
  border: none;
  border-radius: 5px;
}
.addorid__updatevariant_modal::-webkit-scrollbar {
  width: 5px;
}

.addorid__updatevariant_modal::-webkit-scrollbar-thumb {
  background-color: grey;
}
.addorid__updatevariant_modal::-webkit-scrollbar-track {
  background-color: #dbd9d9;
}

.addorid__updateattrmodal_modalclose {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.addorid__updateattrmodal_modalheading {
  margin-bottom: 10px;
  font-weight: 500;
}

.addorid__updateattrmodal_mdlsubheading {
  font-size: 14px;
  font-weight: 500;
  margin: 10px 0;
}
