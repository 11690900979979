.zone_settings_table {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 10px;
}
.zsettings_table_contentwrap {
  height: 75vh;
  overflow: scroll;
}
/* width */
.zsettings_table_contentwrap::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}

/* Track */
.zsettings_table_contentwrap::-webkit-scrollbar-track {
  background: white;
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0.637);
}

/* Handle */
.zsettings_table_contentwrap::-webkit-scrollbar-thumb {
  background: #37388b;
  border-radius: 10px;
}
.zsettings_table_icons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.zsettings_table_content {
  min-width: 1400px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 5px;
  height: fit-content;
  margin-top: 10px;
  margin-bottom: 120px;
}
.zstcontent_border_center {
  height: calc(98%);
  width: 1px;
  background-color: #9f9a9a;
  position: absolute;
  top: 52%;
  left: 50.2%;
  transform: translate(-50%, -50%);
}
.zstable_content_left {
  display: flex;
  justify-content: space-between;
  width: 48%;
  height: fit-content;
}
.zstable_content_left > div {
  padding-bottom: 39px;
}
.zstc_col_questions {
  width: 36%;
  background: #ffffff;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 10px 20px;
}

.zstc_col_settings {
  width: 61%;
  background: #ffffff;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 10px 20px;
}
.zstc_col_heading {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  padding: 10px 0;
  color: #131466;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  position: relative;
}
.zstc_col_headingwrap {
  cursor: pointer;
}
.zstc_col_headingpasteicon {
  position: absolute;
  right: 5px;
  cursor: pointer;
}

.zstc_colset_data {
  padding: 0 5px;
  min-height: 50px;
  font-weight: 500;
  position: relative;
}
.zstc_col_quest {
  margin-left: 5px;
}
.zstable_content_right {
  width: 48%;
  overflow-x: auto;
  display: flex;
  height: fit-content;
}

.zsettings_table_iconwrap {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  align-items: center;
  cursor: pointer;
}
.zsettings_table_iconwrap {
  margin-top: 5px;
  font-size: 10px;
  color: #131466;
}
.zstcr_zone_col {
  width: 200px;
  background: #ffffff;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 10px 20px;
}
.zone_settings_table .swiper {
  width: 100%;
}
.zone_settings_table .swiper-wrapper {
  display: flex;
  justify-content: flex-start;
  padding: 5px;
}
.zstc_col_settingsdata {
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
  color: rgba(19, 20, 102, 0.76);
}
.zstc_col_settingsdataunits {
  width: 70px;
  height: 35px;
  display: flex;
  border: 0.5px solid #d9d9d9;
  border-radius: 1px;
  box-sizing: border-box;
  padding: 2px;
  cursor: pointer;
  margin-right: 5px;
}
.zstc_col_settingsdataunit {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.zstc_col_settingsdataunit {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.zstc_col_settingsdataunitactive {
  background: #131466;
  border-radius: 1px;
  color: white;
}
.zs_dropdown_wrap {
  width: 120px;
  height: 35px;
  position: relative;
  margin-right: 5px;
}

.zszone_select_wrap {
  width: 100%;
  height: 35px;
  border-radius: 5px;
  outline: none;
  border: none;
  padding: 0 10px;
  position: relative;
  background-color: white;
  border: 0.5px solid #d9d9d9;
  border-radius: 1px;
  cursor: pointer;
}
.zszone_select_icon {
  position: absolute;
  right: 10px;
  top: 6px;
}
.zszone_select_value {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 5px;
}
.zs_zone_options_list {
  width: 100%;
  position: absolute;
  left: 0;
  background-color: white;
  box-shadow: 0 0 2px 2px rgba(207, 206, 206, 0.61);
  max-height: 250px;
  overflow-y: auto;
  z-index: 20 !important;
}
.zs_zone_option {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 15px 5px;
  border-top: 1px solid rgba(211, 211, 211, 0.726);
  z-index: 20;
}
.zstc_col_data {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  height: 50px;
  position: relative;
  top: 5px;
  cursor: pointer;
}
.zstc_col_dataactive {
  color: #131466;
  font-weight: 500;
}
.zstc_zcol_data {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  height: 50px;
  position: relative;
  top: -7px;
}
.zstc_zcol_data2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  height: 60px;
  position: relative;
}
.zstc_zcol_data3 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  height: 50px;
  position: relative;
  bottom: 8px;
}
.zstc_col_datafield {
  width: 90px;
  height: 20px;
  border: 0.5px solid #d9d9d9;
  outline: none;
  border-radius: 2px;
  font-size: 12px;
  padding: 2px 5px;
  color: #131466;
  text-align: center;
}

.zstc_col_datacopyicon {
  cursor: pointer;
}
.zstc_col_datacheckbox {
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.zs_rangedata_list {
  min-height: 50px;
  align-self: flex-start;
}
.zs_rangedata_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}
.zs_rangedata_input {
  width: 35px;
  height: 35px;
  border: 0.5px solid #d9d9d9;
  border-radius: 1px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2px 5px;
}
.zs_rangedata_to {
  margin: 0 8px;
}
.zs_rangedata_copy {
  margin: 0 8px;
  cursor: pointer;
}
.zs_rangedata_delete {
  cursor: pointer;
}
.zstcc_settings_dimensionsdata {
  height: 60px;
  width: 100%;
  padding: 0 5px;
  display: flex;
}
.zstcc_settings_dimensionsdataleft {
  flex: 1.2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.zstcc_settings_dimensionsdataright {
  flex: 0.2;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  padding-top: 20px;
}
.zstccsddl_dimension_item {
  width: 40%;
}
.zstccsddl_dimension_itemtop {
  width: 100%;
  height: 35%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.zstccsddl_dimension_itemtop > span {
  width: 35px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(19, 20, 102, 0.61);
}
.zstccsddl_dimension_itembottom {
  width: 100%;
  height: 65%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.zstccsddl_dimension_middletext {
  position: relative;
  top: 8px;
}
.zstcc_settings_dimensionsdataright > * {
  cursor: pointer;
}
.zs_rangedata_deleteplaceholder {
  width: 15px;
}
.active__question_field {
  border: 2px solid #131466 !important;
}
.active__question_setfield {
  outline: 2px solid #131466 !important;
}
.zstc_dimensions_placeholder {
  width: 100%;
  height: 50px;
}
.zstc_cod_placeholder {
  width: 100%;
  height: 50px;
}
.zstcc_settings_coddata {
  width: 100%;
  display: flex;
  padding-left: 40%;
  height: 50px;
  justify-content: space-between;
  align-items: center;
}
.zstcc_settings_coddataleft {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 75%;
}
.zstcc_settings_coddataright {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 25%;
}
.zs_coddata_input {
  width: 50px;
  height: 35px;
  border: 0.5px solid #d9d9d9;
  border-radius: 1px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2px 5px;
}
.zstcc_settings_coddataright > * {
  cursor: pointer;
}
.zstcontent_zone_placeholder {
  width: 30px;
  height: 100%;
}
.zstc_col_databottomtext {
  position: absolute;
  color: grey;
  top: 19px;
  right: 35px;
  font-size: 10px;
}
.zstcc_disabled_datafield {
  pointer-events: none;
  color: lightgrey;
  border-color: lightgrey;
}
.zone_settings_table .swiper-slide {
  max-width: 200px;
}
.zstc_col_datafieldloading {
  position: absolute;
  right: 25px;
  top: -5px;
}
.zstc_col_datafieldloading .lds-dual-ring {
  display: inline-block;
  width: 30px;
  height: 30px;
}
.zstc_col_datafieldloading .lds-dual-ring:after {
  content: " ";
  display: block;
  width: 14px;
  height: 14px;
  margin: 8px;
  border-radius: 50%;
  border: 3px solid #131466;
  border-color: #131466 transparent #131466 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.settings__loader_wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
}
.settings__loader_text {
  margin-top: 10px;
  font-size: 25px;
  color: lightgray;
}
.settings__loader_wrap .lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.settings__loader_wrap .lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #131466;
  animation: lds-grid 1.2s linear infinite;
}
.settings__loader_wrap .lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.settings__loader_wrap .lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.settings__loader_wrap .lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.settings__loader_wrap .lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.settings__loader_wrap .lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.settings__loader_wrap .lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.settings__loader_wrap .lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.settings__loader_wrap .lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.settings__loader_wrap .lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
