.warehouse__content_interval_wrap .ltlinvmodal__shdetails_tableitem {
  font-size: 10px;
  border: 1px solid #ccc;
  padding: 3px;
}

.warehouse__content_interval_wrap {
  margin-bottom: 40px;
}

.warehouse__content_interval_wrap th.invmodal__shdetails_tablehd {
  font-size: 9px;
  border: 1px solid #cccc;
}
.warehouse__content_interval_wrap table.invmodal__shdetails_table {
  min-width: -webkit-fill-available;
}
.ltlinvoice__modal_downloadinvoicewrap.warehouse__content_interval_btns {
  justify-content: flex-end;
  display: flex;
  column-gap: 8px;
  margin-bottom: -40px;
}
.warehouse__invoice_interval_for_print {
  position: absolute;
  z-index: -2;
  visibility: hidden;
  left: -50000px;
}
.fpltlinvoice__modal_wrap.warehouse__invoice_interval_wrap {
  box-shadow: 0 0px 9px 0px #00000042;
  border-radius: 5px;
  padding: 20px 20px 0 20px;
  margin-top: 30px;
}
.warehouse__content_interval_wrap .ltlinvmodal__shdetails_tableitem svg {
  width: 12px;
  height: 14px;
}
/* .ltlinvoice__modal_wrap.warehouse__invoice_interval_print {
  width: 1100px;
  margin: 0 auto;
  border: 15px solid #fff;
} */
.printable__wrap_for_print {
  flex: 0 0 auto;
  width: 95%;
  margin-top: 30px !important;
  margin-right: auto !important;
    margin-left: auto !important;
}
.printable__wrap_for_print .ltlinvmodal__shdetails_tableitem>div {
  align-items: center;
}

@media only screen and (min-width: 1190px) {
  .warehouse__content_interval_wrap .ltlinvmodal__shdetails_tableitem{font-size: 0.732vw;}
  .warehouse__content_interval_wrap th.invmodal__shdetails_tablehd{font-size: 0.659vw;}
  .warehouse__content_interval_wrap .ltlinvmodal__shdetails_tableitem svg { width: 0.878vw;height: 1.025vw;}
}
.warehouse__invoice_interval_for_print.warehouse__content_interval_wrap  .ltlinvmodal__shdetails_tableitem {
  font-size: 10px !important;
}

.warehouse__invoice_interval_for_print.warehouse__content_interval_wrap  th.invmodal__shdetails_tablehd {
  font-size: 9px  !important;
}
.warehouse__invoice_interval_for_print.warehouse__content_interval_wrap  .tax__slip_view_contact_list_box h3 {
  font-size: 12px  !important;
}
.warehouse__invoice_interval_for_print.warehouse__content_interval_wrap  .tax__slip_view_contact_list_box p {
  font-size: 12px  !important;
}


.warehouse__invoice_interval_for_print.warehouse__content_interval_wrap  .tax__slip_view_list_box h3{
  font-size: 12px  !important;
}
.warehouse__invoice_interval_for_print.warehouse__content_interval_wrap  .tax__slip_view_list_box p{
  font-size: 12px  !important;
}
.warehouse__invoice_interval_for_print.warehouse__content_interval_wrap .ltlinvmodal__shdetails_tableitem svg{
  width: 12px  !important;
  height: 14px  !important;
}

th.invmodal__shdetails_tablehd_print,.ltlinvmodal__shdetails_tableitem_print{
  font-size: 9px !important;
  border: 1px solid #ccc;
  padding: 3px;
}
.tax__slip_view_contact_list_box_print h3 ,
.tax__slip_view_contact_list_box_print,
.tax__slip_view_list_box_print h3 ,
.tax__slip_view_list_box_print p {
    font-size: 12px !important;
}
.tax__slip_view_created_list_print {
  display: flex;
  column-gap: 10px !important;
}


@media only screen and (max-width: 768px) {


.tax__slip_view_contact_details {
  flex-wrap: wrap;
}

.tax__slip_view_contact_list_box {
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.tax__slip_view_contact_list_box:nth-child(2) {
  margin: 0 0 10px 0;
}

.tax__slip_view_created_list {
  flex-wrap: wrap;
  column-gap: 3px;
  row-gap: 0;
}

.tax__slip_view_list_box p {
  margin-bottom: 5px;
}

.tax__slip_view_details_header {
  padding-top: 50px;
}
.warehouse__content_interval_wrap table.invmodal__shdetails_table {
  min-width: 1070px;
}

.invmodal__shipment_details {
  overflow: auto;
}

.invmodal__shipment_detailshding {
  position: sticky;
  left: 0;
}

}