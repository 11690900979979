.pkgbundle__details_modal {
  min-height: 250px;
  max-height: 95vh;
  overflow-y: auto;
  min-width: 310px;
  width: 1150px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 30px;
}
.pkgbundle__details_modalhdrrttitle {
  display: flex;
  align-items: center;
  height: 100%;
  color: grey;
  font-weight: 400;
}

.pkgbundle__details_modal::-webkit-scrollbar {
  width: 5px;
}

.pkgbundle__details_modal::-webkit-scrollbar-thumb {
  background-color: #0c69f3;
}

.pkgbundle__details_modal::-webkit-scrollbar-track {
  background-color: #dbd9d9;
}
.pkgbundle__details_modalheader {
  width: 100%;
  min-height: 100px;
  display: flex;
  font-weight: 500;
  font-size: 18px;
  flex-wrap: wrap;
}
.pkgbundle__details_modalheaderlft {
  width: fit-content;
}
.pkgbundle__details_closewrap {
  position: absolute;
  right: 3px;
  top: 2px;
}
.pkgbundle__details_modalheaderrt {
  padding: 0 15px;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}
.pkgbundle__details_modalcontent {
  padding: 20px 0 10px 0;

  width: 100%;
}
.pkgbundle__details_modalcontent > p {
  margin-left: 5px;
}

.pkgbundle__details_modalcontent::-webkit-scrollbar {
  width: 5px;
}

.pkgbundle__details_modalcontent::-webkit-scrollbar-thumb {
  background-color: #0c69f3;
}

.pkgbundle__details_modalcontent::-webkit-scrollbar-track {
  background-color: #dbd9d9;
}
.pkgbundle__details_modalheaderimg {
  max-width: 250px;
  max-height: 250px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-radius: 5px;
  overflow: hidden;
  object-fit: contain;
}
.pkgbundle__details_modalheaderimg > img {
  max-width: 250px;
  max-height: 250px;
}
.pkgbundle__details_modalcontentrow {
  display: flex;
}
.pkgbundle__details_modalcontentlft {
  flex: 1;
}
.pkgbundle__details_modalcontentrt {
  width: fit-content;
  padding-left: 25px;
}
.pkgbundle__details_pkgslist {
  display: grid;
  grid-template-columns: 180px 400px 120px 120px 120px 120px;
}
.pkgbundle__details_pkgslistheading {
  padding: 5px;
  font-weight: 600;
}
.pkgbundle__details_pkgslistdata {
  padding: 5px 3px;
  border-top: 0.5px solid lightgrey;
}
.adcmdetails__modal_detimg {
  float: left;
  margin-right: 15px;
  width: 200px;
  height: 200px;
  object-fit: contain;
}
.pkgbundle__details_pkgslistdataempt {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  font-size: 30px;
  font-weight: 600;
  color: lightgrey;
}
@media (max-width: 1200px) {
  .pkgbundle__details_modal {
    max-width: 95%;
    height: auto;
  }
}
@media (max-width: 1000px) {
  .pkgbundle__details_modalheader {
    flex-direction: column;
  }
  .pkgbundle__details_modalheaderlft {
    width: 100%;
  }
  .pkgbundle__details_modalheaderrt {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .pkgbundle__details_modalcontentrow{display: block !important;}
.pkgbundle__details_pkgslist_wrap {
  width: 100%;
  overflow: auto;
  padding-bottom: 20px;
}

}