.flmtbilingstatus__status_title {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin: 15px 2px 10px 0;
}

.MuiFormControl-root.MuiTextField-root.flmtbilingstatus__status_notes.css-12nl4nz-MuiFormControl-root-MuiTextField-root {
  margin-bottom: 15px;
}

.flmtbilingstatus__field input {
  padding: 5px;
}
.MuiFormControl-root.MuiTextField-root.flmtbilingstatus__field.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: calc(100% - 30px);
}

.flmtbilingstatus_fulfilment_btn {
  margin-top: 10px;
}

.flmtbilingstatus_box_title {
  font-size: 12px;
  width: 100%;
  display: block;
  background: #012958;
  color: #fff;
  padding: 0 3px;
  margin-bottom: 2px;
}

.flmtbilingstatus_data_row {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
}
.flmtbilingstatus_data_row label {
  font-size: 10px;
}
.flmtbilingstatus_data_row strong {
  font-size: 10px;
}

.flmtbilingstatus_data_row {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
}

.flmtbilingstatus_data_row.total_flmtbilingstatus_cost {
  background: #dcdddf;
  padding: 1px 3px;
  display: flex;
  justify-content: space-between;
  font-size: 10px;
}
.flmtbilingstatus_text_area {
  border: 1px solid #dedede;
  border-radius: 5px;
  padding: 6px 7px;
  width: 100%;
  font-size: 14px;
  height: 50px;
}
.flmtbilingstatus__history_icone_wrapper {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.flmtbilingstatus__history_icone {
  cursor: pointer;
  margin-left: 5px;
}
.flmtbilingstatus__history_icone:hover {
  color: #0d6efd !important;
}

.flmtbilingstatus__modal_orderId {
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  justify-content: center;
  font-size: 12px;
  font-weight: 200;
}

@media only screen and (max-width: 768px) {
  .flmtbilingstatus__detail_fulfilment_modal .MuiBox-root {
    width: 90%;
    text-align: center;
  }
  .flmtbilingstatus__status_notes textarea {
    font-size: 12px;
  }
  .flmtbilingstatus_fulfilment_btn {
    justify-content: center !important;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 1170px) {
  .flmtbilingstatus__detail_fulfilment_modal .flmtbilingstatus__status_title {
    font-size: 1.318vw;
  }

  .flmtbilingstatus__detail_fulfilment_modal .flmtbilingstatus__status_notes * {
    font-size: 0.9vw;
  }

  .flmtbilingstatus__detail_fulfilment_modal .flmtbilingstatus__status_notes * {
    font-size: 0.9vw;
  }

  .flmtbilingstatus_fulfilment_btn {
    font-size: 0.952vw;
    padding: 0.293vw 0.732vw;
  }
}
