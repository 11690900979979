.full_bill_sts_history_modal {
  min-height: 250px;
  max-width: 860px;
  width: 95%;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
}
.full_bill_sts_history_modalheading {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid lightgray;
  font-weight: 500;
  font-size: 18px;
}
.full_bill_sts_history_modal_content {
  padding: 20px 0 10px 0;
  height: 60vh;
  overflow: auto;
}
.full_bill_sts_history_modalheading.history__product_heading.history__main_heading {
  padding: 0;
  font-size: 18px;
  line-height: 1;
  height: fit-content;
}

.full_bill_sts_history_modalheading.history__product_heading.history__sub_heading {
  padding: 0;
  font-size: 12px;
  font-weight: 200;
}

.full_bill_sts_history_modal_content.history__product_content {
  height: max-content;
}

.full_bill_sts_history_modal_content.history__product_content
  .editable__table_container {
  height: 50vh;
}

.full_bill_sts_history_modal_footer {
  position: absolute;
  top: 20px;
  right: 10px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.full_bill_sts_history_modal_footer .full_bill_sts_history_modal_footer_btn {
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  background: #f00;
  color: #fff;
  font-family: cursive;
  line-height: 1;
  cursor: pointer;
}

.full_bill_sts_history_modal_orderId {
  align-items: center;
  border-bottom: 0.5px solid #d3d3d3;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  height: 40px;
  justify-content: center;
  font-size: 12px;
  font-weight: 200;
}

@media only screen and (min-width: 1190px) {
  .full_bill_sts_history_modal_content.history__product_content
    .MuiTableCell-sizeSmall {
    font-size: 0.805vw;
  }
  .full_bill_sts_history_modal {
    max-width: 62.958vw;
  }
  .full_bill_sts_history_modalheading.history__product_heading {
    font-size: 1.318vw;
  }
}
