.add__prod_screen {
  display: flex;
  justify-content: center;
}
.add__prod_screenleft {
  min-width: 300px;
}
.add__prod_screenright {
  min-width: 300px;
  padding: 70px 20px 5px 10px;
}
.add__prod_screencenter {
  max-width: 1000px;
  flex: 1;
  padding: 45px 2%;
}
.add__prod_sheader {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}
.add__prod_sheaderback {
  margin-right: 10px;
  cursor: pointer;
  color: black;
}
.addprod__variants_attopaction {
  margin-left: 10px;
}
.add__prod_bottomsec {
  display: flex;
  justify-content: flex-end;
}
.add__prod_saveprodbtn {
  padding: 3px 10px;
  background-color: rgb(41, 182, 95);
  border-radius: 5px;
  color: white;
  font-weight: 500;
  width: 65px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hide_mobile_btn {
  display: flex;
}
.show_mobile_btn {
  display: none;
}

@media only screen and (max-width: 768px) {
  .add__prod_screen {
    flex-wrap: wrap;
  }
  .custom__dropdown_header {
    font-size: 12px;
  }

  input.addprod__custominputfield {
    font-size: 12px;
  }
  .show_mobile_btn {
    display: flex;
  }
  .hide_mobile_btn {
    display: none;
  }
  .add__prod_screencenter {
    order: 2;
    padding-top: 0;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }

  .add__prod_screenright {
    padding: 20px;
    width: 100%;
    padding-bottom: 0;
  }

  section#addproduct_basic_category {
    margin-bottom: 0;
  }
  .addprod__custominputtextarea {
    font-size: 12px;
  }
}
