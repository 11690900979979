
/* orderview  styling*/

.proddetails__desc_modal.product_view_modal_wrap {
    max-height: 90vh;
    overflow: auto;
  }
  
  .product_details_variants_wrap {
    display: flex;
    min-width: fit-content;
  }
  
  /* .product_details_variants_wrap_container {
    width: 100%;
    overflow: auto;
    max-width: 300px;
  }
   */

  
  .product_details_variants_wrap_container::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  
  .product_details_variants_wrap_container::-webkit-scrollbar-thumb {
    background-color: grey;
  }
  
  .product_details_variants_wrap_container::-webkit-scrollbar-track {
    background-color: #dbd9d9;
  }.product_main_modal_wrap {
    display: flex;
    align-items: flex-start;
  }
  
  .product_modal_image_col {
    width: 100%;
    max-width: 500px;
  }
  
  .product_modal_detail_col {
    width: 100%;
    max-width: 500px;
  }
  .product_modal_detail_nameproduct_modal_detail {
    font-size: 19px;
    font-weight: 600;
    color: #0a3e7a;
}

.product_modal_detail_stock_base_warehouse_name {
    font-size: 13px;
    line-height: 1;
}

.product_modal_detail_stock_base_warehouse_quantity {
    font-size: 10px;
}

.productdetails_product_warehouse_counts {
    max-width: fit-content;
    margin-bottom: 3px;
}
.product_modal_detail_brandName {font-size: 14px;color: #525252;}

.product_modal_detail_price {
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
}

.product_modal_detail_product_varient_heading {
    font-size: 15px;
    font-weight: 600;
    margin-top: 10px;
}
.product_modal_detail_product_varient_list_box {
  display: flex;
  column-gap: 5px;
  align-items: center;
  font-size: 12px;
  margin-top: 8px;
}

.product_modal_detail_product_varient_list_box svg {width: 15px;height: 15px;}
.product_details_variants_box {
  border-radius: 5px;
  text-align: center;
  width: 90px;
}

.product_details_variants_variant_name {
  font-size: 10px;
}

img.product_details_variants_image {
  border-radius: 5px;
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin: 0 auto;
}
.product_modal_detail_product_varient_list {
  margin-top: 10px;
}
.product_modal_detail_product_varient_list_heading {
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
}
.productdetails_product_warehouse_counts {
  margin-bottom: 3px;
}

img.swipper_image_big_product_modal {
  width: 400px;
    margin: 0 auto 20px auto;
    height: 400px;
    object-fit: cover;
}.product_details_variants_wrap_container {
  width: 360px;
  overflow: auto;
  padding-bottom: 10px;
}