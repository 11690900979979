.productList__card_wrap {
  width: 100%;
  text-align: center;
}

.productList__card_title {
  text-align: center;
  font-weight: 600;
  font-size: 11px;
  width: 96%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
}

.productList__card_details_wrap {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.productList__card_details_measures {
  display: flex;
  flex-wrap: wrap;
  font-size: 10px;
}

.productList__card_details_measures_weight {
  width: 100%;
  text-align: center;
}

.productList__card_details_measures_LWH {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}

.productList__card_details_measures_weight span {
  display: block;
  width: fit-content;
  margin: 0 auto;
  border: 1px solid #ccc;
  padding: 1px 5px;
  line-height: 1.2;
}

.productList__card_details_measures_LWH span {
  display: block;
  width: fit-content;
  margin: 0 auto;
  border: 1px solid #ccc;
  padding: 1px 5px;
  line-height: 1.2;
}

.productList__card_details_img img,
.productList__card_details_img svg {
  width: 100%;
  max-width: 41px;
  margin-right: 6px;
  margin-left: 10px;
}
.single_list.list-group-item {
  align-items: center;
  display: flex;
  margin-right: 4px;
}

.list-group-horizontal {
  max-height: fit-content !important;
}
.warehouse__helper_text {
  position: absolute;
  top: 77%;
  left: 0;
  width: 100%;
  background: #000;
  font-size: 8px;
  padding: 5px;
  color: #fff;
  line-height: 1.3;
  border-radius: 5px;
}
.warehouse__helper_text_default {
  position: absolute;
  bottom: -7px;
  right: -1px;
  width: 51%;
  background: #000;
  font-size: 10px;
  padding: 5px;
  color: #fff;
  line-height: 1.3;
  border-radius: 5px;
  text-align: center;
}
.single_list.select_none_card.list-group-item {
  cursor: not-allowed;
  margin-right: 4px;
}
.package__name_list_names h6 {
  font-size: 13px;
  margin: 0;
}

.package__listing_field {
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.number__listing {
  margin: 0 !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.package__name_list_names .text-center {
  padding: 0;
}
.package__dimension_weight_volume_label {
  display: flex;
  column-gap: 7px;
}

.package__dimension_weight_volume_label .package__dimension_weight {
  flex-wrap: wrap;
}

.package__dimension_weight_volume_label .package__dimension_volume {
  flex-wrap: wrap;
}
.package__dimension_weight_volume .package__dimension_weight span {
  display: block;
  width: fit-content;
  margin: 0;
}
.package__dimension_weight_btn button {
  font-size: 10px;
  background: #000;
}
.package__dimension_weight_volume .package__dimension_volume span {
  width: fit-content;
  display: block;
}
.package__name_list_wrap_fullfilment_cost h3 {
  font-size: 17px;
  font-weight: 600;
  text-align: center;
}
.package__name_list_names_row,
.package__name_list_row {
  border-top: 1px solid #efefef;
}
.package__name_fullfilment_cost_row {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 400;
  border-bottom: 1px solid #dfdfdf;
  padding: 5px 0;
}

.form__warhouse_select_dropdown_helper_text {
  position: absolute;
  right: -50px;
}
.cation__main_title svg {
  color: #1976d2;
}
.package__name_fullfilment_cost_row:last-child {
  border: none;
  font-size: 14px;
}

.package__name_fullfilment_cost_row:last-child .tile__label_fullfilment_cost {
  font-size: 12px;
  font-weight: 600;
}

.package__name_fullfilment_cost_row:last-child .tile__value_fullfilment_cost {
  font-weight: 600;
}
.package__name_fullfilment_cost_row span.tile__value_fullfilment_cost {
  min-width: fit-content;
}

.expanded .list-group.list-group-horizontal {
  flex-wrap: wrap;
}

.expanded .productList__card_title {
  text-wrap: balance;
}
.collapse__row .productList__card_title {
  width: 100%;
}
.product__scroll_list_box {
  position: relative;
}

.product__scroll_list_box .non_selected_warehouse {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
}
.expand_btn {
  display: block;
  cursor: pointer;
}
.expand_btn .collapse__default {
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-size: 12px;
  font-weight: 600;
}

.expand_btn .collapse__default span {
  border: 1px solid;
  width: 15px;
  height: 15px;
  line-height: 1.2;
  text-align: center;
  display: inline-block;
}

.expand_btn .collapse__active {
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-size: 12px;
  font-weight: 600;
}

.expand_btn .collapse__active span {
  border: 1px solid;
  width: 15px;
  height: 15px;
  line-height: 1.2;
  text-align: center;
}
/* .product_scroll_my_product button.MuiButtonBase-root,.product_scroll_my_product button.MuiButtonBase-root:hover {
    background: none !important;
} */
.expanded .single_list {
  min-height: 100px;
}
.productList__card_details_img img {
  width: 35px;
  height: 25px;
  object-fit: cover;
  border: 1px solid #eaeaea;
  border-radius: 3px;
  padding: 2px;
}
.expanded .single_list .productList__card_details_img {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.expanded .single_list .productList__card_details_wrap {
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.expanded .single_list .productList__card_details_measures_LWH {
  column-gap: 5px;
}
.sales__section_screen .sss__addressection_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.horiscl.expanded {
  flex-wrap: wrap;
}
.saless__products_sectionleft button.MuiButtonBase-root {
  background: transparent !important;
}
.expand_btn button.MuiButtonBase-root.MuiIconButton-root {
  padding: 0;
}
.addTransferProduct__main_wrap.expanded {
  display: flex;
  flex-wrap: wrap;
}

.addTransferProduct__main_wrap.expanded
  .productList__card_wrap.add_tranfer_product_wrap {
  min-height: 120px;
  align-items: center;
  margin-bottom: 10px;
  width: 140px;
}

.add_tranfer_product_wrap_list_box {
  width: 100%;
}
.card-header.myprod_card_header .productDetails__main_label b {
  font-size: 12px;
}
.card-header.myprod_card_header .productDetails__main_label button {
  padding: 0;
  width: 12px;
}
.card-header.myprod_card_header
  .productDetails__main_package_bundles_btn
  button {
  font-size: 11px;
}
.productList__card_title span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 5px;
}
.productDetailsCustom__main_package_bundles_btn .MuiButton-containedPrimary {
  background: transparent !important;
  color: #0d6efd;
  border-bottom: 2px solid #0d6efd !important;
  border-radius: 0 !important;
  font-size: 11px;
}

.productDetailsCustom__main_package_bundles_btn .MuiButton-outlinedInherit {
  background: transparent;
  border: none;
  color: #ccc;
  border-bottom: 2px solid #d2d2d2 !important;
  border-radius: 0 !important;
  font-size: 11px;
}

.product__scrollbar_list_filter_field_wrap {
  width: 50%;
  display: inline-flex;
  justify-content: center;
}

.grid__list_btns_wrap {
  width: 25%;
  display: inline-flex;
  justify-content: flex-end;
}

.warehouse__selection_drop_main_wrap {
  width: 25%;
  display: inline-flex;
  justify-content: flex-start;
}

input.product__scrollbar_list_filter_field {
  width: 90%;
  border-bottom: 1px solid #ccc;
  height: 30px;
  font-size: 12px;
  margin-top: 5px;
  max-width: 380px;
  outline: none !important;
}
.product__scroller_wrap_main_container,
.bundle__scroller_wrap_main_container,
.packages__scroller_wrap_main_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.product__scrollbar_list .editable__table_container {
  height: fit-content;
}
.product_scroll_my_product.myprod_card_body.productDetailsCustom__main_package_bundles {
  padding: 0;
}
.selectQuote__table_wrap .MuiTableContainer-root {
  margin: 0;
}

.card-body.bg-white.shadow.select__quote_card_body {
  padding: 0;
  box-shadow: none !important;
}
.productDetailsCustom__main_package_wrap .card-header {
  margin: 0;
}
.productDetails__main_package_bundles_btn {
  margin-bottom: 15px;
}
.productDetailsCustom__icons_tabs_btn {
  display: flex;
  align-items: center;
  column-gap: 10px;
  border-right: 1px solid #eaeaea;
  padding-right: 10px;
}

.productDetailsCustom__icons_tabs_btn > div {
  border-bottom: 1px solid #ccc;
  min-width: 24px;
  display: inline-flex;
  justify-content: center;
  padding-bottom: 6px;
  cursor: pointer;
}

.productDetailsCustom__icons_tabs_btn > div svg {
  color: #ccc;
}
.productDetailsCustom__icons_tabs_btn > div.tabs__act_true *,
.productDetailsCustom__icons_tabs_btn > div.tabs__act_true {
  color: #0d6efd;
  border-color: #0d6efd;
}
@media only screen and (max-width: 767px) {
  .productDetails__main_wrap {
    flex-wrap: wrap;
    margin: 0;
  }

  .productDetails__main_label {
    width: 100%;
  }

  .productDetails__main_package_bundles_btn {
    margin-left: 0 !important;
    width: fit-content !important;
  }

  .productDetails__main_addPackage_btn {
    margin-left: 0 !important;
    width: fit-content !important;
  }

  .productDetails__main_package_bundles_btn span:last-child {
    margin-left: 6px !important;
  }
  .product_scroll_my_product .MuiButtonBase-root:hover {
    background: none;
  }

  .productDetails__main_search_field {
    margin-left: 0 !important;
    margin-top: 15px !important;
  }

  .product__scrollbar_list img,
  .product__scrollbar_list svg {
    width: 100% !important;
    max-width: 50px !important;
    margin: 0 auto;
  }
  .product__scrollbar_list .single_list.list-group-item {
    width: -moz-fit-content !important;
    width: fit-content !important;
    height: -moz-fit-content;
    min-height: 90px;
    display: flex;
    align-items: center;
    min-width: 140px;
    justify-content: center;
    max-width: 180px;
    padding: 0 8px !important;
  }

  .product__scrollbar_list.row
    .overflow-auto.horiscl.p-2.list-group.list-group-horizontal {
    max-height: fit-content !important;
    display: flex !important;
    justify-content: center;
  }

  .productDetails__main_addPackage_btn button,
  .productDetails__main_package_bundles_btn button {
    font-size: 12px;
  }
  .addressForm__title_col {
    font-size: 15px;
  }
  .product__scroll_list_box {
    margin: 0;
  }

  .product__scrollbar_list
    .product__scroll_listgroup
    .product__scroll_list_box {
    margin: 0 !important;
    min-width: 122px !important;
    width: 48% !important;
    max-width: 122px;
  }

  .product__scroll_listgroup {
    column-gap: 10px;
    row-gap: 10px;
  }
  .bundle__list_main_wrap .bundle__prod_card_wrap.list-group-item {
    width: calc(50% - 2.5px) !important;
    min-width: 0;
    margin: 0;
  }

  .bundle__list_main_wrap .list-group.list-group-horizontal {
    column-gap: 5px;
  }
  .bundle__list_main_wrap .productList__card_details_measures {
    width: 100%;
    justify-content: center;
    column-gap: 6px;
    margin-top: 2px;
  }

  .bundle__list_main_wrap .productList__card_details_measures_weight {
    width: fit-content;
  }

  .bundle__list_main_wrap .productList__card_details_measures_LWH {
    width: fit-content;
  }
  .package__scoller_list_main_wrap
    .package__scoller_list_box_wrap.list-group-item {
    width: calc(50% - 2.5px) !important;
    margin: 0 !important;
    min-width: 0;
  }

  .package__scoller_list_main_wrap {
    column-gap: 5px;
    row-gap: 5px;
  }
  .productDetails__main_package_bundles_btn.productDetailsCustom__main_package_bundles_btn
    > div {
    flex-wrap: wrap;
    width: 100%;
    column-gap: 10px;
  }

  .productDetails__main_package_bundles_btn.productDetailsCustom__main_package_bundles_btn
    span
    button {
    padding: 0;
    min-width: fit-content !important;
  }

  .productDetails__main_package_bundles_btn.productDetailsCustom__main_package_bundles_btn
    span {
    margin-left: 0 !important;
  }
  .productDetailsCustom__main_package_bundles .bundle__list_main_wrap button {
    display: none;
  }
  .productDetails__main_package_bundles_btn.productDetailsCustom__main_package_bundles_btn {
    margin: 0 auto !important;
  }
  .warehouse__selection_drop_main_wrap,
  .product__scrollbar_list_filter_field_wrap,
  .product__scrollbar_list_filter_field_wrap {
    width: fit-content;
  }
  .warehouse__selection_drop_main_wrap button {
    font-size: 11px;
  }
  .grid__list_btns_wrap svg {
    font-size: 16px;
  }
  .product__scroller_wrap_main_container,
  .bundle__scroller_wrap_main_container,
  .packages__scroller_wrap_main_container {
    justify-content: center;
    row-gap: 6px;
    margin-top: 10px;
  }
  .warehouse__selection_drop_sec {
    min-width: 150px !important;
  }
  .card-body.product_scroll_my_product.myprod_card_body.productDetailsCustom__main_package_bundles
    .bolt__table_main_wrap {
    margin-top: 15px;
  }
  .grid__list_btns_wrap button {
    padding: 0;
  }
  .grid__list_btns_wrap {
    column-gap: 5px;
  }
}

@media only screen and (max-width: 550px) {
  .product__scrollbar_list_filter_field_wrap {
    order: 3;
    width: 100%;
  }
  .product__scroller_wrap_main_container,
  .bundle__scroller_wrap_main_container,
  .packages__scroller_wrap_main_container {
    justify-content: space-between;
  }
  input.product__scrollbar_list_filter_field {
    max-width: 100%;
  }
}

@media only screen and (min-width: 1190px) {
  .card-header.myprod_card_header .productDetails__main_label b {
    font-size: 0.878vw;
  }
  .card-header.myprod_card_header .productDetails__main_label button,
  .card-header.myprod_card_header .productDetails__main_label button svg {
    width: 0.952vw;
    height: 0.952vw;
  }
  .card-header.myprod_card_header
    .productDetails__main_package_bundles_btn
    button {
    font-size: 0.805vw;
    padding: 0.293vw 0.732vw;
  }
  .card-header.myprod_card_header {
    padding: 0.586vw 1.171vw;
  }
  .productDetails__main_package_bundles_btn button#Products,
  .productDetails__main_package_bundles_btn button#Bundles,
  .productDetails__main_package_bundles_btn button#Packages {
    min-width: 5.028vw;
    font-size: 0.732vw;
    border-width: 1px !important;
  }
  .product_scroll_my_product.myprod_card_body
    .shadow.single_list.list-group-item {
    width: 9.4vw !important;
    margin-right: 0.293vw;
    padding: 0.146vw !important;
  }

  .product_scroll_my_product.myprod_card_body
    .shadow.single_list.list-group-item
    .productList__card_title {
    font-size: 0.805vw;
  }

  .product_scroll_my_product.myprod_card_body
    .shadow.single_list.list-group-item
    .productList__card_details_img
    img {
    max-width: 3.001vw;
    width: 2.574vw;
    height: 1.838vw;
  }
  .card-body.product_scroll_my_product.myprod_card_body
    .product__scroll_listgroup {
    display: flex !important;
    justify-content: center !important;
    column-gap: 5px;
  }
  .card-body.product_scroll_my_product.myprod_card_body
    .product__scroll_listgroup
    .list-group-item {
    margin: 0;
  }
  .product_scroll_my_product.myprod_card_body .expanded .single_list {
    min-height: 7.353vw;
  }
  .product_scroll_my_product.myprod_card_body
    .shadow.single_list.list-group-item
    .productList__card_details_measures {
    font-size: 0.732vw;
  }
  .package__name_list_wrap_fullfilment_cost h3 {
    font-size: 0.945vw;
  }
  .package__name_fullfilment_cost_row:last-child .tile__label_fullfilment_cost {
    font-size: 0.778vw;
    font-weight: 500;
    color: #000;
  }
  .product_scroll_my_product.myprod_card_body {
    padding: 1.176vw;
  }

  .product_scroll_my_product.myprod_card_body button.MuiButtonBase-root {
    font-size: 0.98vw;
  }
  .productDetailsCustom__main_package_bundles .editable__table_container {
    max-height: 26.103vw;
  }
  .card-body.product_scroll_my_product.myprod_card_body
    .product__scroll_listgroup {
    column-gap: 0.368vw;
    row-gap: 0.368vw;
  }
  .bundle__list_main_wrap .productList__card_details_img svg {
    width: 1.838vw;
    height: 1.838vw;
  }
  .bundle__list_main_wrap .productList__card_details_measures {
    width: 100%;
    justify-content: center;
    column-gap: 0.368vw;
    margin-top: 0.368vw;
  }

  .bundle__list_main_wrap
    .productList__card_details_measures
    .productList__card_details_measures_weight {
    width: fit-content;
  }

  .bundle__list_main_wrap
    .productList__card_details_measures
    .productList__card_details_measures_LWH {
    width: fit-content;
  }
  .productList__card_details_measures_LWH span,
  .productList__card_details_measures_weight span {
    padding: 0.074vw 0.368vw;
  }
  .card-body.product_scroll_my_product.myprod_card_body.productDetailsCustom__main_package_bundles
    .warehouse__selection_drop_main_wrap
    button {
    font-size: 0.805vw;
  }
}
