.marketplaces__header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: grey;
  margin: 20px 5px 0px 5px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid lightgrey;
}
.marketplaces__hdrheading {
  font-weight: 400 !important;
  font-size: 22px;
}
.marketplaces__buttonsWrapper {
  display: flex;
  gap: 20px;
}
.marketplaces__hdraddprod {
  border-radius: 5px;
  box-shadow: 0.3px 0.3px 3px 1px rgb(230, 228, 228);
  display: flex;
  padding: 8px 18px;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
  color: grey;
  cursor: pointer;
}
.marketplaces__hdraddprod:hover {
  color: grey;
}
.marketplaces__hdraddprod > * {
  margin-right: 3px;
}

.marketplace__dispatcher__screen {
  padding: 20px 28px;
  margin: auto;
  max-width: 100%;
}
.marketplaces_marketplace_tabwrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.marketplaces_marketplace_tab {
  width: 150px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 8px;
  box-shadow: 1px 1px 5px 3px rgba(233, 232, 232, 0.5);
  cursor: pointer;
  border-radius: 5px;
  transition: 0.3s;
  position: relative;
}
.marketplaces_marketplace_tabimg {
  max-width: 85%;
  max-height: 90%;
  object-fit: contain;
}
@media only screen and (min-width: 1190px) {
  .marketplaces__header span.marketplaces__hdrheading {
    font-size: 1.611vw;
  }
  .marketplaces__hdraddprod {
    font-size: 1.025vw;
    padding: 0.439vw 0.586vw;
  }
  .marketplaces__hdraddprod svg {
    width: 0.952vw;
    height: 0.952vw;
  }
}
