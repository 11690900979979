.cuscarrier__reqrmnts_box {
  font-size: 12px;
  text-align: left;
  padding: 5px 10px;
  color: gray;
}

.email_space {
  margin-left: 5px;
  margin-right: 5px;
  text-decoration: none;
}
