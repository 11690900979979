.bundles__dispatcher__screen {
  padding: 20px 28px;
  margin: auto;
  max-width: 100%;
}

.bundles__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: grey;
  margin: 20px 5px 0px 5px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid lightgrey;
}
.bundles__hdrheading {
  font-weight: 400 !important;
  font-size: 22px;
}
.bundles__buttonsWrapper {
  display: flex;
  gap: 20px;
}
.bundles__hdraddprod {
  border-radius: 5px;
  box-shadow: 0.3px 0.3px 3px 1px rgb(230, 228, 228);
  display: flex;
  padding: 8px 18px;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
  color: grey;
  cursor: pointer;
}
.bundles__hdraddprod:hover {
  color: grey;
}
.bundles__hdraddprod > * {
  margin-right: 3px;
}
@media only screen and (min-width: 1190px) {
  .bundles__hdrheading{
    font-size: 1.611vw;
  }
  .bundles__hdraddprod{font-size: 1.025vw;padding: 0.439vw 0.586vw}
  .bundles__hdraddprod svg {width: 1.025vw; height: 1.025vw;}
}