.reset__password_screen {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.resetp__main_wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 20px;
  width: 100%;
}
.resetp__left_col {
  width: 100%;
  max-width: 560px;
}

.resetp__right_col {
  width: 100%;
  max-width: 480px;
}
input.resetp__screen_formfield_field:focus {
  outline: none;
}
.resetp__screen_logowrap {
  max-width: 360px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}
.resetp__screen_formwrap {
  max-width: 420px;
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}
.resetps__formwrap_title {
  font-size: 17px;
  margin-bottom: 15px;
  font-weight: 500;
  text-transform: capitalize;
}
.resetp__screen_form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.resetp__screen_formlabel {
  margin-top: 5px;
  font-size: 16px;
  /* color: #0c69f3;
  font-weight: 500; */
}
.resetp__screen_formfield {
  width: 100%;
  border-radius: 5px;
  padding: 0 13px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  outline: none;
  border: 1px solid #c7c7c7;
  height: 43px;
  background: #fff;
}
.resetp__screen_formfield_field {
  width: 100%;
  padding: 8px 10px;
}
.resetp__screen_formfield path {
  fill: #a5a5a5;
}
.resetp__screen_formbtn {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  background: #0c69f3;
  box-shadow: 0px 5px 15px rgb(12 105 243 / 35%);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  cursor: pointer;
}
.resetp__screen_formlink {
  margin-top: 15px;
  text-decoration: none;
}
.resetp__screen_formbtn{
  height: 40px;
}
.resetp__screen_formlink {
  color: #000;
  font-size: 14px;
}

.resetp__screen_formlink span {
  color: #0c69f3;
}

.resetp__screen_main_wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 20px;
  width: 100%;
}

.resetp__screen_left_col {
  width: 100%;
  max-width: 560px;
}

.resetp__screen_right_col {
  width: 100%;
  max-width: 480px;
}

.resetp__screen_formfield_lock {
  width: 20px;
  height: 18px;
}
.resetp__screen_formfield_code {
  width: 20px;
  height: 18px;
}
@media only screen and (max-width: 992px) {

.resetp__main_wrapper {
  width: 80%;
}

.resetps__formwrap_title {
  font-size: 17px;
  line-height: 1.4;
  margin-top: 40px;
}
.reset__password_screen {
  padding-top: 30px;
}
}