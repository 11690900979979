.admnadd__blog_modal {
  min-height: 250px;
  min-width: 310px;
  width: 750px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
}
.admnadd__blog_modalheading {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid lightgray;
  font-weight: 500;
  font-size: 18px;
}
.admnadd__blog_modalcontent {
  padding: 20px 0 10px 0;
}
.admnadd__blog_modalrow {
  display: flex;
}
.admnadd__blog_modalinputwrap {
  flex: 1;
  margin: 0 5px 10px 5px;
}
.admnadd__blog_modalinput {
  width: 100%;
  border: 0.5px solid lightgrey;
  outline: none;
  border-radius: 5px;
  min-height: 45px;
  padding: 3px 10px;
  transition: 0.3s;
}
.admnadd__blog_modallabel {
  width: 100px;
  height: 40px;
  margin: 0 5px 10px 5px;
  font-size: 17px;
  display: flex;
  padding: 2px;
  align-items: center;
}
.admnadd__blog_modalinput:focus {
  outline: 0.5px solid #0c68f373;
}
.admnadd__blog_modallabel2 {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
}
.admnadd__blog_modalfooter {
  width: 100%;
  padding: 10px;
  height: 60px;
  border-top: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.admnadd__blog_modalcncl {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #454545;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}
.admnadd__blog_modalsave {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c69f3;
  border: 1px solid #0c69f3;
  border-radius: 3px;
  margin-left: 10px;
  color: white;
  cursor: pointer;
}
.admnadd__blog_profimagelbl {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 0px 3px 3px rgba(219, 215, 215, 0.24);
    -webkit-box-shadow: 0px 0px 3px 3px rgba(219, 215, 215, 0.24);
    -moz-box-shadow: 0px 0px 3px 3px rgba(219, 215, 215, 0.24); */
  cursor: pointer;
  color: #454545;
  font-weight: 500;
  outline: 2px dashed lightgrey;
  margin-top: 5px;
}
.admnadd__blog_profimagelbl > * {
  margin-right: 5px;
}
.admnadd__blog_profimageinput {
  display: none;
}
