.prdtable__0rder_attachments_modal {
  min-height: 220px;
  min-width: 300px;
  /* width: fit-content; */
  width: 40vw;
  height: fit-content;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
  overflow-y: auto;
  display: inline-grid;
}

.prdtable__0rder_attachments_modal::-webkit-scrollbar {
  width: 5px;
}

.prdtable__0rder_attachments_modal::-webkit-scrollbar-thumb {
  background-color: grey;
}

.prdtable__0rder_attachments_modal::-webkit-scrollbar-track {
  background-color: #dbd9d9;
}

.prdtable__0rder_attachments_modalheading {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid lightgray;
  font-weight: 500;
  font-size: 18px;
}

.prdtable__0rder_attachments_modalcontent {
  padding: 10px;
}

.prdtable__0rder_attachments_modalfooter {
  width: 100%;
  padding: 10px;
  height: 60px;
  border-top: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.prdtable__0rder_attachments_modalcncl {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #454545;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}

.prdtable__0rder_attachments_modalimgs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.prdtable__0rder_attachments_modalimg {
  width: 150px;
  height: 120px;
  border-radius: 5px;
  object-fit: contain;
  margin: 5px;
  box-shadow: 0.5px 0.5px 3px 1px lightgrey;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 10px;
}

.prdtable__0rder_attachments_modaltopbar {
  position: relative;
}

.prdtable__0rder_attachments_modalclose {
  position: absolute;
  right: -8px;
  top: -8px;
  cursor: pointer;
  color: grey;
}

.prdtable__0rder_attachments_modalback {
  position: absolute;
  left: -8px;
  top: -8px;
  cursor: pointer;
  color: grey;
}

.prdtable__0rder_attachments_fullimgview {
  object-fit: contain;
  overflow: hidden;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 0.5px solid lightgrey;
}

.prod___0rder_attachments_es_modalfooter {
  width: 100%;
  padding: 10px;
  height: 60px;
  border-top: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.prod___0rder_attachments_es_modalfootercncl {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #454545;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}

.primary__image_wrap .prdtable__0rder_attachments_modalimg {
  border: 1px solid #000;
}

.primary__image_wrap .prdtable__0rder_attachments_modalimg svg {
  color: #fdd40c;
}

.prdtable__0rder_attachments_modalimg,
.prdtable__images_addnewbtn {
  height: 100px !important;
  max-width: 112px;
}

.uploaded_order__attachment_img {
  border-radius: 12px;
}

.imageSelection__title {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid lightgray;
  font-weight: 500;
  font-size: 18px;
}

.edit_table_attach_img_thumb_modal {
  text-align: center;
  font-size: 21px;
  line-height: 1;
}

.edit_table_attach_img_thumb_modal_wrap .prdtable__0rder_attachments_fullimgview {
  width: 400px;
  height: 400px;
  margin: 20px auto 0 auto;
}

.edit_table_attach_img_thumb_modal_wrap .prdtable__0rder_attachments_fullimgview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.prdtable__0rder_attachments_skeletonwrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.prdtable__0rder_attachments_modalfooter {
  width: 100%;
  padding: 10px;
  height: 60px;
  border-top: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.prdtable__0rder_attachments_modalfootercncl {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #454545;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}

.prdtable__0rder_attachments_modalfootersave {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c69f3;
  border: 1px solid #0c69f3;
  border-radius: 3px;
  margin-left: 10px;
  color: white;
  cursor: pointer;
}

.prdtable__0rder_attachments_addnewbtn {
  width: 100%;
  /*height: 120px; */
  box-shadow: 0.5px 0.5px 3px 1px lightgrey;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
}

.prdtable__0rder_attachments_addnewbtn:active {
  transform: translateY(-1px);
}

.prdtable__0rder_attachments_addnewbtn>img {
  width: 30px;
  height: auto;
  /* height: 60px;
  object-fit: contain; */
}

.prdtable__0rder_attachments_addnewbtn>div {
  font-size: 10px;
  font-weight: 500;
  margin-top: 2px;
}

.prdtable__0rder_attachments_addnewbtn {
  height: 100px !important;
  max-width: 112px;
}

.prdtable__0rder_attachments_modalimg {
  width: 150px;
  height: 120px;
  border-radius: 5px;
  object-fit: contain;
  margin: 5px;
  box-shadow: 0.5px 0.5px 3px 1px lightgrey;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 10px;
}

.prdtable__0rder_attachments_skeletonloader {
  height: 100px !important;
  width: 112px !important;
}

@media only screen and (max-width: 767px) {
  .prdtable__0rder_attachments_addnewbtn {
    min-width: 70px;
    max-width: 70px;
    height: 70px !important;
  }

  .prdtable__0rder_attachments_addnewbtn>div {
    font-size: 8px;
    font-weight: normal;
  }

  .prdtable__0rder_attachments_skeletonloader {
    height: 70px !important;
    width: 70px !important;
  }
}

@media only screen and (max-width: 992px) {
  .edit_table_attach_img_thumb_modal_wrap .prdtable__0rder_attachments_fullimgview {
    width: 100%;
    height: auto;
    margin: 20px auto 0 auto;
  }

  .prdtable__0rder_attachments_modal.edit_table_attach_img_thumb_modal_wrap {
    max-height: 90vh;
  }
}

@media only screen and (min-width: 1190px) {
  .prdtable__0rder_attachments_modal {
    min-height: 16.105vw;
    min-width: 16.105vw;
    padding: 1.464vw;
    max-height: 90vh;
  }

  .prdtable__0rder_attachments_modalimg,
  .prdtable__images_addnewbtn {
    /* height: 7.321vw !important; */
    /* max-width: 8.199vw; */
    max-width: 112px;
    width: 10.981vw;
    margin: 0.366vw;
  }

  .edit_table_attach_img_thumb_modal {
    font-size: 1.537vw;
  }

  svg.prdtable__0rder_attachments_modalclose,
  .prdtable__0rder_attachments_modalback {
    width: 1.83vw;
    height: 1.83vw;
  }

  .editable__table tbody td .shipper__attachments_thumbnail_wrap img {
    max-width: 2.196vw;
    max-height: 1.3vw;
    width: 2.196vw;
    height: 2.196vw;
  }

  .edit_table_attach_img_thumb_modal_wrap .prdtable__0rder_attachments_fullimgview {
    min-width: 29.283vw;
    min-height: 29.283vw;
    margin: 1.464vw auto 0 auto;
    width: fit-content;
    height: fit-content;
  }

  .prdtable__images_modal.note__attachment_modal_container {
    padding: 1.464vw;
  }

  .prdtable__hist_modal.notes__attachments_modal_container .prdtable__hist_modalheading {
    font-size: 1.318vw;
    height: fit-content;
  }
}