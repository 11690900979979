.custom__autocomplete_wrap {
  width: 100%;
  position: relative;
}
.custom__autocomplete_list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 100;
  box-shadow: 0 0 2px 3px rgb(230, 230, 230);
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 50px;
}
.custom__autocomplete_listitem {
  padding: 5px;
  cursor: pointer;
}
.custom__autocomplete_listitem:hover {
  background-color: rgba(211, 211, 211, 0.39);
}
.custom__autocomplete_list::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.custom__autocomplete_list::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.custom__autocomplete_list::-webkit-scrollbar-thumb {
  background: #d2d2d2;
}
