.aduser__details_modal {
  width: 1200px;
  padding: 30px;
  position: relative;
}
.aduser__details_heading {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid lightgrey;
  display: flex;
  color: rgb(189, 188, 188);
}
.aduser__details_heading > h2 {
  padding-left: 5px;
}
.aduser__details_modalrow {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.aduser__details_modalrowitem {
  display: grid;
  grid-template-columns: 200px 1.2fr;
  min-width: 50%;
}
.aduser__details_modalcol {
  padding: 0 15px;
}
.aduser__details_modalcolbold {
  font-weight: bold;
}
.aduser__details_modalclose {
  position: absolute;
  top: 0px;
  right: 0px;
}
.aduser__details_comboxlist {
  padding: 10px 0;
}
/* .aduser__details_comboxlistcontent {
    max-height: 150px;
    padding: 10px 0;
    overflow: auto;
  }
  .aduser__details_comboxlistcontent::-webkit-scrollbar {
    width: 2px;
  }
  
  .aduser__details_comboxlistcontent::-webkit-scrollbar-thumb {
    background-color: #0c69f3;
  }
  .aduser__details_comboxlistcontent::-webkit-scrollbar-track {
    background-color: #dbd9d9;
  } */
.aduser__details_comboxlistitembold {
  font-weight: 600;
  color: black !important;
}
.aduser__details_comboxlistitem {
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid rgb(231, 230, 230);
  padding: 3px 2px;
  color: grey;
}
.aduser__details_comboxlileft {
  width: 250px;
  font-size: 20px;
}
.aduser__details_comboxliright {
  width: 150px;
  font-size: 20px;
}
.aduser__details_comboxlirsrvcs {
  font-size: 20px;
  width: 220px;
}
.aduser__details_comboxheading {
  color: rgb(189, 188, 188);
  margin-top: 20px;
}

@media (max-width: 768px) {
  .aduser__details_modal_wrap {
    max-width: 95%;
    padding: 15px 0 !important;
  }
  .aduser__details_modal_wrap .aduser__details_modalrowitem {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
  }

  .aduser__details_modal_wrap .aduser__details_modalcolbold {
    width: 100%;
  }

  .aduser__details_modal_wrap .aduser__details_modalcol {
    width: 100%;
    padding: 0;
  }

  .aduser__details_modal_wrap .aduser__details_modalrow {
    align-items: flex-start;
  }
  .aduser__details_modal_wrap .aduser__details_comboxlist {
    width: 100%;
    overflow: auto;
  }

  .aduser__details_modal_wrap .aduser__details_companiesbox {
    width: 100%;
  }

  .aduser__details_modal_wrap
    .aduser__details_comboxlistitem.aduser__details_comboxlistitembold {
    width: 500px;
  }

  .aduser__details_modal_wrap .aduser__details_comboxlistcontent {
    width: 500px;
  }
}
