.navbar__todays_date {
  box-shadow: 0px 0px 0px 0px rgba(207, 202, 202, 0.75);
  -webkit-box-shadow: 0px 0px 0px 0px rgba(207, 202, 202, 0.75);
  -moz-box-shadow: 0px 0px 0px 0px rgba(207, 202, 202, 0.75);
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 3px;
  overflow: hidden;
  height: 40px;
  position: relative;
  bottom: 5px;
}

.navbar__todays_date .day {
  font-size: 28px;
  color: white;
  background-color: #0c6efd;
  padding: 0 10px;
}

.navbar__todays_date .month {
  font-weight: bold;
  color: #0c6efd;
  font-size: 13px;
}
.navbar__todays_date .year {
  font-size: 13px;
  margin-top: 1px;
}
.navbar__todays_dateright {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(218, 217, 217, 0.705);
  flex: 1;
  height: 100%;
  padding: 0 20px 0 10px;
}
.navbar__todays_dateright * {
  line-height: 15px;
}
.cnavbar__navitem_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 58px;
}
.navbar__price_badge {
  width: 150px;
  height: 55px;
  position: relative;
  top: -10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 15px;
  overflow: hidden;
}
.navbar__price_badge > .navbar__price_badgeimgwrap {
  height: 70%;
  position: relative;
  z-index: 20;
}
.navbar__price_badgeimgwrap > img {
  object-fit: contain;
  height: 100%;
}
.navbar__price_badgebalance {
  width: fit-content;
  display: flex;
  align-items: center;
  height: 125%;
  width: 140px;
  padding: 0 5px;
  background-color: white;
  color: #0c6efd;
  position: absolute;
  right: 0;
  top: -5px;
  transform: translateX(67%);
  transition: 0.5s ease;
  z-index: 18;
  cursor: pointer;
}
.navbar__price_badgebalance > img {
  height: 80%;
  object-fit: contain;
  margin-right: 2px;
}
.navbar__price_badgeimgwrap:hover .navbar__price_badgebalance {
  transform: translateX(0);
}
.navbar__price_badgebalanceamnt {
  border-left: 0;
  height: 90%;
  flex: 1;
  display: flex;
  align-items: center;
  border: 3px solid #0c6efd;
  border-left: 0;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 5px;
  color: #454545;
  background-color: white;
  margin-left: 2px;
  font-weight: 600;
  padding: 0 5px;
  font-size: 18px;
}
.navbar__userprofile_section {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.navbar__userprofile_sectiondrop {
  color: #0c6efd;
  margin: 0 5px;
  font-size: 11px;
}
.navbar__link_wrap {
  position: relative;
  font-size: 8px ;
  text-transform: uppercase;
  color: grey !important;
}
.navbar__link_wrap:hover{
  color: #1976d2 !important;
}
.navbar__item_badge {
  position: absolute;
  padding: 2px;
  min-width: 20px;
  border-radius: 50%;
  font-size: 10px;
  color: white;
  background-color: #0d6efd;
  box-shadow: 1px 1px 3px 1px #0d6efd73;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0px;
  /* top: 5px; */
  font-weight: 500;
}
.navbar__userprofile_avatar {
  background-color: white !important;
  color: #0c6efd !important;
  font-size: 18px !important;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  letter-spacing: 1px;
  font-weight: 500;
  position: relative;
}

.navbar__userprofile_avatarbadge {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0px 0px 5px 0px rgba(212, 207, 207, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(212, 207, 207, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(212, 207, 207, 0.75);
  border-radius: 50%;
}
.navbar-brand img {
  max-width: 300px;
}

/*custom css*/
.header__navigation_main_list {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  column-gap: 0.6vw;
}

.header__navigation_main_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__main_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px 0 20px;
}

.header__dataUser-col_wrap {
  width: 100%;
  max-width: 180px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header__navigation_main_wrap {
  width: 58%;
}

.header__logo_wrap {
  width: 100%;
  max-width: 300px;
}

.date__badge_component {
  width: fit-content;
  height: auto;
  background: transparent;
  display: flex;
  align-items: flex-start;
  position: unset;
}

.date__badge_componenthiconlft,.date__badge_componenthiconrt {
  background: #fff;
  border: 1px solid #808080;
  display: none;
}

.date__badge_componentheader {
  background: transparent;
  color: #808080;
  align-items: inherit;
  height: auto;
  width: auto;
  border: 1px solid;
  border-radius: 0;
  padding: 2px 5px;
}

.date__badge_componentbody {
  font-size: 12px;
  display: inline-block;
  min-width: 22px;
  line-height: 1;
  border: 1px solid #0c6efd;
  border-radius: 0;
  padding: 2px 5px;
  color: #fff;
  background: #0c6efd;
  text-align: center;
}

.date__badge_componenthtxt {
  font-size: 12px;
  line-height: 1;
}

.navbar__userprofile_section .navbar__userprofile_avatar {
  width: fit-content;
  height: auto;
}

.header__datebadge_col {
  display: flex;
  padding-right: 9px;
}
.header__navigation_main_list .active {
  border-bottom: 5px solid;
  border-top-left-radius: 100px;
  color: #1976d2!important;
  font-weight: 700;
}


.profile__avatar_image {  
  width: 35px;
  height: 35px;
  border: 1px solid;
  border-radius: 100%;
  padding: 3px;
}


.navbar__userprofile_avatar .sb-avatar.sb-avatar--src {
  width: 35px !important;
  height: 35px !important;
  border: 1px solid;
  border-radius: 50px;
  overflow: hidden;
}

.navbar__userprofile_avatar .sb-avatar.sb-avatar--src img.sb-avatar__image {
  width: 100% !important;
  height: auto !important;
}


.header__navigation_main_wrap .nav-link svg.header__nav_btn_icon {
  width: 30px;
  height: 20px;
}
@media only screen and (min-width: 1190px) {
  .header__navigation_main_wrap .nav-link svg.header__nav_btn_icon {
    width: 1.89vw;
    height: 1.3vw;
}
.header__navigation_main_list a.nav-link.navbar__link_wrap {
    font-size: 0.586vw;
    text-align: center;
}
.header__logo_wrap,.navbar-brand img{
  max-width: 13.962vw;
}
.header__main_wrap{padding: 1.098vw 1.464vw 0  1.464vw;}
.header__dataUser-col_wrap * {
  font-size: 0.678vw;
}
.header__dataUser-col_wrap .profile__avatar_image {
  width: 2.2vw;
  height: 2.2vw;
}
.header__dataUser-col_wrap {
  max-width: 13.177vw;
}
.navbar__item_badge {
  padding: 0.054vw;
  min-width: 0.864vw;
  font-size: 0.6vw;
}
.header__datebadge_col{
  padding-right: 0.659vw;
}
.date__badge_componentheader,.date__badge_componentbody{
  padding: 0.146vw 0.366vw;
}
}

@media only screen and (min-width: 992px) and (max-width: 1740px) {
  .header__navigation_main_wrap .nav-link {
  font-size: 10px !important;
}
.header__navigation_main_wrap .nav-link .navbar__item_badge {
  min-width: 13px;
  height: auto;
  line-height: 1.5;
  font-size: 6px;
  top: 0 !important;
  padding: 2px;
}

.header__dataUser-col_wrap {
  max-width: fit-content;
}

.date__badge_component {
  width: fit-content;
  max-width: 100%;
}
.header__logo_wrap {
  max-width: fit-content;
}

.header__logo_wrap img {
  max-width: 15vw;
}

.header__navigation_main_wrap {
  width: 64%;
}
}


@media only screen and  (max-width: 992px) {

.headernav__navicon_btn {
  width: 35px;
  height: 30px;
  border: 1px solid #b1aeae;
  border-radius: 3px;
  padding: 6px 4px;
}

.headernav__navicon_btn span {
  width: 100%;
  display: block;
  height: 2px;
  margin-bottom: 5px;
  background: #000;
}
.header__navigation_main_wrap .header__navigation_main_list{
  right: -3000px;
  transition: ease all 0.5s;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 300px;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  z-index: 6;
  background: #fff;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 40px 24px 24px 24px;  
  transition: ease all 0.5s;
}
.header__main_wrap {
  flex-wrap: wrap;
}

.header__navigation_main_wrap {
  width: fit-content;
}

.header__logo_wrap {
  max-width: 100%;
  display: inline-flex;
  justify-content: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 15px;
}
.header__logo_wrap img{max-width: 240px;}
.MuiBox-root.css-1sptd97 {
  margin-top: 15px;
}
.header__navigation_main_wrap.navigation__mobile_open .header__navigation_main_list {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 300px;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  z-index: 6;
  background: #fff;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 40px 24px 24px 24px;
  transition: ease all 0.5s;
  z-index: 9999;
}

.header__navigation_main_wrap.navigation__mobile_open .header__navigation_main_list li {
  width: 100%;
  margin-bottom: 15px;
}

.header__navigation_main_wrap.navigation__mobile_open .header__navigation_main_list li .nav-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.header__navigation_main_wrap.navigation__mobile_open .header__navigation_main_list li .nav-link svg.m-auto {
  margin: 0 !important;
  margin-right: 10px !important;
}

.header__mobile_overlay {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  background: #000000a3;
  z-index: 6;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
}

.header__navigation_main_wrap.navigation__mobile_open .header__navigation_main_list li .nav-link .navbar__item_badge {
  position: absolute;
  right: 0 !important;
}

.headernav__btn_cross {
  position: fixed;
  top: 5px;
  right: 10px;
  width: 25px;
  height: 25px;
  z-index: 7;
  display: block;    
  transition: ease all 3s;
  z-index: 99999;
}

.headernav__btn_cross span:first-child {
  display: block;
  width: 20px;
  height: 2px;
  background: #000;
  transform: rotate(-45deg);
  position: relative;
  top: 2px;
}

.headernav__btn_cross span:last-child {
  display: block;
  width: 20px;
  height: 2px;
  background: #000;
  transform: rotate(45deg);
}


.css-1vyamtt-MuiStepLabel-labelContainer {
  position: relative;
}

.MuiStepLabel-horizontal:hover .MuiStepLabel-label{
  display: block !important;
    position: absolute;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0;
    font-size: 11px;
    min-width: 75px;
    text-align: center !important;
    left: -15px;
}

}

@media only screen and  (max-width: 767px) {
.content__stepper_main>span>span {
  display: none;
  position: fixed;
  top: 0;
  width: 100%;
  text-align: center !important;
}
}
/*styling for ticket nav*/
/* .navbar__item_badge.ticket__badge {
  right: 11px;
  top: -8px;
} */