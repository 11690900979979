.transfer__request_modalfooter {
  width: 100%;
  padding: 10px;
  height: 60px;
  border-top: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}

.transfer__request_modalreset {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #454545;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  width: 70px;
  height: 35px;
  font-size: 13px;
}

.transfer__request_statuschange_section {
  border: 1px solid #d9d9d9;
  padding: 16px 16px 5px 16px;
  margin-bottom: 15px;
}
