.admnadd__balance_modal {
  min-height: 250px;
  max-width: 550px;
  width: 90%;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
}
.admnadd__balance_modalheading {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid lightgray;
  font-weight: 500;
  font-size: 18px;
}
.admnadd__balance_modalcontent {
  padding: 30px 0 20px 0;
}
.admnadd__balance_modalrow {
  display: flex;
}
.admnadd__balance_modalinputwrap {
  flex: 1;
  margin: 0 5px 10px 5px;
}
.admnadd__balance_modalinput {
  width: 100%;
  border: 0.5px solid lightgrey;
  outline: none;
  border-radius: 5px;
  height: 50px;
  padding: 0 10px;
  transition: 0.3s;
}
.admnadd__balance_modallabel {
  width: 100px;
  height: 40px;
  margin: 0 5px 10px 5px;
  font-size: 17px;
  display: flex;
  padding: 2px;
  align-items: center;
}
.admnadd__balance_modalinput:focus {
  outline: 0.5px solid #0c68f373;
}
.admnadd__balance_modallabel2 {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
}
.admnadd__balance_modalfooter {
  width: 100%;
  padding: 10px;
  padding-bottom: 0;
  height: 60px;
  border-top: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.admnadd__balance_modalcncl {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #454545;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}
.admnadd__balance_modalsave {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0c69f3;
  border: 1px solid #0c69f3;
  border-radius: 3px;
  margin-left: 10px;
  color: white;
  cursor: pointer;
}
.admnadd__balance_modallbl {
  margin-bottom: 5px;
  margin-left: 2px;
  font-size: 16px !important;
  color: #454545 !important ;
}
