.stock_received_section_screen {
  width: 100%;
  padding: 40px 5%;
}
.stock_received_accept_reject_input {
  height: 25px;
  font-size: 12px !important;
  width: 140px;
}
.stock_received_footer_buttons {
  align-items: center;
  border-top: 0.5px solid #d3d3d3;
  display: flex;
  height: 60px;
  justify-content: flex-end;
  margin-top: 20px;
  padding: 10px;
  width: 100%;
}
.stockRcieved__genral_stock_id_text {
  font-size: 13px;
  font-weight: 600;
}

.stockRcieved__genral_stock_id_num {
  font-size: 11px;
}
.stockRcieved__genral_destination_wrap_text {
  font-size: 13px;
  font-weight: 600;
}

.stockRcieved__genral_destination_wrap_value {
  font-size: 11px;
}

.stockRcieved__genral_supplier_title {
  font-size: 13px;
  font-weight: 600;
}

.stockRcieved__genral_supplier_value {
  font-size: 11px;
}
.stockRcieved__genral_shipment_details_heading {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 5px;
}

.stockRcieved__genral_shipment_details_title {
  font-size: 12px;
  font-weight: 500;
}

.stockRcieved__genral_shipment_details_content {
  font-size: 11px;
}
.add__adj_byform_col.stockRcieved__genral_details_wrap_destination,.add__adj_byform_col.stockRcieved__genral_details_wrap_supplier {
  margin-top: 13px;
}
.stock_received_section_screen.warehouse__user_active  td.stockreciveform__detail_table_tableitem.BsTrash_td {
  text-align: center;
  justify-content: center;
}
.stock_received_section_screen.warehouse__user_active .editable__table_container {
  height: 450px;
}
@media only screen and (max-width: 991px) {

.stockreciveform__grndetails_body_wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: fit-content;
  justify-content: space-between;
  padding-bottom: 5px;
  margin: 0 !important;
}

.stockreciveform__grndetails_body_wrap .stockreciveform__detail_table_tableitem {
  display: none;
}

.stockreciveform__grndetails_body_wrap .stockreciveform__detail_table_tableitem.stockreciveform__shown_mobile {
display: inline-flex;
}

td.stockreciveform__detail_table_tableitem.stockreciveform__shown_mobile.stockreciveform__stock_sku {
  order: 4;
}

thead.stockreciveform__grndetails_body_header {
  display: none;
}


.stockreciveform__tableitem.stockreciveform__shown_mobile.stockreciveform__stock_status .MuiLinearProgress-root {
  width: 100% !important;
  min-width: 75px;
}

td.stockreciveform__detail_table_tableitem.font_size.stockreciveform__shown_mobile.stockreciveform__stock_quantity {
  width: fit-content;
}

.stockreciveform__grndetails_body_wrap {
  border-bottom: 1px solid #eaeaea;
}
.stockreciveform__grndetails_body_wrap:last-child {
  border-bottom: none;
}
.stockreciveform__grndetails_body_wrap {
  width: 100%;
}
tr.stockreciveform__grndetails_body_wrap {
  position: relative;
  padding-left: 30px;
}

td.stockreciveform__detail_table_tableitem.stockreciveform__shown_mobile.stockreciveform__img_wrap {
  position: absolute;
  left: -7px;
  top: 5px;
  max-width: 45px;
}

td.stockreciveform__detail_table_tableitem.font_size.stockreciveform__shown_mobile.stockreciveform__name_wrap {
  font-weight: 700;
  width: 80%;
  text-align: left;
  font-size: 13px;
}

td.stockreciveform__detail_table_tableitem.font_size.stockreciveform__shown_mobile.stockreciveform__sku_wrap {width: 70%;text-align: left;}

td.stockreciveform__detail_table_tableitem.font_size.stockreciveform__shown_mobile.stockreciveform__sku_wrap:before {
  content: 'SKU:';
  font-weight: 600;
  padding-right: 5px;
  min-width: 30px;
}

td.stockreciveform__detail_table_tableitem.stockreciveform__shown_mobile.stockreciveform__img_wrap img.product__table_mainimg {
  width: 100%;
  height: auto;
}

td.stockreciveform__detail_table_tableitem.font_size.stockreciveform__shown_mobile.stockreciveform__qty_wrap {
  width: 25%;
  display: inline-flex;
  justify-content: flex-end;
}

td.stockreciveform__detail_table_tableitem.font_size.stockreciveform__shown_mobile.stockreciveform__qty_wrap:before {
  content: 'QTY:';
  font-weight: 600;
  padding-right: 5px;
  min-width: 32px;
}

td.stockreciveform__detail_table_tableitem.font_size.stockreciveform__shown_mobile.stockreciveform__w_notes_wrap {
  width: 100%;
}

td.stockreciveform__detail_table_tableitem.font_size.stockreciveform__shown_mobile.stockreciveform__w_notes_wrap input.stock_received_accept_reject_input.form-control {
  width: 100% !important;
}

td.stockreciveform__detail_table_tableitem.font_size.stockreciveform__shown_mobile.stockreciveform__shipper_wrap:before {
  content: 'Shipper Notes:';
  font-weight: 600;
  padding-right: 5px;
  min-width: fit-content;
}

td.stockreciveform__detail_table_tableitem.font_size.stockreciveform__shown_mobile.stockreciveform__shipper_wrap{
  text-align: left;
}
td.stockreciveform__detail_table_tableitem.font_size.stockreciveform__shown_mobile.stockreciveform__status_wrap span.MuiLinearProgress-root.MuiLinearProgress-colorPrimary {
  width: 100% !important;
  min-width: 60px;
}
.stockreciveform__cost_summary_card {
  margin-top: 10px;
}
.notes__attachments_modal_attachment_thumbnail{flex-wrap: wrap;        row-gap: 10px;}
.attachments__formsec_imagebtns .attachments__formsec_uploadimagebtn{
  margin-right: 0;
  width: 100%;
  max-width: 250px;
  font-size: 12px;
  padding: 0 10px;
}
.attachments__formsection_mdbtmtext{text-align: center;}

td.stockreciveform__detail_table_tableitem.font_size.stockreciveform__shown_mobile.stockreciveform__name_wrap {
  order: 1;
}

td.stockreciveform__detail_table_tableitem.font_size.stockreciveform__shown_mobile.stockreciveform__sku_wrap {
  order: 2;
}
td.stockreciveform__detail_table_tableitem.font_size.stockreciveform__shown_mobile.stockreciveform__qty_wrap {
  order: 3;
}
.stockreciveform__tableitem.font_size.stockreciveform__shown_mobile.stockreciveform__status_wrap {
  order: 4;
}

td.stockreciveform__detail_table_tableitem.font_size.stockreciveform__shown_mobile.stockreciveform__accept_wrap {
  order: 5;
}


td.stockreciveform__detail_table_tableitem.font_size.stockreciveform__shown_mobile.stockreciveform__w_notes_wrap {
  order: 6;
}

td.stockreciveform__detail_table_tableitem.font_size.stockreciveform__shown_mobile.stockreciveform__shipper_wrap {
  order: 7;
}

.stockreciveform__grndetails_body_wrap .stockreciveform__tableitem.stockreciveform__shown_mobile:nth-child(10) input.stock_received_accept_reject_input.form-control {
  width: 100% !important;
}
.stockreciveform__table_wrapper.shipper__grn_wrap_has_shipper td.stockreciveform__detail_table_tableitem.font_size.stockreciveform__shown_mobile.stockreciveform__qty_wrap{
  order: 4;
  width: 100% !important;
  max-width: 150px !important;
  align-items: center;
}
.proddetails__desc_modal.detail__modal_order_view .stockreciveform__table_wrapper.shipper__grn_wrap_has_shipper td.stockreciveform__detail_table_tableitem.font_size.stockreciveform__shown_mobile.stockreciveform__qty_wrap{
  order: 3;
  width: fit-content !important;
  max-width: 150px !important;
  align-items: center;
}
.proddetails__desc_modal.detail__modal_order_view .stockreciveform__table_wrapper.shipper__grn_wrap_has_shipper .stockreciveform__tableitem.font_size.stockreciveform__shown_mobile.stockreciveform__status_wrap {
  order: 4;
}

.stockreciveform__table_wrapper.shipper__grn_wrap_has_shipper .stockreciveform__tableitem.font_size.stockreciveform__shown_mobile.stockreciveform__status_wrap {
  order: 3;
}

.stockreciveform__table_wrapper.shipper__grn_wrap_has_shipper td.stockreciveform__detail_table_tableitem.font_size.stockreciveform__shown_mobile:nth-child(10) {
  order: 10;
  width: 100% !important;
}
.stockreciveform__table_wrapper.shipper__grn_wrap_has_shipper td.stockreciveform__detail_table_tableitem.BsTrash_td {
   order: 1;
}

.stockreciveform__table_wrapper.shipper__grn_wrap_has_shipper td.stockreciveform__detail_table_tableitem.BsTrash_td svg.p-2.overlaybtn {
  border: none;
  box-shadow: none !important;
  padding: 2px !important;
  width: 19px;
  height: auto;
}

.stockreciveform__table_wrapper.shipper__grn_wrap_has_shipper td.stockreciveform__detail_table_tableitem.BsTrash_td a {
  box-shadow: none !important;
}

.stock_received_section_screen.warehouse__user_active td.stockreciveform__detail_table_tableitem.font_size.stockreciveform__shown_mobile.stockreciveform__status_wrap {
  order: 3;
}
.stock_received_section_screen.warehouse__user_not_active td.stockreciveform__detail_table_tableitem.font_size.stockreciveform__shown_mobile.stockreciveform__status_wrap {
  order: 3;
}
.stock_received_section_screen.warehouse__user_active td.stockreciveform__detail_table_tableitem.font_size.stockreciveform__shown_mobile.stockreciveform__sku_wrap {
    width: 100%;
}
}

@media only screen and (max-width: 600px) {
.add__adj_byform_col.stockRcieved__genral_details_wrap_destination *, .add__adj_byform_col.stockRcieved__genral_details_wrap_supplier *{
  font-size: 9px;
}
.add__adj_byform_col.stockRcieved__genral_details_wrap_destination svg, .add__adj_byform_col.stockRcieved__genral_details_wrap_supplier svg{
  font-size: 20px;
}
.sss__payment_section .sss__prodsection_heading{
  margin: 0;
  font-size: 15px;
}
.sss__prodsection_heading.mt-2{
  margin: 0;
  font-size: 15px;
}



td.stockreciveform__detail_table_tableitem.stockreciveform__shown_mobile.stockreciveform__img_wrap img.product__table_mainimg {
  width: 25px;
  height: 25px;
  object-fit: cover;
  border-radius: 0;
}
.stock_received_section_screen.warehouse__user_active .editable__table_container,.stock_received_section_screen.warehouse__user_not_active .editable__table_container{
  height: 350px;
}
}