.prod__location_modal {
  min-height: 250px;
  max-width: 610px;
  width: 90%;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
}
.prod__location_modalheading {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
}
.prod__location__sub_heading {
  display: flex;
  justify-content: center;
  padding: 0;
  font-size: 12px;
  font-weight: 200;
}

.prod__location_desc_modalfooter {
  width: 100%;
  padding: 10px;
  height: 60px;
  border-top: 0.5px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.prod__location_modalcncl {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: #454545;
  background: #d9d9d9;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}

.product_location_box_wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 5px;
}

.product_location_box_active {
  width: 100%;
  padding: 0px 10px;
}

.product_location_box_line {
  border-top: 0.5px solid lightgray;
  margin-bottom: 5px;
}

.product_location_box_modalsave {
  width: 90px;
  height: 40px;
  font-size: 15px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: #0c69f3;
  border: 1px solid #0c69f3;
  border-radius: 3px;
  margin-left: 10px;
  color: white;
  cursor: pointer;
}

.product_location_box__input {
  height: 100%;
  font-size: 12px !important;
}

@media only screen and (max-width: 767px) {
  .prod__location_modal {
    max-height: 90vh;
    overflow: auto;
  }
  .prod__location_modalheading {
    font-size: 17px;
    text-align: center;
    border: none;
  }
  .prod__location_box_label {
    min-width: 80px;
  }
}
