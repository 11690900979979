.date__badge_component {
  border-radius: 5px;
  width: 50px;
  height: 50px;
  background-color: rgb(225, 232, 247);
  position: relative;
  top: -10px;
}
.date__badge_componentheader {
  width: 100%;
  height: 40%;
  background-color: #0c6efd;
  color: white;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.date__badge_componentbody {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0c6efd;
  font-size: 20px;
  font-weight: 500;
}
.date__badge_componenthiconlft {
  width: 5px;
  height: 10px;
  border-radius: 5px;
  background-color: rgb(225, 232, 247);
  position: absolute;
  top: -6px;
  left: 20%;
}
.date__badge_componenthiconrt {
  width: 5px;
  height: 10px;
  border-radius: 5px;
  background-color: rgb(225, 232, 247);
  position: absolute;
  top: -6px;
  right: 20%;
}
