.transhistory__screen_wrapper {
  padding: 20px 28px;
  margin: auto;
  max-width: 100%;
}

.transtable__invoice_btn {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  box-shadow: 2px 2px 3px 2px rgba(172, 171, 171, 0.75);
  -webkit-box-shadow: 2px 2px 3px 2px rgba(172, 171, 171, 0.75);
  -moz-box-shadow: 2px 2px 3px 2px rgba(172, 171, 171, 0.75);
  font-size: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.transtable__invoice_btn > img {
  width: 16px;
}
.transtable__invoice_btndisabled {
  pointer-events: none;
  opacity: 0.4;
}
.transhistory__paymentamnt_data {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
.transhistory__description {
  word-wrap: break-word !important;
  align-items: flex-start !important;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  max-height: 68px;
  overflow-wrap: anywhere !important;
  padding: 10px 1px !important;
  overflow: auto !important;
}
.transhistory__description:hover {
  overflow: auto !important;
}
