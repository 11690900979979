.image__modal_wrap {
  min-width: 600px;
  max-width: 95%;
  max-height: 800px;
  position: relative;
}
.image__modal_wrap > img {
  min-width: inherit;
  max-height: inherit;
  object-fit: contain;
}
.image__modalclose_btn {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #0c6efd;
  background-color: white;
  padding: 3px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
  transition: 0.2s;
}
.image__modalclose_btn:hover {
  transform: scale(1.2);
}
@media (max-width: 600px) {
  .image__modal_wrap {
    min-width: 95%;
  }
}

@media only screen and (min-width: 1190px) {

.image__modal_wrap.payments_pending_transaction_list {
  padding: 40px 50px 40px 50px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.image__modal_wrap.payments_pending_transaction_list img {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  min-height: 100%;
  border-radius: 3px;
  border: 1px solid #ededed;
}

}