.currencyStock__add_new_currency_modal_wrap {
  min-height: 250px;
  min-width: 310px;
  width: 550px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
}

/* button.currencyStock__add_new_currency_create_btn {
  border-radius: 5px;
  box-shadow: 0.3px 0.3px 3px 1px rgb(230, 228, 228);
  display: flex;
  padding: 8px 18px;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  color: grey !important;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-bottom: 15px;
  margin-top: 15px;
  column-gap: 5px;
  cursor: pointer !important;
} */

.currencyStock__add_new_currency .currencyStock__add_new_currency_title {
  font-size: 15px;
  margin-bottom: 0;
  color: #454545;
  font-weight: bold;
  text-transform: uppercase;
}

.currencyStock__add_new_currency .currencyStock__add_new_currency_code {
  width: 49%;
  margin-bottom: 20px;
}

.currencyStock__add_new_currency .currencyStock__add_new_currency_name {
  width: 49%;
  margin-bottom: 20px;
}

.currencyStock__add_new_currency .currencyStock__add_new_currency_rate {
  width: 49%;
  margin-bottom: 20px;
}

.currencyStock__add_new_currency .currencyStock__add_new_currency_symbol {
  width: 49%;
  margin-bottom: 20px;
}

/* .currencyStock__add_new_currency .currencyStock__add_new_currency_create_btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
  color: #fff !important;
  background: #1976d2;
  padding: 8px 10px;
  column-gap: 6px;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;
  margin-top: 0;
  margin-left: auto;
} */

.currencyStock__add_new_currency_header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #d2d2d2;
  margin-bottom: 30px;
  border-radius: 5px 5px 0 0;
  width: 100%;
}

.currencyStock__add_new_currency_body {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 2%;
}

@media only screen and (max-width: 768px) {
  .currencyStock__add_new_currency {
    order: 1;
    margin-bottom: 30px;
  }

  .currencyStock__table_list {
    order: 2;
  }
  .currencyStock__add_new_currency_modal_wrap {
    width: 90%;
    top: calc(50% - 185px);
  }
  .products__adpage_hdraddprod span {
    display: none;
  }
}
