.navbar-nav .nav-link.active {
    color: #1976D2 !important;
    font-weight: bold;
    /* text-decoration-line: underline ! important;
      text-decoration-thickness: 5px ! important;
      text-decoration-skip-ink: 1; */
    /* border-top: 3px solid; */
    border-bottom: 5px solid;
    border-top-left-radius: 100px;
    /* border-radius: 15px; */
    /* bottom: -10px; */
  }
  
  .navbar .navbar-nav .nav-link:hover {
    color: #1976D2 !important;
    /* font-weight: bold; */
  }
  
  .overlaybtninvoice {
    color: #000000;
  }
  
  .overlaybtninvoice:hover {
    color: #ffffff !important;
    background-color: #6d90c4 !important;
    border-color: #6d90c4 !important;
  }

  .overlaybtn {
    color: #000000;
  }
  
  .overlaybtn:hover {
    color: #ffffff !important;
    background-color: #1976D2 !important;
    border-color: #1976D2 !important;
  }
  
  .fade-in-form {
    flex: 1;
  }
  
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
  
  .btn-close {
    border: 2px solid #e2edfb !important;
    border-radius: 25% !important;
    width: 2px;
    color: #000000;
  }
  
  .btn-close:hover {
    color: #ffffff !important;
    background-color: #0c6efd !important;
  }
  
  .modal-dialog.modal-xl {
    max-width: 1293px !important;
    margin-top: 6rem;
  }
  
  .forget {
    color: #0c6efd;
  }
  
  .forget:hover {
    color: #000000;
    text-shadow: #edf0f3;
    cursor: pointer;
  }
  
  .signup {
    color: #0c6efd;
    text-decoration-line: none;
  }
  
  .signup:hover {
    color: #000000;
    text-shadow: #edf0f3;
    cursor: pointer;
  }
  
  input[type="time" i] {
    /* align-items: center;
      display: inline-flex;
      font-family: monospace; */
    /* padding-inline-start: 1px; */
    /* cursor: default;
      overflow: hidden; */
    /* padding: 0px; */
  }
  
  .o::-webkit-calendar-picker-indicator {
    filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg)
      brightness(95%) contrast(80%);
    /* width: 64px;
      height: 64px;
      background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAEr0lEQVR4nO2af2hVZRjHP88550I/zNrCiLaUpBYkJYYWNmtFUU3UchPJzGGJs1oRhRDDJkWaYLAERdugZG70hwyj1BY0Y6VWWCEUEqbVH1v900hI/8ndc57+uLvRLtvded9zzr2i5wOXcc7e9/k+93mf57nnPedASkpKSsqli5TbgZkv6fTsCFsR6oDrLc2cVej3Alp/2yUnTSaWNQA3Pae3InwNVMRiUPjLdZh9ersMhZ3ixSJsiSdsUaUC6HOUtb6DI0o3UCfQdWqXrA5j5+YXtZosnSj1BLQDy8P64Ni5Hg+uz4NeAIzQ/PO78vsvO2XQ8XnWC8ANeDSsndPbZegyYZ0XgBfwiIkPViUwa50uJuAVhHnAlTY2QqAnOsVogWY1qwKc6JTQ38u4BO5Yq2/h0wqAms42wnhx3MBcxCgAd67RxRrQCpwXaFOl5/j78oe5bHHmrFGr0LoWs4wC4ObSHhE2HntPtprLhXTKMrNs5pkGYC4CrkM3wN2rdaEoHQjV5tJFsEhlKEEJuDAFhaOjaZ9ROhSqE+4FoSlFCYzBCXIr/2V3+K4bhvtWWfaApDOgsMZsa9VUJ8l5kTLAJuI2OknOMwuAFj+OC1u7ifeAtATSEih+nGfDYT2KcE9Is0c2L5B7w9htXK6Po6xSUFW6P+yVj8LMK0YiGVDph/7yAAsm0wFY3qgt6rPjf6caly3Tlt5e2TmZP8Uw2m15OrbOCo/zVI6YfSbTWdmgta7yTv68B695imYCtq1s0NrJ/Cn6nUwGhy2Ba7NmToyjcxa46skGnRFkGRFlrwuZ/P979snmp5bqNcB6YO8TS3Se45Fxcv78baKVTAlEDYDPAYQVLvyAAAFTgQHg/vyYoTO0zria9cANrvATfm77rHDARCuRDKjMcoRxansCDheeuMKn5R+XCsjdFVL4zHdZ4fkM58cMDEj2mSX/OTB19G9f4NASUhcwvQ4Iih/nuW3p2K5uyq6Dcgaof7pep0mGzO6Pc5uv5kVjI57XH/G5LuOhnftluNDWZFyQV4J5dvfJn2H0OwvGmZBIAF5YaLab2/FJuN1kEguQSAlMdD4qYfWNbJoMDrsC2z6N9/6Aqb4JF+ReoJT6iWRA60N2d3QK2dI/NpPKngFpDwi5Am9+frH2gBJfB5RCP22CRoNLdEuslPppBhg5cKn3gHFScAio3jE/nt/9SVFAGJzIHxuiPRpTmkXpAG6M7kooBsWheSJ/bIhUAs9/I33A9Ohu2FH2n8Fy0jVfq7Lj3FA1JVIPKCd6nqY4XnGLVALloGu2VrkeTSivx2HPqgQ+mKN1orwKzAWmxeGIEX58puwyQBmIzwVjhoHvRXlbhf6oxmy3w+cF2hyPnseOx/+WWFj23R69Jq0yQISNS35M7i2xsJRtO+z57IkuHZ04AmD2cDTgnBeAE5T/Nfv9NVo1+m6w0bPAQowC4CrfugoiNEURjYPLHZrc3NPg76LYMWuCPu0qPAC8cahG8YU9D58sbRM8VKNVAk0Eo9cBSnsUe8ap/MUtugnYEEU0RjbVnZK2KAasavmrmbpIhZeBu4ApURyw4BxwDKW99lc5WGLtlJSUlJSLin8BxSm4QhC/BVoAAAAASUVORK5CYII=");
     */
  }
  
  .o::-webkit-calendar-picker-indicator:hover {
    cursor: pointer;
  }
  
  .c::-webkit-calendar-picker-indicator {
    filter: invert(18%) sepia(93%) saturate(5628%) hue-rotate(359deg)
      brightness(97%) contrast(123%);
  }
  
  .c::-webkit-calendar-picker-indicator:hover {
    cursor: pointer;
  }
  
  .marketplace:hover {
    cursor: pointer;
    border: 3px solid #0c6efd !important;
    transform: translateY(1px);
    transition: 0.5s;
  }
  
  .navigation:hover {
    cursor: pointer;
    color: #0c6efd;
    transform: translateY(1px);
    transition: 0.5s;
  }

.check_button_true {
  color: #0c6efd;
}

.check_button_false {
  color: #ffffff;
}


.horiscl::-webkit-scrollbar {
  height: 10px !important;
}

/* Track */
.horiscl::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 2px;
}
 
/* Handle */
.horiscl::-webkit-scrollbar-thumb {
  background: #C8C9CA; 
  border-radius: 2px;
}

/* Handle on hover */
.horiscl::-webkit-scrollbar-thumb:hover {
  background: #C8C9CA; 
}

.single_list:hover {
  border: 1px solid #1976D2 !important;
}

.kgEPYv:hover {
  background-color: #ECF4FF !important;
  border: none !important;
}

.kgEPYv {
  border: 1px solid #e5e7eb !important;
  margin-top: 1rem;
}

.gZWIKX {
  border-bottom-color: #FFFFFF !important;
}

.jxflYm {
  border-top-color: #FFFFFF !important;
}

.active>.page-link, .page-link.active {
  background-color: #1976D2 !important;
}

/* .MuiFormHelperText-root {
  color: #1976D2 !important;
} */
  