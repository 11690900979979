.allTickets__main_list {
    width: 100%;
    min-width: 330px;
    padding: 10px;
    box-shadow: 0 0px 3px 0px #f7f7f7;
    border: 1px solid #f7f7f7;
    border-radius: 3px;
    margin-left: 15px;
}

.allTickets__main_list_wrapper {
    padding: 0;
    list-style: none;
    margin-bottom: 0;
    max-height: 80vh;
    overflow: auto;
    padding-right: 4px;
}

li.allTickets__main_list_li {
    display: flex;
    justify-content: space-between;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 6px;
    cursor: pointer;
}

.allTickets__ticket_name {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.allTickets__icon_counter {
    background: #0C69F3;
    color: #fff;
    width: 26px;
    height: 26px;
    position: relative;
    border-radius: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.allTickets__icon_counter span {
    position: absolute;
    top: 0;
    font-size: 7px;
    background: #FF9800;
    width: 10px;
    height: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    top: 0;
    left: 0;
}

.allTickets__name_id {
    font-size: 13px;
    line-height: 1.3;
    padding-left: 5px;
}

.allTickets__date_time {
    font-size: 8px;
    text-align: right;
}

.allTickets__ticket_description {
    font-size: 13px;
    font-weight: 500;
    color: #525252;
  
    -webkit-box-orient: vertical;
    max-width: 95%;
    margin-bottom: 8px;
    text-transform: capitalize;
}
.allTickets__ticket_status {
    display: flex;
    align-items: center;
    gap: 3px;
    margin-bottom: 8px;
}
.allTickets__ticket_status .allTickets__ticket_item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
}
.allTickets__ticket_status .allTickets__ticket_item .title {
    font-family: Poppins;
font-size: 10px;
font-weight: 500;
line-height: 8px;
letter-spacing: 0em;
text-align: center;
color: #6C6C6CB8;
text-transform: uppercase;

}
 .allTickets__ticket_item_tag {
   
    font-size: 10px;
    font-weight: 400;
  text-transform: uppercase;
    padding: 4px;
    background-color: #0C69F3;
    color: white;
    min-width: 60px;
    text-align: center;
    border-radius: 2px;
}

.allTickets__ticket_item_tag.Package.Lost,.allTickets__ticket_item_tag.Lost{
    background-color: #D21C3D;
}
.allTickets__ticket_item_tag.Inquiry {
    background-color: #0C69F3;
}
.allTickets__ticket_item_tag.Damage{
    background-color:#D21C3D;
}

.allTickets__id {
    font-size: 9px;
    color: #454545;
}
.allTickets__ticket_item_tag.Normal{
    background-color: #0C69F3;
}
.allTickets__ticket_item_tag.High {
    background-color:#D21C3D;
}
.allTickets__ticket_item_tag.Medium{
    background-color: #FFB406
}
.allTickets__ticket_item_tag.Open,.allTickets__ticket_item_tag.Dispute {
    background-color: #0C69F3;
}
.allTickets__ticket_item_tag.Resolved {
    background-color: #1CA306;
}
span.allTickets__role {
    color: #0C69F3;
}

.disussionShipping__details_heading span {
    font-size: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: 600;
}
span.allTickets__name {
    color: #000;
}   

.tickets__page_main_wrap {
    display: flex;
    column-gap: 20px;
    max-width: 1340px;
    width: 100%;
    margin: 30px auto;
    justify-content: center;
}

.disussion_main_wrap {
    width: 100%;
    /* max-width: 524px; */
    padding: 15px;
    box-shadow: 0 0px 3px 0px #f7f7f7;
    border: 1px solid lightgrey;
    border-radius: 3px;
}

.disussion__main__header {
    background: #F8F8F8;
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.disussion__header_right_actions {
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
}
.disussion__header_right_actions button {
    border: 1px solid #0C69F3;
    border-radius: 3px;
    padding: 3px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0C69F3;
    font-size: 10px;
    min-width: 70px;
}
/* .disussion__header_right_actions button.close_ticket {
    background-color: #0C69F3;
    color: white;
} */
.disussion__header_left_title {
    font-size: 17px;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #000000B0;
}

.disussion__header_left_title span {
    font-size: 13px;
    color: #0C69F3;
    margin-top: 5px;
}

.disussion__header_left_Id {
    font-size: 9px;
    color: #454545;
}


.disussion__header_left_subject {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    max-width: 95%;
    border-radius: 4px;
}
.disussion__header_right__stautses {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 5px;
    position: relative;
}
.disussion__header_right__stautses button {
    width: 60px;
    padding: 4.5px;
    background-color: white;
    color: #374957;
    border: 1px solid #ccc;
    border-radius: 5px;
  
    font-size: 9px;
}
.disussion__header_right__stautses select {
    width: 80px;
    font-size: 9px;
    text-align: center;
    padding: 4px;
    outline: none;
    background-color: white;
    border-radius: 5px;
    border: none;
    border: 1px solid #ccc;
}
.disussion__header_right_subject.Open {
    color: #fff;
    font-size: 10px;
    background-color: #FFC107;
    text-align: center;
    width: fit-content;
    padding: 1px 9px;
    text-transform: uppercase;
    margin-left: auto;
    border-radius: 4px;
}

.disussion__header_right_contact {
    display: flex;
    align-items: center;
    gap: 10px;
    
}
.disussion__main__header_right_col {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
}

.disussion__main__header_left_col {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
}

.disussion__header_right_email {
    font-size: 11.5px;
    display: flex;
    gap: 4px;
    align-items: center;
}
.disussion__header_right_email span {
    margin-top: 0.5px;
}
.disussion__header_right_email svg {
    color: #0c6efd;
    width: 15px;
    
}
.disussion__header_right_phone {
    font-size: 11.5px;
    display: flex;
    align-items: center;
    gap: 4px;
}
.disussion__header_right_phone span {
    margin-top: 2px;
}
.disussion__header_right_phone svg {
    color: #0c6efd;
    width: 15px;
   
}
.discussion__loader {
    position: fixed;
    background-color: rgba(0,0,0,0.85);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.discussion__loader_spinner {
    border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
.disussion__header_right_subject.Closed {
    color: #fff;
    font-size: 10px;
    background-color: #4CAF50;
    text-align: center;
    width: fit-content;
    padding: 1px 9px;
    text-transform: uppercase;
    margin-left: auto;
    border-radius: 4px;
}
.disussion__header_right_subject {
    color: #fff;
    font-size: 10px;
    background-color: #0C69F3;
    text-align: center;
    width: fit-content;
    padding: 1px 9px;
    text-transform: uppercase;
    margin-left: auto;
    border-radius: 4px;
    margin-bottom: 3px;
}
.allTickets__status {
    color: #fff;
    font-size: 8px;
    background-color: #FFC107;
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    padding: 3px 6px 1px 6px;
    text-transform: uppercase;
    margin-left: auto;
    line-height: 1;
}
.allTickets__status {
    color: #fff;
    font-size: 8px;
    background-color: #FFC107;
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    padding: 3px 6px 1px 6px;
    text-transform: uppercase;
    margin-left: auto;
    line-height: 1;
}

.disussion_shipping_details_box {
    width: 100%;
   
}

.disussion_shipping_details_container {
    display: flex;
    align-items: flex-start;
    column-gap: 20px;
}
.disussionShipping__package_details {
    margin-bottom: 12px;
}
.disussionShipping__package_details table {
    width: 100%;
    margin-top: 10px;
}
.disussionShipping__package_details table th {
    font-size: 7px;
    color: #909090;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    padding: 4px 2px;
}
.disussionShipping__package_details table td {
 
    padding: 4px 1px;
    color: #7E7E7E;
    font-size: 8px;
    font-weight: 500;
    text-transform: capitalize;
    text-align: center;

}
.disussionShipping__package_details table .icon-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;
}
.disussionShipping__package_details table .icon svg{
   background-color: #09BB09;
   color: white;
}
.disussionShipping__package_details table .count {
    border: 1px solid #0C69F3;
    height: 19px;
    width: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 9px;
    color: #0C69F3;
    font-weight: 500;
    background-color: #CBE0FF;

}
.allTickets__shipment_image_modal {
    max-width: 700px;
    width: 100%;
   min-height: 250px;
   height: auto;
    z-index: 55;
    position: relative;
    position: absolute;
    background: white;
    border-radius: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.allTickets__shipment_image_modal img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
}
.allTickets__shipment_image_modal button {
    position: absolute;
    height: 33px;
    width: 33px;
    background-color: #0C69F3;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    right: -10px;
    top: -10px;
    z-index: 44;
}
.allTickets__shipment_modal {
    max-width: 700px;
    width: 100%;
   
   
    z-index: 55;
    min-height: 200px;
    position: absolute;
    background: white;
    border-radius: 15px;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
}
.allTickets__shipment_modal_header {
    border-bottom: 1px solid #ccc;
    padding: 8px;
    display: flex;
    justify-content: flex-end;
}
.allTickets__shipment_modal_header button {
    background-color: #0C69F3;
    padding: 5px;
    width: 33px;
    font-size: 17px;
    height: 33px;
    font-weight: bolder;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
   
    color: white;
   
   

}
.disussionShipping__details_billing_details_wrapper::-webkit-scrollbar {
    width: 5px;
  }
  
  .disussionShipping__details_billing_details_wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #9f9f9f;
    border-radius: 10px;
  }
  .disussionShipping__details_billing_details_wrapper::-webkit-scrollbar-thumb {
    background: #0d6efd;
    border-radius: 10px;
  }
.disussionShipping__details_billing_details_wrapper {
    max-height: 380px;
    overflow-y: auto;
    padding-right: 8px;
    margin-bottom: 10px;
}
.disussionShipping__details_billing_details {
    padding-bottom: 10px;
    
}
.disussionShipping__details_billing_details .shipment_charges_header {
    padding: 4px 7px;
    background-color: #0C69F3;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    color:white;
    margin-bottom: 5px;
}
.shimpent_charges_data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}
.shimpent_charges_data .title {
    font-size: 13px;
    color: #374957;
    font-weight: 400;
    margin: 0px;
    line-height: 1.2;
}
.shimpent_charges_data .value {
    font-size: 13px;
    color:#4CAF50;
    font-weight: 600;
    margin: 0px;
    line-height: 1.2;
}
.shimpent_charges_text_charges .title_main {
    font-size: 14.5px;
    font-weight: 700;
    margin-bottom: 10px;
}

.disussionShipping__package_files_wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
}
.disussionShipping__package_files_wrapper .title {
    color: #706F6F;
    font-size: 13px;
    margin: 0px;
    margin-top: 12px;
    font-weight: 500;
    padding-bottom: 3px;
    width: max-content;
 
}
.allTickets__shipment_modal_body {
    padding: 20px;
}
.allTickets__shipment_modal_body  .data {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}
.allTickets__shipment_modal_body  .data .data-item {
    flex: 0 0 45%;
    display: flex;align-items: center;
    margin-bottom: 15px;
    gap: 15px;

}
.allTickets__shipment_modal_body  .company-info {
    
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 15px auto;
    gap: 12px;
}
.allTickets__shipment_modal_body  .company-info img {
    width: 120px;
    
    height: 120px;
    border-radius: 50%;
    box-shadow: 0 0 3px gray;
    object-fit: contain;
    padding: 12px;
}
.allTickets__shipment_modal_body  .company-info .name {
    color: #6C6C6C;
    font-size: 13px;
    font-weight:800 ;
}
.allTickets__shipment_modal_body  .data .data-item .title {
    font-size: 14px;
    color: #6C6C6C;
    font-size: 600;
    font-family: Poppins;
    margin: 0;
    line-height: 1.3;
    text-transform: uppercase;
}
.allTickets__shipment_modal_body  .data .data-item .value {
    font-size: 14px;
    color: #4CAF50;
    font-family: Poppins;
    font-size: 800;
    text-transform: uppercase;
    margin: 0;
    line-height: 1.3;
}
.disussionShipping__package_files {
    display: flex;
    flex-wrap: wrap;
    gap: 9px;
}
.disussionShipping__package_file_holder {
   width: 66px;
    display: flex;
    justify-content: center;
  
    cursor: pointer;
    flex: 0 0 auto;
   
    border-radius: 5px;
    /* background-color: #ccc; */
}
.disussionShipping__package_file_holder  .file_wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    height: 66px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    position: relative;
}
.disussionShipping__package_file_holder .image_wrapper {
    width: 66px;

    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
}
.disussionShipping__package_file_holder .image_wrapper .icon,.disussionShipping__package_file_holder .file_wrapper .icon {
    position: absolute;
    height: 25px;
    width: 25px;
    top: -5px;
    right: -5px;
    border-radius: 50%;
    color: white;
    font-size: 12px;
    background-color: #0C69F3;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.disussionShipping__package_file_holder .image_wrapper .name {
    font-size: 9px;
    color: #333;
    padding-left: 4px;
}
.disussionShipping__package_file_holder a {
    font-size: 10px;
    padding: 4px;
    color: #0C69F3;
}
.disussionShipping__package_file_holder img {
    width: 100%;
    max-height: 66px;
    height: 66px;
    /* height: 100%; */
    
    object-fit: cover;
    border-radius: 5px;
}
.disussionShipping__package_type {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0px;
    width: 100%;
    margin: 0px;
    margin-bottom: 14px;
    border-bottom:3px solid #D9D9D9;
}

.disussionShipping__package_type li {
   
    color: #6C6C6C;
    padding: 8px 5px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    position: relative;
}
.disussionShipping__package_type li.active::before {
    position: absolute;
    content: '';
    height: 3px;
    width: 100%;
    bottom: -3px;
    background-color: #0C69F3;
}
.disussionShipping__details_main_wrap {
    width: 100%;
    max-width: 325px;
    padding: 15px;
    box-shadow: 0 0px 3px 0px #f7f7f7;
    border: 1px solid #f7f7f7;
    border-radius: 3px;

}

.disussionShipping__details_label {
    font-size: 11px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #374957;
}
.disussionShipping__details_data {
    font-size: 11px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #374957;
}

.disussionShipping__details_tickenum {
    display: flex;
    justify-content: space-between;
    margin-bottom:6px;
}

.disussionShipping__details_shippingId {
    display: flex;
    justify-content: space-between;
    margin-bottom:6px;
}

.disussionShipping__details_order_status {
    display: flex;
    justify-content: space-between;
    margin-bottom:6px;
}

.disussionShipping__details_orderplaced {
    display: flex;
    justify-content: space-between;
    margin-bottom:6px;
}

.disussionShipping__details_master_tracking {
    display: flex;
    justify-content: space-between;
    margin-bottom:6px;
}

.disussionShipping__details_carrier_racking {
    display: flex;
    justify-content: space-between;
    margin-bottom:6px;
}

.disussionShipping__details_heading {
    font-size: 16px;
    color: #555;
    margin-bottom: 10px;
    text-align: center;
}
.disussionShipping__details_tab {
    display: flex;
    justify-content: center;
    padding: 0px;
    margin: 0px;
    margin-bottom:12px;
    border-bottom: 3px solid #D9D9D9;
}
.disussionShipping__details_tab li {
    font-size: 13.5px;
    color: #6C6C6C;
    text-align: center;
    font-weight: 600;
    padding: 8px 3px;
    width: 100%;
    position: relative;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

}
.disussionShipping__details_tab li.active{
    color: #0C69F3;
    transition: all 0.3s ease-in-out;
}
.disussionShipping__details_tab li.active::before {
    position: absolute;
    left: 0px;
    transition: all 0.3s ease-in-out;
    content: '';
    height: 3px;
    width: 100%;
    background-color: #0C69F3;
    bottom: -3px;
}
.allTickets__main_list_wrapper::-webkit-scrollbar {
    width: 5px;
  }
  
  .allTickets__main_list_wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #9f9f9f;
    border-radius: 10px;
  }
  .allTickets__main_list_wrapper::-webkit-scrollbar-thumb {
    background: #0d6efd;
    border-radius: 10px;
  }
  
  .discussionBody__chats_recipient_name_accr {
    background: #0C69F3;
    color: #fff;
    width: 26px;
    height: 26px;
    position: relative;
    border-radius: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin: 0 auto;
}
.discussionBody__chats_image {
    width: 120px;
    height: 90px;
    cursor: pointer;
    z-index: 4;
   

}
.discussionBody__chats_image img {
    height: 90px;
    width: 120px;
    border-radius: 8px;
    object-fit: cover;
 }
 .discussionBody__chats_file {
    font-size: 13px;
    cursor: pointer;
 }

.discussionBody__chats_recipient_name {
    font-size: 10px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 100%;
}

.discussionBody__chats_recipient_name_role {
    text-align: center;
    width: 60px;
    position: absolute;
    left: 0;
}

.discussionBody__chats_recipient_role {
    font-size: 10px;
    color: #FFB406;
    line-height: 1;
}

.discussionBody__chats_box {
    position: relative;
    padding: 20px 0;
}

.discussionBody__chats_recipient_queries {
    padding-left: 70px;
}

.discussionBody__chats_recipient_first_query {
    font-size: 12.5px;
    border: 1px solid #D9D9D9;
    line-height: 1.3;
    padding: 9px;
    width: fit-content;
    border-radius: 0 5px 5px 0;
    border-left: 3px solid #FFB406;
    margin-bottom: 10px;
    max-width: 85%;
}

.discussionBody__chats_recipient_last_query {
    font-size: 14px;
    border: 1px solid #D9D9D9;
    line-height: 1.3;
    padding: 9px;
    width: fit-content;
    border-radius: 0 5px 5px 0;
    border-left: 3px solid #FFB406;
    margin-bottom: 10px;
}

.discussionBody__chats_recipient_query_time {
    font-size: 11px;
    color: #706F6F;
}
.discussionBody__chats_carrier_name_accr {
    background: #0C69F3;
    color: #fff;
    width: 26px;
    height: 26px;
    position: relative;
    border-radius: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin: 0 auto;
    margin-bottom: 3.5px;
}

.discussionBody__chats_carrier_name {
    font-size: 10px;
    overflow: hidden;
    display: -webkit-box;
    margin-bottom: 2px;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 100%;
}

.discussionBody__chats_carrier_name_role {
    text-align: center;
    width: 60px;
    position: absolute;
    right: 0;
}

.discussionBody__chats_carrier_role {
    font-size: 10px;
    color: #EF4A52;
    line-height: 1;
}

.discussionBody__chats_box {
    position: relative;
    padding: 20px 0;
    max-height: 57vh;
    min-height: 57vh;
    overflow: auto;
}
.discussionBody__chats_box::-webkit-scrollbar {
    width: 5px;
  }
  
  .discussionBody__chats_box::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #9f9f9f;
    border-radius: 10px;
  }
  .discussionBody__chats_box::-webkit-scrollbar-thumb {
    background: #0d6efd;
    border-radius: 10px;
  }
.discussionBody__chats_carrier_queries {
    padding-right: 70px;
    margin-top: 20px;
}

.discussionBody__chats_carrier_first_query {
    font-size: 12.5px;
    border: 1px solid #D9D9D9;
    line-height: 1.3;
    padding: 9px;
    width: fit-content;
    border-radius: 5px 0 0 5px;
    border-right: 3px solid #EF4A52;
    margin-bottom: 7px;
    margin-left: auto;
    max-width: 85%;
}

.discussionBody__chats_carrier_last_query {
   font-size: 14px;
    border: 1px solid #D9D9D9;
    line-height: 1.3;
    padding: 9px;
    width: fit-content;
    border-radius: 5px 0 0 5px;
    border-right: 3px solid #EF4A52;
    margin-bottom: 10px;
    margin-left: auto;
}

.discussionBody__chats_carrier_query_time {
    font-size: 10px;
    color: #00000096;
    margin-left: auto;
    width: fit-content;
}
.discussionBody__chats_form_message_box {
    border-bottom: 1px solid #D9D9D9;
    position: relative;
}
.loader__image{
    position: absolute;
    right: 5px;
    width: 40px;
    height: 40px;
    top: 2px;
    z-index: 55;
}
.loader__image .__image {
    max-width: 40px;
}

.loader__image_large{
    position: absolute;
    left: 50%;
    z-index: 55;
    width: 90px;
    height: 90px;
    top: 50%;
    transform: translate(-50%,-50%);
}
.loader__image_large .__image {
    max-width: 90px;
}

.loader__image_img{
    position: absolute;
    left: 50%;
    width: 90px;
    height: 90px;
    z-index: 55;
    top: 50%;
    transform: translate(-50%,-50%);
}
.loader__image_img .__image {
    max-width: 90px;
}
.discussionBody__chats_form_message_box textarea {
    border: none;
    outline: none;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    font-size: 12px;
    color: #5F5E5E;
    max-height: 40px;
}
 
.discussionBody__chats_form{
    border: none;
    display: flex;
    border-radius: 3px;
    align-items: center;
    gap:9px;
}
.discussionBody__chats_form form {
    width: 100%;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
}
.discussionBody__chats_form .account_role_box_main {
    flex: 0 0 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
}
.discussionBody__chats_form .account_role_box_roleText {
    color: #706F6F;
    font-size: 11px;
    line-height: 14px;
}
.discussionBody__chats_form .profile__avatar_image {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: none;
}
.discussionBody__chats_form .account_role_box_firstName {
    font-size: 11px;
    color: #09BB09;
    font-weight: 400;
    line-height: 14px;
}
.discussion__visisble_roles_box {
    display: flex;
    justify-content: space-between;
    column-gap: 7px;
    align-items: center;
    padding: 7px 10px;
}
.discussion__visisble_roles_box .discussion__visisble_roles_box_right {
    display: flex;
    align-items: center;
    gap: 7px;
}
.discussion__visisble_roles_box .discussion__visisble_roles_box_left {
    display: flex;
    align-items: center;
    gap: 4px;
    width: max-content;
    position: relative;
}
.discussion__visisble_roles_box .discussion__visisble_roles_box_left input {

    opacity: 0;
    z-index: 15;
    position: absolute;
    width: 100%;
    height: 100%;
}
.discussion__visisble_roles_box .discussion__visisble_roles_box_left svg {
    color: #0C69F3;
    font-size: 13px;
    cursor: pointer;
}
.discussion__visisble_roles_box label {
    display: inline;
    font-size: 11px;
}

.discussion__visisble_roles_box label input[type="checkbox"] {
    position: relative;
    top: 2px;
    margin-right: 5px;
    width: 10px;
}

.discussion__visisble_roles_box_title {
    font-size: 11px;
    font-weight: 500;
    margin-top: 2px;
}
.discussionBody__chats_form_selected_file {
    height: 80px;
    width: 120px;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
   flex-wrap: wrap;
   word-break: break-all;
   font-size: 12px;
   background-color: #ddd;
   
}
.discussionBody__chats_form_selected_file img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.discussionBody__chats_form_selected_file .icon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    right: -10px;
    top: -10px;
    cursor: pointer;
    z-index: 5;
    border-radius: 50%;
    background-color: #0C69F3;
    color: white;
}
button.discussionBody__chats_form_submit {
    height: 23px;
    border: 1px solid #0C69F3;
    color: #0C69F3;
    font-size: 11px;
    font-weight: 600;
    padding: 0 13px;
    border-radius: 3px;
    margin-left: 10px;
    line-height: 2.1;
}

.allTickets__name_Ids {
    display: flex;
    align-items: center;
    gap: 8px;
}
.allTickets__main_list_right_left {
    display: flex;
    gap: 4px;
}
.allTickets__main_list_right_left .title {
    font-size: 10px;
    font-weight: 400;
    color: #374957;
}
.allTickets__main_list_right_left .value {
    font-size: 10px;
    font-weight: 600;
    color: #374957;
}
li.allTickets__main_list_li.selected {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: gainsboro;
}
.discussionBody__chats_recipient ,.discussionBody__chats_carrier {
    margin-bottom: 20px;
}


.disussion_main_wrap {
    position: relative;
}


.pagination__forList_main_wrap {
    display: flex;
    justify-content: center;
    height: 30px;
    align-items: center;
    font-size: 13px;
}

.pagination__forList_main_Icon {
    border: 1px solid #868686;
    width: 20px;
    height: 20px;
    margin: 0 8px;
    color: #868686;
}


button.disable__btn_arrow .pagination__forList_main_Icon {
    color: #D9D9D9;
    border: 1px solid #D9D9D9;
    cursor: not-allowed;
}
.discussion_default_loader {
    position: absolute;
    width: 100%;
    z-index: 9999;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffffeb;
}

.discussion_default_loader img.discussion_default_loader_image {
    width: 150px;
    height: 150px;
}.allTickets__main_list_right_col {
    min-width: 87px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
}
.allTickets__main_list_right_item_wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: flex-end;
}
.allTickets__main_list_right_title {
    
font-size: 9px;
font-weight: 400;
line-height: 1;

letter-spacing: 0em;
color: #454545;
text-transform: uppercase;

}
.allTickets__main_list_right_value {
    
    font-size: 11px;
    font-weight: 400;
    line-height: 1;
    
    letter-spacing: 0em;
    color: #444;
    text-transform: uppercase;
    
    }
.High.allTickets__date_time:before {
    content: '';
    width: 6px;
    height: 6px;
    display: inline-block;
    background: #ef4a52;
    border-radius: 6px;
    margin-right:4px;
}

.Low.allTickets__date_time:before {
    content: '';
    width: 6px;
    height: 6px;
    display: inline-block;
    background: #ffc107;
    border-radius: 6px;
    margin-right:4px;
}

.Medium.allTickets__date_time:before {
    content: '';
    width: 6px;
    height: 6px;
    display: inline-block;
    background: #ffc107;
    border-radius: 6px;
    margin-right:4px;
}
.allTickets__main_list_title {
    font-family: Poppins;
font-size: 13px;
font-weight: 600;
line-height: 20px;
letter-spacing: 0em;
margin-bottom: 10px;
color: #000;
}
.allTickets__status_filter {
    padding: 0px;
    margin: 0px;
    display: flex;
    height: 50px;
    border-radius: 5px;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10px;
    border: 0.88px solid #D9D9D9;
}

.allTickets__main_list_search {
    border: 0.88px solid #D9D9D9;
    
    position: relative;
    margin-bottom: 10px;
    border-radius: 5px;
    height: 40px;
    overflow: hidden;
}
.allTickets__main_list_search  svg {
    font-size: 22px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #9F9A9A;
    left: 12px;
}
.allTickets__main_list_search input {
    width: 100%;
    border: none;
    outline: none;
    padding: 10px;
    font-size: 14px;
    height: 40px;
    padding-left: 40px;
}
.allTickets__status_filter li {
    cursor: pointer;
    font-size: 12px;
    position: relative;
    min-width: 40px;
    
    color: #6C6C6C;
    font-weight: 600;
    padding: 10px;
    border-bottom: 2px solid transparent;

}
.allTickets__status_filter li.active {
    border-bottom: 2px solid #0C69F3;
    color: #0C69F3;
}
.allTickets__status_filter li .count {
    position: absolute;
    top: 0px;
   
    right: -5px;
    height: 15px;
    width: 15px;
    font-weight: 300;
    border-radius: 50%;
    display: flex;
    background-color: #6C6C6C;
    color: white;
    font-size: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.allTickets__status_filter li.active .count {
    background-color: #0C69F3;
}
.allTickets__shipment_logo img {
    width: 100%;
    margin:0px;
}

.allTickets__shipment_logo {
    max-width: 40px;
    margin-left: auto;
}
@media only screen and (min-width: 768px) and (max-width: 1170px) {
    .disussion_main_wrap {
        width: 100%;
        max-width: 100%;
        order: 2;
        margin-top: 15px;
    }
    
    .disussion_shipping_details_container {
        flex-wrap: wrap;
    }
    
    .disussionShipping__details_main_wrap {
        max-width: 100%;
    }
    
    
    .disussionShipping__details_order_details {
        display: flex;
        flex-wrap: wrap;
        column-gap: 30px;
    }
   
    .disussionShipping__details_label {padding-right: 15px;font-weight: 600;}
    
    .disussionShipping__details_carrier_racking,.disussionShipping__details_tickenum, .disussionShipping__details_shippingId, .disussionShipping__details_order_status, .disussionShipping__details_orderplaced, .disussionShipping__details_master_tracking {
        width: 28%;
    }
    
    .allTickets__main_list {
        max-width: 550px;
    }
}

@media only screen and (max-width: 767px) {

.tickets__page_main_wrap {
    flex-wrap: wrap;
    justify-content: center;
}

.disussion_shipping_details_container {
    flex-wrap: wrap;
    justify-content: center;
}

.disussion_main_wrap {
    order: 2;
}

.disussionShipping__details_main_wrap {
    margin: 0 auto;
}
.allTickets__main_list {
    max-width: 100%;
}
.disussion__header_left_title {
    font-size: 15px;
}

.disussion__header_left_subject {
    font-size: 13px;
}

.disussion__header_right_email {
    font-size: 9px;
}

.disussion__header_right_phone {
    font-size: 9px;
}

.discussionBody__chats_recipient_first_query {
    font-size: 12px;
}

.discussionBody__chats_recipient_query_time {
    font-size: 8px;
}

.discussionBody__chats_carrier_first_query {  font-size: 12px;}

.discussionBody__chats_carrier_query_time {font-size: 8px;}

}
