.shipper__stepepr_wrap span span {
    font-size: 11px;
}

.shipper__step_num_count {
    width: 20px;
    height: 20px;
    padding: 0 !important;
    font-size: 12px;
    text-align: center;
    line-height: 1.7;
}
.mainStepper__wrapper .MuiStep-root .MuiStepConnector-line {
    border-top-style: dashed;
}
.shadow.shipper__step_num_count {
    border-radius: 100%;
}
@media only screen and (min-width: 1190px) {

    .shipper__stepepr_wrap span span{font-size:0.805vw ;}
    .shipper__step_num_count{font-size: 0.878vw;width: 1.464vw;height: 1.464vw;}
}