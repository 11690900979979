.grndetails__modal {
  min-height: 250px;
  max-width: 920px;
  width: 90%;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 20px;
}

.grndetails__modalheading {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid lightgray;
  font-weight: 500;
  font-size: 12px;
  padding-bottom: 5px;
}

.grndetails__modal_top_wrap {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
}

.grndetails__modal__shipment_summary {
  width: 100%;
  max-width: fit-content;
  min-width: 50%;
}

.grndetails__modal_modal_wrap {
  font-weight: 400 !important;
}

.grndetails__modal_sumitemhding {
  margin: 0px 0 4px 0;
  font-size: 11px;
  background: #dcdddf;
  margin-right: 2px;
  padding: 2px 7px;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .grndetails__modal.detail__modal_grn_view {
    padding: 17px 0 !important;
  }
  .proddetails__desc_modal.detail__modal_order_view .add__adj_byform_modalcontent {
    padding: 0 15px;
}

table.stockreciveform__table.stockreciveform__detail_table td.stockreciveform__tableitem {
    border: none !important;
}
}

@media only screen and (min-width: 1170px) {
  .grndetails__modal.detail__modal_grn_view {
    max-width: 67.35vw;
  }
  .grndetails__modal.detail__modal_grn_view .grndetails__modalheading {
    font-size: 0.878vw;
  }
  .proddetails__desc_modal.detail__modal_order_view.grn__modal_block{
    max-width: 85.35vw;
  }

  .proddetails__desc_modal.detail__modal_order_view.grn__modal_block .stockreciveform__table.stockreciveform__detail_table {
    width: 100%;
}

.proddetails__desc_modal.detail__modal_order_view.grn__modal_block .add__adj_byform_modalcontent {
    padding: 0 15px;
}
table.stockreciveform__table.stockreciveform__detail_table {
  width: 100%;
}
.stockreciveform__table_wrapper table.stockreciveform__table.stockreciveform__detail_table td.stockreciveform__tableitem.stockreciveform__shown_mobile.stockreciveform__img_wrap {
  width: 60px;
}
.stockreciveform__table_wrapper table.stockreciveform__table.stockreciveform__detail_table td.stockreciveform__tableitem.font_size.stockreciveform__shown_mobile.stockreciveform__name_wrap {
  width: 130px;
}

.stockreciveform__table_wrapper table.stockreciveform__table.stockreciveform__detail_table td.stockreciveform__tableitem.BsTrash_td {
  justify-content: center;
}
.stock_received_section_screen .editable__table_container {
  max-height: 650px;
}
}

