.admnblog__details_modal {
  min-height: 250px;
  max-height: 95vh;
  overflow: auto;
  min-width: 310px;
  width: 1150px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24px;
  border: none;
  outline: none;
  padding: 30px;
}
.admnblog__details_modal::-webkit-scrollbar {
  width: 5px;
}

.admnblog__details_modal::-webkit-scrollbar-thumb {
  background-color: #0c69f3;
}

.admnblog__details_modal::-webkit-scrollbar-track {
  background-color: #dbd9d9;
}
.admnblog__details_modalheader {
  width: 100%;
  min-height: 250px;
  display: flex;
  font-weight: 500;
  font-size: 18px;
  flex-wrap: wrap;
}
.admnblog__details_modalheaderlft {
  width: fit-content;
}
.admnblog__details_modalheaderrt {
  flex: 1;
  padding: 0 15px;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.admnblog__details_modalcontent {
  padding: 20px 0 10px 0;
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
}

.admnblog__details_modalcontent::-webkit-scrollbar {
  width: 5px;
}

.admnblog__details_modalcontent::-webkit-scrollbar-thumb {
  background-color: #0c69f3;
}

.admnblog__details_modalcontent::-webkit-scrollbar-track {
  background-color: #dbd9d9;
}
.admnblog__details_modalheaderimg {
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-radius: 5px;
  overflow: hidden;
}
.admnblog__details_modalheaderimg > img {
  width: 100%;
  object-fit: cover;
}
.admnblog__details_modalhdrrtslug {
  color: rgb(197, 196, 196);
  margin: 5px 0 15px 0;
}
.admnblog__details_modalhdrrtdate {
  background-color: rgba(233, 233, 233, 0.719);
  color: grey;
  border-radius: 2px;
  font-size: 12px;
  padding: 3px 8px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.admnblog__details_modalhdrrtdate svg {
  margin-right: 3px;
}
.admnblog__details_modalhdrrtdates {
  display: flex;
  margin: 6px 0;
}

@media (max-width: 1200px) {
  .admnblog__details_modal {
    max-width: 95%;
    height: auto;
  }
}
@media (max-width: 1000px) {
  .admnblog__details_modalheader {
    flex-direction: column;
  }
  .admnblog__details_modalheaderlft {
    width: 100%;
  }
  .admnblog__details_modalheaderrt {
    width: 100%;
  }
}
