.ticketCreate__modal_wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 520px;
    background-color: white;
    border-radius: 5px;
    color: black;
    font-family: "Arial";
    margin-top: 20px;
    margin-bottom: 20px;
    max-height: 90vh;
    overflow: hidden;
    padding: 30px;
}   

.ticketCreate__modal_heading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 0.5px solid lightgray;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 10px;
}
.ticketModal__form_field label.add__package_profimagelbl {
    margin-top: 24px;
    height: 35px;
    border-radius: 5px;
    font-size: 12px;
    outline: 1px solid #dedede;
}

.ticketModal__form_field label.add__package_profimagelbl {
    color: #ccc !important;
}
.ticketModal__form_field label.add__package_profimagelbl.imageuploaded {color: #454545 !important;}
