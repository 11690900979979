.adcomtable__company_logo {
  max-width: 120px;
  max-height: 40px;
  object-fit: contain;
}
.companies_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: grey;
  margin: 20px 5px 0px 5px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid lightgrey;
}

.companies_heading {
  font-weight: 400 !important;
  font-size: 22px;
}
.companies_text_blur {
  filter: blur(3px);
}
.companies_text_blur:hover {
  filter: none;
}
