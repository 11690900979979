.payViaCard_main_wrap {
    display: flex;
    flex-wrap: wrap;
    column-gap: 11px;
}

.card__main_box_wrap {
    width: 100%;
    max-width: 235px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 21px 13px 13px 20px;
    position: relative;
    min-height: 83px;
    margin-bottom: 12px;
}


.card__main_box_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.card__main_box_header h2 {
    font-size: 12px;
}

.card__main_box_header strong {
    font-size: 10px;
    text-align: center;
    color: #000;
    border-radius: 2px;
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.credit__card_del_btn {
    width: 15px;
    position: absolute;
    top: 3px;
    right: 10px;
    color: #F44336;
    cursor: pointer;
}

.credit__card_del_btn path:last-child {
    stroke: #ffffff;
    stroke-width: 0.4px;
}
.MuiBackdrop-root.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
    opacity: 0.35 !important;
}
input.card__main_box_radio {
    position: absolute;
    top: 7px;
    left: 7px;
    width: 10px;
    cursor: pointer;
}

.card__main_box_default_tag {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 9px;
    background: #000;
    color: #fff;
    padding: 2px 8px;
    letter-spacing: 0.8px;

}

.card__main_box_cardNum {
    font-size: 12px;
}

.newCard__main_wrapper {
    display: inline-flex;
    align-items: flex-end;
    padding-bottom: 5px;
    font-size: 14px;
    width: 100%;
    align-items: baseline;
}

.newCard__main_wrapper input {
    margin-bottom: 5px;
    width: 10px;
    cursor: pointer;
}
.payment__stripe_main{
    /* background: #f1f1f1; */
    padding: 19px 19px 1px 19px;
    border-radius: 5px;
    /* box-shadow: 0px 0px 50px 0px #b9b9b994; */
    border: 1px solid #e5e5e5;
    max-width: 735px;
    margin: 0 auto;
    margin-bottom: 20px;
}
input[type="radio"]{cursor: pointer;}
.footer__disclamer_wrap {
    font-size: 11px;
}
.footer__disclamer_wrap a{color: var(--bs-modal-color);}
.footer__disclamer_wrap a:hover{color: #000;}
@media screen and (min-width: 768px) and (max-width: 992px) {
    .card__main_box_wrap{
        max-width: 46%;
    }
    .payment__stripe_main {
        max-width: 94%;
    }
}

@media screen and (max-width: 767px) {
    .card__main_box_wrap {
        width: 100%;
        max-width: 210px;
    }
}

@media screen and (max-width: 497px) {
    .card__main_box_wrap {
        max-width: 44%;
        padding: 7px  10px 30px 22px;
        min-height: 74px;
    }
    
    .card__main_box_header h2 {
        margin: 0;
    }
    
    .card__main_box_default_tag {
        bottom: 0;
        left: 0;
        top: auto;
        right: auto;
    }
    .fade.modal.show {
        padding-left: 0 !important;
    }
}

@media screen and (min-width: 1190px) {
    .modal__shipment_confirmation .modal-title {
        font-size: 1.457vw;
    }
    
    .modal__shipment_confirmation .modal-dialog {
        --bs-modal-width: 58.565vw;
    }
    .modal__shipment_confirmation_sec_title {
        font-size: 0.78vw;
    }
    .modal__shipment_confirmation_list_title b {
        font-size: 0.85vw;
    }
    
    .modal__shipment_confirmation_list_detail {
        font-size: 0.75vw;
    }
    .button__shipment_confirmation_cancel {
        font-size: 35px;
    }
    
    .button__shipment_confirmation_wrap button {
        font-size: 0.78vw;
    }
    .modal__shipment_confirmation_disclamer_wrap{
        font-size: 0.705vw !important;
    }
    .modal__shipment_confirmation_sec_title input[type="radio"] {
        width: 0.878vw;
        height: 0.878vw;
    }
    .payViaCard_main_wrap .card__main_box_wrap {
        max-width: 17.204vw;
        padding: 1.537vw 0.952vw 0.952vw 1.464vw;
    }
    
    .payViaCard_main_wrap .card__main_box_wrap input.card__main_box_radio {
        width: 0.732vw;
        height: 0.732vw;
    }
    
    .card__main_box_header h2 {
        font-size: 0.778vw;
    }
    
    .card__main_box_header strong {
        font-size: 0.732vw;
    }
    
    .card__main_box_cardNum {
        font-size: 0.778vw;
    }
    
    .card__main_box_default_tag {
        font-size: 0.659vw;
    }
    
    .newCard__main_wrapper {
        font-size: 0.9vw;
    }
    
    .newCard__main_wrapper input[type="radio"] {
        width: 0.732vw;
        height: 0.732vw;
    }
    
    form#payment-form.payment__stripe_main {
        margin: 0;
        padding: 0;
        min-height: 2.05vw;
        max-width: 35.505vw;
        margin-left: 1.098vw;
        margin-bottom: 0.366vw;
    }
}